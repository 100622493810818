<template>
    <div style="width: 30px">
        <b-input type="color" style="border: none" v-model="primaryColor" @change="changePrimaryColor" size="sm" />
    </div>
</template>
<script>
export default {
    name: 'PrimaryColorCtrl',
    data() {
        return {
            primaryColor: localStorage.getItem('primary-color') ? localStorage.getItem('primary-color') : this.$store.state.settings.PRIMARY_COLOR,
        };
    },
    computed: {
        storePrimaryColor() {
            return this.$store.state.settings.PRIMARY_COLOR;
        },
    },
    watch: {
        storePrimaryColor: function (value) {
            if (!this.$store.state.settings.PRIMARY_COLOR_CHANGE_ALLOWED) this.primaryColor = value;
            else if (!localStorage.getItem('primary-color')) this.primaryColor = value;
        },
    },
    mounted() {
        if (this.$store.state.settings.PRIMARY_COLOR_CHANGE_ALLOWED) {
            document.documentElement.style.setProperty('--primary', this.primaryColor);
            document.documentElement.style.setProperty('--primary-button-font-color', this.$ProdJsTools.contrastColor(this.primaryColor));
        } else {
            document.documentElement.style.setProperty('--primary', this.$store.state.settings.PRIMARY_COLOR);
            document.documentElement.style.setProperty('--primary-button-font-color', this.$ProdJsTools.contrastColor(this.$store.state.settings.PRIMARY_COLOR));
        }
    },
    methods: {
        changePrimaryColor() {
            document.documentElement.style.setProperty('--primary', this.primaryColor);
            localStorage.setItem('primary-color', this.primaryColor);
            document.documentElement.style.setProperty('--primary-button-font-color', this.$ProdJsTools.contrastColor(this.primaryColor));
        },
    },
};
</script>
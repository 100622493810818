<template>
    <div>
        <b-tab>
            <template #title>{{$t('actions.counting.title')}}</template>
            <b-card>
                <b-form>
                    <b-row>
                        <b-col cols="6">
                            <b-form-group :label="$t('actions.counting.operation') + ' *'" label-for="operation">
                                <b-form-select id="operation" v-model="Operation" :options="availableOperations" :class="{'disabled': !$store.state.auth.user.permissions.PermissionWrite}"/>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <hr color="grey"/>
                    <b-row>
                        <b-col cols="4">
                            <b-form-group :label="$t('actions.counting.sourceGroup') + ' *'" label-for="sourceGroup">
                                <b-form-select id="sourceGroup" v-model="SourceGroup" :options="availableGroups" v-on:change="sourceGroupChanged" :class="{'disabled': !$store.state.auth.user.permissions.PermissionWrite}"/>
                            </b-form-group>
                        </b-col>
                        <b-col cols="4">
                            <b-form-group :label="$t('actions.counting.sourceItem') + ' *'" label-for="sourceItem">
                                <b-form-select id="sourceItem" v-model="SourceItem" :options="availableSourceItems" :class="{'disabled': !$store.state.auth.user.permissions.PermissionWrite}"/>
                            </b-form-group>
                        </b-col>
                        <b-col cols="4">
                            <b-form-group :label="$t('actions.counting.unit') + ' *'" label-for="unit">
                                <b-form-select id="unit" v-model="Unit" :options="availableUnits" :class="{'disabled': !$store.state.auth.user.permissions.PermissionWrite}"/>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <hr color="grey"/>
                    <b-row>
                        <b-col cols="6">
                            <b-form-group :label="$t('actions.counting.resultGroup') + ' *'" label-for="resultGroup">
                                <b-form-select id="resultGroup" v-model="ResultGroup" :options="availableGroups" v-on:change="resultGroupChanged" :class="{'disabled': !$store.state.auth.user.permissions.PermissionWrite}"/>
                            </b-form-group>
                        </b-col>
                        <b-col cols="6">
                            <b-form-group :label="$t('actions.counting.resultItem') + ' *'" label-for="resultItem">
                                <b-form-select id="resultItem" v-model="ResultItem" :options="availableResultItems" :class="{'disabled': !$store.state.auth.user.permissions.PermissionWrite}"/>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </b-form>
            </b-card>
        </b-tab>
    </div>
</template>

<script>
import ToastAlert from '@/utils/ToastAlert';
import ProdComEquipmentService from "@/services/prodcom.equipments.service.js";
export default {
    name: "Comptage",
    props:{
        /**
         * Action object from parent component. Contains the equipment concerned,
         * and details about the action if opening an existing action.
         */
        action: Object,
        /**
         * Current project's Guid
         */
        propProject: String,
    },
    async mounted(){
        if(!this.$store.state.auth.user.permissions.PermissionRead) return;
        // Get groups
        var data = await ProdComEquipmentService.getGroups(this.action.Equipment, this.propProject);
        if(data.success == "n") this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(data.ret), 'warning'));
        else {
            this.availableGroups = data.ret.List;
            this.SourceGroup = this.action.ActionConfiguration?.SourceGroup == null ? this.availableGroups[0] : this.action.ActionConfiguration?.SourceGroup;
            this.ResultGroup = this.action.ActionConfiguration?.ResultGroup == null ? this.availableGroups[0] : this.action.ActionConfiguration?.ResultGroup;
            await this.sourceGroupChanged();
            this.SourceItem = this.action.ActionConfiguration?.SourceItem == null ? this.availableSourceItems[0] : this.action.ActionConfiguration?.SourceItem;
            await this.resultGroupChanged();
            this.ResultItem = this.action.ActionConfiguration?.ResultItem == null ? this.availableSourceItems[0] : this.action.ActionConfiguration?.ResultItem;
            this.Operation = this.action.ActionConfiguration?.Operation == null ? this.availableOperations[0].value : this.action.ActionConfiguration?.Operation;
            this.Unit = this.action.ActionConfiguration?.Unit == null ? this.availableUnits[0].value : this.action.ActionConfiguration?.Unit;
        }
    },
    data(){
        return {
            Operation: 0,
            Unit: 0,
            SourceGroup: '',
            SourceItem: '',
            ResultGroup: '',
            ResultItem: '',
            availableOperations: [
                {text: this.$t('actions.counting.operations.delta'), value: 0},
                {text: this.$t('actions.counting.operations.add'), value: 1},
                {text: this.$t('actions.counting.operations.cadence'), value: 2},
                {text: this.$t('actions.counting.operations.remove'), value: 3},
            ],
            availableUnits: [
                {text: 's', value: 0},
                {text: 'mn', value: 1},
                {text: 'h', value: 2},
            ],
            availableGroups: [],
            availableSourceItems: [],
            availableResultItems: [],
        }
    },
    methods:{
        /**
         * Called by parent when validating the configuration.
         * Needs to return the current configuration of the action. 
         */
        async validate(){
            var result = {
                Operation: this.Operation,
                Unit: this.Unit,
                SourceGroup: this.SourceGroup,
                SourceItem: this.SourceItem,
                ResultGroup: this.ResultGroup,
                ResultItem: this.ResultItem,
            }
            return result;
        },
        async sourceGroupChanged(e){
            if(!this.$store.state.auth.user.permissions.PermissionRead) return;
            const result = await ProdComEquipmentService.getItems(this.action.Equipment, this.SourceGroup, this.propProject);
            if(result.success == "n") this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(result.ret, result.retParams), 'warning'));
            else{
                this.availableSourceItems = [];
                for(const [key, val] of Object.entries(result.ret.List)){
                    this.availableSourceItems.push(val.Name);
                }
            }
        },
        async resultGroupChanged(e){
            if(!this.$store.state.auth.user.permissions.PermissionRead) return;
            const result = await ProdComEquipmentService.getItems(this.action.Equipment, this.ResultGroup, this.propProject);
            if(result.success == "n") this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(result.ret, result.retParams), 'warning'));
            else{
                this.availableResultItems = [];
                for(const [key, val] of Object.entries(result.ret.List)){
                    this.availableResultItems.push(val.Name);
                }
            }
        },
    }
}
</script>
<template>

    <b-tabs pills card h-100 d-flex flex-column style="width: 100%">
        <b-tab>
           <template #title>
                <span class="tabHeaderText">{{ $t('Configuration') }}</span>
           </template>

           <div class="tabContent" >
                 <b-row>
                    <b-col cols="6">
                        <b-form-group :label="$t('equipment.EluCloud.RefreshDelay') + ' *'" label-for="refreshDelay">
                            <b-form-input
                                @change="
                                    (value) => {
                                        if (value > 3600000) {
                                            this.refreshDelay = 3600000;
                                        } else if (value < 1000) {
                                            this.refreshDelay = 1000;
                                        }
                                    }
                                "
                                type="number"
                                min="500"
                                max="3600000"
                                id="refreshDelay"
                                v-model="refreshDelay"
                                :class="{ disabled: !this.$store.state.auth.user.permissions.PermissionWrite }"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col cols="6">
                        <b-form-checkbox v-model="isStockMag"  switch style="margin-top: 40px" :class="{ disabled: !this.$store.state.auth.user.permissions.PermissionWrite }">
                            {{ $t('equipment.stock.mag') }}
                        </b-form-checkbox>
                        <b-form-checkbox v-model="isStockProd"  switch style="margin-top: 40px" :class="{ disabled: !this.$store.state.auth.user.permissions.PermissionWrite }">
                            {{ $t('equipment.stock.prod') }}
                        </b-form-checkbox>
                        <b-form-checkbox v-model="isHD"  switch style="margin-top: 40px" :class="{ disabled: !this.$store.state.auth.user.permissions.PermissionWrite }">
                            {{ $t('equipment.stock.HD') }}
                        </b-form-checkbox>
                    </b-col>
                </b-row>
           </div>
        </b-tab>
        <b-tab>
            <template #title>
                <span class="tabHeaderText">{{ $t('Data Source') }}</span>
            </template>
            <DataSource :onEntityChange="onEntityChange" :datasource="dataSource" ref="managerDataSource" />
            <div class="tabContent" v-if="dataSource.Provider != 'ProductysManager'">
                <b-form class="triggerForm">
                    <b-row style="margin-top: 5px">
                        <b-col cols="4">
                            <b-form-group :label="$t('Entity')" label-for="entity">
                                <b-form-input id="pkEntity" type="number" v-model="pkEntity" min="0" max="999999999" />
                            </b-form-group>
                        </b-col>
                    </b-row>
                </b-form>
            </div>
        </b-tab>
    </b-tabs>
</template>

<script>
import { uuid } from 'vue-uuid';
import ToastAlert from '@/utils/ToastAlert';

import DataSource from '@/components/configuration/DataSources/DataSource.vue';
import ProdComEquipmentService from '@/services/prodcom.equipments.service.js';
import ItemConfiguration from '@/components/configuration/Equipments/ItemConfiguration.vue';

export default {
    name: 'Stock',
    components : {
        DataSource,
        ItemConfiguration
    },
    props: {
        equipment: Object,
        project: String,
        updateMode: Boolean,
    },
    data() {
        return {
            isStockProd : this.equipment?.includeProdStock ?? false,
            isStockMag : this.equipment?.includeStock ?? true,
            isHD : this.equipment?.includeHD ?? false,
            connectedToManager: 0,
            pkEntity: this.equipment?.Entity ?? 1,
            dataSource: { type: 'datasource', ProjectId: this.project, Provider: 'PostgreSQL', EntityList: [] },
            loadingConnectionCheck: false,
            refreshDelay: this.equipment?.refreshDelay ?? 60000,
            itemColumns: [
                { key: 'Name', dataField: 'Name', dataType: 'string', caption: this.$t('Name') },
                { key: 'Type', dataField: 'Type', dataType: 'string', caption: this.$t('Type') },
            ],
            groups: this.equipment?.Groups ?? [{ id: uuid.v4(), Name: `${this.$t('Group')} 1`, Active: true, RefreshRate: 500, Items: [] }],
            groupConfiguration: {
                rows: [
                    {
                        columns: [{ text: this.$t('Refresh rate'), variable: 'RefreshRate', inputType: 'number', cols: '6', min: '100', max: '99999', defaultValue: 500 }],
                    },
                ],
            },
        };
    },
    async mounted() {
    },
    methods: {
        async saveEquipmentCommunication() {
            //await this.$refs.itemConfiguration.saveEquipmentCommunication();
        },
        /**
         * Synchronizes the data changes from the wizard into the given equipment. This method should be called before updateing / saving / adding
         * the equipment in the parent control.
         * @public This method is public.
         */
        async synchronizeEquipment() {
            this.equipment.Groups = this.groups;
            this.equipment.refreshDelay = this.refreshDelay;
            this.equipment.Entity = this.dataSource.type == 'datasource_manager' ? this.dataSource.Entity : this.pkEntity;
            this.equipment.includeProdStock = this.isStockProd;
            this.equipment.includeStock = this.isStockMag;
            this.equipment.includeHD = this.isHD;
            if (this.dataSource.type == 'datasource_manager') {
                // data source obtained from Productys Manager
                var config = await this.$refs['managerDataSource']?.getConfiguration();
                this.equipment.DataSourceManager = config;
                this.equipment.DataSourceManager.Name = 'Productys Manager';
                this.equipment.DataSourceManager.Id = uuid.v4();
                this.equipment.Entity = config.Entity;
            } else if (this.dataSource.type == 'datasource') {
                // data source obtained from this project's configigured data source
                var templateId = this.$refs['managerDataSource']?.getTemplateId();

                this.equipment.DataSource = templateId;
            }
        },
        async refreshVariablesCounter(searchingForItems, variableCounter) {},
        /**
         * Checks if the given connection parameters result in a valid connection.
         */
        validate() {
            return {
                refreshDelay: this.refreshDelay,
                Name: this.Name,
                DatabaseId: this.equipment.DataSource,
                pkEntity: this.pkEntity,
                includeStock: this.isStockMag,
                includeProdStock : this.isStockProd,
                includeHD : this.isHD
            };
        },
        async onEntityChange(e) {
           
        },
    },
    
};
</script>
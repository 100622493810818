import utils from "@/utils";

class ProdComDataSourceService {
    async getDataSourceProviders(){
        return await utils.PostQuery('dataSource.GetDataSourceProviders');
    }
    
    async getDataSourceList(projectId){
        return await utils.PostQuery('dataSource.GetDataSourceList', { Id: projectId });
    }
    
    async hasDataSource(equipmentName, projectId){
        return await utils.PostQuery('dataSource.HasDataSource', 
        {
            EquipmentName: equipmentName,
            Id: projectId,
        });
    }

    async getDataSourceTemplate(dataSourceId, projectId){
        return await utils.PostQuery('dataSource.GetDataSourceTemplate', 
        {
            Id: dataSourceId,
            ProjectId: projectId
        });
    }

    async getDataSource(equipmentName, projectId){
        return await utils.PostQuery('dataSource.GetDataSource', 
        {
            EquipmentName: equipmentName,
            Id: projectId
        });
    }

    async getDataSourceTables(equipmentName, projectId){
        return await utils.PostQuery('dataSource.GetTables', 
        {
            EquipmentName: equipmentName,
            Id: projectId
        });
    }

    async getDataSourceTableFields(equipmentName, tableName, projectId){
        return await utils.PostQuery('dataSource.GetTableFields', 
        {
            EquipmentName: equipmentName,
            TableName: tableName,
            Id: projectId
        });
    }

    async checkConnection(dataSource){
        return await utils.PostQuery('dataSource.CheckConnection', dataSource);
    }

    async saveTemplateConnection(dataSource){
        return await utils.PostQuery('dataSource.SaveTemplateConnection', dataSource);
    }

    async deleteTemplateConnection(dataSourceId, projectId){
        return await utils.PostQuery('dataSource.DeleteTemplate', 
        {
            Id: dataSourceId,
            ProjectId: projectId
        });
    }

    async createDataSource(dataSource){
        return await utils.PostQuery('dataSource.CreateDataSource', dataSource);
    }

    async getDataSourceIdFromEquipment(equipmentName, projectId){
        return await utils.PostQuery('dataSource.GetDataSourceIdFromEquipment', 
        {
            EquipmentName: equipmentName,
            Id: projectId
        });
    }

    async setEquipmentDataSource(equipmentName, dataSourceId, projectId){
        return await utils.PostQuery('dataSource.SetEquipmentDataSource', 
        {
            EquipmentName: equipmentName,
            DataSourceId: dataSourceId,
            Id: projectId,
        });
    }

    async checkProductysManagerDataSource(server, port, username, password, databaseName, entity){
        return await utils.PostQuery('dataSource.CheckProductysManagerDataSource', 
        {
            Server: server,
            Port: port,
            Username: username,
            Password: password,
            DatabaseName: databaseName,
            Entity: entity
        });
    }

    async getQueries(equipmentName, projectId){
        return await utils.PostQuery('dataSource.GetQueries', 
        {
            EquipmentName: equipmentName,
            Id: projectId
        });
    }

    async getQueryParameters(equipmentName, projectId, queryName){
        return await utils.PostQuery('dataSource.GetQueryParameters', 
        {
            EquipmentName: equipmentName,
            Id: projectId,
            QueryName: queryName,
        });
    }

    async getRows(equipmentName, projectId, tableName, skip, take, top, columns, sorting){
        return await utils.PostQuery('dataSource.GetRecords', 
        {
            EquipmentName: equipmentName,
            Id: projectId,
            TableName: tableName,
            Skip: skip,
            Top: top,
            Take: take,
            Sorting: sorting,
            Columns: columns,
        });
    }

    async getMonitoringTableValues(variables) {
        return await utils.PostQuery('dataSource.GetMonitoringTableValues', 
        {
            Variables: variables,
        });
    }
    async setDataSourceToAllEq(projectId,dataSourceId) {
        return await utils.PostQuery('dataSource.SetDatasourceToAllEquipments',
            {
                Id: projectId,
                DataSourceId: dataSourceId
            });
    }
}

export default new ProdComDataSourceService();
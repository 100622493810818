import store from "@/store/index.js";

class DatabaseService {

    async getAdminConnectionStrings() {
        const settings = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                Token: store.state.auth.user.accessToken,
                Method: 'databases.GetAdminConnectionStrings',
                Parameters: {},
            }),
        };

        const res = await fetch(store.state.settings.API_URL, settings);
        const data = await res.json();
        return data;
    }

    async getConnectionStrings() {
        const settings = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                Token: store.state.auth.user.accessToken,
                Method: 'databases.GetConnectionStrings',
                Parameters: {},
            }),
        };

        const res = await fetch(store.state.settings.API_URL, settings);
        const data = await res.json();
        return data;
    }

    async getDatabaseChosenTableViews(connectionStringId) {
        const settings = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                Token: store.state.auth.user.accessToken,
                Method: 'databases.GetDatabaseChosenTableViews',
                Parameters: {
                    Id: connectionStringId,
                },
            }),
        };

        const res = await fetch(store.state.settings.API_URL, settings);
        const data = await res.json();
        return data;
    }

    async getPublicSshKey() {
        const settings = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                Token: store.state.auth.user.accessToken,
                Method: 'databases.GetSshKey',
                Parameters: {},
            }),
        };
        const res = await fetch(store.state.settings.API_URL, settings);
        const data = await res.json();
        return data;
    }

    async getDatabaseTableColums(connectionStringId, tableName) {
        const settings = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                Method: 'databases.GetDatabaseTableColums',
                Token: store.state.auth.user.accessToken,
                Parameters: {
                    Id: connectionStringId,
                    TableName: tableName,
                },
            }),
        };

        const res = await fetch(store.state.settings.API_URL, settings);
        const data = await res.json();
        return data;
    }

    async getAllDatabaseTableColums(connectionStringId, tableName) {
        const settings = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                Method: 'databases.GetAllDatabaseTableColums',
                Token: store.state.auth.user.accessToken,
                Parameters: {
                    Id: connectionStringId,
                    TableName: tableName,
                },
            }),
        };

        const res = await fetch(store.state.settings.API_URL, settings);
        const data = await res.json();
        return data;
    }

    async getChartSeries(connectionStringId, tableName, series, periodFilter, filteredColumn, dataSourcePreFilters, componentType) {
        const settings = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                Method: 'databases.GetChartSeries',
                Token: store.state.auth.user.accessToken,
                Parameters: {
                    Id: connectionStringId,
                    TableName: tableName,
                    Series: series,
                    PeriodFilter: periodFilter,
                    FilteredColumn: filteredColumn,
                    DataSourcePreFilters: dataSourcePreFilters,
                    ComponentType: componentType,
                },
            }),
        };

        const res = await fetch(store.state.settings.API_URL, settings);
        const data = await res.json();
        return data;
    }

    async getDatabaseTableRow(connectionStringId, tableName, rowNumber, requestTotalCount, periodFilter, filteredColumn, dataSourcePreFilters, componentType) {
        const settings = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                Method: 'databases.GetDatabaseTableRow',
                Token: store.state.auth.user.accessToken,
                Parameters: {
                    Id: connectionStringId,
                    TableName: tableName,
                    RowNumber: rowNumber,
                    RequestTotalCount: requestTotalCount,
                    PeriodFilter: periodFilter,
                    FilteredColumn: filteredColumn,
                    DataSourcePreFilters: dataSourcePreFilters,
                    ComponentType: componentType,
                },
            }),
        };

        const res = await fetch(store.state.settings.API_URL, settings);
        const data = await res.json();
        return data;
    }

    async getQueryBuilderSql(connectionStringId, queryBuilderEntities, queryBuilderLinks, queryBuilderGroupBys, queryBuilderSortOrders, filters) {
        const settings = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                Method: 'databases.GetQueryBuilderSql',
                Token: store.state.auth.user.accessToken,
                Parameters: {
                    Id: connectionStringId,
                    QueryBuilderEntities: queryBuilderEntities,
                    QueryBuilderLinks: queryBuilderLinks,
                    QueryBuilderGroupBys: queryBuilderGroupBys,
                    QueryBuilderSortOrders: queryBuilderSortOrders,
                    Filters: filters,
                },
            }),
        };

        const res = await fetch(store.state.settings.API_URL, settings);
        const data = await res.json();
        return data;
    }

    async updateRow(connectionStringId, tableName, primaryKeys, updatedValues) {
        const settings = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                Method: 'databases.UpdateRow',
                Token: store.state.auth.user.accessToken,
                Parameters: {
                    Id: connectionStringId,
                    TableName: tableName,
                    PrimaryKeys: primaryKeys,
                    UpdatedValues: updatedValues
                },
            }),
        };
        const res = await fetch(store.state.settings.API_URL, settings);
        const data = await res.json();
        return data;
    }

    async getFKRelationship(connectionStringId, tableName, columnName) {
        const settings = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                Method: 'databases.GetFKRelationship',
                Token: store.state.auth.user.accessToken,
                Parameters: {
                    Id: connectionStringId,
                    TableName: tableName,
                    ColumnName: columnName,
                },
            }),
        };
        const res = await fetch(store.state.settings.API_URL, settings);
        const data = await res.json();
        return data;
    }

    async getDatabaseTableRows(connectionStringId, tableName, dataSourcePreFilters, columns, componentType, loadOptions, distinct, take) {
        const settings = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                Method: 'databases.GetDatabaseTableRows',
                Token: store.state.auth.user.accessToken,
                Parameters: {
                    Id: connectionStringId,
                    TableName: tableName,
                    DataSourcePreFilters: dataSourcePreFilters,
                    ComponentType: componentType,
                    LoadOptions: loadOptions ? loadOptions : {},
                    Columns: columns,
                    Distinct: distinct,
                    Take: take ? take : 10,
                },
            }),
        };

        const res = await fetch(store.state.settings.API_URL, settings);
        const data = await res.json();
        return data;
    }
}

export default new DatabaseService();
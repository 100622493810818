<template>
    <div>
        <b-tab>
            <!-- <b-tab :lazy="tabLazy" @click="tabLazy = false"> -->
            <template #title>
                {{ $t('actions.types.RequeteAPI') }}
                <font-awesome-icon icon="fa fa-warning" class="ml-1 text-danger" v-if="invalidState" />
            </template>
            <b-card>
                <b-form>
                    <b-row>
                        <b-col cols="8">
                            <b-form-group :label="$t('actions.RequeteAPI.url') + ' *'" label-for="url">
                                <b-form-input id="url" v-model="url" :class="{'disabled': !$store.state.auth.user.permissions.PermissionWrite}"/>
                            </b-form-group>
                        </b-col>        
                        <b-col cols="4">
                            <b-form-group :label="$t('actions.RequeteAPI.method') + ' *'" label-for="method">
                                <b-form-select id="method" v-model="method" :options="protocolMethods" :class="{'disabled': !$store.state.auth.user.permissions.PermissionWrite}"/>
                            </b-form-group>
                        </b-col>     
                    </b-row>
                    <b-row>
                        <b-col cols="6">
                            <b-form-group :label="$t('actions.RequeteAPI.endpoint') + ' *'" label-for="endpoint">
                                <b-form-input id="endpoint" v-model="endpoint" :class="{'disabled': !$store.state.auth.user.permissions.PermissionWrite}"/>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    
                    <b-row>
                        <b-col>
                            <label for="editor" v-text="$t('actions.RequeteAPI.body')" />
                            <CodeEditor id="editor" ref="editor" :Formula="action.ActionConfiguration?.Body" :propProject="this.propProject" style="height: calc(100vh - 500px)" />
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <label for="MyGridItems" v-text="$t('actions.RequeteAPI.TableReturn')" />
                            <DxDataGrid
                                class="mx-3"
                                id="MyGridItems"
                                :dataSource="items"
                                :allow-column-reordering="true"
                                :repaint-changes-only="true"
                                :show-borders="true"
                                :selection="{ mode: 'single' }"
                                :columnAutoWidth="true"
                                :allow-column-resizing="true"
                                @init-new-row="onRowInserting"
                                @row-updating="onItemValueChanged"
                                @editing-start="onEditingStart"
                            >
                                <DxEditing :allow-updating="$store.state.auth.user.permissions.PermissionWrite" :allow-adding="$store.state.auth.user.permissions.PermissionWrite" :allow-deleting="$store.state.auth.user.permissions.PermissionWrite" :use-icons="true" mode="row" />
                                <DxColumn data-field="Group" data-type="string" :allow-editing="true" :caption="$t('Group')" :set-cell-value="onGroupValueChanged" alignment="left">
                                    <DxLookup :data-source="equipmentGroups" />
                                </DxColumn>
                                <DxColumn data-field="Item" data-type="string" :allow-editing="true" :caption="$t('Item')" alignment="left">
                                    <DxLookup :data-source="currentGroupItems" />
                                </DxColumn>
                                <DxColumn  data-field="TableField" data-type="string" :allow-editing="true" :caption="$t('actions.RequeteAPI.valueFormat')" alignment="left">
                                </DxColumn>
                            </DxDataGrid>
                        </b-col>
                    </b-row>
                
                </b-form>
            </b-card>
        </b-tab>
    </div>
</template>

<script>
import ToastAlert from '@/utils/ToastAlert';
import TreeView from '@/components/TreeView.vue';
import CodeEditor from '@/components/CodeEditor.vue';
import ProdComEquipmentService from '@/services/prodcom.equipments.service.js';
import { uuid } from 'vue-uuid';
import { DxDataGrid, DxColumn, DxLookup, DxEditing } from 'devextreme-vue/data-grid';
export default {
    name: 'RequeteAPI',
    props: {
        /**
         * Action object from parent component. Contains the equipment concerned,
         * and details about the action if opening an existing action.
         */
        action: Object,
        /**
         * Current project's Guid
         */
        propProject: String,
    },
    components: {
        ToastAlert,
        TreeView,
        CodeEditor,
        DxDataGrid,
        DxColumn,
        DxLookup,
        DxEditing,
    },
    data() {
        return {
            items : [] ,
            dataValueFormat : [],
            equipmentGroups: [],
            equipmentItems: [],
            tabLazy: true,
            url : this.action.ActionConfiguration?.Url ? this.action.ActionConfiguration.Url : "" ,
            protocolMethods : ["GET", "POST" /*, "PUT", "DELETE"*/],
            method : this.action.ActionConfiguration?.Method ? this.action.ActionConfiguration.Method : "GET",
            endpoint : this.action.ActionConfiguration?.Endpoint ? this.action.ActionConfiguration.Endpoint : "/",
            idEditor: `monaco-editor-${uuid.v4()}`, // Generate a unique ID to avoid conflicts when mounting the editor.
            editor: null,
            availableGroups: [],
            availableDestinationItems: [],
            currentGroupItems: []
        };
    },
    computed: {
        invalidState() {
            return this.destinationGroupState == false || this.destinationItemState == false;
        },
        destinationGroupState() {
            return this.destinationGroup == null || this.destinationGroup == '' ? false : null;
        },
        destinationItemState() {
            return this.destinationItem == null || this.destinationItem == '' ? false : null;
        },
    },
    async mounted() {

        // Get groups
        var data = await ProdComEquipmentService.getGroups(this.action.Equipment, this.propProject);
        //let myVariablesColors = new Array();
        if (data.success == 'n') this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(data.ret), 'warning'));
        else {
            this.equipmentGroups = data.ret.List;
        }

        await this.getEquipmentItems();

        // If action already has some data, set them.
        if (this.action.ActionConfiguration != null) {
            // Set variables
            for (var it of this.action.ActionConfiguration.ValueOutputs) {
                this.items.push({ Group: it.DestinationGroup, Item: it.DestinationItem, TableField: it.FormatValue });
            }
        }

    },
    methods: {
         async getEquipmentItems() {
            // get all equipment's variable (could not get the DevExtreme component to dynamically query this on value change...)
            this.equipmentItems = [];
            for (var grp of this.equipmentGroups) {
                var result = await ProdComEquipmentService.getItems(this.action.Equipment, grp, this.propProject);
                if (result.success == 'n') this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(result.ret, result.retParams), 'warning'));
                else {
                    for (const [key, val] of Object.entries(result.ret.List)) {
                        this.equipmentItems.push({ group: grp, item: val.Name, value: val });
                    }
                }
            }
        },
        getEquipmentItemsForCurrentGroup(group) {
            this.currentGroupItems = this.equipmentItems.filter((it) => it.group === group).map((it) => it.item);
        },
        /**
         * Called by parent when validating the configuration.
         * Needs to return the current configuration of the action.
         */
        async validate() {
            //if (this.invalidState) return null;
            var result = {
                Body: this.$refs.editor.getValue(),
                Url : this.url,
                Endpoint : this.endpoint,
                Method : this.method,
                ValueOutputs : []
            };
            this.items.forEach(element => {
               result.ValueOutputs.push({ "DestinationGroup" : element.Group, "DestinationItem": element.Item, "FormatValue" : element.TableField})
            });
            return result;
        },
        /**
         * Query server for list of variables when group has changed
         */
        async destinationGroupChanged(e) {
            const result = await ProdComEquipmentService.getItems(this.action.Equipment, this.destinationGroup, this.propProject);
            if (result.success == 'n') this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(result.ret, result.retParams), 'warning'));
            else {
                this.availableDestinationItems = [];
                for (const [key, val] of Object.entries(result.ret.List)) {
                    this.availableDestinationItems.push(val.Name);
                }
            }
        },
         onRowInserting(e) {
            var firstVariableNonExistant = this.equipmentItems.filter((x) => !this.items.find((y) => y.Group == x.group && y.Item == x.item))?.[0];
            if (firstVariableNonExistant != null) {
                e.data.Group = firstVariableNonExistant.group;
                e.data.Item = firstVariableNonExistant.item;
                e.data.TableField = "root";
            }
            this.getEquipmentItemsForCurrentGroup(e.data.Group);
            e.data.Item = e.data.Item ?? this.currentGroupItems[0];
        },
        // Warning: on this event, make sure to unfocus Group cell before selecting Item cell. Otherwise, the event won't be called.
        // TODO: fix this behaviour.
        onItemValueChanged(e) {
            if (e.newData.Item == null) return; // Make sure we only set TableField when Item is modified, not with other columns.
        },
        onGroupValueChanged(newData, value, currentRowData) {
            newData.Group = value;
            if (value == null) {
                newData.Item;
                return; // Make sure we only set Item when Group is modified, not with other columns.
            }
            this.getEquipmentItemsForCurrentGroup(value);
            if (this.currentGroupItems.length > 0) newData.Item = this.currentGroupItems[0];
        },
        onEditingStart(e) {
            this.getEquipmentItemsForCurrentGroup(e.data.Group);
        },
    },
};
</script>
<style lang="scss">
.highlight-blue {
    background-color: blue;
    color: white;
}
</style>
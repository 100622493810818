<template>
    <div>
        <b-tab>
            <template #title>{{$t('actions.executable.executable')}}</template>
            <b-card>
                <b-form>
                    <b-row>
                        <b-col cols="6">
                            <b-form-group :label="$t('actions.executable.pathExecutable') + ' *'" label-for="executablePath">
                                <b-form-input id="executablePath" v-model="ExecutablePath" :class="{'disabled': !$store.state.auth.user.permissions.PermissionWrite}"/>
                            </b-form-group>
                        </b-col>
                        <b-col cols="1">
                            <div v-on:click="checkPath" v-if="checkPathStatus == 0" style="margin-top: 30px;">
                                <font-awesome-icon icon="check" class="top-menu-icon mt-2 mr-1 fa-lg" color="#1ca8dd" :class="{'disabled': !$store.state.auth.user.permissions.PermissionRead}"/>
                            </div>
                            <div v-on:click="checkPath" v-else-if="checkPathStatus == 1" style="margin-top: 30px;">
                                <font-awesome-icon icon="spinner-third" class="fa-spin-custom mt-2 fa-lg" color="#1ca8dd"/>
                            </div>
                            <div v-on:click="checkPath" v-else-if="checkPathStatus == 2" style="margin-top: 30px;">
                                <font-awesome-icon icon="pen" class="top-menu-icon mt-2 mr-1 fa-lg" color="#1ca8dd" :class="{'disabled': !$store.state.auth.user.permissions.PermissionWrite}"/>
                            </div>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <b-form-checkbox style="margin-top: 35px" switch v-model="UseTimeout" :class="{'disabled': !$store.state.auth.user.permissions.PermissionWrite}">{{$t('actions.executable.waitTimeout')}}</b-form-checkbox>
                        </b-col>
                        <b-col>
                            <b-form-group :label="$t('Timeout') + ' *'" label-for="timeout">
                                <b-form-input id="timeout" v-model="Timeout" type="number" :disabled="!UseTimeout" min="0" max="999999" :class="{'disabled': !$store.state.auth.user.permissions.PermissionWrite}"/>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <b-form-group :label="$t('actions.executable.arguments')" label-for="arguments">
                                <b-form-input id="arguments" v-model="Arguments" :class="{'disabled': !$store.state.auth.user.permissions.PermissionWrite}"/>
                            </b-form-group>
                        </b-col>
                    </b-row>
                        <b-row>
                            <b-col cols="9"></b-col>
                            <b-col cols="3">
                                <b-button style="width: 100%" v-on:click="onAddVariables" :class="{'disabled': !$store.state.auth.user.permissions.PermissionWrite}">{{$t('actions.sendmail.addVariables')}}</b-button>
                            </b-col>
                        </b-row>
                </b-form>
            </b-card>
        </b-tab>
        <MessageBuilder :project="propProject" :equipment="action.Equipment" :body="Arguments" :onOk="onMessageBuilderOk" />
    </div>
</template>

<script>
import ToastAlert from '@/utils/ToastAlert';
import MessageBuilder from "@/components/MessageBuilder.vue";
import ProdComGeneralService from "@/services/prodcom.general.service.js";
/**
 * "Launch Executable" action configuration.
 */
export default {
    name: "Executable",
    props:{
        /**
         * Action object from parent component. Contains the equipment concerned,
         * and details about the action if opening an existing action.
         */
        action: Object,
        /**
         * Current project's Guid
         */
        propProject: String,
    },
    components: {
        MessageBuilder
    },
    data(){
        return {
            ExecutablePath: "",
            UseTimeout: false,
            Timeout: 60000,
            Arguments: "",
            UsedVariables: [],
            checkPathStatus: 0,
        }
    },
    async mounted(){
        if(this.action.ActionConfiguration != null){
            this.ExecutablePath = this.action.ActionConfiguration.ExecutablePath;
            this.UseTimeout = this.action.ActionConfiguration.UseTimeout;
            this.Timeout = this.action.ActionConfiguration.Timeout;
            this.Arguments = this.action.ActionConfiguration.Arguments;
            this.UsedVariables = this.action.ActionConfiguration.UsedVariables;
        }
    },
    methods:{
        /**
         * Called by parent when validating the configuration.
         * Needs to return the current configuration of the action. 
         */
        async validate(){
            var result = {
                ExecutablePath: this.ExecutablePath,
                UseTimeout: this.UseTimeout,
                Timeout: this.Timeout,
                Arguments: this.Arguments,
                UsedVariables: this.UsedVariables,
            }
            return result;
        },
        /**
         * Checks back to the server to see if the executable entered by the user
         * is correct. Might have to later do a further check to see if the file
         * is indeed an executable and is accessible by the user.
         */
        async checkPath(e){
            this.checkPathStatus = 1;
            const result = await ProdComGeneralService.checkPath(this.ExecutablePath);
            if(result.success == "n") this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(result.ret), 'warning'));
            else{
                if(result.ret){
                    this.$EventBus.$emit('show-toast', new ToastAlert(this.$t('actions.executable.fileExists'), 'info'));
                }else{
                    this.$EventBus.$emit('show-toast', new ToastAlert(this.$t('actions.executable.fileNotExists'), 'warning'));
                }
            }
            this.checkPathStatus = 0;
        },
        async onAddVariables(e){
            this.$bvModal.show("messageBuilder");
        },
        async onMessageBuilderOk(e){
            this.Arguments = e.Body;
            this.UsedVariables = e.Variables;
        }
    }
}
</script>
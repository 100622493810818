import Vue from 'vue';
import Vuex from 'vuex';
import { auth } from './auth.module';
import constants from '@/utils/constants';
import { uuid } from 'vue-uuid';

Vue.use(Vuex);

const store = new Vuex.Store({
    state: {
        equipments: [],
        currentEquipmentId: null,
        tabs: [],
        activeTab: 0,
        settings: {},
        selectedEquipmentMonitoring: null,
        copiedPanelSettings: {},
        theme: 'dark',
        sideMenuSize: localStorage.getItem('sideBarWidth') == null ? 290 : localStorage.getItem('sideBarWidth'),
        minSideMenuSize: 100,
        topMenuSize: 60,
        bottomMenuSize: 28,
        // Context menu variables accessible to all the components in the app
        contextMenu: {
            positionX: 0,
            positionY: 0,
            items: [],
            display: false,
        },
        monitoringGraph: {
            selectedVariables: [],
            refreshInterval: 2, // 0: 1 second; 1: 10 seconds; 2: 1 minute
            displaySeparated: false,
            type: 'spline',
        },
        selectedVariablesMonitoring: [],
        runtimeStatus: 0, // Stopped
        helpText: '',
        helpTitle: '',
        expired: 0,
        monacoSettings: {},
        // monacoKeywords: [],
        // monacoArrayTokenRoot: [],
    },
    mutations: {
        resetLoadingCount(state) {
            state.loadingCount = 0;
        },
        increaseLoadingCount(state) {
            state.loadingCount = state.loadingCount + 1;
        },
        decreaseLoadingCount(state) {
            state.loadingCount = state.loadingCount - 1;
        },
        updateSettings(state, settings) {
            state.settings = settings;
        },
        updatePeriodFilter(state, periodFilter) {
            state.periodFilter = periodFilter;
        },
        updatePrimaryColor(state, color) {
            state.settings.PRIMARY_COLOR = color;
        },
        updatePrimaryColorChangeAllowed(state, allowed) {
            state.settings.PRIMARY_COLOR_CHANGE_ALLOWED = allowed;
        },

        setMonacoSettings(state, settings) {
            state.monacoSettings[settings.projectId] = {
                keywords: settings.keywords,
                tokenRoot: settings.tokenRoot,
            };
        },

        removeMonacoProjectSettings(state, projectId) {
            delete state.monacoSettings[projectId];
        },

        removeMonacoSettings(state) {
            state.monacoSettings = {};
        },

        setEquipments(state, equipments) {
            state.equipments = equipments;
        },

        setEquipment(state, equipmentData) {
            // TODO: set current selected equipment
        },

        selectTabAsFirst(state, tab) {
            var index = state.tabs.findIndex((t) => t.uuid == tab.uuid);
            if (index != -1) {
                state.tabs.splice(index, 1);
                state.tabs.unshift(tab);
                state.activeTab = state.tabs.findIndex((t) => t.uuid == tab.uuid);
            }
        },

        selectAction(state, action) {
            var result = state.tabs.findIndex((act) => act.type == constants.TAB_TYPE_ACTION && act.Name == action.Name && act.Equipment == action.Equipment && act.project == action.project);
            if (result == -1) {
                action.type = constants.TAB_TYPE_ACTION;
                action.uuid = uuid.v4();
                state.tabs.push(action);
                state.activeTab = state.tabs.length - 1;
            } else {
                state.activeTab = result;
            }
        },

        selectDataSource(state, dataSource) {
            if (dataSource == null) return;
            if (dataSource.Equipment == null) return;
            var result = state.tabs.findIndex((src) => src.type == constants.TAB_TYPE_DATASOURCE && src.Equipment == dataSource.Equipment && src.ProjectId == dataSource.ProjectId);
            if (result == -1) {
                dataSource.type = constants.TAB_TYPE_DATASOURCE;
                dataSource.uuid = uuid.v4();
                state.tabs.push(dataSource);
                state.activeTab = state.tabs.length - 1;
            } else {
                dataSource.type = constants.TAB_TYPE_DATASOURCETEMPLATE;
                dataSource.uuid = dataSource.Id;
                state.tabs[result] = dataSource;
                state.activeTab = result;
            }
        },

        selectVariables(state, variables) {
            if (variables == null) return;
            if (variables.Equipment == null) return;
            if (variables.ProjectId == null) return;
            var result = state.tabs.findIndex((src) => src.type == constants.TAB_TYPE_VARIABLES && src.Equipment == variables.Equipment && src.ProjectId == variables.ProjectId);
            if (result == -1) {
                variables.type = constants.TAB_TYPE_VARIABLES;
                variables.uuid = uuid.v4();
                state.tabs.push(variables);
                state.activeTab = state.tabs.length - 1;
            } else {
                state.activeTab = result;
            }
        },

        selectCommunication(state, communication) {
            if (communication == null) return;
            if (communication.Equipment == null) return;
            var result = state.tabs.findIndex((src) => src.type == constants.TAB_TYPE_COMMUNICATION && src.Equipment == communication.Equipment && src.ProjectId == communication.ProjectId);
            if (result == -1) {
                communication.type = constants.TAB_TYPE_COMMUNICATION;
                communication.uuid = uuid.v4();
                state.tabs.push(communication);
                state.activeTab = state.tabs.length - 1;
            } else {
                state.activeTab = result;
            }
        },

        selectDataSourceTemplate(state, dataSource) {
            if (dataSource == null) return;
            if (dataSource.Id == null) return;
            var result = state.tabs.findIndex((src) => src.type == constants.TAB_TYPE_DATASOURCETEMPLATE && src.Id == dataSource.Id && src.ProjectId == dataSource.ProjectId);
            if (result == -1) {
                dataSource.type = constants.TAB_TYPE_DATASOURCETEMPLATE;
                dataSource.uuid = uuid.v4();
                state.tabs.push(dataSource);
                state.activeTab = state.tabs.length - 1;
            } else {
                dataSource.type = constants.TAB_TYPE_DATASOURCETEMPLATE;
                dataSource.uuid = dataSource.Id;
                state.tabs[result] = dataSource;
                state.activeTab = result;
            }
        },

        selectActionGlobalView(state, actionData) {
            if (actionData == null) return;
            if (actionData.Equipment == null) return;
            var result = state.tabs.findIndex((src) => src.type == constants.TAB_TYPE_ACTIONGLOBALVIEW && src.Equipment == actionData.Equipment && src.ProjectId == actionData.ProjectId);
            if (result == -1) {
                actionData.type = constants.TAB_TYPE_ACTIONGLOBALVIEW;
                actionData.uuid = uuid.v4();
                state.tabs.push(actionData);
                state.activeTab = state.tabs.length - 1;
            } else {
                state.activeTab = result;
            }
        },

        selectEquipmentMonitor(state, equipment) {
            if (equipment == null) return;
            state.selectedEquipmentMonitoring = equipment;
        },
        setCurrentLicense(state, license) {
            state.settings.LICENSE = license;
        },
        setTheme(state, theme) {
            state.theme = theme;
        },
        setSideMenuSize(state, newSize) {
            if (newSize > state.minSideMenuSize) {
                state.sideMenuSize = newSize;
                localStorage.setItem('sideBarWidth', newSize);
            }
        },
        closeTabsForEquipment(state, equipment) {
            state.activeTab = 0;
            state.tabs = state.tabs.filter((src) => src.Equipment != equipment.Name);
            // let idx = 0;
            // const max_iterations = 500; // Close at most 500 tabs, to avoid crashing the full app
            // let currentIteration = 0;
            // state.activeTab = 0;
            // while (idx != -1 && currentIteration < max_iterations) {
            //     idx = state.tabs.findIndex(src => src.Equipment == equipment.Name && (src.ProjectId == equipment.ProjectId || src.project == equipment.ProjectId)) // (src.type == constants.TAB_TYPE_DATASOURCE || src.type == constants.TAB_TYPE_ACTION) &&
            //     if (idx != -1) {
            //         state.tabs.splice(idx, 1);
            //     }
            //     currentIteration++;
            // }
        },
        closeTabsForProject(state, projectId) {
            state.activeTab = 0;
            state.tabs = state.tabs.filter((src) => src.ProjectId != projectId && src.project != projectId);
        },
        closeTabsForActionGroup(state, actionGroup) {
            state.activeTab = 0;
            state.tabs = state.tabs.filter((src) => !(src.type == 'action' && src.Group == actionGroup.group && src.project == actionGroup.project));
        },
        closeTabsForAction(state, action) {
            state.activeTab = 0;
            state.tabs = state.tabs.filter((src) => src.Name != action.action || src.Equipment != action.equipment || src.project != action.project);
        },
        closeTabsForDataSource(state, dataSource) {
            state.activeTab = 0;
            state.tabs = state.tabs.filter((src) => src.Id != dataSource.dataSource || src.ProjectId != dataSource.project);
        },
        closeTab(state, tab) {
            var result = this.findTab(state, tab);
            if (result != -1) state.tabs.splice(result, 1);
        },
        closeOtherTabs(state, tab) {
            var result = this.findTab(state, tab);
            if (result != -1) state.tabs = [state.tabs[result]];
        },
        closeRightTabs(state, tab) {
            var result = this.findTab(state, tab);
            if (result != -1) {
                state.tabs = state.tabs.slice(0, result + 1);
            }
        },
        closeAllTabs(state) {
            state.tabs = [];
        },
        addVariableMonitorChart(state, variable) {
            state.monitoringGraph.selectedVariables.push(variable);
        },
        removeVariableMonitorChart(state, variable) {
            const itemToRemoveIndex = state.monitoringGraph.selectedVariables.findIndex((it) => it.Group == variable.parent.text && it.Variable == variable.text);
            if (itemToRemoveIndex != -1) state.monitoringGraph.selectedVariables.splice(itemToRemoveIndex, 1);
        },
        changeMonitoringGraphRefreshInterval(state, interval) {
            state.monitoringGraph.refreshInterval = interval;
        },
        changeMonitoringGraphdisplaySeparated(state, value) {
            state.monitoringGraph.displaySeparated = value;
        },
        setRuntimeStatus(state, runtimeStatus) {
            state.runtimeStatus = runtimeStatus;
        },
        displayHelp(state, helpText) {
            state.helpText = helpText;
        },
        displayHelpTitle(state, helpTitle) {
            state.helpTitle = helpTitle;
        },
        setConnectionId(state, connectionId) {
            state.connectionId = connectionId;
        },
    },
    modules: {
        auth,
    },
});

store.findTab = (state, tab) => {
    if (tab == null || tab == undefined) return -1;
    if (tab.uuid == null || tab.uuid == undefined) return -1;
    return state.tabs.findIndex((src) => src.uuid == tab.uuid);
};

export default store;

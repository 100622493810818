<template>
    <div>
        <b-tab>
            <template #title>{{$t('actions.echangeEquipement.title')}}</template>
            <b-card>
                <b-form>
                    <b-row>
                        <b-col>
                            <b-form-group :label="$t('actions.echangeEquipement.sourceGroup') + ' *'" label-for="sourceGroup">
                                <b-form-select id="sourceGroup" v-model="SourceGroup" :options="availableSourceGroups" v-on:change="sourceGroupChanged"  :class="{'disabled': !$store.state.auth.user.permissions.PermissionWrite}"/>
                            </b-form-group>
                        </b-col>
                        <b-col>
                            <b-form-group :label="$t('actions.echangeEquipement.destinationTarget') + ' *'" label-for="destinationTarget">
                                <b-form-select id="destinationTarget" v-model="DestinationTarget" :options="availableEquipments" v-on:change="destinationTargetChanged"  :class="{'disabled': !$store.state.auth.user.permissions.PermissionWrite}"/>
                            </b-form-group>
                        </b-col>
                        <b-col>
                            <b-form-group :label="$t('actions.echangeEquipement.destinationGroup') + ' *'" label-for="destinationGroup">
                                <b-form-select id="destinationGroup" v-model="DestinationGroup" :options="availableDestinationGroups" v-on:change="destinationGroupChanged"  :class="{'disabled': !$store.state.auth.user.permissions.PermissionWrite}"/>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <b-form-checkbox style="margin-top: 35px" switch v-model="ParallelExecution"  :class="{'disabled': !$store.state.auth.user.permissions.PermissionWrite}">{{$t('actions.echangeEquipement.parallelExecution')}}</b-form-checkbox>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col style="margin-top: 35px">
                        <DxDataGrid
                            :dataSource="Links"
                            :allow-column-reordering="true"
                            :repaint-changes-only="true"
                            :show-borders="false"
                            :selection="{ mode: 'single' }"
                            :columnAutoWidth="true"
                            show-scrollbar="onHover"
                            width="100%"
                            :allow-column-resizing="true">
                            <DxEditing
                                :allow-updating="$store.state.auth.user.permissions.PermissionRead"
                                :allow-adding="$store.state.auth.user.permissions.PermissionRead"
                                :allow-deleting="$store.state.auth.user.permissions.PermissionRead"
                                :use-icons="true"
                                mode="row"
                            />
                            <DxColumn data-field="SourceVariable" data-type="string" :allow-editing="true" :caption="$t('Source Variable')" alignment="left" >
                                <DxLookup :data-source="sourceItems"/>
                            </DxColumn>
                            <DxColumn data-field="DestinationVariable" data-type="string" :allow-editing="true" :caption="$t('Destination Variable')" alignment="left" >
                                <DxLookup :data-source="destinationItems"/>
                            </DxColumn>
                        </DxDataGrid>
                        </b-col>
                    </b-row>
                </b-form>
            </b-card>
        </b-tab>
    </div>
</template>

<script>
import ToastAlert from '@/utils/ToastAlert';
import {
    DxDataGrid,
    DxColumn,
    DxToolbar,
    DxItem,
    DxLookup,
    DxEditing,
} from 'devextreme-vue/data-grid';
import ProdComEquipmentService from "@/services/prodcom.equipments.service.js";
export default {
    name: "EchangeEquipement",
    props:{
        /**
         * Action object from parent component. Contains the equipment concerned,
         * and details about the action if opening an existing action.
         */
        action: Object,
        /**
         * Current project's Guid
         */
        propProject: String,
    },
    components: {
        DxDataGrid,
        DxColumn,
        DxEditing,
        DxToolbar,
        DxItem,
        DxLookup,
    },
    data(){
        return {
            SourceGroup: '',
            DestinationTarget: '',
            DestinationGroup: '',
            ParallelExecution: false,
            Links: [],
            sourceItems: [],
            destinationItems: [],
            availableSourceGroups: [],
            availableEquipments: [],
            availableDestinationGroups: [],
        }
    },
    async mounted(){
        if(!this.$store.state.auth.user.permissions.PermissionRead) return;
        // Get source groups
        var data = await ProdComEquipmentService.getGroups(this.action.Equipment, this.propProject);
        if(data.success == "n") this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(data.ret), 'warning'));
        else {
            this.availableSourceGroups = data.ret.List;
        }
        if(this.action.ActionConfiguration == null ){
            this.SourceGroup = this.availableSourceGroups[0];
            await this.sourceGroupChanged();
        }
        // Get list of equipments
        data = await ProdComEquipmentService.getAllEquipments(this.propProject);
        if(data.success == "n") this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(data.ret), 'warning'));
        else {
            for(const i of data.ret.map(r => r.Name)){
                if(i != this.action.Equipment) this.availableEquipments.push(i);
            }
            if(this.action.ActionConfiguration == null ){
                this.DestinationTarget = this.availableEquipments[0];
                await this.destinationTargetChanged();
            }
        }
        if(this.action.ActionConfiguration != null){
            this.ParallelExecution = this.action.ActionConfiguration.ParallelExecution;
            this.SourceGroup = this.action.ActionConfiguration.SourceGroup;
            await this.sourceGroupChanged();
            this.DestinationTarget = this.action.ActionConfiguration.DestinationTarget;
            await this.destinationTargetChanged();
            this.DestinationGroup = this.action.ActionConfiguration.DestinationGroup;
            await this.destinationGroupChanged();
            this.Links = this.action.ActionConfiguration.Links;
        }
    },
    methods: {
        /**
         * Called by parent when validating the configuration.
         * Needs to return the current configuration of the action. 
         */
        async validate(){
            var result = {
                SourceGroup: this.SourceGroup,
                DestinationTarget: this.DestinationTarget,
                DestinationGroup: this.DestinationGroup,
                ParallelExecution: this.ParallelExecution,
                Links: this.Links,
            }
            return result;
        },
        async sourceGroupChanged(e){
        if(!this.$store.state.auth.user.permissions.PermissionRead) return;
            const result = await ProdComEquipmentService.getItems(this.action.Equipment, this.SourceGroup, this.propProject);
            if(result.success == "n") this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(result.ret, result.retParams), 'warning'));
            else{
                this.sourceItems = [];
                for(const [key, val] of Object.entries(result.ret.List)){
                    this.sourceItems.push(val.Name);
                }
            }
        },
        async destinationTargetChanged(e){
        if(!this.$store.state.auth.user.permissions.PermissionRead || this.DestinationTarget == null || this.DestinationTarget == '') return;
            const result = await ProdComEquipmentService.getGroups(this.DestinationTarget, this.propProject);
            if(result.success == "n") this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(result.ret), 'warning'));
            else {
                this.availableDestinationGroups = result.ret.List;
                this.DestinationGroup = this.availableDestinationGroups[0];
                await this.destinationGroupChanged();
            }
        },
        async destinationGroupChanged(e){
        if(!this.$store.state.auth.user.permissions.PermissionRead) return;
            const result = await ProdComEquipmentService.getItems(this.DestinationTarget, this.DestinationGroup, this.propProject);
            if(result.success == "n") this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(result.ret, result.retParams), 'warning'));
            else{
                this.destinationItems = [];
                for(const [key, val] of Object.entries(result.ret.List)){
                    this.destinationItems.push(val.Name);
                }
            }
        }
    }
}
</script>
<template>
    <b-container fluid>
        <div class="login-form mt-5">
            <img class="login-logo" src="@/assets/img/logo_big.png" />
            <b-form class="mt-4" @submit="doLogin">
                <b-form-group id="input-group-email">
                    <b-form-input :maxlength="$store.state.settings.INPUT_EMAIL_MAX_LENGTH" id="input-mail" v-model="form.email" type="email" :placeholder="$t('Email address')" required></b-form-input>
                </b-form-group>

                <b-form-group id="input-group-password">
                    <b-form-input :maxlength="$store.state.settings.INPUT_MAX_LENGTH" id="input-password" v-model="form.password" type="password" :placeholder="$t('Password')" required></b-form-input>
                </b-form-group>

                <!-- <b-form-group id="input-group-4">
                    <b-form-checkbox value="me">{{ $t('Remember me') }}</b-form-checkbox>
                </b-form-group> -->
                <b-row>
                    <b-col>
                        <b-button v-if="!loading" type="button" variant="primary" block class="mt-4" @click="doLoginAsGuest"><font-awesome-icon icon="sign-in-alt" /> {{ $t('signin as guest') }}</b-button>
                    </b-col>
                    <b-col>
                        <b-button v-if="!loading" type="submit" variant="primary" block class="mt-4" :disabled="!this.form.email || !this.form.password"><font-awesome-icon icon="sign-in-alt" /> {{ $t('signin') }}</b-button>
                    </b-col>
                </b-row>
                
                
                <div class="text-center"><font-awesome-icon v-if="loading" icon="sync" class="fa-lg mt-4" spin /></div>
            </b-form>
        </div>
    </b-container>
</template>
<script>
import ToastAlert from '@/utils/ToastAlert';
export default {
    name: 'Login',
    data() {
        return {
            form: {
                email: '',
                password: '',
            },
            loading: false,
        };
    },
    computed: {
        loggedIn() {
            return this.$store.state.auth.status.loggedIn && this.$store.state.auth.user != null && this.$store.state.auth.user.email != '';
        },
    },
    created() {
        if (this.loggedIn) {
            this.$router.push('/');
        }
    },
    methods: {
        async doLogin(event) {
            event.preventDefault();
            this.loading = true;
            if (this.form.email && this.form.password) {
                await this.$store.dispatch('auth/login', this.form).then(
                    () => {
                        if (this.$router.currentRoute.name != 'Configuration') this.$router.push('/');
                    },
                    (error) => {
                        this.loading = false;
                        this.$EventBus.$emit('show-toast', new ToastAlert(this.$t('Bad email or password'), 'warning'));
                    },
                );
            }
            this.loading = false;
        },
        async doLoginAsGuest(e){
            this.loading = true;
            await this.$store.dispatch('auth/loginAsGuest', this.form).then(
                    () => {
                        if (this.$router.currentRoute.name != 'Configuration') this.$router.push('/');
                    },
                    (error) => {
                        this.loading = false;
                        this.$EventBus.$emit('show-toast', new ToastAlert(this.$t('Bad email or password'), 'warning'));
                    },
                );
            this.loading = false;
        }
    },
};
</script>
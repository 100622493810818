<template>
    <svg class="icon" xmlns="http://www.w3.org/2000/svg" height="24" width="24">
        <path
            d="M 4 2 C 2.9 2 2 2.9 2 4 L 2 20 C 2 21.1 2.9 22 4 22 L 20 22 C 21.1 22 22 21.1 22 20 L 22 4 C 22 2.9 21.1 2 20 2 L 4 2 z M 8 4 L 10 4 L 10 8 L 20 8 L 20 20 L 8 20 L 8 10 L 4 10 L 4 8 L 8 8 L 8 4 z M 16 9 L 12.998047 12 L 15.001953 12 L 15 14 C 15 14.56503 14.56503 15 14 15 L 12 15 L 12 13 L 9 16 L 12 19 L 12 17 L 14 17 C 15.64497 17 17 15.64497 17 14 L 17.001953 12 L 18.998047 12 L 16 9 z"
        />
    </svg>
</template>
<script>
export default {
    name: 'PivotGridIcon',
};
</script>
<style>
svg.icon {
    margin-left: -3px;
}
svg.icon > path {
    fill: var(--text-color);
}
</style>
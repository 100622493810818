import store from "@/store/index.js";

class RTWebConnectionService {
    async getRTWebConnections() {
        const settings = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                Method: 'rtwebconnections.get',
                Token: store.state.auth.user.accessToken,
                Parameters: {},
            }),
        };

        const res = await fetch(store.state.settings.API_URL, settings);
        const data = await res.json();
        return data;
    }
    async getRTWebConnectionData(serverName, serverPort, isSecured) {
        const settings = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                Method: 'rtwebconnections.getRTWebConnectionData',
                Token: store.state.auth.user.accessToken,
                Parameters: {
                    ServerName: serverName,
                    ServerPort: serverPort,
                    IsSecured: isSecured,
                },
            }),
        };

        const res = await fetch(store.state.settings.API_URL, settings);
        const data = await res.json();
        return data;
    }
    async getRTWebConnectionDataFromId(id) {
        const settings = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                Method: 'rtwebconnections.getRTWebConnectionDataFromId',
                Token: store.state.auth.user.accessToken,
                Parameters: {
                    Id: id,
                },
            }),
        };
        const res = await fetch(store.state.settings.API_URL, settings);
        const data = await res.json();
        return data;
    }
    async getSelectedItemsData(id, lastUpdate, selectedItems, attributes) {
        const settings = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                Method: 'rtwebconnections.getSelectedItemsData',
                Token: store.state.auth.user.accessToken,
                Parameters: {
                    Id: id,
                    LastUpdate: lastUpdate,
                    SelectedItems: selectedItems,
                    Attributes: attributes,
                },
            }),
        };
        const res = await fetch(store.state.settings.API_URL, settings);
        const data = await res.json();
        return data;
    }
    async setItemData(id, deviceName, groupName, itemName, attributeName, newValue) {
        const settings = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                Method: 'rtwebconnections.setItemData',
                Token: store.state.auth.user.accessToken,
                Parameters: {
                    Id: id,
                    deviceName: deviceName,
                    GroupName: groupName,
                    ItemName: itemName,
                    AttributeName: attributeName,
                    NewValue: newValue,
                },
            }),
        };
        const res = await fetch(store.state.settings.API_URL, settings);
        const data = await res.json();
        return data;
    }
    async getDevicesWithEvents(id) {
        const settings = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                Method: 'rtwebconnections.getDevicesWithEvents',
                Token: store.state.auth.user.accessToken,
                Parameters: {
                    Id: id,
                },
            }),
        };

        const res = await fetch(store.state.settings.API_URL, settings);
        const data = await res.json();
        return data;
    }
    async getDeviceEvents(id, lastUpdate, devicesNames, priority, prioritySign) {
        const settings = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                Method: 'rtwebconnections.getDeviceEvents',
                Token: store.state.auth.user.accessToken,
                Parameters: {
                    Id: id,
                    LastUpdate: lastUpdate,
                    DevicesNames: devicesNames,
                    Priority: priority,
                    PrioritySign: prioritySign,
                },
            }),
        };

        const res = await fetch(store.state.settings.API_URL, settings);
        const data = await res.json();
        return data;
    }
    async createRTWebConnection(alias, serverName, serverPort, isSecured) {
        const settings = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                Method: 'rtwebconnections.create',
                Token: store.state.auth.user.accessToken,
                Parameters: {
                    Alias: alias,
                    ServerName: serverName,
                    ServerPort: serverPort,
                    IsSecured: isSecured,
                },
            }),
        };

        const res = await fetch(store.state.settings.API_URL, settings);
        const data = await res.json();
        return data;
    }
    async updateRTWebConnection(id, alias, serverName, serverPort, isSecured) {
        const settings = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                Method: 'rtwebconnections.update',
                Token: store.state.auth.user.accessToken,
                Parameters: {
                    Id: id,
                    Alias: alias,
                    ServerName: serverName,
                    ServerPort: serverPort,
                    IsSecured: isSecured,
                },
            }),
        };

        const res = await fetch(store.state.settings.API_URL, settings);
        const data = await res.json();
        return data;
    }
    async removeRTWebConnection(id) {
        const settings = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                Method: 'rtwebconnections.delete',
                Token: store.state.auth.user.accessToken,
                Parameters: {
                    Id: id,
                },
            }),
        };

        const res = await fetch(store.state.settings.API_URL, settings);
        const data = await res.json();
        return data;
    }
    async getDevicesActions(id, devices) {
        const settings = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                Method: 'rtwebconnections.getDevicesActions',
                Token: store.state.auth.user.accessToken,
                Parameters: {
                    Id: id,
                    Devices: devices
                },
            }),
        };

        const res = await fetch(store.state.settings.API_URL, settings);
        const data = await res.json();
        return data;
    }
    async executeAction(id, deviceName, actionName) {
        const settings = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                Method: 'rtwebconnections.executeAction',
                Token: store.state.auth.user.accessToken,
                Parameters: {
                    Id: id,
                    DeviceName: deviceName,
                    ActionName: actionName
                },
            }),
        };

        const res = await fetch(store.state.settings.API_URL, settings);
        const data = await res.json();
        return data;
    }   
}

export default new RTWebConnectionService();
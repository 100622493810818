<template>
    <div>
        <DxDataGrid
        ref="grid"
        :data-source="dataSource"
        :showColumnHeaders="false"
        :allow-column-reordering="true"
        :repaint-changes-only="true"
        :show-borders="true"
        :highlight-changes="true"
        :selection="{ mode: 'single' }"
        :columnAutoWidth="false"
        :allow-column-resizing="true">
            <template #trend-template="{ data }">
                <div class="text-center">
                    <font-awesome-icon v-if="data.data.Tendance > 0" icon="arrow-alt-up" class="fa-lg rotate-45" />
                    <font-awesome-icon v-else-if="data.data.Tendance < 0" icon="arrow-alt-down" class="fa-lg rotate--45"/>
                    <font-awesome-icon v-else icon="arrow-alt-right" class="fa-lg" />
                </div>
            </template>
            <template #data-left="{data}">
                <div v-if="data.column.dataField==$t('Name')" class="mr-3">{{ data.displayValue }}</div>
                <div v-else :title="data.displayValue" class="text-left">
                    {{ data.displayValue }}
                </div>
            </template>
            <template #data-value="{data}">
                <div :title="data.data.Valeur" class="text-right">
                    <div>
                        {{ data.data.Valeur }}
                    </div>
                </div>
            </template>
            <template #data-valuen1="{data}">
                <div :title="data.data.ValeurN1" class="text-right">
                    <div>
                        {{ data.data.ValeurN1 }}
                    </div>
                </div>
            </template>
            <template #data-hd="{data}">
                <div class="text-center">
                    {{ format_date(data.displayValue, 'DD/MM/YYYY HH:mm:ss') }}
                </div>
            </template>
            <template #data-duration="{data}">
                <div class="text-center">
                    {{ format_duration(data.displayValue, 'HH:mm:ss') }}
                </div>
            </template>
            <DxLoadPanel :enabled="false"/>
            <DxColumn data-field="key" data-type="string" :visible="false" :allow-editing="false" caption="Key" alignment="center" cell-template="data-left"/>
            <DxColumn data-field="Nom" data-type="string" :allow-editing="false" :caption="$t('Name')" alignment="center" cell-template="data-left"/>
            <DxColumn data-field="Type" data-type="string" :allow-editing="false" alignment="center" cell-template="data-left"/>
            <DxColumn data-field="Valeur" data-type="string" :allow-editing="true" :caption="$t('Value')" alignment="center" cell-template="data-value"/> 
            <DxColumn data-field="HD" data-type="datetime" :allow-editing="false" :caption="$t('HD')" alignment="center" cell-template="data-hd"/> 
            <DxColumn data-field="ValeurN1" data-type="string" :visible="true" :allow-editing="false" :caption="$t('ValueN1')" alignment="center" cell-template="data-valuen1"/>
            <DxColumn data-field="CompteurHoraire1" :visible="true" data-type="string" :allow-editing="false" :caption="$t('DurationCol')" alignment="center" cell-template="data-duration"/>
            <DxColumn data-field="CompteurHoraire1Cumulé" :visible="true" data-type="string" :allow-editing="false" :caption="$t('Cumulative Duration')" alignment="center" cell-template="data-duration"/>
            <DxColumn data-field="Tendance" :visible="true" data-type="string" :allow-editing="false" cell-template="trend-template" :caption="$t('Trend')" alignment="center"/> 
        </DxDataGrid>
    </div>
</template>
<script>

import {
  DxDataGrid,
  DxColumn,
  DxLoadPanel
} from 'devextreme-vue/data-grid';
import dayjs from "dayjs";
import moment from "moment";
import CustomStore from 'devextreme/data/custom_store';

export default {
    name: 'Monitor',
    components: {
        DxDataGrid,
        DxColumn,
        DxLoadPanel
    },
    props: {
        templateData: {
        type: Object,
        default: () => {},
        },
        durationMode: Number,
    },
    data() {
        return {
            dataSource: null,
        }
    },
    created() {
        if(this.templateData != undefined) {
            this.createDataSource()
        }
    },
    beforeDestroy() {
        clearInterval(this.TimerRefreshArray);
        this.dataSource = null;
    },
    methods: {
        createDataSource() {
            this.dataSource = new CustomStore({
                load: () => {
                    if(this.templateData == undefined) return;
                    var temp = []
                    if(this.templateData != undefined && typeof this.templateData.data.Valeur == 'object' && typeof this.templateData.data.ValeurN1 == 'object' && this.templateData.data.Valeur.length == this.templateData.data.ValeurN1.length) {
                        for(let i=0;i<this.templateData.data.Valeur.length;i++) {
                            temp.push({
                                Type: this.templateData.data.Type.slice(0, this.templateData.data.Type.length - 2), //type of parrent without [] at the end
                                key: i, 
                                Groupe: this.templateData.data.Groupe,
                                Nom: this.templateData.data.Nom + '_' + i,
                                Valeur: this.templateData.data.Valeur[i],
                                ValeurN1: this.templateData.data.ValeurN1[i],
                                HD: this.templateData.data.HD,
                                CompteurHoraire1: this.templateData.data.CompteurHoraire1,
                                CompteurHoraire1Cumulé: this.templateData.data.CompteurHoraire1Cumulé,
                                Tendance: this.templateData.data.Tendance,
                                IsSimulated: this.templateData.data.IsSimulated
                            })
                        }
                    } else { temp.push(this.templateData.data); }
                    return temp;
                },
                key: 'key',
            })
        },
        update(key, data) {
            for(let i in data) {
                let temp = {
                    Type: data.Type, //type of parrent without [] at the end
                    key: i, 
                    Groupe: data.Groupe,
                    Nom: data.Nom,
                    Valeur: data.Valeur[i],
                    ValeurN1: data.ValeurN1[i],
                    HD: data.HD,
                    CompteurHoraire1: data.CompteurHoraire1,
                    CompteurHoraire1Cumulé: data.CompteurHoraire1Cumulé,
                    Tendance: data.Tendance,
                    IsSimulated: data.IsSimulated
                }
                this.dataSource.push([{type: 'update', key: i, data: temp}]);
            }
            this.grid.refresh(true);
        },
        format_duration(value, format){
            if (value) {
                return this.durationMode == 0 ?
                    value == '00:00:00' ? "-" : moment.duration(value).humanize() :
                    value;
            }else{
                return "no value"
            }
        },
        format_date(value, format){
            if (value) {
                return dayjs(value).format(format)
            }else{
                return "no value"
            }
        },
        split_array(input) {
            // remove [ ]
            if(input.startsWith('[') && input.endsWith(']') ) {
                input = input.splice(0, 1);
                input = input.splice(input.length -1, 1);
            }
            return input.split(',');
        }
    },
    computed: {
        grid() {
            return this.$refs.grid.instance;
        }
    }
}
</script>

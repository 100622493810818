<template>
    <b-card no-body v-if="isNumeric">
        <b-form style="margin: 1.25rem">
            <b-row>
                <b-col>
                    <b-form-group :label="$t('simulation.lowLimit')">
                        <b-form-input
                            id="lowLimit"
                            min="-1000000"
                            max="999999"
                            @change="
                                (value) => {
                                    if (this.simulationDetails.LowLimit >= this.simulationDetails.HighLimit) {
                                        this.simulationDetails.LowLimit = this.simulationDetails.HighLimit * 1 - 1;
                                    }
                                    if (value == null || value == '') {
                                        this.simulationDetails.LowLimit = 0;
                                    } else if (value > 999999) {
                                        this.simulationDetails.LowLimit = 999999;
                                    } else if (value < -1000000) {
                                        this.simulationDetails.LowLimit = -1000000;
                                    }
                                }
                            "
                            v-model="simulationDetails.LowLimit"
                            type="number"
                        />
                    </b-form-group>
                </b-col>
                <b-col>
                    <b-form-group :label="$t('simulation.highLimit')">
                        <b-form-input
                            id="highLimit"
                            min="-999999"
                            max="1000000"
                            @change="
                                (value) => {
                                    if (this.simulationDetails.HighLimit <= this.simulationDetails.LowLimit) {
                                        this.simulationDetails.HighLimit = this.simulationDetails.LowLimit * 1 + 1;
                                    }
                                    if (value == null || value == '') {
                                        this.simulationDetails.HighLimit = 0;
                                    } else if (value > 1000000) {
                                        this.simulationDetails.HighLimit = 1000000;
                                    } else if (value < -999999) {
                                        this.simulationDetails.HighLimit = -999999;
                                    }
                                }
                            "
                            v-model="simulationDetails.HighLimit"
                            type="number"
                        />
                    </b-form-group>
                </b-col>
            </b-row>
        </b-form>
    </b-card>
</template>

<script>
export default {
    name: 'Ramp',
    props: {
        simulationDetails: Object,
        type: String,
    },
    computed: {
        isNumeric() {
            return (
                this.type == 'System.UInt16' || this.type == 'System.Int16' || this.type == 'System.UInt32' || this.type == 'System.Int32' || this.type == 'System.Uint64' || this.type == 'System.Int64' || this.type == 'System.Single' || this.type == 'System.Double' || this.type == 'System.Decimal'
            );
        },
    },
    mounted() {
        if (this.simulationDetails.LowLimit == null || this.simulationDetails.LowLimit == '') {
            this.$set(this.simulationDetails, 'LowLimit', 0);
        }
        if (this.simulationDetails.HighLimit == null || this.simulationDetails.HighLimit == '') {
            this.$set(this.simulationDetails, 'HighLimit', 10);
        }
    },
};
</script>
<template>
    <div>
        <div class="ml-3 mr-3">
            <b-modal id="newInternalVariable" v-b-modal.modal-multi-1 size="xl" @ok="OnokAddVariableInternal" :title="$t('New.Internal.Variable')" :cancel-title="$t('Cancel')" :ok-title="$t('Ok')">
                <b-form class="m-3">
                    <b-row>
                        <b-col md="4">
                            <b-form-group :label="$t('Name')">
                                <b-form-input id="variableName" v-model="variableName" />
                            </b-form-group>
                        </b-col>
                        <b-col md="4">
                            <b-form-group :label="$t('Type')">
                                <b-form-select v-model="selectedTypeOption" :options="this.itemColumns[1].options"></b-form-select>
                            </b-form-group>
                        </b-col>
                        <b-col md="4">
                            <b-form-group :label="$t('Description')">
                                <b-form-input id="descriptionVariable" v-model="descriptionVariable" />
                            </b-form-group>
                        </b-col>
                    </b-row>

                    <b-row>
                        <b-col md="12">
                            <b-form-group :label="$t('Formula')">
                                <!--<b-form-textarea id="formula" v-model="formula" />-->
                                <CodeEditor ref="editor" id="formula" :Formula="formula" :propProject="project" />
                            </b-form-group>
                        </b-col>
                    </b-row>
                </b-form>
            </b-modal>
            <b-row class="ml-1 mt-2">
                <b-col cols="auto" class="ml-auto">
                    <b-button class="text-lowercase" variant="outline-primary" @click="clickAddVariableInternal"> <font-awesome-icon icon="plus" class="cursor-pointer" />{{ ' ' + $t('Internal.Variable') }} </b-button>
                </b-col>
            </b-row>
        </div>
        <div class="tabContent ml-3 mr-3 mb-3 mt-2">
            <b-row>
                <b-col>
                    <DxDataGrid
                        :dataSource="dataSource"
                        :allow-column-reordering="true"
                        :show-borders="true"
                        :selection="{ mode: 'single' }"
                        :columnAutoWidth="false"
                        :allow-column-resizing="true"
                        @row-dbl-click="onRowDoubleClick"
                        style="min-height: calc(100vh - 200px); max-height: calc(100vh - 200px)"
                        :paging="{
                            enabled: false,
                        }"
                        :header-filter="{
                            visible: true,
                            search: {
                                enabled: true,
                            },
                        }"
                        :filter-row="{
                            visible: true,
                            applyFilter: 'auto',
                        }"
                        :search-panel="{
                            visible: true,
                            width: 240,
                            placeholder: 'Search...',
                        }"
                    >
                        <DxColumn :group-index="0" :sort-index="0" data-field="Group" data-type="string" :caption="$t('Group')" :calculate-group-value="calculateGroupValue" :group-cell-template="groupCellTemplate" />
                        <DxColumn v-for="column in itemColumns" :key="column.key" :data-field="column.dataField" :data-type="column.dataType" :caption="column.caption" alignment="left">
                            <DxLookup v-if="column.options" :data-source="column.options" />
                        </DxColumn>
                    </DxDataGrid>
                </b-col>
            </b-row>

            <!-- Variable details popup -->
            <VariableDetails
                :PopupId="id"
                :Readonly="!$store.state.auth.user.permissions.PermissionWrite"
                :onChanges="onChanges"
                :Project="project"
                :Equipment="equipment"
                :Group="selectedVariable == null ? '' : selectedVariable.Group"
                :Variable="selectedVariable == null ? '' : selectedVariable.Name"
                :Type="selectedVariable == null ? '' : selectedVariable.Type"
                :GroupVarList="dataSource"
            />
        </div>
    </div>
</template>

<script>
import ProdComEquipmentService from '@/services/prodcom.equipments.service.js';
import ItemConfiguration from '@/components/configuration/Equipments/ItemConfiguration.vue';
import VariableDetails from '@/components/configuration/Variables/VariableDetails.vue';
import ToastAlert from '@/utils/ToastAlert';
import CodeEditor from '@/components/CodeEditor.vue';
import { uuid } from 'vue-uuid';
import { DxDataGrid, DxColumn, DxGrouping, DxGroupPanel, DxSearchPanel, DxPaging, DxPager, DxToolbar, DxItem, DxScrolling, DxColumnChooser, DxLookup } from 'devextreme-vue/data-grid';
export default {
    name: 'Variables',
    components: {
        DxColumn,
        DxGroupPanel,
        DxGrouping,
        DxPaging,
        DxScrolling,
        DxPager,
        DxSearchPanel,
        DxDataGrid,
        DxToolbar,
        DxItem,
        DxColumnChooser,
        DxLookup,
        ItemConfiguration,
        VariableDetails,
        CodeEditor,
    },
    props: {
        equipment: String,
        project: String,
    },
    data() {
        return {
            dataSource: [],
            itemColumns: [
                { key: 'Name', dataField: 'Name', dataType: 'string', caption: this.$t('Name') },
                { key: 'Type', dataField: 'Type', dataType: 'string', caption: this.$t('Type'), options: ['System.Double', 'System.Float', 'System.UInt16', 'System.Int16', 'System.UInt32', 'System.Int32', 'System.UInt64', 'System.Int64', 'System.Boolean', 'System.DateTime', 'System.String'] },
            ],
            // groups: [],
            groupConfiguration: { rows: [] },
            selectedVariable: null,
            idEditor: `monaco-editor-${uuid.v4()}`,
            editor: null,
            monaco: null,
            selectedTypeOption: 'System.Double',
            variableName: '',
            formula: '',
            descriptionVariable: '',
            id: `vars_${uuid.v4()}`,
        };
    },
    async mounted() {
        await this.getData();
    },
    methods: {
        calculateGroupValue(rowData) {
            if (rowData.Group == 'ProdCom') return 'ZZZZZZZZZZZ';
            return rowData.Group;
        },
        groupCellTemplate(container, options) {
            if (options.value == 'ZZZZZZZZZZZ') {
                container.append(`ProdCom`);
            } else {
                container.append(`${options.text}`);
            }
        },
        async onChanges(refreshTreeviewEquipments) {
            await this.getData();
            if (refreshTreeviewEquipments) this.$EventBus.$emit('refresh-treeview-equipments');
        },
        async getData() {
            if (!this.$store.state.auth.user.permissions.PermissionRead) return;
            var result = await ProdComEquipmentService.getEquipmentVariables(this.equipment, this.project);
            if (result.success == 'n') this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(result.ret), 'warning'));
            else {
                const vars = result.ret.List;
                // Put the variables in the Group ProdCom at the end of the list
                const prodcomGroup = vars.find((v) => v.Group == 'ProdCom');
                if (prodcomGroup) {
                    vars.splice(vars.indexOf(prodcomGroup), 1);
                    vars.push(prodcomGroup);
                }
                this.dataSource = vars;
            }

            // result = await ProdComEquipmentService.getGroupListDetailed(this.equipment, this.project)
            // if (result.success == "n") this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(result.ret), 'warning'));
            // else {
            //     this.groups = result.ret;
            // }
        },
        /**
         * When double clicking variable, open its details in a modal.
         */
        async onRowDoubleClick(e) {
            this.selectedVariable = e.data;
            this.$bvModal.show(this.id);
        },

        async clickAddVariableInternal() {
            this.$bvModal.show('newInternalVariable');
        },
        async OnokAddVariableInternal(e) {
            this.formula = this.$refs.editor.getValue();
            let result = await ProdComEquipmentService.addInternalVariable(this.equipment, this.project, this.variableName, this.selectedTypeOption, this.formula, this.descriptionVariable);
            if (result.success == 'n') this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(result.ret), 'warning'));
            else {
                this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(result.ret), 'info'));
                await this.getData();
                this.$EventBus.$emit('refresh-treeview-equipments');
            }
        },
    },
};
</script>

<style></style>
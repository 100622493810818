<template>
    <div>
        <b-tab>
            <template #title>{{ $t('actions.consignationSourceEquipement.title1') }}</template>
            <!-- UI if equipment has a data source configured -->
            <b-card v-if="equipmentHasDataSource">
                <b-form>
                    <b-row>
                        <b-col cols="6">
                            <b-form-group :label="$t('actions.consignationSourceEquipement.sourceTable') + ' *'" label-for="sourceTable">
                                <b-form-select id="sourceTable" v-model="SourceTable" :options="availableSourceTables" v-on:change="sourceTableChanged" :class="{ disabled: !$store.state.auth.user.permissions.PermissionWrite }" />
                            </b-form-group>
                        </b-col>
                        <b-col cols="6">
                            <b-form-group :label="$t('Criteria') + ' *'" label-for="crtieria">
                                <div>
                                    <b-form-select id="crtieria" text-field="Name" value-field="Value" v-model="Criteria" :options="CriteriaList" style="width: 70%" :class="{ disabled: !$store.state.auth.user.permissions.PermissionWrite }" />
                                    <font-awesome-icon icon="minus" class="top-menu-icon mr-1 fa-lg" color="red" style="margin-left: 5%; width: 7%" v-on:click="onRemoveCriteria" v-if="Criteria != '' && Criteria != null" :class="{ disabled: !$store.state.auth.user.permissions.PermissionWrite }" />
                                    <font-awesome-icon icon="plus" class="top-menu-icon mr-1 fa-lg" color="green" style="width: 7%" v-on:click="onAddCriteria" :class="{ disabled: !$store.state.auth.user.permissions.PermissionWrite }" />
                                    <font-awesome-icon icon="pen" class="top-menu-icon mr-1 fa-lg" color="blue" style="width: 7%" v-on:click="onEditCriteria" v-if="Criteria != '' && Criteria != null" :class="{ disabled: !$store.state.auth.user.permissions.PermissionWrite }" />
                                </div>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="6">
                            <b-form-group :label="$t('actions.consignationSourceEquipement.associationGroup') + ' *'" label-for="assocGroup" :class="{ disabled: Criteria == '' || Criteria == null }">
                                <b-form-select id="assocGroup" v-model="AssociationGroup" :options="availableGroups" v-on:change="associationGroupChanged" :disabled="Criteria == '' || Criteria == null" :class="{ disabled: !$store.state.auth.user.permissions.PermissionWrite }" />
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <DxDataGrid
                                :disabled="Criteria == '' || Criteria == null"
                                :dataSource="Links"
                                :allow-column-reordering="true"
                                :repaint-changes-only="true"
                                :show-borders="false"
                                :selection="{ mode: 'single' }"
                                :columnAutoWidth="true"
                                show-scrollbar="onHover"
                                width="100%"
                                :allow-column-resizing="true"
                            >
                                <DxEditing :allow-updating="$store.state.auth.user.permissions.PermissionWrite" :allow-adding="$store.state.auth.user.permissions.PermissionWrite" :allow-deleting="$store.state.auth.user.permissions.PermissionWrite" :use-icons="true" mode="row" />
                                <DxColumn data-field="SourceVariable" data-type="string" :allow-editing="true" :caption="$t('Source Variable')" alignment="left">
                                    <DxLookup :data-source="availableDestinationItems" />
                                </DxColumn>
                                <DxColumn data-field="DestinationVariable" data-type="string" :allow-editing="true" :caption="$t('Data Source Variable')" alignment="left">
                                    <DxLookup :data-source="availableDataSourceItems" />
                                </DxColumn>
                            </DxDataGrid>
                        </b-col>
                    </b-row>
                </b-form>
            </b-card>
            <!-- Warn user if equipment has no data source configured -->
            <b-card v-else>
                <span>This action requires that the equipment has a data source configured. Please configure the equipment data source before editing.</span>
            </b-card>
        </b-tab>
        <SourceCriteria :Criteria="Criteria" :OnCriteriaValidate="OnCriteriaValidate" :OnCriteriaCancel="OnCriteriaCancel" :Action="this.action" :Project="this.propProject" :Table="this.SourceTable" :ExistingCriterias="CriteriaList.map((ct) => ct.Name)" />
    </div>
</template>

<script>
import { DxDataGrid, DxColumn, DxToolbar, DxItem, DxLookup, DxEditing } from 'devextreme-vue/data-grid';
import ProdComDataSourceService from '@/services/prodcom.datasources.service.js';
import ProdComEquipmentService from '@/services/prodcom.equipments.service.js';
import ToastAlert from '@/utils/ToastAlert';
import SourceCriteria from '@/components/configuration/Actions/SourceCriteria.vue';
export default {
    name: 'UpdateEquipment',
    props: {
        /**
         * Action object from parent component. Contains the equipment concerned,
         * and details about the action if opening an existing action.
         */
        action: Object,
        /**
         * Current project's Guid
         */
        propProject: String,
    },
    components: {
        DxDataGrid,
        DxColumn,
        DxEditing,
        DxToolbar,
        DxItem,
        DxLookup,
        SourceCriteria,
    },
    data() {
        return {
            SourceTable: '',
            Criteria: null,
            CriteriaList: [],
            AssociationGroup: '',
            Links: [],
            availableSourceTables: [],
            availableGroups: [],
            equipmentHasDataSource: false,
            availableDataSourceItems: [],
            availableDestinationItems: [],
            sourceCriteriaType: '',
            criteriaIndexBeforeCancel: -1,
        };
    },
    async mounted() {
        if (!this.$store.state.auth.user.permissions.PermissionRead) return;
        // Check if equipment has a data source
        var result = await ProdComDataSourceService.hasDataSource(this.action.Equipment, this.propProject);
        if (result.success == 'n') this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(result.ret, result.retParams), 'warning'));
        else {
            this.equipmentHasDataSource = result.ret;
        }
        // If he doesn't have a data source, don't continue with other queries
        if (!this.equipmentHasDataSource) return;

        // Get list of data source tables
        result = await ProdComDataSourceService.getDataSourceTables(this.action.Equipment, this.propProject);
        if (result.success == 'n') this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(result.ret, result.retParams), 'warning'));
        else {
            this.availableSourceTables = result.ret;
            this.SourceTable = this.action.ActionConfiguration?.SourceTable == null ? this.availableSourceTables[0] : this.action.ActionConfiguration?.SourceTable;
            await this.sourceTableChanged();
        }

        // Get list of equipment's group
        result = await ProdComEquipmentService.getGroups(this.action.Equipment, this.propProject);
        if (result.success == 'n') this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(result.ret), 'warning'));
        else {
            this.availableGroups = result.ret.List;
            this.AssociationGroup = this.action.ActionConfiguration?.AssociationGroup == null ? this.availableGroups[0] : this.action.ActionConfiguration?.AssociationGroup;
            await this.associationGroupChanged();
        }

        // Set links from existing action configuration
        this.Links = this.action.ActionConfiguration?.Links == null ? [] : this.action.ActionConfiguration?.Links;
        if (this.action.ActionConfiguration?.CriteriaList != null) {
            this.CriteriaList = [];
            for (const ct of this.action.ActionConfiguration?.CriteriaList) {
                this.CriteriaList.push({ Name: ct.Name, Value: ct });
            }
        }
        if (this.CriteriaList?.length > 0) {
            this.Criteria = this.CriteriaList[0]?.Value;
        }
    },
    methods: {
        /**
         * Called by parent when validating the configuration.
         * Needs to return the current configuration of the action.
         */
        async validate() {
            var result = {
                SourceTable: this.SourceTable,
                CriteriaList: this.CriteriaList.map((ct) => ct.Value),
                AssociationGroup: this.AssociationGroup,
                Links: this.Links,
            };
            return result;
        },
        /**
         * Updates source item list when source group changes
         */
        async sourceTableChanged(e) {
            if (!this.$store.state.auth.user.permissions.PermissionRead || this.SourceTable == null || this.SourceTable == '') return;
            const result = await ProdComDataSourceService.getDataSourceTableFields(this.action.Equipment, this.SourceTable, this.propProject);
            if (result.success == 'n') this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(result.ret), 'warning'));
            else {
                this.availableDataSourceItems = result.ret;
            }
        },
        /**
         * Updates association item list when association group changes
         */
        async associationGroupChanged(e) {
            if (!this.$store.state.auth.user.permissions.PermissionRead || this.AssociationGroup == null || this.AssociationGroup == '') return;
            const result = await ProdComEquipmentService.getItems(this.action.Equipment, this.AssociationGroup, this.propProject);
            if (result.success == 'n') this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(result.ret, result.retParams), 'warning'));
            else {
                this.availableDestinationItems = [];
                for (const [key, val] of Object.entries(result.ret.List)) {
                    this.availableDestinationItems.push(val.Name);
                }
            }
        },
        /**
         * Remove currently selected criteria
         */
        async onRemoveCriteria(e) {
            const idx = this.CriteriaList.findIndex((ct) => ct.Value == this.Criteria);
            if (idx != -1) {
                this.CriteriaList.splice(idx, 1);
                this.Criteria = this.CriteriaList.length == 0 ? null : this.CriteriaList[idx]?.Value;
            }
        },
        /**
         * Display criteria modal for addition.
         */
        async onAddCriteria(e) {
            this.criteriaIndexBeforeCancel = this.CriteriaList.findIndex((ct) => ct.Value == this.Criteria);
            this.Criteria = { Name: '', Table: this.SourceTable };
            this.$bvModal.show('criteria');
            this.sourceCriteriaType = 'add';
        },
        /**
         * Display criteria modal for edition.
         */
        async onEditCriteria(e) {
            this.criteriaIndexBeforeCancel = this.CriteriaList.findIndex((ct) => ct.Value == this.Criteria);
            this.$bvModal.show('criteria');
            this.sourceCriteriaType = 'edit';
        },
        /**
         * Callback on criteria validate. Called when clicking on 'OK' on the modal.
         */
        async OnCriteriaValidate(e) {
            if (e != null) {
                // User displayed the popup from the 'Add' button
                if (this.sourceCriteriaType == 'add') {
                    var idx = this.CriteriaList.findIndex((ct) => ct.Name == e.Name);
                    // Don't add if already exists
                    if (idx == -1) {
                        this.CriteriaList.push(e);
                        this.Criteria = this.CriteriaList[this.CriteriaList.length - 1]?.Value;
                    } else {
                        // Warn user that it already exists
                        this.$EventBus.$emit('show-toast', new ToastAlert(this.$t('This element already exists'), 'warning'));
                        return false;
                    }
                    // User displayed the popup from the 'Edit' button
                } else if (this.sourceCriteriaType == 'edit') {
                    var idx = this.CriteriaList.findIndex((ct) => ct.Name == e.Name && ct.Name != this.CriteriaList[this.criteriaIndexBeforeCancel]?.Name);
                    if (idx == -1) {
                        this.CriteriaList.splice(this.criteriaIndexBeforeCancel, 1);
                        this.CriteriaList.push(e);
                        this.Criteria = this.CriteriaList[this.CriteriaList.length - 1]?.Value;
                    } else {
                        this.$EventBus.$emit('show-toast', new ToastAlert(this.$t('This element already exists'), 'warning'));
                        return false;
                    }
                }
                return true;
            }
        },
        /**
         * Reset the criteria selection when cancelling the modal.
         */
        async OnCriteriaCancel(e) {
            this.Criteria = this.CriteriaList?.[0]?.Value;
        },
    },
};
</script>
<template>
    <div>
        <b-tab>
            <template #title>{{$t('actions.sendmail.serverconfig')}}</template>
            <b-card>
                <b-form>
                    <b-row>
                        <b-col>
                            <b-form-group :label="$t('actions.sendmail.serverconfig') + ' *'" label-for="host">
                                <b-form-input id="host" v-model="Host" :class="{'disabled': !$store.state.auth.user.permissions.PermissionWrite}"/>
                            </b-form-group>
                        </b-col>
                        <b-col>
                            <b-form-group :label="$t('actions.sendmail.port') + ' *'" label-for="port">
                                <b-form-input id="port" v-model="Port" type="number" min="0" max="65535" :class="{'disabled': !$store.state.auth.user.permissions.PermissionWrite}"/>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <b-form-checkbox switch v-model="Ssl">SSL</b-form-checkbox>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <b-form-checkbox switch v-model="NeedsLogin">{{$t('actions.sendmail.needsLogin')}}</b-form-checkbox>
                        </b-col>
                    </b-row>
                    <b-row v-if="NeedsLogin">
                        <b-col>
                            <b-form-group :label="$t('User name') + ' *'" label-for="userName">
                                <b-form-input id="userName" v-model="Username" :class="{'disabled': !$store.state.auth.user.permissions.PermissionWrite}"/>
                            </b-form-group>
                        </b-col>
                        <b-col>
                            <b-form-group :label="$t('Password') + ' *'" label-for="password">
                                <b-form-input id="password" v-model="Password" type="password" :class="{'disabled': !$store.state.auth.user.permissions.PermissionWrite}"/>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </b-form>
            </b-card>
        </b-tab>
        <b-tab>
            <template #title>{{$t('actions.sendmail.messageconfig')}}</template>
            <b-card>
                <b-form>
                    <b-row>
                        <b-col :cols="SizeCol">
                            <b-form-group :label="$t('actions.sendmail.sender') + ' *'" label-for="sender">
                                <b-form-input id="sender" v-model="Sender" type="email" :class="{'disabled': !$store.state.auth.user.permissions.PermissionWrite}"/>
                            </b-form-group>
                        </b-col>
                        <b-col :cols="SizeCol">
                            <b-form-group :label="$t('actions.sendmail.recipients') + ' *'" label-for="recipients">
                                <b-form-tags id="recipients" v-model="Recipients" separator=" ,;" remove-on-delete :class="{'disabled': !$store.state.auth.user.permissions.PermissionWrite}"/>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col :cols="SizeCol">
                            <b-form-group :label="$t('actions.sendmail.subject') + ' *'" label-for="subject">
                                <b-form-input id="subject" v-model="Subject" :class="{'disabled': !$store.state.auth.user.permissions.PermissionWrite}"/>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <b-form-group :label="$t('actions.sendmail.messageconfig') + ' *'" label-for="body">
                                <b-form-textarea id="body" v-model="Body" :class="{'disabled': !$store.state.auth.user.permissions.PermissionWrite}"></b-form-textarea>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="9"></b-col>
                        <b-col cols="3">
                            <b-button style="width: 100%" v-on:click="onAddVariables" :class="{'disabled': !$store.state.auth.user.permissions.PermissionWrite}">{{$t('actions.sendmail.addVariables')}}</b-button>
                        </b-col>
                    </b-row>
                </b-form>
            </b-card>
        </b-tab>
        <MessageBuilder :project="propProject" :equipment="action.Equipment" :body="Body" :onOk="onMessageBuilderOk" />
    </div>
</template>

<script>
import MessageBuilder from "@/components/MessageBuilder.vue";
/**
 * "Send Mail" action configuration.
 */
export default {
    name: 'EnvoiMail',
    props:{
        /**
         * Action object from parent component. Contains the equipment concerned,
         * and details about the action if opening an existing action.
         */
        action: Object,
        /**
         * Current project's Guid
         */
        propProject: String,
    },
    components: {
        MessageBuilder,
    },
    data(){
        return {
            Host: '',
            Port: 587,
            NeedsLogin: true,
            Ssl: true,
            Username: "",
            Password: "",
            Sender: "",
            Recipients: [],
            Subject: "",
            Body: "",
            UsedVariables: [],
            SizeCol: 6,
            itemListVariables: [],
        }
    },
    async mounted(){
        if(this.action.ActionConfiguration != null){
            this.Host = this.action.ActionConfiguration.Host;
            this.Port = this.action.ActionConfiguration.Port;
            this.NeedsLogin = this.action.ActionConfiguration.NeedsLogin;
            this.Ssl = this.action.ActionConfiguration.Ssl;
            this.Username = this.action.ActionConfiguration.Username;
            this.Password = this.action.ActionConfiguration.Password;
            this.Sender = this.action.ActionConfiguration.Sender;
            this.Recipients = this.action.ActionConfiguration.Recipients;
            this.Subject = this.action.ActionConfiguration.Subject;
            this.Body = this.action.ActionConfiguration.Body;
            this.UsedVariables = this.action.ActionConfiguration.UsedVariables;
        }
    },
    methods:{
        /**
         * Called by parent when validating the configuration.
         * Needs to return the current configuration of the action. 
         */
        async validate(){
            var result = {
                Host: this.Host,
                Port: this.Port,
                NeedsLogin: this.NeedsLogin,
                Ssl: this.Ssl,
                Username: this.Username,
                Password: this.Password,
                Sender: this.Sender,
                Recipients: this.Recipients,
                Subject: this.Subject,
                Body: this.Body,
                UsedVariables: this.UsedVariables,
            }
            return result;
        },
        async onAddVariables(e){
            this.$bvModal.show("messageBuilder");
        },
        async onMessageBuilderOk(e){
            this.Body = e.Body;
            this.UsedVariables = e.Variables;
        }
    }
}
</script>
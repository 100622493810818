<template>
    <div>
        <!-- <b-modal id="bv-modal-ask-license" v-if="minLevelRole <= $RoleLevels.Admin" :title="$t('Ask license')" ok-only centered size="lg" hide-footer no-close-on-esc no-close-on-backdrop> -->
        <b-modal id="bv-modal-help" :title="$t('Help')" ok-only centered size="lg" hide-footer no-close-on-esc no-close-on-backdrop>
            <h4>-> {{$store.state.helpTitle}}</h4>
            <br>
            <p class="preserve-newline">
                {{$store.state.helpText}}
            </p>
            
        </b-modal>
    </div>
</template>
<script>
export default{
    name: "Help"
}
</script>
<style>
.preserve-newline {
            white-space: pre-line;
        }
</style>
import store from "@/store/index.js";

class DashboardService {
    // Compute cases for language compatibility
    dxPanelToApiPanel(dxPAnel) {
        const apiPanel = {
            Id: dxPAnel.id,
            SizeX: dxPAnel.sizeX,
            SizeY: dxPAnel.sizeY,
            Row: dxPAnel.col,
            Column: dxPAnel.col
        }
        return apiPanel;
    }
    // Compute cases for language compatibility
    dxSummaryItemsToApiSummaryItems(totalItems) {
        let apiTotalItems = [];
        if (totalItems && totalItems.length > 0) {
            for (let index = 0; index < totalItems.length; index++) {
                const totalItem = totalItems[index];
                const apiTotalItem = {
                    Column: totalItem.column,
                    SummaryType: totalItem.summaryType,
                    ValueFormat: totalItem.valueFormat ? {
                        Precision: totalItem.valueFormat.precision
                    } : null,
                }
                apiTotalItems.push(apiTotalItem);
            }
        }
        return apiTotalItems;
    }
    async getAllDashboards() {
        const settings = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                Method: 'dashboards.get',
                Token: store.state.auth.user.accessToken,
                Parameters: {},
            }),
        };

        const res = await fetch(store.state.settings.API_URL, settings);
        const data = await res.json();
        return data;
    }
    async getDashboardById(id) {
        const settings = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                Method: 'dashboards.getDashboard',
                Token: store.state.auth.user.accessToken,
                Parameters: {
                    Id: id
                },
            }),
        };

        const res = await fetch(store.state.settings.API_URL, settings);
        const data = await res.json();
        return data;
    }
    async saveDashboard(dashboard) {
        // Parse dashboard panels
        const panels = [];
        if (store.state.panels[dashboard.Id]) {
            for (let index = 0; index < store.state.panels[dashboard.Id].length; index++) {
                const panel = store.state.panels[dashboard.Id][index];
                const panelSettings = store.state.panelsSettings[panel.Id];
                panel.Name = panelSettings.panelName;
                panel.ConnectionStringId = (panelSettings.connectionStringId && panelSettings.connectionStringId != null) ? panelSettings.connectionStringId : 0;
                panel.ConnectionStringAlias = panelSettings.connectionStringAlias ? panelSettings.connectionStringAlias : null;
                panel.SelectedTableName = panelSettings.tableName;
                panel.AutoRefresh = panelSettings.autoRefresh;
                panel.RefreshInterval = panelSettings.refreshInterval ? panelSettings.refreshInterval : 60;
                panel.BackgroundColor = panelSettings.backgroundColor ? panelSettings.backgroundColor : 'default';
                panel.Displayer = panelSettings.panelType;
                panel.IsPrefiltered = panelSettings.isPrefiltered;
                panel.FilteredColumn = panelSettings.filteredColumn;
                panel.PreFilters = panelSettings.preFilters ? JSON.stringify(panelSettings.preFilters) : null;
                panel.DataSourcePreFilters = panelSettings.dataSourcePreFilters ? JSON.stringify(panelSettings.dataSourcePreFilters) : null;

                // dataGrids settings
                panel.ColumnCaptions = JSON.stringify(panelSettings.columnCaptions);
                panel.RelationshipSettings = JSON.stringify(panelSettings.relationshipSettings);
                panel.HourlyRelationshipSettings = JSON.stringify(panelSettings.hourlyRelationshipSettings);
                panel.SummaryTotalItems = panelSettings.summaryTotalItems;
                panel.SummaryGroupItems = panelSettings.summaryGroupItems;
                panel.ComponentState = JSON.stringify(panelSettings.componentState);
                // forms settings
                panel.LabelLocation = panelSettings.labelLocation;
                panel.ColCount = panelSettings.colCount;
                panel.CurrentRow = panelSettings.currentRow;
                panel.HiddenFields = panelSettings.hiddenFields;
                panel.FieldsOrder = panelSettings.fieldsOrder;
                panel.FKRelationshipSettings = JSON.stringify(panelSettings.FKRelationshipSettings);
                // pivot grids settings
                panel.ShowChart = panelSettings.showChart;
                panel.PivotGridChartHeight = panelSettings.pivotGridChartHeight;
                // charts settings
                panel.Series = JSON.stringify(panelSettings.series);
                panel.ChartType = panelSettings.chartType;
                panel.ChartLabelsPosition = panelSettings.chartLabelsPosition;
                panel.LegendPosition = panelSettings.legendPosition;
                panel.LegendColumns = panelSettings.legendColumns;
                panel.LegendRows = panelSettings.legendRows;
                // webSource settings
                panel.WebSourceId = (panelSettings.webSourceId && panelSettings.webSourceId != null) ? panelSettings.webSourceId : 0;
                panel.WebSourceAlias = panelSettings.webSourceAlias;
                // diagram settings
                panel.DiagramData = panelSettings.diagramData ? JSON.stringify(panelSettings.diagramData) : null;
                // rtSource settings
                panel.RTSourceId = (panelSettings.rtSourceId && panelSettings.rtSourceId != null) ? panelSettings.rtSourceId : 0;
                panel.RTSourceAlias = panelSettings.rtSourceAlias;
                panel.SelectedRTItems = JSON.stringify(panelSettings.selectedRTItems);
                panel.RTChartMaxPoints = panelSettings.rtChartMaxPoints;
                panel.SelectedDevicesNames = JSON.stringify(panelSettings.selectedDevicesNames);
                panel.RTEventsPriority = panelSettings.rtEventsPriority;
                panel.RTEventsPrioritySign = panelSettings.rtEventsPrioritySign;
                panel.RTEventsFormatting = JSON.stringify(panelSettings.rtEventsFormatting);
                panel.RTEventsFontZoom = panelSettings.rtEventsFontZoom;
                panel.RTElements = JSON.stringify(panelSettings.rtElements);

                panels.push(panel);
            }
        }
        let bodyParameters = {
            Name: dashboard.Name,
            Group: dashboard.Group,
            HorizontalSpacing: dashboard.HorizontalSpacing,
            VerticalSpacing: dashboard.VerticalSpacing,
            Columns: dashboard.Columns,
            AspectRatio: dashboard.AspectRatio,
            IsPublic: dashboard.IsPublic,
            Mode: dashboard.Mode,
            DashboardItems: panels
        }
        let bodyMethod = 'dashboards.create';
        if (dashboard.Id > 0) {
            bodyParameters.Id = dashboard.Id;
            bodyMethod = 'dashboards.update';
        }
        const settings = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                Method: bodyMethod,
                Token: store.state.auth.user.accessToken,
                Parameters: bodyParameters,
            }),
        };

        const res = await fetch(store.state.settings.API_URL, settings);
        const data = await res.json();
        return data;
    }
    async removeDashboardById(id) {
        const settings = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                Method: 'dashboards.delete',
                Token: store.state.auth.user.accessToken,
                Parameters: {
                    Id: id
                },
            }),
        };

        const res = await fetch(store.state.settings.API_URL, settings);
        const data = await res.json();
        return data;
    }
    async getDashboard(id) {
        const settings = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                Method: 'dashboards.getDashboard',
                Token: store.state.auth.user.accessToken,
                Parameters: {
                    Id: id
                },
            }),
        };

        const res = await fetch(store.state.settings.API_URL, settings);
        const data = await res.json();
        return data;
    }
    async addDashboardItems(id, items) {
        const settings = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                Method: 'dashboards.addItems',
                Token: store.state.auth.user.accessToken,
                Parameters: {
                    Id: id,
                    DashboardItems: items,
                },
            }),
        };

        const res = await fetch(store.state.settings.API_URL, settings);
        const data = await res.json();
        return data;
    }
    async checkDashboardSourcesBeforeUpload(jsondata) {
        const settings = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                Method: 'dashboards.checkDashboardSourcesBeforeUpload',
                Token: store.state.auth.user.accessToken,
                Parameters: {
                    DashboardItems: jsondata.DashboardItems
                },
            }),
        };
        const res = await fetch(store.state.settings.API_URL, settings);
        const data = await res.json();
        return data;
    }
    async uploadDashboard(jsonData) {
        const settings = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                Method: 'dashboards.uploadDashboard',
                Token: store.state.auth.user.accessToken,
                Parameters: jsonData,
            }),
        };
        const res = await fetch(store.state.settings.API_URL, settings);
        const data = await res.json();
        return data;
    }
    async downloadDashboard(id) {
        const settings = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                Method: 'dashboards.downloadDashboard',
                Token: store.state.auth.user.accessToken,
                Parameters: {
                    Id: id
                },
            }),
        };
        const res = await fetch(store.state.settings.API_URL, settings);
        const data = await res.json();
        return data;
    }
}

export default new DashboardService();
<template>
    <b-tab>
        <template #title>
            {{ $t('actions.acquisitionEquipement.archiving') }}
            <font-awesome-icon icon="fa fa-warning" class="ml-1 text-danger" v-if="invalidState" />
        </template>
        <b-card v-if="equipmentHasDataSource">
            <b-form>
                <b-row>
                    <b-col>
                        <b-form-group :label="$t('actions.acquisitionEquipement.tableConfig') + ' *'" label-for="tableConfigurationGroup">
                            <b-form-checkbox-group id="tableConfigurationGroup" v-model="selectedConfigurationTable" :options="availableConfigurationsTable" @change="onTableConfigChange" :class="{ disabled: !$store.state.auth.user.permissions.PermissionWrite }"> </b-form-checkbox-group>
                        </b-form-group>
                    </b-col>
                    <b-col>
                        <b-form-group :label="$t('actions.acquisitionEquipement.tableName') + ' *'" label-for="tableName">
                            <b-form-input id="tableName" v-if="selectedConfigurationTable == 'newTable'" v-model="tableName" :state="tableNameState" :class="{ disabled: !$store.state.auth.user.permissions.PermissionWrite }" />
                            <b-form-select id="tableName" v-else v-model="tableName" :options="availableTables" :state="tableNameState" :class="{ disabled: !$store.state.auth.user.permissions.PermissionWrite }" />
                        </b-form-group>
                    </b-col>
                    <b-col>
                        <b-form-group :label="$t('actions.acquisitionEquipement.nomHorodatage')" label-for="nomHorodatage">
                            <b-form-input id="nomHorodatage" v-model="nomHorodatage" :class="{ disabled: !this.$store.state.auth.user.permissions.PermissionWrite }" />
                        </b-form-group>
                    </b-col>
                </b-row>
                <hr color="grey" />
                <b-row>
                    <b-col>
                        <b-form-group :label="$t('actions.acquisitionEquipement.archivalType') + ' *'" label-for="archivalType">
                            <b-form-checkbox-group id="archivalType" v-model="selectedArchivalType" :options="availableArchivalTypes" @change="onArchivalTypeChange" :class="{ disabled: !$store.state.auth.user.permissions.PermissionWrite }"> </b-form-checkbox-group>
                        </b-form-group>
                    </b-col>
                </b-row>
                <hr color="grey" />
                <b-row>
                    <DxDataGrid
                        class="mx-3"
                        :dataSource="items"
                        :allow-column-reordering="true"
                        :repaint-changes-only="true"
                        :show-borders="true"
                        :selection="{ mode: 'single' }"
                        :columnAutoWidth="true"
                        :allow-column-resizing="true"
                        @init-new-row="onRowInserting"
                        @row-updating="onItemValueChanged"
                        @editing-start="onEditingStart"
                    >
                        <DxEditing :allow-updating="$store.state.auth.user.permissions.PermissionWrite" :allow-adding="$store.state.auth.user.permissions.PermissionWrite" :allow-deleting="$store.state.auth.user.permissions.PermissionWrite" :use-icons="true" mode="row" />
                        <DxColumn data-field="Group" data-type="string" :allow-editing="true" :caption="$t('Group')" :set-cell-value="onGroupValueChanged" alignment="left">
                            <DxLookup :data-source="equipmentGroups" />
                        </DxColumn>
                        <DxColumn data-field="Item" data-type="string" :allow-editing="true" :caption="$t('Item')" alignment="left">
                            <DxLookup :data-source="currentGrouItems" />
                        </DxColumn>
                        <DxColumn v-if="selectedArchivalType == 'column'" data-field="Variable" data-type="string" :allow-editing="true" :caption="$t('Var')" alignment="left">
                            <DxLookup :data-source="equipmentItemVariables" />
                        </DxColumn>
                        <DxColumn v-if="selectedArchivalType == 'column'" data-field="TableField" data-type="string" :allow-editing="true" :caption="$t('actions.acquisitionEquipement.tableField')" alignment="left">
                            <DxLookup v-if="selectedConfigurationTable == 'existingTable'" :data-source="dataTableField" />
                        </DxColumn>
                    </DxDataGrid>
                </b-row>
            </b-form>
        </b-card>
        <b-card v-else>
            <span>This action requires that the equipment has a data source configured. Please configure the equipment data source before editing.</span>
        </b-card>
    </b-tab>
</template>

<script>
import { DxDataGrid, DxColumn, DxLookup, DxEditing } from 'devextreme-vue/data-grid';
import ProdComDataSourceService from '@/services/prodcom.datasources.service.js';
import ProdComEquipmentService from '@/services/prodcom.equipments.service.js';
import ToastAlert from '@/utils/ToastAlert';

export default {
    name: 'AcquisitionEquipement',
    props: {
        /**
         * Action object from parent component. Contains the equipment concerned,
         * and details about the action if opening an existing action.
         */
        action: Object,
        /**
         * Current project's Guid
         */
        propProject: String,
    },
    components: {
        DxDataGrid,
        DxColumn,
        DxLookup,
        DxEditing,
    },
    watch: {
        async tableName(newVal, oldVal) {
            if (newVal == null || newVal == '') return;
            if (this.selectedConfigurationTable.findIndex((it) => it == 'existingTable') != -1) {
                if (!this.$store.state.auth.user.permissions.PermissionRead) return;
                var result = await ProdComDataSourceService.getDataSourceTableFields(this.action.Equipment, newVal, this.project);
                if (result.success == 'n') this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(result.ret, result.retParams), 'warning'));
                else {
                    this.dataTableField = result.ret;
                }
            }
        },
        propProject(newVal, oldVal) {
            this.project = newVal;
        },
    },
    computed: {
        invalidState() {
            return this.tableNameState == false;
        },
        tableNameState() {
            return this.tableName == null || this.tableName == '' ? false : null;
        },
    },
    async mounted() {
        if (!this.$store.state.auth.user.permissions.PermissionRead) return;
        // Check if equipment has a data source
        var result = await ProdComDataSourceService.hasDataSource(this.action.Equipment, this.project);
        if (result.success == 'n') this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(result.ret, result.retParams), 'warning'));
        else {
            this.equipmentHasDataSource = result.ret;
        }
        // Don't do anything more if the equipment doesn't have a data source (avoid useless queries)
        if (!this.equipmentHasDataSource) return;
        // Get equipment group list
        result = await ProdComEquipmentService.getGroups(this.action.Equipment, this.project);
        if (result.success == 'n') this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(result.ret, result.retParams), 'warning'));
        else {
            this.equipmentGroups = result.ret.List;
        }

        // Get equipment item list
        await this.getEquipmentItems();

        // Get the data source table list
        result = await ProdComDataSourceService.getDataSourceTables(this.action.Equipment, this.project);
        if (result.success == 'n') this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(result.ret, result.retParams), 'warning'));
        else {
            this.availableTables = result.ret;
            var i = 1;
            while (this.availableTables.findIndex((r) => r === `${this.action.Equipment} ${i}`) != -1) {
                i++;
            }
            // if (this.action.ActionConfiguration != null && this.action.ActionConfiguration.NewTable)
            this.tableName = `${this.action.Equipment} ${i}`;
            // this.tableName = this.availableTables[0];
        }

        // If action already has some data, set them.
        if (this.action.ActionConfiguration != null) {
            // Set variables
            this.selectedConfigurationTable = this.action.ActionConfiguration.NewTable ? ['newTable'] : ['existingTable'];
            this.selectedArchivalType = this.action.ActionConfiguration.ArchivingType == 1 ? ['line'] : ['column'];
            this.nomHorodatage = this.action.ActionConfiguration.NomHorodatage;
            this.tableName = this.action.ActionConfiguration.TableName;
            for (var it of this.action.ActionConfiguration.ArchivalItems) {
                this.items.push({ Group: it.GroupName, Item: it.ItemName, Variable: it.Variable, TableField: it.Name });
            }
        }
    },
    data() {
        return {
            availableConfigurationsTable: [
                { text: 'new table', value: 'newTable' },
                { text: 'existing table', value: 'existingTable' },
            ],
            selectedConfigurationTable: ['newTable'],
            availableArchivalTypes: [
                { text: 'Line', value: 'line' },
                { text: 'Column', value: 'column' },
            ],
            selectedArchivalType: ['column'],
            tableName: 'new table',
            availableTables: [],
            items: [],
            equipmentGroups: [],
            equipmentItems: [],
            equipmentItemVariables: ['Valeur', 'ValeurN1', 'Max', 'Min', 'NbVariations'],
            dataTableField: [],
            nomHorodatage: 'HD',
            equipmentHasDataSource: false,
            project: this.propProject,
            currentGrouItems: [],
        };
    },
    methods: {
        async getEquipmentItems() {
            // get all equipment's variable (could not get the DevExtreme component to dynamically query this on value change...)
            this.equipmentItems = [];
            for (var grp of this.equipmentGroups) {
                var result = await ProdComEquipmentService.getItems(this.action.Equipment, grp, this.project);
                if (result.success == 'n') this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(result.ret, result.retParams), 'warning'));
                else {
                    for (const [key, val] of Object.entries(result.ret.List)) {
                        this.equipmentItems.push({ group: grp, item: val.Name, value: val });
                    }
                }
            }
        },
        getEquipmentItemsForCurrentGroup(group) {
            this.currentGrouItems = this.equipmentItems.filter((it) => it.group === group).map((it) => it.item);
        },
        async onTableConfigChange(e) {
            if (e.length == 2) this.selectedConfigurationTable = [e[1]];
            if (e.length == 0) {
                // TODO: switch to the other one... Later.
            }
        },
        async onArchivalTypeChange(e) {
            if (e.length == 2) this.selectedArchivalType = [e[1]];
            if (e.length == 0) {
                // TODO: switch to the other one... Later.
            }
        },
        async validate() {
            if (this.invalidState) return null;
            var result = {
                UseServerDataSource: true,
                ArchivingType: this.selectedArchivalType == 'line' ? 1 : 2,
                NewTable: this.selectedConfigurationTable == 'newTable',
                TableName: this.tableName,
                ArchivalLineAllWithoutString: false, // Wut ? what's that ???
                ArchivalItems: [],
                NomHorodatage: this.nomHorodatage,
            };
            for (var it of this.items) {
                result.ArchivalItems.push({ ItemName: it.Item, Name: it.TableField, GroupName: it.Group, Variable: it.Variable });
            }
            return result;
        },
        onRowInserting(e) {
            var firstVariableNonExistant = this.equipmentItems.filter((x) => !this.items.find((y) => y.Group == x.group && y.Item == x.item))?.[0];
            if (firstVariableNonExistant != null) {
                e.data.Group = firstVariableNonExistant.group;
                e.data.Item = firstVariableNonExistant.item;
                e.data.TableField = this.selectedConfigurationTable == 'newTable' ? firstVariableNonExistant.item : this.dataTableField[0];
            }
            this.getEquipmentItemsForCurrentGroup(e.data.Group);
            e.data.Item = e.data.Item ?? this.currentGrouItems[0];
            e.data.Variable = 'Valeur';
        },
        // Warning: on this event, make sure to unfocus Group cell before selecting Item cell. Otherwise, the event won't be called.
        // TODO: fix this behaviour.
        onItemValueChanged(e) {
            if (e.newData.Item == null) return; // Make sure we only set TableField when Item is modified, not with other columns.
            e.oldData.TableField = e.newData.Item;
        },
        onGroupValueChanged(newData, value, currentRowData) {
            newData.Group = value;
            if (value == null) {
                newData.Item;
                return; // Make sure we only set Item when Group is modified, not with other columns.
            }
            this.getEquipmentItemsForCurrentGroup(value);
            if (this.currentGrouItems.length > 0) newData.Item = this.currentGrouItems[0];
        },
        onEditingStart(e) {
            this.getEquipmentItemsForCurrentGroup(e.data.Group);
        },
    },
};
</script>
/**
 * Put all icons imported from FontAwesome here instead of the main.js file.
 */

import Vue from "vue";
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText } from '@fortawesome/vue-fontawesome';

Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('font-awesome-layers', FontAwesomeLayers);
Vue.component('font-awesome-layers-text', FontAwesomeLayersText);

import {
    faSignOutAlt,
    faSignInAlt,
    faChevronDown,
    faMoon,
    faSun,
    faPlus,
    faMinus,
    faCog,
    faFolderOpen,
    faFolderPlus,
    faSync,
    faSave,
    faTrash,
    faUser,
    faUsers,
    faUserTag,
    faDatabase,
    faCheck,
    faSearch,
    faStar,
    faMinusSquare,
    faCheckSquare,
    faSquare,
    faSignature,
    faTable,
    faPager,
    faChartPie,
    faChartBar,
    faWindowMaximize,
    faWindowRestore,
    faBars,
    faSlidersH,
    faCommentAlt,
    faFileAlt,
    faStepBackward,
    faStepForward,
    faEquals,
    faFilter,
    faTimes,
    faExclamationTriangle,
    faCircle,
    faSortAmountDownAlt,
    faSortAmountUp,
    faArrowRight,
    faLink,
    faClock,
    faFileInvoice,
    faEdit,
    faDownload,
    faPause,
    faCogs,
    faTools,
    faLayerGroup,
    faGlobe,
    faPlay,
    faArrowCircleUp,
    faArrowCircleDown,
    faEraser,
    faFont,
    faCopy,
    faPaste,
    faLaugh,
    faGrin,
    faSmile,
    faMeh,
    faFrown,
    faSadTear,
    faSadCry,
    faFrownOpen,
    faArrowUp,
    faArrowDown,
    faArrowLeft,
    faSquareFull,
    faCarBattery,
    faCaretUp,
    faCaretDown,
    faThumbsUp,
    faThumbsDown,
    faImage,
    faBackward,
    faForward,
    faInfo,
    faThermometerHalf,
    faQuestionCircle,
    faThumbtack,
    faBold,
    faItalic,
    faUnderline,
    faDesktop,
    faCaretLeft,
    faCaretRight,
    faAlignLeft,
    faAlignCenter,
    faExpand,
    faTh,
    faSearchPlus,
    faSearchMinus,
    faUndo,
    faList,
    faEye,
    faPalette,
    faCircleNotch,
    faProjectDiagram,
    faPen,
    faCloud,
    faAngleRight,
    faAngleDown,
    faMicrochip,
    faTag,
    faPauseCircle,
    faWifi,
    faArrowsRotate,
    faRightLeft,
} from '@fortawesome/free-solid-svg-icons'
library.add(faSignOutAlt)
library.add(faSignInAlt)
library.add(faChevronDown)
library.add(faMoon)
library.add(faSun)
library.add(faPlus)
library.add(faMinus)
library.add(faCog)
library.add(faFolder)
library.add(faFolderOpen)
library.add(faFolderPlus)
library.add(faSync)
library.add(faSave)
library.add(faTrash)
library.add(faUser)
library.add(faUsers)
library.add(faCloud)
library.add(faUserTag)
library.add(faDatabase)
library.add(faCheck)
library.add(faCheckCircle)
library.add(faTimesCircle)
library.add(faSearch)
library.add(faStar)
library.add(faStop)
library.add(faMinusSquare)
library.add(faCheckSquare)
library.add(faSquare)
library.add(faSignature)
library.add(faTable)
library.add(faPager)
library.add(faChartPie)
library.add(faChartBar)
library.add(faWindowMaximize)
library.add(faWindowRestore)
library.add(faBars)
library.add(faSlidersH)
library.add(faCommentAlt)
library.add(faFileAlt)
library.add(faStepBackward)
library.add(faStepForward)
library.add(faEquals)
library.add(faFilter)
library.add(faTimes)
library.add(faExclamationTriangle)
library.add(faExclamationCircle)
library.add(faCircle)
library.add(faSortAmountDownAlt)
library.add(faSortAmountUp)
library.add(faSpinnerThird)
library.add(faEmptySet)
library.add(faLink)
library.add(faClock)
library.add(faFileInvoice)
library.add(faEdit)
library.add(faDownload)
library.add(faPause)
library.add(faCogs)
library.add(faTools)
library.add(faLayerGroup)
library.add(faGlobe)
library.add(faPlay)
library.add(faArrowCircleUp)
library.add(faArrowCircleDown)
library.add(faAngleRight)
library.add(faAngleDown)
library.add(faIndustry)
library.add(faEraser)
library.add(faFont)
library.add(faCopy)
library.add(faPaste)
library.add(faLaugh)
library.add(faGrin)
library.add(faSmile)
library.add(faMeh)
library.add(faFrown)
library.add(faFrownOpen)
library.add(faSadTear)
library.add(faSadCry)
library.add(faArrowUp)
library.add(faArrowRight)
library.add(faArrowDown)
library.add(faArrowLeft)
library.add(faSquareFull)
library.add(faCarBattery)
library.add(faCaretUp)
library.add(faCaretDown)
library.add(faThumbsUp)
library.add(faThumbsDown)
library.add(faImage)
library.add(faBackward)
library.add(faForward)
library.add(faInfo)
library.add(faThermometerHalf)
library.add(faQuestionCircle)
library.add(faThumbtack)
library.add(faBold)
library.add(faItalic)
library.add(faUnderline)
library.add(faDesktop)
library.add(faCaretLeft)
library.add(faCaretRight)
library.add(faAlignLeft)
library.add(faAlignCenter)
library.add(faExpand)
library.add(faTh)
library.add(faSearchPlus)
library.add(faSearchMinus)
library.add(faUndo)
library.add(faList)
library.add(faEye)
library.add(faPalette)
library.add(faCircleNotch)
library.add(faSlidersHSquare)
library.add(faProjectDiagram)
library.add(faPen)
library.add(faArrowAltUp),
library.add(faArrowAltDown)
library.add(faArrowAltRight)
library.add(faHourglassHalf)
library.add(faMicrochip)
library.add(faTag)
library.add(faRedo)
library.add(faWifi)
library.add(faArrowsRotate)
library.add(faRightLeft)
import {
    faArrows,
    faArrowsH,
    faArrowsV,
    faBringForward,
    faContainerStorage,
    faEngineWarning,
    faSendBackward,
    faSlidersHSquare,
    faTachometerSlow,
    faTextSize,
    faArrowAltDown,
    faArrowAltUp,
    faArrowAltRight,
    faStop,
    faHourglassHalf,
    faCheckCircle,
    faTimesCircle,
    faExclamationCircle,
    faSpinner,
    faSpinnerThird,
    faEmptySet,
    faFunction,
    faRedo,
    faFolder,
    faIndustryAlt,
    faFileCode,
    faPlayCircle,
    faDoNotEnter,
    faIndustry as fasIndustry,
    faFileExport,
    faFileImport,
    faFile,
    faBan,
    faMonitorHeartRate,
    faGears,
    faWrench,
    faChartLine,
    faSquareExclamation,
    faArrowUpFromBracket,
    faSquareQuestion,
    faUpRightFromSquare,
    faFloppyDiskCircleArrowRight,
} from "@fortawesome/pro-solid-svg-icons";
library.add(faBringForward)
library.add(faSendBackward)
library.add(faArrows)
library.add(faEngineWarning)
library.add(faContainerStorage)
library.add(faTachometerSlow)
library.add(faArrowsV)
library.add(faArrowsH)
library.add(faTextSize)
library.add(faFunction)
library.add(faIndustryAlt)
library.add(faFileCode)
library.add(faPlayCircle)
library.add(faPauseCircle)
library.add(faDoNotEnter)
library.add(fasIndustry)
library.add(faFileExport)
library.add(faFileImport)
library.add(faBan)
library.add(faMonitorHeartRate)
library.add(faGears)
library.add(faArrowRightFromBracket)
library.add(faFile)
library.add(faWrench)
library.add(faChartLine)
library.add(faSquareExclamation)
library.add(faArrowUpFromBracket)
library.add(faSquareQuestion)
library.add(faUpRightFromSquare)
library.add(faFloppyDiskCircleArrowRight)

import {
    faIndustry,
    faContainerStorage as falContainerStorage
} from "@fortawesome/pro-light-svg-icons";
import { 
    faArrowRightFromBracket,
    faEthernet,
    faCalendars
} from "@fortawesome/pro-regular-svg-icons";
library.add(faIndustry)
library.add(faEthernet)
library.add(falContainerStorage)
library.add(faCalendars)
<template>
    <div>
        <font-awesome-icon icon="font" class="mr-1 cursor-pointer" size="xs" transform="down-3" @click="changeFontSize(-1)" />
        <font-awesome-icon icon="font" class="mr-1 cursor-pointer" size="lg" @click="changeFontSize(1)" />
    </div>
</template>
<script>
export default {
    name: 'FontSizeCtrl',
    mounted() {
        this.changeFontSize(0);
    },
    methods: {
        changeFontSize(delta) {
            var currentSize = localStorage.getItem('font-size') ? parseInt(localStorage.getItem('font-size')) : 14;
            document.documentElement.style.setProperty('--font-size', currentSize + delta + 'px');
            localStorage.setItem('font-size', currentSize + delta);
            this.$EventBus.$emit('font-size-changed');
        },
    },
};
</script>
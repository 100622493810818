<template>
    <div>
        <div>
            <b-row>
                <!-- server IP or domain name -->
                <b-col>
                    <b-form-group :label="$t('Database IP')" label-for="serverIP" :class="{ disabled: datasource.type == 'datasource' || !this.$store.state.auth.user.permissions.PermissionWrite }">
                        <b-form-input type="text" :state="serverState" v-model="server" autocomplete="off" />
                    </b-form-group>
                </b-col>
                <!-- server port (5432 by default) -->
                <b-col>
                    <b-form-group :label="$t('Database Port')" label-for="serverPort" :class="{ disabled: datasource.type == 'datasource' || !this.$store.state.auth.user.permissions.PermissionWrite }">
                        <b-form-input
                            type="number"
                            min="1"
                            max="65535"
                            @change="
                                (value) => {
                                    if (value == null || value == '') {
                                        this.port = 5432;
                                    } else if (value > 65535) {
                                        this.port = 65535;
                                    } else if (value < 1) {
                                        this.port = 1;
                                    }
                                }
                            "
                            v-model="port"
                            autocomplete="off"
                        />
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <!-- userName -->
                <b-col>
                    <b-form-group :label="$t('User name')" label-for="username" :class="{ disabled: datasource.type == 'datasource' || !this.$store.state.auth.user.permissions.PermissionWrite }">
                        <b-form-input type="text" :state="userNameState" v-model="userName" autocomplete="off" />
                    </b-form-group>
                </b-col>
                <b-col>
                    <b-form-group :label="$t('Password')" label-for="password" :class="{ disabled: datasource.type == 'datasource' || !this.$store.state.auth.user.permissions.PermissionWrite }">
                        <b-form-input type="password" v-model="Password" autocomplete="off" />
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <!-- TODO: b-form-select with searching of available databases. cf Productys Explorer. -->
                <!-- Data source base -->
                <b-col>
                    <b-form-group :label="$t('Database')" label-for="database" :class="{ disabled: datasource.type == 'datasource' || !this.$store.state.auth.user.permissions.PermissionWrite }">
                        <b-form-input type="text" :state="databaseState" v-model="database" autocomplete="off" />
                    </b-form-group>
                </b-col>
                <!-- TODO: b-form-select with searching of available schemas. cf Productys Explorer  -->
                <!-- database schema -->
                <b-col>
                    <b-form-group :label="$t('Database scheme')" label-for="schema" :class="{ disabled: datasource.type == 'datasource' || !this.$store.state.auth.user.permissions.PermissionWrite }">
                        <b-form-input
                            type="text"
                            @change="
                                (value) => {
                                    if (value == null || value == '') {
                                        this.schema = 'public';
                                    }
                                }
                            "
                            v-model="schema"
                            autocomplete="off"
                        />
                    </b-form-group>
                </b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
/**
 * PostgreSQL provider configuration.
 */
export default {
    name: 'PostgreSQL',
    props: ['datasource'],
    data() {
        return {
            server: this.datasource?.Server ?? '127.0.0.1',
            port: this.datasource?.Port ?? 5432,
            userName: this.datasource?.Username ?? 'postgres',
            Password: this.datasource?.Password ?? '',
            database: this.datasource?.Database ?? '',
            schema: this.datasource?.Schema ?? '',
        };
    },
    watch: {
        datasource(newVal, oldVal) {
            this.server = newVal.Server;
            this.port = newVal.Port;
            this.userName = newVal.Username;
            this.Password = newVal.Password;
            this.database = newVal.Database;
            this.schema = newVal.Schema;
        },
    },
    computed: {
        invalidState() {
            return this.serverState === false || this.userNameState === false || this.databaseState === false;
        },
        serverState() {
            return this.server == null || this.server == '' ? false : null;
        },
        userNameState() {
            return this.userName == null || this.userName == '' ? false : null;
        },
        databaseState() {
            return this.database == null || this.database == '' ? false : null;
        },
    },
    async mounted() {
        // TODO: search for database and schema if IP, Port, username and password are set.
    },
    methods: {
        /**
         * Returns the formated data for parent validation
         */
        async validate() {
            if (this.invalidState) return null;
            return {
                Server: this.server,
                Port: this.port,
                Username: this.userName,
                Password: this.Password,
                Database: this.database,
                Schema: this.schema,
                Provider: 'PostgreSQL',
                Equipment: this.datasource.Equipment,
            };
        },
    },
};
</script>
import store from "@/store/index.js";

class WebSourceService {
    async getWebSources() {
        const settings = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                Method: 'websources.get',
                Token: store.state.auth.user.accessToken,
                Parameters: {
                },
            }),
        };

        const res = await fetch(store.state.settings.API_URL, settings);
        const data = await res.json();
        return data;
    }
    async getUrlFromWebSourceId(id){
        const settings = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                Method: 'websources.getUrlFromId',
                Token: store.state.auth.user.accessToken,
                Parameters: {
                    Id: id,
                },
            }),
        };

        const res = await fetch(store.state.settings.API_URL, settings);
        const data = await res.json();
        return data;
    }
}

export default new WebSourceService();
<template>
    <b-modal id="backup" v-b-modal.modal-multi-2 size="xl"
        @cancel="onItemCancel" @ok="onItemOk" @show="onItemShow"
        :title="$t('Backup') + ' - ' + this.projectName" :cancel-title="$t('Cancel')" :ok-title="$t('Ok')">
        <b-form>
            <b-row>
                <b-col>
                    <b-form-textarea
                        id="textarea"
                        v-model="backupNotes"
                        placeholder="Notes..."
                        rows="3"
                        max-rows="6"></b-form-textarea>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <span>Available variables: {{this.availableVariables}}</span>
                </b-col>
            </b-row>
        </b-form>
    </b-modal>
</template>

<script>
import ProdComProjectService from "@/services/prodcom.projects.service.js"
import ToastAlert from '@/utils/ToastAlert';
export default {
    name: "BackupWizard",
    props: {
        propProject: Object,
        /**
         * Function called when loading the component. Must be asynchronous.
         */
        SetLoadingState: Function,
        OnLoad: Function,
        OnOk: Function,
        OnCancel: Function,
    },
    data(){
        return {
            projectName: "",
            projectId: "",
            backupNotes: "{{projectName}}",
            availableVariables: "{{projectName}}, {{prodcomVersion}}, {{dateTaken}}"
        }
    },
    watch: {
        propProject(newValue, oldValue){
            this.projectName = newValue?.itemData.text;
            this.projectId = newValue?.itemData.internalId;
        }
    },
    methods: {
        async onItemCancel(e){
            if(this.OnCancel != null) this.OnCancel();
        },
        async onItemOk(e){
            if (this.SetLoadingState != null) this.SetLoadingState(true);
            const data = await ProdComProjectService.backup(this.projectId, this.backupNotes);
            if(data.success == "n") this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(data.ret, data.retParams), 'warning'));
            else {
                this.$EventBus.$emit('show-toast', new ToastAlert(this.$t('project.backuped'), 'info'));
            }
            if(this.OnOk != null) this.OnOk(this.projectId, this.backupNotes);
            if (this.SetLoadingState != null) this.SetLoadingState(false);
        },
        async onItemShow(e){
            this.backupNotes = "{{projectName}}";
            //if(this.OnLoad != null) this.itemListServer = await this.OnLoad(this.projectId, this.backupNotes);
        }
    }
}
</script>
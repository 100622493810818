<template>
    <b-card :header="Title" :class="{ disabled: Readonly }">
        <b-row>
            <b-col cols="6">
                <b-form-group :label="$t('variables.details.normType')">
                    <b-form-select id="normType" v-model="Norm.SrcValCriteria" :options="normOptions" :class="{ disabled: Readonly }" />
                </b-form-group>
            </b-col>
            <b-col v-if="Norm.SrcValCriteria == 0" cols="6">
                <b-form-group :label="$t('Constant')">
                    <b-form-input
                        id="constant"
                        type="number"
                        min="-9999999999999999999999999999"
                        max="9999999999999999999999999999"
                        @change="
                            (value) => {
                                if (value == null || value == '') {
                                    this.Norm.ConstantValue = 0;
                                } else if (value > 9999999999999999999999999999) {
                                    this.Norm.ConstantValue = 9999999999999999999999999999;
                                } else if (value < -9999999999999999999999999999) {
                                    this.Norm.ConstantValue = -9999999999999999999999999999;
                                }
                            }
                        "
                        v-model="Norm.ConstantValue"
                        :class="{ disabled: Readonly }"
                    />
                </b-form-group>
            </b-col>
        </b-row>
        <b-row v-if="Norm.SrcValCriteria != 0">
            <b-col>
                <b-form-group :label="$t('Group')">
                    <b-form-select id="group" :state="VariableGroupState" v-model="Norm.VariableGroup" :options="groupList" :class="{ disabled: Readonly }" />
                </b-form-group>
            </b-col>
            <b-col>
                <b-form-group :label="$t('Item')">
                    <b-form-select id="var" :state="VariableItemState" v-model="Norm.VariableItem" :options="variableList" :class="{ disabled: Readonly }" />
                </b-form-group>
            </b-col>
        </b-row>
    </b-card>
</template>

<script>
export default {
    name: 'VariableNorm',
    props: {
        Title: String,
        Norm: Object,
        Readonly: Boolean,
        GroupVarList: Array,
    },
    computed: {
        invalidState() {
            return this.VariableGroupState === false || this.VariableItemState === false;
        },
        VariableGroupState() {
            return !this.Readonly && this.Norm.SrcValCriteria != 0 && (this.Norm.VariableGroup == null || this.Norm.VariableGroup == '') ? false : null;
        },
        VariableItemState() {
            return !this.Readonly && this.Norm.SrcValCriteria != 0 && (this.Norm.VariableItem == null || this.Norm.VariableItem == '') ? false : null;
        },
        variableList() {
            if (this.Norm.VariableGroup == '' || this.Norm.VariableGroup == null) return [];
            return this.GroupVarList.filter((v) => v.Group == this.Norm.VariableGroup).map((v) => v.Name);
        },
    },
    data() {
        return {
            normOptions: [
                { value: 0, text: this.$t('constant') },
                { value: 1, text: this.$t('variable') },
            ],
            groupList: [],
            selectedGroup: '',
        };
    },
    async mounted() {
        this.groupList = [...new Set(this.GroupVarList.map((a) => a.Group))];
    },
};
</script>
var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-tabs',{class:{ disabled: _vm.loadingConnectionCheck },staticStyle:{"width":"100%"},attrs:{"pills":"","card":"","h-100":"","d-flex":"","flex-column":""}},[_c('b-tab',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('span',{staticClass:"tabHeaderText"},[_vm._v(" "+_vm._s(_vm.$t('Connection'))+" "),(_vm.invalidState)?_c('font-awesome-icon',{staticClass:"ml-1 text-danger",attrs:{"icon":"fa fa-warning"}}):_vm._e()],1)]},proxy:true}])},[_c('div',[_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('equipment.EluCloud.address') + ' *',"label-for":"address"}},[_c('b-form-input',{class:{ disabled: !this.$store.state.auth.user.permissions.PermissionWrite },attrs:{"id":"address","state":_vm.addressState},on:{"change":(value) => {
                                    if (value == null || value == '') {
                                        this.address = 'localhost';
                                    }
                                    this.machinesLoadedState = false;
                                }},model:{value:(_vm.address),callback:function ($$v) {_vm.address=$$v},expression:"address"}})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('equipment.EluCloud.port') + ' *',"label-for":"port"}},[_c('b-form-input',{class:{ disabled: !this.$store.state.auth.user.permissions.PermissionWrite },attrs:{"id":"port","type":"number","min":"1","max":"65535","state":_vm.portState},on:{"change":(value) => {
                                    if (value == null || value == '') {
                                        this.port = 31750;
                                    } else if (value > 65535) {
                                        this.port = 65535;
                                    } else if (value < 1) {
                                        this.port = 1;
                                    }
                                    this.machinesLoadedState = false;
                                }},model:{value:(_vm.port),callback:function ($$v) {_vm.port=$$v},expression:"port"}})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('equipment.EluCloud.username'),"label-for":"user"}},[_c('b-form-input',{class:{ disabled: !this.$store.state.auth.user.permissions.PermissionWrite },attrs:{"id":"user"},on:{"change":(value) => {
                                    this.machinesLoadedState = false;
                                }},model:{value:(_vm.username),callback:function ($$v) {_vm.username=$$v},expression:"username"}})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('equipment.EluCloud.token'),"label-for":"token"}},[_c('b-form-input',{class:{ disabled: !this.$store.state.auth.user.permissions.PermissionWrite },attrs:{"id":"token"},on:{"change":(value) => {
                                    this.machinesLoadedState = false;
                                }},model:{value:(_vm.token),callback:function ($$v) {_vm.token=$$v},expression:"token"}})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('equipment.EluCloud.RefreshDelay') + ' *',"label-for":"refreshDelay"}},[_c('b-form-input',{class:{ disabled: !this.$store.state.auth.user.permissions.PermissionWrite },attrs:{"id":"refreshDelay","min":"500","max":"60000","type":"number","state":_vm.refreshDelayState},on:{"change":(value) => {
                                    if (value == null || value == '') {
                                        this.refreshDelay = 2000;
                                    } else if (value > 60000) {
                                        this.refreshDelay = 60000;
                                    } else if (value < 500) {
                                        this.refreshDelay = 500;
                                    }
                                }},model:{value:(_vm.refreshDelay),callback:function ($$v) {_vm.refreshDelay=$$v},expression:"refreshDelay"}})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Machines') + ' *',"label-for":"machinesParameter"}},[_c('b-form-select',{staticClass:"col-10",class:{ disabled: !this.$store.state.auth.user.permissions.PermissionWrite || !_vm.machinesLoadedState },attrs:{"id":"machinesParameter","state":_vm.machinesLoadedState,"options":_vm.availableMachines},model:{value:(_vm.ChoosenMachine),callback:function ($$v) {_vm.ChoosenMachine=$$v},expression:"ChoosenMachine"}}),_c('b-button',{staticClass:"col-1 ml-1",class:{ disabled: !this.$store.state.auth.user.permissions.PermissionRead, 'btn-danger': !_vm.machinesLoadedState, 'btn-success': _vm.machinesLoadedState },attrs:{"id":"buttonSearch"},on:{"click":_vm.onBrowseMachines}},[(!_vm.loadingBrowseMachineCheck)?_c('font-awesome-icon',{staticClass:"cursor-pointer",attrs:{"icon":"search"}}):_vm._e(),(_vm.loadingBrowseMachineCheck)?_c('font-awesome-icon',{staticClass:"fa-lg fa-spin-custom",attrs:{"icon":"fas fa-spinner-third"}}):_vm._e()],1)],1)],1),_c('b-col',{attrs:{"cols":"1"}})],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-button',{class:{ disabled: !this.$store.state.auth.user.permissions.PermissionRead },on:{"click":_vm.onCheckConnection}},[_vm._v(" "+_vm._s(_vm.$t('equipment.opcua.checkConnection'))+" "),(_vm.loadingConnectionCheck)?_c('font-awesome-icon',{staticClass:"fa-lg fa-spin-custom",attrs:{"icon":"fas fa-spinner-third"}}):_vm._e()],1)],1)],1)],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-card',{attrs:{"no-body":""}},[_c('b-form',{staticStyle:{"margin":"1.25rem"}},[_c('b-row',[_c('b-col',[_c('b-form-group',{attrs:{"label":_vm.$t('simulation.lowLimit')}},[_c('b-form-input',{attrs:{"id":"lowLimit","min":"0","max":"999999","step":"1","type":"number"},on:{"change":(value) => {
                                if (this.simulationDetails.LowLimit >= this.simulationDetails.HighLimit) {
                                    this.simulationDetails.HighLimit = this.simulationDetails.LowLimit * 1 + this.simulationDetails.Increment * 1;
                                }
                                if (value == null || value == '') {
                                    this.simulationDetails.LowLimit = 0;
                                } else if (value > 999999) {
                                    this.simulationDetails.LowLimit = 999999;
                                } else if (value < -1000000) {
                                    this.simulationDetails.LowLimit = -1000000;
                                }
                            }},model:{value:(_vm.simulationDetails.LowLimit),callback:function ($$v) {_vm.$set(_vm.simulationDetails, "LowLimit", $$v)},expression:"simulationDetails.LowLimit"}})],1)],1),_c('b-col',[_c('b-form-group',{attrs:{"label":_vm.$t('simulation.highLimit')}},[_c('b-form-input',{attrs:{"id":"highLimit","min":"1","max":"1000000","step":"1","type":"number"},on:{"change":(value) => {
                                if (this.simulationDetails.HighLimit <= this.simulationDetails.LowLimit) {
                                    this.simulationDetails.LowLimit = this.simulationDetails.HighLimit * 1 - this.simulationDetails.Increment * 1;
                                }
                                if (value == null || value == '') {
                                    this.simulationDetails.HighLimit = 0;
                                } else if (value > 1000000) {
                                    this.simulationDetails.HighLimit = 1000000;
                                } else if (value < -999999) {
                                    this.simulationDetails.HighLimit = -999999;
                                }
                            }},model:{value:(_vm.simulationDetails.HighLimit),callback:function ($$v) {_vm.$set(_vm.simulationDetails, "HighLimit", $$v)},expression:"simulationDetails.HighLimit"}})],1)],1),_c('b-col',[_c('b-form-group',{attrs:{"label":_vm.$t('simulation.increment')}},[_c('b-form-input',{attrs:{"id":"increment","min":"1","max":"1000000","step":"1","type":"number"},on:{"change":(value) => {
                                if (this.simulationDetails.Increment > this.simulationDetails.HighLimit * 1 - this.simulationDetails.LowLimit * 1) {
                                    this.simulationDetails.Increment = this.simulationDetails.HighLimit * 1 - this.simulationDetails.LowLimit * 1;
                                }
                                if (value == null || value == '') {
                                    this.simulationDetails.Increment = 1;
                                } else if (value > 1000000) {
                                    this.simulationDetails.Increment = 1000000;
                                } else if (value < 1) {
                                    this.simulationDetails.Increment = 1;
                                }
                            }},model:{value:(_vm.simulationDetails.Increment),callback:function ($$v) {_vm.$set(_vm.simulationDetails, "Increment", $$v)},expression:"simulationDetails.Increment"}})],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
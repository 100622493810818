<template>
    <b-row>
        <b-col cols="4">
            <b-list-group>
                <div class="text-center pb-3">
                    <font-awesome-icon icon="plus" class="cursor-pointer" v-on:click="createNewRole" v-bind:alt="$t('Add role')" v-bind:title="$t('Add role')" />
                    <font-awesome-icon icon="check" v-bind:class="{ disabled: currentRole.IsProtected && minLevelRole > $RoleLevels.Admin }" class="cursor-pointer ml-3" v-if="!saving && currentRole.Id != null" v-on:click="saveRole" v-bind:alt="$t('Save')" v-bind:title="$t('Save')" />
                    <font-awesome-icon icon="sync" class="cursor-pointer ml-3" spin v-if="saving" />
                    <font-awesome-icon icon="minus" v-bind:class="{ disabled: currentRole.IsProtected }" class="cursor-pointer ml-3" v-if="currentRole && currentRole.Id != null && currentRole.Id != 0" v-on:click="removeRole" v-bind:alt="$t('Remove')" v-bind:title="$t('Remove')" />
                </div>
                <b-list-group-item v-for="(role, idx) in roles" :key="'role' + role.Id" :active="currentRole && role.Id == currentRole.Id" v-on:click="selectRole(role.Id)" button v-bind:class="{ 'font-italic': role.Id == 0 }"
                    >{{ roleLabel(role) }} <i v-if="idx == 0 || roles[idx - 1].Level != role.Level" class="small float-right">{{ $t('Level') + ' ' + role.Level }}</i></b-list-group-item
                >
            </b-list-group>
        </b-col>
        <b-col cols="8">
            <b-card no-body v-if="currentRole.Id != null" class="p-3">
                <b-form>
                    <b-row>
                        <b-col>
                            <b-form-group :label="$t('Name') + ' *'" label-for="currentRole_Name">
                                <b-form-input :maxlength="$store.state.settings.INPUT_NAME_MAX_LENGTH" :readonly="currentRole.IsProtected && minLevelRole > $RoleLevels.SuperAdmin" class="sm" ref="currentRole_Name" id="currentRole_Name" v-model="currentRole.Name" required></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col>
                            <b-form-group :label="$t('Level') + ' *'" label-for="currentRole_Level">
                                <b-form-input :readonly="currentRole.IsProtected && minLevelRole > $RoleLevels.SuperAdmin" type="number" id="currentRole_Level" v-model="currentRole.Level" :min="minLevelRole" max="999" step="1" required></b-form-input>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <b-form-group :label="$t('Groups')" label-for="currentRole_Groups">
                                <DxTagBox id="currentRole_Groups" :max-displayed-tags="2" :items="allGroups" v-model="groupsInRole[currentRole.Id]" display-expr="Name" value-expr="Id"></DxTagBox>
                            </b-form-group>
                        </b-col>
                        <b-col>
                            <b-form-group :label="$t('Users')" label-for="currentRole_Users">
                                <DxTagBox id="currentRole_Users" :max-displayed-tags="2" :items="allUsers" v-model="usersInRole[currentRole.Id]" display-expr="Email" value-expr="Id"></DxTagBox>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-card :header="$t('roles.permissions')" :class="{'disabled': currentRole.IsProtected && minLevelRole > $RoleLevels.Admin}">
                        <b-row>
                            <b-col>
                                <b-form-checkbox v-model="currentRole.PermissionRead">{{$t('roles.permissions.read')}}</b-form-checkbox>
                            </b-col>
                            <b-col>
                                <b-form-checkbox v-model="currentRole.PermissionWrite">{{$t('roles.permissions.write')}}</b-form-checkbox>
                            </b-col>
                            <b-col>
                                <b-form-checkbox v-model="currentRole.PermissionDisplayHistory">{{$t('roles.permissions.displayHistory')}}</b-form-checkbox>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                                <b-form-checkbox v-model="currentRole.PermissionDisplayRun">{{$t('roles.permissions.displayRun')}}</b-form-checkbox>
                            </b-col>
                            <b-col>
                                <b-form-checkbox v-model="currentRole.PermissionManageRun">{{$t('roles.permissions.manageRun')}}</b-form-checkbox>
                            </b-col>
                            <b-col>
                                <b-form-checkbox v-model="currentRole.PermissionWriteRun">{{$t('roles.permissions.writeRun')}}</b-form-checkbox>
                            </b-col>
                        </b-row>
                    </b-card>
                    <!-- <b-row>
                        <b-col v-if="currentRole.Level == $RoleLevels.SuperUser || currentRole.Level == $RoleLevels.User">
                            <b-form-group :label="$t('Databases sources')" label-for="currentRole_Databases">
                                <DxTagBox id="currentRole_Databases" :max-displayed-tags="10" :items="allConnections" v-model="connectionsInRole[currentRole.Id]" display-expr="Alias" value-expr="Id"></DxTagBox>
                            </b-form-group>
                        </b-col>
                        <b-col v-if="currentRole.Level == $RoleLevels.SuperUser || currentRole.Level == $RoleLevels.User">
                            <b-form-group :label="$t('Web sources')" label-for="currentRole_WebSources">
                                <DxTagBox id="currentRole_WebSources" :max-displayed-tags="10" :items="allWebSources" v-model="webSourcesInRole[currentRole.Id]" display-expr="Alias" value-expr="Id"></DxTagBox>
                            </b-form-group>
                        </b-col>
                    </b-row> -->
                </b-form>
                <FormErrors :propFormErrors="formErrors" />
            </b-card>
        </b-col>
    </b-row>
</template>
<script>
import UserService from '@/services/user.service.js';
import RoleService from '@/services/role.service.js';
import GroupService from '@/services/group.service.js';
import DashboardService from '@/services/dashboard.service.js';
import ReportService from '@/services/report.service.js';
import DatabaseService from '@/services/database.service.js';
import WebSourceService from '@/services/websource.service.js';
import DxTagBox from 'devextreme-vue/tag-box';
import ToastAlert from '@/utils/ToastAlert';
import FormErrors from '@/components/FormErrors.vue';

export default {
    name: 'RoleTabPanel',
    components: {
        DxTagBox,
        ToastAlert,
        FormErrors,
    },
    data() {
        return {
            saving: false,
            roles: [],
            allUsers: [],
            allGroups: [],
            allDashboards: [],
            allReports: [],
            allConnections: [],
            allWebSources: [],
            currentRole: {
                Id: null,
                Name: null,
                Level: this.minLevelRole > 0 ? minLevelRole : 1,
                IsProtected: false,
                PermissionRead: true,
                PermissionWrite: false,
                PermissionDisplayHistory: false,
                PermissionDisplayRun: false,
                PermissionManageRun: false,
                PermissionWriteRun: false,
            },
            usersInRole: {},
            groupsInRole: {},
            dashboardsInRole: {},
            reportsInRole: {},
            connectionsInRole: {},
            webSourcesInRole: {},
            formErrors: [],
        };
    },
    async mounted() {
        await this.refresh('RoleTabPanel');
        this.$EventBus.$on('refresh-admin-panel-tab', this.refresh);
    },
    computed: {
        minLevelRole() {
            return this.$store.state.auth.user == null ? 999 : this.$store.state.auth.user.minLevelRole;
        },
    },
    methods: {
        checkFormValidity() {
            this.formErrors = [];
            if (!this.currentRole.Name || this.currentRole.Level === null || this.currentRole.Level == '') this.formErrors.push(this.$t('Check required fields'));
            return this.formErrors.length == 0;
        },
        async refresh(tabRef) {
            if (tabRef == 'RoleTabPanel') {
                this.formErrors = [];
                this.currentRole = {
                    Id: null,
                    Name: null,
                    Level: this.minLevelRole > 0 ? this.minLevelRole : 1,
                    PermissionRead: true,
                    PermissionWrite: false,
                    PermissionDisplayHistory: false,
                    PermissionDisplayRun: false,
                    PermissionManageRun: false,
                    PermissionWriteRun: false,
                };
                await this.getRoles();
                await this.getUsers();
                await this.getGroups();
                // await this.getDashboards();
                // await this.getReports();
                // await this.getConnections();
                // await this.getWebSources();
            }
        },
        roleLabel(role) {
            if (role.Id != 0 && role.Name != '' && role.Name != null) return role.Name;
            else if (role.Id == 0) return this.$t('New role');
            else return this.$t('Please enter name');
        },
        async createNewRole() {
            if (this.roles.findIndex((role) => role.Id == 0) == -1) {
                this.formErrors = [];
                this.roles.unshift({
                    Id: 0,
                    Name: this.$t('New role'),
                    Level: this.minLevelRole > 0 ? this.minLevelRole : 1,
                    IsProtected: false,
                    PermissionRead: true,
                    PermissionWrite: false,
                    PermissionDisplayHistory: false,
                    PermissionDisplayRun: false,
                    PermissionManageRun: false,
                    PermissionWriteRun: false,
                });
                await this.selectRole(0);
                await this.$refs.currentRole_Name.focus();
                this.$refs.currentRole_Name.select();
            }
        },
        async getRoles() {
            const data = await RoleService.getAllRoles();
            if (data.success == 'n') this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(data.ret, data.retParams), 'warning'));
            else this.roles = data.ret;
            this.$emit('role-count', Array.isArray(this.roles) ? this.roles.filter((role) => role.Id != 0).length : 0);
        },
        async getUsers() {
            const data = await UserService.getAllUsers();
            if (data.success == 'n') this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(data.ret, data.retParams), 'warning'));
            else this.allUsers = data.ret;
        },
        async getGroups() {
            const data = await GroupService.getAllGroups();
            if (data.success == 'n') this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(data.ret, data.retParams), 'warning'));
            else this.allGroups = data.ret;
        },
        async getDashboards() {
            const data = await DashboardService.getAllDashboards();
            if (data.success == 'n') this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(data.ret, data.retParams), 'warning'));
            else {
                this.allDashboards = data.ret.map(function (d) {
                    d.Name = d.IsPublic ? d.Name + ' (P)' : d.Name;
                    return d;
                });
            }
        },
        async getReports() {
            const data = await ReportService.getAllReports();
            if (data.success == 'n') this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(data.ret, data.retParams), 'warning'));
            else {
                this.allReports = data.ret.map(function (r) {
                    r.Name = r.IsPublic ? r.Name + ' (P)' : r.Name;
                    return r;
                });
            }
        },
        async getConnections() {
            const data = await DatabaseService.getConnectionStrings();
            if (data.success == 'n') this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(data.ret, data.retParams), 'warning'));
            else {
                this.allConnections = data.ret;
            }
        },
        async getWebSources() {
            const data = await WebSourceService.getWebSources();
            if (data.success == 'n') this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(data.ret, data.retParams), 'warning'));
            else {
                this.allWebSources = data.ret;
            }
        },
        async selectRole(id) {
            this.formErrors = [];
            this.currentRole = this.roles.find((role) => role.Id == id);
            if (id != 0) {
                await this.getUsersInRole();
                await this.getGroupsInRole();
            }
        },
        async getUsersInRole() {
            const data = await RoleService.getUsersInRole(this.currentRole.Id);
            if (data.success == 'n') this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(data.ret, data.retParams), 'warning'));
            else this.$set(this.usersInRole, this.currentRole.Id, data.ret ? data.ret.map((user) => user.Id) : []);
        },
        async getGroupsInRole() {
            const data = await RoleService.getGroupsInRole(this.currentRole.Id);
            if (data.success == 'n') this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(data.ret, data.retParams), 'warning'));
            else this.$set(this.groupsInRole, this.currentRole.Id, data.ret ? data.ret.map((group) => group.Id) : []);
        },
        async getDashboardsInRole() {
            const data = await RoleService.getDashboardsInRole(this.currentRole.Id);
            if (data.success == 'n') this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(data.ret, data.retParams), 'warning'));
            else this.$set(this.dashboardsInRole, this.currentRole.Id, data.ret ? data.ret.map((dash) => dash.Id) : []);
        },
        async getReportsInRole() {
            const data = await RoleService.getReportsInRole(this.currentRole.Id);
            if (data.success == 'n') this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(data.ret, data.retParams), 'warning'));
            else {
                this.$set(this.reportsInRole, this.currentRole.Id, data.ret ? data.ret.map((rep) => rep.Id) : []);
            }
        },
        async getConnectionsInRole() {
            const data = await RoleService.getConnectionsInRole(this.currentRole.Id);
            if (data.success == 'n') this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(data.ret, data.retParams), 'warning'));
            else {
                this.$set(this.connectionsInRole, this.currentRole.Id, data.ret ? data.ret.map((c) => c.Id) : []);
            }
        },
        async getWebSourcesInRole() {
            const data = await RoleService.getWebSourcesInRole(this.currentRole.Id);
            if (data.success == 'n') this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(data.ret, data.retParams), 'warning'));
            else {
                this.$set(this.webSourcesInRole, this.currentRole.Id, data.ret ? data.ret.map((c) => c.Id) : []);
            }
        },
        async saveRole() {
            if (!this.checkFormValidity()) return false;
            this.saving = true;
            let settings = null;
            if (this.currentRole.Id == 0) {
                // New role
                settings = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        Method: 'roles.create',
                        Token: this.$store.state.auth.user.accessToken,
                        Parameters: {
                            Name: this.currentRole.Name,
                            Level: this.currentRole.Level,
                            Users: this.usersInRole[this.currentRole.Id],
                            Groups: this.groupsInRole[this.currentRole.Id],
                            Dashboards: this.dashboardsInRole[this.currentRole.Id],
                            Reports: this.reportsInRole[this.currentRole.Id],
                            Connections: this.connectionsInRole[this.currentRole.Id],
                            WebSources: this.webSourcesInRole[this.currentRole.Id],
                            PermissionRead: this.currentRole.PermissionRead,
                            PermissionWrite: this.currentRole.PermissionWrite,
                            PermissionDisplayHistory: this.currentRole.PermissionDisplayHistory,
                            PermissionDisplayRun: this.currentRole.PermissionDisplayRun,
                            PermissionManageRun: this.currentRole.PermissionManageRun,
                            PermissionWriteRun: this.currentRole.PermissionWriteRun,
                        },
                    }),
                };
            } else {
                // Update role
                settings = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        Method: 'roles.update',
                        Token: this.$store.state.auth.user.accessToken,
                        Parameters: {
                            Id: this.currentRole.Id,
                            Name: this.currentRole.Name,
                            Level: this.currentRole.Level,
                            Users: this.usersInRole[this.currentRole.Id],
                            Groups: this.groupsInRole[this.currentRole.Id],
                            Dashboards: this.dashboardsInRole[this.currentRole.Id],
                            Reports: this.reportsInRole[this.currentRole.Id],
                            Connections: this.connectionsInRole[this.currentRole.Id],
                            WebSources: this.webSourcesInRole[this.currentRole.Id],
                            PermissionRead: this.currentRole.PermissionRead,
                            PermissionWrite: this.currentRole.PermissionWrite,
                            PermissionDisplayHistory: this.currentRole.PermissionDisplayHistory,
                            PermissionDisplayRun: this.currentRole.PermissionDisplayRun,
                            PermissionManageRun: this.currentRole.PermissionManageRun,
                            PermissionWriteRun: this.currentRole.PermissionWriteRun,
                        },
                    }),
                };
            }

            const res = await fetch(this.$store.state.settings.API_URL, settings);
            const data = await res.json();

            if (data.success == 'n') {
                this.saving = false;
                this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(data.ret, data.retParams), 'warning'));
            } else if (this.currentRole.Id == 0) {
                this.usersInRole[this.currentRole.Id] = [];
                this.groupsInRole[this.currentRole.Id] = [];
                this.dashboardsInRole[this.currentRole.Id] = [];
                this.reportsInRole[this.currentRole.Id] = [];
                this.connectionsInRole[this.currentRole.Id] = [];
                this.webSourcesInRole[this.currentRole.Id] = [];
                await this.refresh('RoleTabPanel');
            }

            this.saving = false;
            this.$emit('role-count', this.roles.filter((role) => role.Id != 0).length);
        },
        async removeRole(id) {
            const value = await this.$bvModal.msgBoxConfirm(this.$t('Are you sure ?'), {
                okTitle: this.$t('yes'),
                cancelTitle: this.$t('no'),
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true,
                buttonSize: 'sm',
            });
            if (value) {
                const settings = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        Method: 'roles.delete',
                        Token: this.$store.state.auth.user.accessToken,
                        Parameters: {
                            Id: this.currentRole.Id,
                        },
                    }),
                };
                const res = await fetch(this.$store.state.settings.API_URL, settings);
                const data = await res.json();

                if (data.success == 'n') this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(data.ret, data.retParams), 'warning'));
                else {
                    this.currentRole = {
                        Id: null,
                        Name: null,
                        Level: null,
                    };
                    await this.refresh('RoleTabPanel');
                }
            }
        },
    },
};
</script>
<style scoped>
.dx-tagbox {
    border-radius: 0;
    border-top: none;
    border-left: none;
    border-right: none;
}
</style>
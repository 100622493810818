<template>
    <DxDataGrid :dataSource="connections" :allow-column-reordering="true" :row-alternation-enabled="true">
        <DxGroupPanel :visible="true" />
        <DxFilterRow :visible="true" />
        <DxHeaderFilter :visible="true" :allow-search="true" />
        <DxSearchPanel :visible="true" :width="240" />
        <DxColumn data-field="isCurrent" :caption="$t('Current')" data-type="boolean" />
        <DxColumn data-field="ConnectionHD" :caption="$t('Date')" data-type="datetime" :sort-index="0" sort-order="desc" />
        <DxColumn data-field="Ip" :caption="$t('Ip')" />
        <DxSorting mode="multiple" />
        <DxPaging :page-size="15" />
    </DxDataGrid>
</template>
<script>
import ToastAlert from '@/utils/ToastAlert';
import SettingService from '@/services/setting.service.js';
import { DxDataGrid, DxGroupPanel, DxFilterRow, DxHeaderFilter, DxSearchPanel, DxColumn, DxSorting, DxPaging } from 'devextreme-vue/data-grid';

export default {
    Name: 'ConnectionsTabPanel',
    props: ['propModalVisible'],
    components: {
        DxDataGrid,
        DxGroupPanel,
        DxFilterRow,
        DxHeaderFilter,
        DxSearchPanel,
        DxColumn,
        DxSorting,
        DxPaging,
    },
    data() {
        return {
            connections: [],
        };
    },
    async mounted() {
        await this.refresh('ConnectionsTabPanel');
        this.$EventBus.$on('refresh-admin-panel-tab', this.refresh);
        this.$EventBus.$on('connectionsCountHasChanged', this.getConnections);
    },
    methods: {
        async refresh(tabRef) {
            if (tabRef == 'ConnectionsTabPanel') {
                await this.getConnections();
            }
        },
        async getConnections() {
            // Check if admin modal is visible, if not return
            let adminPanelDomElement = document.getElementById('adminPanel');
            if (!adminPanelDomElement) return;

            var data = await SettingService.getConnections();
            if (data.success == 'n') this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(data.ret, data.retParams), 'warning'));
            else {
                this.nbConnections = data.ret.length;
                var connections = data.ret.map((connection) => {
                    connection.isCurrent = connection.ConnectionId == this.$store.state.connectionId;
                    return connection;
                });
                this.connections = connections;
                this.$emit('message-count', this.nbConnections);
            }
        },
    },
};
</script>
import store from "@/store/index.js";
import { createStringifyStream } from "big-json";
import 'core-js';

/**
 * Posts a query to the backend server
 * @param method The method to be executed
 * @param parameters The parameters to be sent to the method
 * @return The result of the query, as json data.
 */
async function PostQuery(method, parameters = undefined) {
    const settings = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            Method: method,
            Parameters: parameters,
            Token: store?.state?.auth?.user?.accessToken,
        }),
    };
    const res = await fetch(store.state.settings.API_URL, settings);
    const data = await res.json();
    // If license has expired (information returned by the API)
    // set the state in the store for further logic. (done in App.vue)
    if (data.expired == true && store.state.expired == 0) {
        store.state.expired = 1;
    }
    // If expiration was reset (unlimited license, new license applied, ...)
    // reset the expire state of the store.
    if (data.expired == false) {
        store.state.expired = 0;
    }
    return data;
}

async function SaveFile(content, fileName, type) {
    var blob = new Blob([content], { type: type });
    var a = document.createElement("a");
    var url = URL.createObjectURL(blob);
    a.href = url;
    a.download = fileName;
    a.click();
    setTimeout(function () {
        // Remove link from DOM
        a.remove();
        window.URL.revokeObjectURL(url);
    }, 0);
}

async function SaveBlobToFile(blob, fileName) {
    var a = document.createElement("a");
    var url = URL.createObjectURL(blob);
    a.href = url;
    a.download = fileName;
    a.click();
    setTimeout(function () {
        // Remove link from DOM
        a.remove();
        window.URL.revokeObjectURL(url);
    }, 0);
}

/**
 * Stringifies a large object using big-json.
 * @param {*} object The object to be stringified 
 * @returns The stringified object
 */
async function stringifyAsync(object) {
    return new Promise(resolve => {
        var stringifyStream = createStringifyStream({ body: object });
        let finalString = "";
        stringifyStream.on('data', function (strChunk) {
            finalString += strChunk;
        });
        stringifyStream.on('end', function (chunk) {
            resolve(finalString);
        })
    })
}

export default {
    PostQuery,
    SaveFile,
    SaveBlobToFile,
    stringifyAsync,
}
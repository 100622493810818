var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-tabs',{staticStyle:{"width":"100%"},attrs:{"pills":"","card":"","h-100":"","d-flex":"","flex-column":""}},[_c('b-tab',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('span',{staticClass:"tabHeaderText"},[_vm._v(_vm._s(_vm.$t('Configuration')))])]},proxy:true}])},[_c('div',{staticClass:"tabContent"},[_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('equipment.EluCloud.RefreshDelay') + ' *',"label-for":"refreshDelay"}},[_c('b-form-input',{class:{ disabled: !this.$store.state.auth.user.permissions.PermissionWrite },attrs:{"type":"number","min":"500","max":"3600000","id":"refreshDelay"},on:{"change":(value) => {
                                    if (value > 3600000) {
                                        this.refreshDelay = 3600000;
                                    } else if (value < 1000) {
                                        this.refreshDelay = 1000;
                                    }
                                }},model:{value:(_vm.refreshDelay),callback:function ($$v) {_vm.refreshDelay=$$v},expression:"refreshDelay"}})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-checkbox',{class:{ disabled: !this.$store.state.auth.user.permissions.PermissionWrite },staticStyle:{"margin-top":"40px"},attrs:{"switch":""},model:{value:(_vm.isStockMag),callback:function ($$v) {_vm.isStockMag=$$v},expression:"isStockMag"}},[_vm._v(" "+_vm._s(_vm.$t('equipment.stock.mag'))+" ")]),_c('b-form-checkbox',{class:{ disabled: !this.$store.state.auth.user.permissions.PermissionWrite },staticStyle:{"margin-top":"40px"},attrs:{"switch":""},model:{value:(_vm.isStockProd),callback:function ($$v) {_vm.isStockProd=$$v},expression:"isStockProd"}},[_vm._v(" "+_vm._s(_vm.$t('equipment.stock.prod'))+" ")]),_c('b-form-checkbox',{class:{ disabled: !this.$store.state.auth.user.permissions.PermissionWrite },staticStyle:{"margin-top":"40px"},attrs:{"switch":""},model:{value:(_vm.isHD),callback:function ($$v) {_vm.isHD=$$v},expression:"isHD"}},[_vm._v(" "+_vm._s(_vm.$t('equipment.stock.HD'))+" ")])],1)],1)],1)]),_c('b-tab',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('span',{staticClass:"tabHeaderText"},[_vm._v(_vm._s(_vm.$t('Data Source')))])]},proxy:true}])},[_c('DataSource',{ref:"managerDataSource",attrs:{"onEntityChange":_vm.onEntityChange,"datasource":_vm.dataSource}}),(_vm.dataSource.Provider != 'ProductysManager')?_c('div',{staticClass:"tabContent"},[_c('b-form',{staticClass:"triggerForm"},[_c('b-row',{staticStyle:{"margin-top":"5px"}},[_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Entity'),"label-for":"entity"}},[_c('b-form-input',{attrs:{"id":"pkEntity","type":"number","min":"0","max":"999999999"},model:{value:(_vm.pkEntity),callback:function ($$v) {_vm.pkEntity=$$v},expression:"pkEntity"}})],1)],1)],1)],1)],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
import store from "@/store/index.js";
import utils from "@/utils";

class SettingService {
    async getPrimaryColor() {
        return await utils.PostQuery('settings.getPrimaryColor');
    }
    async setPrimaryColor(color) {
        return await utils.PostQuery('settings.setPrimaryColor',
            {
                Value: color,
            });
    }
    async getPrimaryColorChangeAllowed() {
        return await utils.PostQuery('settings.getPrimaryColorChangeAllowed');
    }
    async setPrimaryColorChangeAllowed(allowed) {
        return await utils.PostQuery('settings.setPrimaryColorChangeAllowed',
            {
                Value: allowed,
            });
    }
    async getSettings(settingList) {
        return await utils.PostQuery('settings.getSettings',
            {
                Settings: settingList,
            });
    }
    async setSettings(settingList) {
        return await utils.PostQuery('settings.setSettings',
            {
                SettingValues: settingList,
            });
    }
    async getConnections() {
        const settings = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                Method: 'settings.getCurrentConnections',
                Token: store.state.auth.user.accessToken,
                Parameters: {},
            }),
        };

        const res = await fetch(store.state.settings.API_URL, settings);
        const data = await res.json();
        return data;
    }

    async getKafkaAddress() {
        return await utils.PostQuery('project.GetKafkaAddress');
    }

    async updateKafkaConfig(address) {
        return await utils.PostQuery('project.UpdateKafkaAddress',
            {
                address: address,
            });
    }

    async refreshKafkaConfig() {
        return await utils.PostQuery('project.RefreshKafkaConfig');
    }

    async getKafkaState() {
        return await utils.PostQuery('project.GetKafkaState');
    }
}

export default new SettingService();
<template>
    <footer style="font-size: 13px; font-family: Segoe WPC,Segoe UI,sans-serif; z-index: 3000">
        <div class="footer-left pl-2" >
            <div>
                <span style="margin-right: 5px;" v-b-modal.bv-modal-about-box>
                    {{ productFullName }}
                    <span style="margin-right: 15px;" v-if="LICENSE.Mode < $LicenseModes.Purchase" v-bind:class="{ 'text-red': (new Date(LICENSE.Expiration).getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24) <= 30 }">
                        <font-awesome-icon icon="info" class="ml-2 mr-1" />
                        {{ $t($LicenseModesLabel[$store.state.settings.LICENSE.Mode]) }}
                    </span>
                    <font-awesome-icon style="margin-right: 15px;" v-else icon="info" class="ml-2" />
                </span>

                <font-awesome-icon v-if="$store.state.runtimeStatus == 0" icon="fa-stop" class="fa-lg cursor-pointer" color="#c66900" :title="$t('runtime.status.stopped')" @click="startRuntime"/>
                <font-awesome-icon v-if="$store.state.runtimeStatus == 1" icon="fa-play" class="fa-lg cursor-pointer" color="#4dc615" :title="$t('runtime.status.started')" @click="stopRuntime"/>
                <font-awesome-layers v-if="$store.state.runtimeStatus == 2" :title="$t('runtime.status.startPending')">
                    <font-awesome-icon icon="fa-play" class="fa-lg" color="#c66900" />
                    <font-awesome-icon icon="sync" class="fa-xs fa-spin-custom" transform="down-10.0 right-10.0"/>
                </font-awesome-layers>
                <font-awesome-layers v-if="$store.state.runtimeStatus == 3" :title="$t('runtime.status.stopPending')">
                    <font-awesome-icon icon="fa-stop" class="fa-lg" color="#c66900" />
                    <font-awesome-icon icon="sync" class="fa-xs fa-spin-custom" transform="down-10.0 right-10.0"/>
                </font-awesome-layers>
                <font-awesome-icon v-if="$store.state.runtimeStatus == 5" icon="das fa-square-exclamation" class="fa-lg" color="#c72614" :title="$t('runtime.status.runtimeUnavailable')" />

            </div>

        </div>
        <Transition mode="out-in" name="footer-text-in">
            <div class="footer-center text-center" :key="currentText">{{ currentText }}</div>
        </Transition>
        <div class="footer-right text-right pr-2" >Copyright © Productys {{ new Date().getFullYear() }}</div>
    </footer>
</template>
<script>
import MessageService from '@/services/message.service.js';
import {HttpTransportType, HubConnectionBuilder, LogLevel} from "@microsoft/signalr";
import ProdComMonitoringService from "@/services/prodcom.monitoring.service.js"
import constants from "@/utils/constants";
import ToastAlert from '@/utils/ToastAlert';
export default {
    name: 'Footer',
    data() {
        return {
            messages: [],
            currentTextIndex: 0,
            currentText: '',
            currentTimeout: null,
            hubConnection: null,
        };
    },
    computed: {
        productFullName() {
            return this.$store.state.settings.PRODUCT_FULL_NAME;
        },
        LICENSE() {
            return this.$store.state.settings.LICENSE;
        },
    },
    async created(){
        // Create the SignalR connection hub
        this.hubConnection = new HubConnectionBuilder()
            .withUrl(this.$store.state.settings.API_URL + "/runtimeStatus", {
                skipNegotiation: true,
                transport: HttpTransportType.WebSockets
            })
            .withAutomaticReconnect()
            .configureLogging(LogLevel.Debug)
            .build()
        // Query the runtime status value
        const result = await ProdComMonitoringService.runtimeStatus();
         if(result.success == "y"){
            this.$store.commit('setRuntimeStatus', result.ret);
        }else{
            this.$store.commit('setRuntimeStatus', 5);
        }
        // Start connection
        await this.hubConnection?.start()
        this.hubConnection?.on(constants.MONITOR_RUNTIMESTATUS,
            (data) => {
                this.$store.commit('setRuntimeStatus', data?.runtimeState ?? 5);
            }
        );
        this.hubConnection?.on(constants.MONITOR_RUNTIME_STOP_WARNING,
            (data) => {
                if(data.stopsIn == 0){
                    this.$EventBus.$emit('show-toast', new ToastAlert(this.$t('license.runtime.stopsNow'), 'warning'));
                }else{
                    this.$EventBus.$emit('show-toast', new ToastAlert(this.$t('license.runtime.stopsIn', [`${data.stopsIn}`]), 'warning'));
                }
            }
        );
        this.hubConnection?.on(constants.MONITOR_RUNTIME_LICENSE_ERROR,
            (data) => {
                this.$EventBus.$emit('show-toast', new ToastAlert("data?.parameters?.Text", 'warning'))
                // this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(data?.parameters?.Text, data?.parametekrs?.Parameters), 'warning'))
            }
        );
    },
    beforeDestroy(){
        // Stop the SignalR hub and clear its reference.
        this.hubConnection?.stop();
        this.hubConnection = null;
    },
    async mounted() {
        this.$EventBus.$on('refresh-messages', this.refresh);
        await this.refresh();
    },
    methods: {
        async refresh() {
            this.currentText = '';
            if (this.currentTimeout != null) clearTimeout(this.currentTimeout);
            this.currentTextIndex = 0;
            // get messages
            // const data = await MessageService.getAllMessages(this.$i18n.locale);
            // if (data.success == 'y') this.messages = data.ret;
            // if (this.messages.length > 0) {
            //     this.currentText = this.messages[this.currentTextIndex].Text;
            //     this.currentTimeout = setTimeout(() => {
            //         this.changeMessage();
            //     }, this.messages[this.currentTextIndex].Duration * 1000);
            // }
        },
        changeMessage() {
            this.currentTextIndex++;
            if (this.currentTextIndex == this.messages.length) this.refresh();
            else {
                this.currentText = this.messages[this.currentTextIndex].Text;
                this.currentTimeout = setTimeout(() => {
                    this.changeMessage();
                }, this.messages[this.currentTextIndex].Duration * 1000);
            }
        },
        async startRuntime(){
            if(!this.$store.state.auth.user.permissions.PermissionManageRun) return;
            const result = await ProdComMonitoringService.startRuntime();
            if(result.success == "n") {
                this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(result.ret, result.retParams), 'warning'));
            }
            else {
                this.$EventBus.$emit('show-toast', new ToastAlert(this.$t('runtime.started'), 'info'))
            }
        },
        async stopRuntime(){
            if(!this.$store.state.auth.user.permissions.PermissionManageRun) return;
            const result = await ProdComMonitoringService.stopRuntime();
            if(result.success == "n") {
                this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(result.ret, result.retParams), 'warning'));
            }
            else {
                this.$EventBus.$emit('show-toast', new ToastAlert(this.$t('runtime.stopped'), 'info'))
            }
        },
    },
};
</script>
<style scoped>
footer {
    width: 100%;
    position: fixed;
    bottom: 0;
    height: 28px;
    line-height: 28px;
    background-color: var(--navbar-background-color);
    display: flex;
    border-top: 1px solid var(--header-border-bottom);
    z-index: 1000;
}
.footer-left {
    flex: 0 0 400px;
    z-index: 1500;
    background-color: var(--navbar-background-color);
}
.footer-center {
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    z-index: 1000;
}
.footer-right {
    flex: 0 0 400px;
    z-index: 1500;
    background-color: var(--navbar-background-color);
}
.footer-text-in-enter-active {
    animation: bounce-in 0.4s;
}

.footer-text-in-leave-active {
    animation: bounce-in 0.4s reverse;
}

@keyframes bounce-in {
    0% {
        transform: scale(0);
    }
    50% {
        transform: scale(1.2);
    }
    100% {
        transform: scale(1);
    }
}
</style>
<template>
    <div>
        <b-row>
            <!-- TODO: allow for searching IP addresses (?) -->
            <!-- Server IP or domain name -->
            <b-col>
                <b-form-group :label="$t('Database IP')" label-for="serverIP" :class="{'disabled': (datasource.type == 'datasource') || !this.$store.state.auth.user.permissions.PermissionWrite}">
                    <b-form-input type="text" v-model="Server" autocomplete="off"/>
                </b-form-group>                
            </b-col>
            <!-- Server port (5432 by default) -->
            <b-col>
                <b-form-group :label="$t('Database Port')" label-for="serverPort" :class="{'disabled': (datasource.type == 'datasource') || !this.$store.state.auth.user.permissions.PermissionWrite}">
                    <b-form-input type="number" v-model="Port" autocomplete="off"/>
                </b-form-group>   
            </b-col>
        </b-row>
        <b-row>
            <!-- TODO: b-form-select with searching of available databases -->
            <!-- Data source base -->
            <b-col>
                <b-form-group :label="$t('Database')" label-for="database" :class="{'disabled': (datasource.type == 'datasource') || !this.$store.state.auth.user.permissions.PermissionWrite}">
                    <b-form-input type="text" v-model="Database" autocomplete="off"/>
                </b-form-group>                
            </b-col>
            <!-- TODO: b-form-select with searching of available  -->
            <!-- Database schema -->
            <b-col/>
        </b-row>
        <b-row>
            <!-- Integrated Security (SSPI) -->
            <b-col>
                <b-form-checkbox v-model="IntegratedSecurity" :value="true" :unchecked-value="false" switch :class="{'disabled': (datasource.type == 'datasource') || !this.$store.state.auth.user.permissions.PermissionWrite}">
                    {{ $t('Integrated security (SSPI)') }}
                </b-form-checkbox>
            </b-col>
        </b-row>
        <b-row v-if="!IntegratedSecurity">
            <!-- Username -->
            <b-col>
                <b-form-group :label="$t('User name')" label-for="username" :class="{'disabled': (datasource.type == 'datasource') || !this.$store.state.auth.user.permissions.PermissionWrite}">
                    <b-form-input type="text" v-model="Username" autocomplete="off"/>
                </b-form-group>                
            </b-col>
            <b-col>
                <b-form-group :label="$t('Password')" label-for="password" :class="{'disabled': (datasource.type == 'datasource') || !this.$store.state.auth.user.permissions.PermissionWrite}">
                    <b-form-input type="password" v-model="Password" autocomplete="off"/>
                </b-form-group>                
            </b-col>
        </b-row>

    </div>
</template>

<script>
    export default{
        name: "MSSQLServer",
        props: ['datasource'],
        watch: {
            datasource(newVal, oldVal){
                this.Server = newVal.Server;
                this.Port = newVal.Port;
                this.Username = newVal.Username;
                this.Password = newVal.Password;
                this.Database = newVal.Database;
                this.IntegratedSecurity = newVal.IntegratedSecurity;
            }
        },
        data() {
            return{
                Server: (this.datasource.Server == null ? "127.0.0.1" : this.datasource.Server),
                Port: (this.datasource.Port == null ? 1433 : this.datasource.Port),
                IntegratedSecurity: (this.datasource.IntegratedSecurity == null ? true : this.datasource.IntegratedSecurity),
                Username: (this.datasource.Username == null ? "" : this.datasource.Username),
                Password: (this.datasource.Password == null ? "" : this.datasource.Password),
                Database: (this.datasource.Database == null ? "" : this.datasource.Database),
                Schema: "dbo"
            }
        },
        methods: {
        /**
         * Returns the formated data for parent validation
         */
        async validate(){
            return {
                Server: this.Server,
                Port: this.Port,
                Username: this.Username,
                Password: this.Password,
                Database: this.Database,
                IntegratedSecurity: this.IntegratedSecurity,
                Provider: "MS SQL Server",
                Equipment: this.datasource.Equipment,
            }
        }
        }
    }
</script>
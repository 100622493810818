<template>
    <div class="tabContent">
        <b-navbar class="shadow" v-if="showToolbar">
            <b-container fluid class="navbar-container">
                <b-navbar-nav>
                    <!-- Check connection -->
                    <b-nav-item>
                        <font-awesome-layers class="top-menu-icon" :title="$t('Check connection')" v-on:click="onCheckConnection" :class="{ disabled: invalidState || !$store.state.auth.user.permissions.PermissionRead }">
                            <font-awesome-icon icon="fas fa-database" class="fa-lg" />
                            <font-awesome-icon v-if="checkingConnexion" icon="sync" class="fa-xs fa-spin-custom" transform="down-10.0 right-10.0" color="#c8b900" />
                            <font-awesome-icon v-else icon="fa-check" class="fa-xs" transform="down-10.0 right-15.0" color="#4dc615" />
                        </font-awesome-layers>
                    </b-nav-item>
                    <!-- Create database -->
                    <b-nav-item>
                        <font-awesome-layers class="top-menu-icon" :title="$t('Create database')" v-on:click="onCreateDataSource" :class="{ disabled: invalidState || !$store.state.auth.user.permissions.PermissionWrite }">
                            <font-awesome-icon icon="fas fa-database" class="fa-lg" />
                            <font-awesome-icon v-if="creatingDatabase" icon="sync" class="fa-xs fa-spin-custom" transform="down-10.0 right-10.0" color="#c8b900" />
                            <font-awesome-icon v-else icon="fa-plus" class="fa-xs" transform="down-10.0 right-15.0" color="#4dc615" />
                        </font-awesome-layers>
                    </b-nav-item>
                    <!-- Save -->
                    <b-nav-item v-on:click="onSaveConnection" :class="{ disabled: invalidState || !$store.state.auth.user.permissions.PermissionWrite }">
                        <font-awesome-layers class="top-menu-icon" :title="$t('Save')">
                            <font-awesome-icon icon="fas fa-floppy-disk" class="fa-lg" />
                            <font-awesome-icon v-if="savingDatabase" icon="sync" class="fa-xs fa-spin-custom" transform="down-10.0 right-10.0" color="#c8b900" />
                        </font-awesome-layers>
                    </b-nav-item>
                    <!-- Cancel -->
                    <b-nav-item v-on:click="onCancel">
                        <font-awesome-icon icon="far fa-arrow-right-from-bracket" class="top-menu-icon fa-lg" :title="$t('Cancel')" />
                    </b-nav-item>
                </b-navbar-nav>
                <b-navbar-nav class="font-bold"> {{ this.datasource.Equipment ? this.datasource.Equipment + ' > ' : '' }}{{ this.datasource.Name }} </b-navbar-nav>
                <b-navbar-nav>
                    <!-- YEEELP -->
                    <b-nav-item v-on:click="displayHelp" :title="$t('Help')">
                        <font-awesome-icon icon="fas fa-square-question" class="top-menu-icon mr-1 fa-lg" />
                    </b-nav-item>
                </b-navbar-nav>
            </b-container>
        </b-navbar>
        <b-form class="triggerForm" autocomplete="off">
            <b-row>
                <b-col v-if="datasource.type == 'datasourcetemplate'">
                    <b-form-group :label="$t('Name') + ' *'" label-for="name">
                        <b-form-input :disabled="false" :state="dataSourceNameState" v-model="dataSourceName" autocomplete="off" :class="{ disabled: !$store.state.auth.user.permissions.PermissionWrite }" />
                    </b-form-group>
                </b-col>
                <b-col v-else-if="datasource.type == 'datasource'">
                    <b-form-group :label="$t('Reference data source')" label-for="referenceDataSource">
                        <b-form-select id="referenceDataSource" :state="dataSourceNameState" v-model="selectedReferenceDataSource" :options="referenceDataSources" :class="{ disabled: !$store.state.auth.user.permissions.PermissionWrite }" />
                    </b-form-group>
                </b-col>

                <b-col>
                    <b-form-group :label="$t('Provider')" label-for="provider" v-if="datasource.type == 'datasourcetemplate'">
                        <b-form-select v-model="SelectedProvider" :options="Providers" :class="{ disabled: !$store.state.auth.user.permissions.PermissionWrite }" />
                    </b-form-group>
                    <b-form-group :label="$t('Provider')" label-for="provider" v-if="datasource.type == 'datasource'" :class="{ disabled: datasource.type == 'datasource' || !$store.state.auth.user.permissions.PermissionWrite }">
                        <b-form-input v-model="SelectedProvider" autocomplete="off" />
                    </b-form-group>
                </b-col>
            </b-row>

            <!-- Data source provider specific configuration -->
            <PostgreSQL :datasource="datasource.type == 'datasource' ? this.selectedReferenceDataSource : this.datasource" v-if="SelectedProvider == 'PostgreSQL'" ref="dataSource" />
            <MSSQLServer :datasource="datasource.type == 'datasource' ? this.selectedReferenceDataSource : this.datasource" v-else-if="SelectedProvider == 'MS SQL Server'" ref="dataSource" />
            <ProductysManager :datasource="datasource.type == 'datasource' ? this.selectedReferenceDataSource : this.datasource" v-if="SelectedProvider == 'ProductysManager'" ref="dataSource" />
            <!-- <b-row v-else>
                <b-col cols="3">
                    <b-button v-on:click="onCheckConnection" style="width: 100%">Check Connection</b-button>
                </b-col>
                <b-col cols="3" v-if="datasource.type == 'datasourcetemplate'">
                    <b-button v-on:click="onCreateDataSource" style="width: 100%" >Create Database</b-button>
                </b-col>
                <b-col cols="3">
                    <b-button v-on:click="onSaveConnection" style="width: 100%">Save</b-button>
                </b-col>
            </b-row> -->
        </b-form>
    </div>
</template>

<script>
import ProdComDataSourceService from '@/services/prodcom.datasources.service.js';
import ToastAlert from '@/utils/ToastAlert';
import PostgreSQL from './Providers/PostgreSQL.vue';
import MSSQLServer from './Providers/MSSQLServer.vue';
import ProductysManager from './Providers/ProductysManager.vue';
/**
 * Global data source configuration view. From here we call
 * the different configuration panels depending on the data source provider.
 */
export default {
    name: 'DataSource',
    components: { PostgreSQL, MSSQLServer, ProductysManager },
    props: {
        datasource: Object,
        showToolbar: Boolean,
        closeTab: Function,
    },
    data() {
        return {
            Providers: [],
            SelectedProvider: this.datasource.Provider == null ? '' : this.datasource.Provider,
            dataSourceName: '',
            referenceDataSources: [],
            selectedReferenceDataSource: '',
            checkingConnexion: false,
            creatingDatabase: false,
            savingDatabase: false,
        };
    },
    watch: {
        selectedReferenceDataSource(newVal, oldVal) {
            this.SelectedProvider = newVal?.Provider;
            this.selectedReferenceDataSource = newVal;
            if (this.selectedReferenceDataSource != null) this.selectedReferenceDataSource.type = 'datasource';
        },
        datasource: {
            handler(newVal, oldVal) {
                this.SelectedProvider = newVal?.Provider;
            },
            deep: true,
        },
    },
    computed: {
        invalidState() {
            return this.dataSourceNameState === false;
        },
        dataSourceNameState() {
            if (this.datasource.type == 'datasourcetemplate') return this.dataSourceName == null || this.dataSourceName == '' ? false : null;
            else return this.selectedReferenceDataSource == null ? false : null;
        },
    },
    async mounted() {
        if (this.datasource.type == 'datasource') {
            // Get list of available data source templates
            var data = await ProdComDataSourceService.getDataSourceList(this.datasource.ProjectId);
            if (data.success == 'n') this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(data.ret), 'warning'));
            else {
                this.referenceDataSources = [];
                for (const ds of data.ret) {
                    this.referenceDataSources.push({ value: ds, text: ds.Name });
                }
                this.selectedReferenceDataSource = this.referenceDataSources?.[0]?.value;
            }
            // Get data source of equipment from reference in equipment
            if (!this.datasource.Equipment) return;
            data = await ProdComDataSourceService.getDataSourceIdFromEquipment(this.datasource.Equipment, this.datasource.ProjectId);
            if (data.success == 'n') this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(data.ret), 'warning'));
            else {
                var ds = this.referenceDataSources?.find((datas) => datas.value.Id === data.ret)?.value;
                if (ds != null) this.selectedReferenceDataSource = ds;
            }
        } else {
            // Get list of available providers from ProdCom (don't display providers that would not have been loaded at the start of ProdCom)
            var data = await ProdComDataSourceService.getDataSourceProviders();
            if (data.success == 'n') this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(data.ret, data.retParams), 'warning'));
            else {
                // TODO: change to be able to translate the provider (eg: give compatible versions)
                this.Providers = data.ret;
                this.dataSourceName = this.datasource.Name;
            }
        }
    },
    methods: {
        async onCheckConnection() {
            this.checkingConnexion = true;
            try {
                var config = null;
                if (this.$refs['dataSource'] != null && this.$refs['dataSource'].validate != null) {
                    config = await this.$refs['dataSource'].validate();
                }
                const data = await ProdComDataSourceService.checkConnection(config);
                if (data.success == 'n') this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(data.ret, data.retParams), 'warning'));
                else {
                    if (data.ret === true) {
                        this.$EventBus.$emit('show-toast', new ToastAlert(this.$t('Connection successful'), 'info'));
                    } else {
                        this.$EventBus.$emit('show-toast', new ToastAlert(this.$t('Connection failed'), 'warning'));
                    }
                }
            } catch (error) {
                console.log(error);
            }
            this.checkingConnexion = false;
        },
        async onSaveConnection() {
            this.savingDatabase = true;
            try {
                if (this.datasource.type == this.$constants.TAB_TYPE_DATASOURCE) {
                    // Save reference
                    var chosenId = this.selectedReferenceDataSource.Id;
                    const data = await ProdComDataSourceService.setEquipmentDataSource(this.datasource.Equipment, chosenId, this.datasource.ProjectId);
                    if (data.success == 'n') this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(data.ret, data.retParams), 'warning'));
                    else {
                        this.$EventBus.$emit('show-toast', new ToastAlert(this.$t('Saved data source.'), 'info'));
                        this.$EventBus.$emit('refresh-treeview-equipments');
                    }
                } else if (this.datasource.type == this.$constants.TAB_TYPE_DATASOURCETEMPLATE) {
                    // Save full configuration
                    var config = null;
                    if (this.$refs['dataSource'] != null && this.$refs['dataSource'].validate != null) {
                        config = await this.$refs['dataSource'].validate();
                        if (config == null) {
                            this.savingDatabase = false;
                            return;
                        }
                        config.Name = this.dataSourceName;
                        config.Id = this.datasource.Id;
                        config.ProjectId = this.datasource.ProjectId;
                    }
                    const data = await ProdComDataSourceService.saveTemplateConnection(config);
                    if (data.success == 'n') this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(data.ret, data.retParams), 'warning'));
                    else {
                        this.$EventBus.$emit('show-toast', new ToastAlert(this.$t('Saved data source.'), 'info'));
                        this.$EventBus.$emit('refresh-treeview-equipments');
                    }
                }
            } catch (error) {
                console.log(error);
            }
            this.savingDatabase = false;
            return;
        },
        async onCreateDataSource() {
            this.creatingDatabase = true;
            try {
                // Check connection before saving.
                var config = null;
                if (this.$refs['dataSource'] != null && this.$refs['dataSource'].validate != null) {
                    config = await this.$refs['dataSource'].validate();
                }
                if (config == null) {
                    this.creatingDatabase = false;
                    return;
                }
                const data = await ProdComDataSourceService.createDataSource(config);
                if (data.success == 'n') this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(data.ret, data.retParams), 'warning'));
                else {
                    this.$EventBus.$emit('show-toast', new ToastAlert(this.$t('Data source created.'), 'info'));
                }
            } catch (error) {
                console.log(error);
            }
            this.creatingDatabase = false;
        },
        async getConfiguration() {
            var config = null;
            if (this.$refs['dataSource'] != null && this.$refs['dataSource'].validate != null) {
                config = await this.$refs['dataSource'].validate();
                config.Name = this.dataSourceName;
                config.Id = this.datasource.Id;
                config.ProjectId = this.datasource.ProjectId;
            }
            return config;
        },
        async onCancel() {
            if (this.closeTab != null) {
                await this.closeTab();
            }
        },
        getTemplateId() {
            return this.selectedReferenceDataSource.Id;
        },
        async displayHelp() {
            this.$store.commit('displayHelp', this.$t('help.datasources'));
            this.$bvModal.show('bv-modal-help');
        },
    },
};
</script>

<style lang="scss">
.triggerForm {
    margin: 1.25rem;
}
</style>
import store from "@/store/index.js";

class MessageService {
    async getAllMessages(languageCode) {
        const settings = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                Method: 'messages.get',
                Parameters: {
                    LanguageCode: languageCode
                },
            }),
        };

        const res = await fetch(store.state.settings.API_URL, settings);
        const data = await res.json();
        return data;
    }
}

export default new MessageService();

<template>
  <b-nav-text class="ml-4 font-weight-bold">{{clockStr}}</b-nav-text>
</template>
<script>
export default {
    name : "Clock",
    data () {
      return {
        clockStr: ''
      }
    },
    mounted() {
      this.computeClockStr();
      setInterval(() => {
        this.computeClockStr();
      }, 30000);
    },
    methods: {
      computeClockStr() {
      const currentLocale = localStorage.getItem("locale");
      this.$i18n.locale = currentLocale;
        const d = new Date();
        this.clockStr = d.toLocaleDateString(currentLocale, { year: '2-digit', month: '2-digit', day: '2-digit' }) +
        ' ' + d.toLocaleTimeString(currentLocale, { hour: '2-digit', minute: '2-digit' }) +
        ' - ' + this.$t('short_week') + d.getWeekNumber();
      }
    }
}
</script>
<template>
    <div>
        <b-row>
            <b-col cols="4">
                <b-list-group class="mb-2">
                    <div class="text-center" v-if="$store.state.auth.user.permissions.PermissionWrite">
                        <font-awesome-icon v-if="(this.currentLink != null && this.currentLink.Id != 0) || this.currentLink == null" v-on:click="createNewLink" icon="plus" class="cursor-pointer" v-bind:alt="$t('Add')" v-bind:title="$t('Add')" />
                        <font-awesome-icon v-else icon="plus" color="grey" v-bind:alt="$t('Add')" v-bind:title="$t('Add')" />
                        <font-awesome-icon v-if="this.currentLink != null" v-on:click="removeLink" icon="minus" class="cursor-pointer ml-3" v-bind:alt="$t('Remove')" v-bind:title="$t('Remove')" />
                        <font-awesome-icon v-else icon="minus" color="grey" class="ml-3" v-bind:alt="$t('Remove')" v-bind:title="$t('Remove')" />
                        <font-awesome-icon v-if="this.currentLink != null && this.currentLink.Id == 0" icon="check" class="cursor-pointer ml-3 scale-animation" v-on:click="saveLink" v-bind:alt="$t('Save')" v-bind:title="$t('Save')" />
                        <!-- <font-awesome-icon v-else icon="check" color="grey" class="ml-3" v-bind:alt="$t('Save')" v-bind:title="$t('Save')" /> -->
                    </div>
                </b-list-group>
                <b-list-group-item v-for="link in links" :key="link.Id" :active="currentLink && link.Id == currentLink.Id" v-on:click="selectLink(link.Id)" v-bind:class="{ 'font-italic': link.Id == 0 }" button>
                    <div>{{ getName(link) }}</div>
                </b-list-group-item>
            </b-col>
            <b-col cols="8">
                <b-card v-if="this.currentLink != null && this.currentLink.Id != null">
                    <b-form>
                        <b-row>
                            <b-col cols="4">
                                <b-form-group :label="$t('Group')" label-for="GroupeAction">
                                    <b-form-select v-model="selectedGroupType" :state="validLink" @change="saveLink(currentLink.Id)" :options="availableGroupTypes" :class="{ disabled: !$store.state.auth.user.permissions.PermissionWrite }" />
                                    <b-form-invalid-feedback>{{ invalidLinkFeedback }}</b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>
                            <b-col cols="4">
                                <b-form-group :label="$t('Action')" label-for="Action">
                                    <b-form-select v-model="selectedAction" :state="validLink" @change="saveLink(currentLink.Id)" :options="availableActions" :class="{ disabled: !$store.state.auth.user.permissions.PermissionWrite }" />
                                    <b-form-invalid-feedback>{{ invalidLinkFeedback }}</b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>
                            <b-col cols="4">
                                <b-form-group :label="$t('triggers.sequence.executionCondition')" label-for="Condition">
                                    <b-form-select v-model="selectedCondition" @change="saveLink(currentLink.Id)" :options="availableConditions" :class="{ disabled: !$store.state.auth.user.permissions.PermissionWrite }" />
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </b-form>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import ProdComActionService from '@/services/prodcom.actions.service.js';
import ToastAlert from '@/utils/ToastAlert';
import { uuid } from 'vue-uuid';
export default {
    name: 'Sequence',
    props: {
        action: Object,
        propProject: String,
        equipment: String,
    },
    data() {
        return {
            links: [],
            currentLink: null,
            availableGroupTypes: [
                { value: 1, text: this.$t('triggers.sequence.archivalAllAppear') },
                { value: 2, text: this.$t('triggers.sequence.archivalAllDisappear') },
            ],
            availableConditions: [
                { value: 0, text: this.$t('triggers.sequence.conditionAlways') },
                { value: 1, text: this.$t('triggers.sequence.conditionSuccess') },
                { value: 2, text: this.$t('triggers.sequence.conditionFailure') },
            ],
            validLink: true,
            invalidLinkFeedback: this.$t('triggers.sequence.invalidLinkFeedback'),
            allActions: [],
            currentLink: null,
            selectedGroupType: null,
            selectedAction: null,
            selectedCondition: 0,
        };
    },
    async mounted() {
        const result = await ProdComActionService.getActions(this.equipment, this.propProject);
        if (result.success == 'n') this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(result.ret, result.retParams), 'warning'));
        else {
            this.allActions = [];
            for (const act of result.ret.List) {
                if (act.Name != this.action?.Name) {
                    this.allActions.push({ value: act, text: act.Name });
                    if (this.availableGroupTypes.findIndex((it) => it.value == act.Group) == -1) {
                        this.availableGroupTypes.push({ value: act.Group, text: act.Group });
                    }
                }
            }
            this.allActions.push({ value: 0, text: this.$t('triggers.sequence.allActions') });
        }
        this.links = this.action?.DeclencheurConfiguration?.Links ?? [];
    },
    watch: {
        selectedGroupType(newVal, oldVal) {
            // Automatically select first one on the list
            if (this.selectedAction == null) this.selectedAction = this.availableActions[0]?.value;
            if (this.availableActions.findIndex((it) => it?.value?.Name == this.selectedAction.Name) == -1) this.selectedAction = this.availableActions[0]?.value;
        },
    },
    computed: {
        availableActions() {
            if (this.selectedGroupType == null) return [];
            let result = this.allActions.filter((it) => it.value.Group == this.selectedGroupType);
            result.unshift({ value: 0, text: this.$t('triggers.sequence.allActions') });
            return result;
        },
        valid() {
            return this.validLink;
        },
    },
    methods: {
        /**
         * Adds a new link to the list of links
         */
        async createNewLink() {
            this.links.push({ Id: 0, Action: 0, TypeGroupe: 1, Condition: 0, GroupeAction: null });
            (this.selectedGroupType = 1), (this.selectedAction = 0), (this.selectedCondition = 0), await this.selectLink(0);
        },

        /**
         * Selects a new link by its id.
         * @param id {string} ID of the link to be selected
         */
        async selectLink(id) {
            const foudLink = this.links.find((gr) => gr.Id == id);
            if (foudLink != this.currentLink) {
                this.currentLink = foudLink;
                if (foudLink.Action == null || foudLink.Action == '') {
                    this.selectedAction = 0;
                } else {
                    this.selectedAction = this.allActions.find((it) => it.value.Name == foudLink.Action)?.value ?? 0;
                }
                if (this.currentLink.TypeGroupe == 1 || this.currentLink.TypeGroupe == 2) {
                    // TypeGroupes.ArchivageApparitionEvt or TypeGroupes.ArchivageDisparitionEvt
                    this.selectedGroupType = this.currentLink.TypeGroupe;
                } else {
                    // TypeGroupes.None
                    this.selectedGroupType = foudLink.GroupeAction;
                }
                this.selectedCondition = foudLink?.Condition;
            }
            this.validLink = true;
            this.saveLink(this.currentLink.Id);
        },

        /**
         * Gets the name of the given link (changes depending on its configuration.)
         * @param link {object} The link to get the name from
         * @return {string} The name of the link
         */
        getName(link) {
            let src = '';
            if (link.Action == null || link.Action == '') {
                if (link.TypeGroupe == 0) {
                    // TypeGroupes.None
                    src = `${this.$t('triggers.sequence.allActionsOf')}[${this.availableGroupTypes.find((it) => it.value == link.GroupeAction)?.text}]`;
                } else if (link.TypeGroupe == 1 || link.TypeGroupe == 2) {
                    // TypeGroupes.ArchivageApparitionEvt or TypeGroupes.ArchivageDisparitionEvt
                    src = `${this.$t('triggers.sequence.allActionsOf')}[${this.availableGroupTypes.find((it) => it.value == link.TypeGroupe)?.text}]`;
                }
            } else {
                src = link.Action;
            }
            const cond = this.availableConditions.find((it) => it.value == link.Condition)?.text;
            return `${src}<=>${cond}`;
        },

        /**
         * Removes the currently selected link
         */
        async removeLink(e) {
            const idx = this.links.findIndex((it) => this.currentLink.Id === it.Id);
            if (idx != -1) {
                this.links.splice(idx, 1);
                this.currentLink = null;
            }
            if (this.links.length > 0) {
                if (idx < this.links.length) await this.selectLink(this.links[idx].Id);
                else await this.selectLink(this.links[this.links.length - 1].Id);
            }
            this.validLink = true;
        },

        /**
         * Saves the current link being edited
         */
        async saveLink() {
            // Check if link already exists
            const anyExists = this.links.find((link) => {
                if (link.Id == this.currentLink.Id) return false; // Don't check itself
                let sameGroupType = false;
                if (this.selectedGroupType == 1 || this.selectedGroupType == 2) {
                    sameGroupType = link.TypeGroupe == this.selectedGroupType;
                } else {
                    sameGroupType = link.GroupeAction == this.selectedGroupType;
                }
                let sameAction = false;
                if (this.selectedAction == 0) {
                    sameAction = link.Action == null || link.Action == '';
                } else {
                    sameAction = link.Action == this.selectedAction?.Name;
                }
                return sameGroupType && sameAction;
            });
            if (anyExists != null) {
                this.validLink = false;
                return false;
            } else {
                this.validLink = true;
            }
            if (this.currentLink.Id == 0) {
                // New link.
                const actionName = this.selectedAction == 0 ? null : this.selectedAction?.Name;
                const condition = this.selectedCondition;
                let groupType = 0;
                let groupName = this.selectedGroupType;
                if (this.selectedGroupType == 1 || this.selectedGroupType == 2) {
                    groupType = this.selectedGroupType;
                    // From what I understand, we still need the groupName even if we choose 1 of the 2 predefined links...
                    const foudLinkName = this.availableGroupTypes.find((it) => it.value == this.selectedGroupType);
                    groupName = foudLinkName?.text;
                }
                this.currentLink.Id = uuid.v4();
                this.currentLink.Action = actionName;
                this.currentLink.TypeGroupe = groupType;
                this.currentLink.Condition = condition;
                this.currentLink.GroupeAction = groupName;
                // Remove link with ID 0 and add back the new one.
                let index = this.links.findIndex((it) => it.Id == 0);
                if (index != -1) {
                    this.links.splice(index, 1);
                    this.links.push(this.currentLink);
                }
            } else {
                // Existing link.
                const actionName = this.selectedAction == 0 ? null : this.selectedAction?.Name;
                const condition = this.selectedCondition;
                let groupType = 0;
                let groupName = this.selectedGroupType;
                if (this.selectedGroupType == 1 || this.selectedGroupType == 2) {
                    groupType = this.selectedGroupType;
                    // From what I understand, we still need the groupName even if we choose 1 of the 2 predefined links...
                    const foudLinkName = this.availableGroupTypes.find((it) => it.value == this.selectedGroupType);
                    groupName = foudLinkName?.text;
                }
                this.currentLink.Action = actionName;
                this.currentLink.TypeGroupe = groupType;
                this.currentLink.Condition = condition;
                this.currentLink.GroupeAction = groupName;
            }
        },

        async validate() {
            // Remove link with ID 0
            this.links = this.links.filter((it) => it.Id != 0);
            this.validLink = true;
            this.currentLink = null;
            return {
                Links: this.links,
            };
        },
    },
};
</script>
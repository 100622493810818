<template>
    <b-modal id="messageBuilder" v-b-modal.modal-multi-2 size="xl"
        @cancel="onItemCancel" @ok="onItemOk" @show="onItemShow"
        :title="$t('messageBuilder.title')" :cancel-title="$t('Cancel')" :ok-title="$t('Ok')">
        <b-form>
            <b-row>
                <b-col>
                    <b-form-group :label="$t('messageBuilder.variables') + ' *'" label-for="variables">
                        <TreeView id="variables" :item="itemListVariables" :itemSelect="onSelectItemActions" :multiSelect="false"/>
                    </b-form-group>
                </b-col>
                <b-col>
                    <b-form-group :label="$t('messageBuilder.body') + ' *'" label-for="body">
                        <b-form-textarea id="body" v-model="Body"/>
                    </b-form-group>
                </b-col>
            </b-row>
        </b-form>
    </b-modal>
</template>

<script>
import ToastAlert from '@/utils/ToastAlert';
import TreeView from "@/components/TreeView.vue";
import ProdComEquipmentService from "@/services/prodcom.equipments.service.js";
/***
 * Constructs a message with the equipment's variables.
 */
export default {
    name: "MessageBuilder",
    props:{
        /**
         * Guid of selected project
         */
        project: String,
        /**
         * Name of selected equipment
         */
        equipment: String,
        /**
         * Existing body message, if any exists
         */
        body: String,
        /**
         * 'OK' click callback
         */
        onOk: Function,
        /**
         * 'Cancel' click callback
         */
        onCancel: Function
    },
    components: {
        TreeView
    },
    data(){
        return {
            itemListVariables: [],
            Body: this.body,
            UsedVariables: [],
        }
    },
    methods: {
        /**
         * Called when an item in the treeview is selected. Appends the variable to the message
         * and to the list of variables used.
         */
        async onSelectItemActions(e){
            var selected = e[0];
            if(selected?.parent != null && selected?.parent?.parent != null && selected?.parent?.parent != null && selected?.items == null){
                this.Body = `${this.Body} #${selected?.parent?.parent.text}.${selected?.parent?.text}.${selected?.text}#`
                this.UsedVariables.push({Group: selected?.parent?.parent.text, Item: selected?.parent?.text, VarInterne: selected?.text})
            }
        },
        /**
         * Called when the user cancels the wizard.
         */
        async onItemCancel(e) {
            if(this.onCancel != null){
                await this.onCancel();
            }
        },
        /**
         * Called when the user validates the wizard.
         */
        async onItemOk(e) {
            if(this.onOk != null){
                // Callback to parent.
                await this.onOk({Body: this.Body, Variables: this.UsedVariables});
            }
        },
        /**
         * Sets everything up when the popup is shown.
         */
        async onItemShow(e) {
            this.Body = this.body;
            this.itemListVariables = [];
            // Build the treeview data from the equipment's variables
            const data = await ProdComEquipmentService.getEquipmentVariables(this.equipment, this.project);

            if(data.success == "n") this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(data.ret), 'warning'));
            else {
                for(const variable of data.ret.List){
                    var existingGroup = this.itemListVariables.find(ac => ac.text == variable.Group);
                    if(existingGroup == null){
                        this.itemListVariables.push({id: variable.Group, text: variable.Group, expanded: false, items: [], contextMenuSelected: false, selected: false})
                        existingGroup = this.itemListVariables.find(ac => ac.text == variable.Group);

                    }
                    existingGroup.items.push({id: variable.Group + "_" + variable.Name, text: variable.Name, expanded: false, items: null, obj: variable, contextMenuSelected: false, selected: false,
                        items: [
                            {id: variable.Group + "_" + variable.Name + "_Valeur", text: "Valeur", expanded: false, items: null, icon: "fas fa-tag", iconColor: "#f57c00", contextMenuSelected: false, selected: false},
                            {id: variable.Group + "_" + variable.Name + "_ValeurN1", text: "ValeurN1", expanded: false, items: null, icon: "fas fa-tag", iconColor: "#f57c00", contextMenuSelected: false, selected: false},
                            {id: variable.Group + "_" + variable.Name + "_Max", text: "Max", expanded: false, items: null, icon: "fas fa-tag", iconColor: "#f57c00", contextMenuSelected: false, selected: false},
                            {id: variable.Group + "_" + variable.Name + "_Min", text: "Min", expanded: false, items: null, icon: "fas fa-tag", iconColor: "#f57c00", contextMenuSelected: false, selected: false},
                            {id: variable.Group + "_" + variable.Name + "_NbVar", text: "NbVariations", expanded: false, items: null, icon: "fas fa-tag", iconColor: "#f57c00", contextMenuSelected: false, selected: false},
                        ]
                    })
                }
            }
        },
    }
}
</script>

<style>

</style>
<template>
    <b-row>
        <b-col cols="4">
            <b-list-group>
                <div class="text-center">
                    <font-awesome-icon icon="sync" class="cursor-pointer mr-3" spin v-if="saving || statusText" />
                    <font-awesome-icon icon="plus" v-bind:class="{ disabled: saving || loading }" class="cursor-pointer" v-on:click="createNewConnectionString" v-bind:alt="$t('Add database')" v-bind:title="$t('Add database')" />
                    <font-awesome-icon icon="check" v-bind:class="{ disabled: saving || loading, 'scale-animation': needSave }" class="cursor-pointer ml-3" v-if="!saving && this.currentConnection.Id != null" v-on:click="saveConnectionString" v-bind:alt="$t('Save')" v-bind:title="$t('Save')" />
                    <font-awesome-icon icon="minus" v-bind:class="{ disabled: saving || loading }" class="cursor-pointer ml-3" v-if="currentConnection && currentConnection.Id > 0" v-on:click="removeConnectionString" v-bind:alt="$t('Remove')" v-bind:title="$t('Remove')" />
                </div>
                <div class="pt-1 pb-3 text-center">
                    <transition name="fade">
                        <div class="font-italic" v-if="statusText">{{ statusText }}</div>
                    </transition>
                </div>
                <b-list-group-item
                    :disabled="saving || loading || getDatabaseTables_running || getDatabaseViews_running"
                    v-for="cs in connectionStrings"
                    :key="cs.Id"
                    :active="currentConnection && cs.Id == currentConnection.Id"
                    v-on:click="selectConnectionString(cs.Id)"
                    button
                    v-bind:class="{ 'font-italic': cs.Id == 0 }"
                    >{{ csLabel(cs) }}</b-list-group-item
                >
            </b-list-group>
        </b-col>
        <b-col cols="8">
            <b-card no-body v-if="currentConnection.Id != null">
                <b-tabs pills card v-model="tabIndex">
                    <b-tab active :title="$t('Connection')">
                        <b-form>
                            <b-row>
                                <b-col>
                                    <b-form-group :label="$t('Alias') + ' *'" label-for="currentConnection_Alias">
                                        <b-form-input :maxlength="$store.state.settings.INPUT_NAME_MAX_LENGTH" :disabled="saving || loading" class="sm" ref="currentConnection_Alias" id="currentConnection_Alias" v-model="currentConnection.Alias" required></b-form-input>
                                    </b-form-group>
                                </b-col>
                                <b-col>
                                    <b-form-group :label="$t('Provider') + ' *'" label-for="currentConnection_provider">
                                        <b-form-select :disabled="saving || loading" v-model="currentConnection.Provider" :options="providers"></b-form-select>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                            <hr color="grey" />
                            <b-row>
                                <b-col>
                                    <b-form-checkbox class="mb-1" v-model="currentConnection.ExternalNetwork" switch style="padding-bottom: 5px">
                                        {{ $t('External network (SSH connexion)') }}
                                    </b-form-checkbox>
                                </b-col>
                                <b-col v-show="currentConnection.ExternalNetwork">
                                    <b-form-checkbox class="mb-1" v-model="currentConnection.CertificateAuthentication" switch>
                                        {{ $t('Certificate authentication') }}
                                    </b-form-checkbox>
                                </b-col>
                            </b-row>
                            <b-row v-show="currentConnection.ExternalNetwork">
                                <b-col>
                                    <b-form-group :label="$t('SSH Server IP') + ' *'" label-for="currentConnection_ipssh">
                                        <b-form-input :maxlength="$store.state.settings.INPUT_URL_MAX_LENGTH" :disabled="saving || loading" id="currentConnection_ipssh" v-model="currentConnection.ServerSSH" required></b-form-input>
                                    </b-form-group>
                                </b-col>
                                <b-col>
                                    <b-form-group :label="$t('SSH Port') + ' *'" label-for="currentConnection_portssh">
                                        <b-form-input :disabled="saving || loading" type="number" id="currentConnection_portssh" v-model="currentConnection.PortSSH" min="1" max="65565" setp="1" required></b-form-input>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                            <b-row v-show="currentConnection.ExternalNetwork">
                                <b-col>
                                    <b-form-group :label="$t('SSH User') + ' *'" label-for="currentConnection_userssh">
                                        <b-form-input :maxlength="$store.state.settings.INPUT_NAME_MAX_LENGTH" :disabled="saving || loading" id="currentConnection_userssh" v-model="currentConnection.UserSSH" required></b-form-input>
                                    </b-form-group>
                                </b-col>
                                <b-col v-show="!currentConnection.CertificateAuthentication">
                                    <b-form-group :label="$t('SSH Password') + ' *'" label-for="currentConnection_passwordssh">
                                        <b-form-input :maxlength="$store.state.settings.INPUT_MAX_LENGTH" :disabled="saving || loading" type="password" id="currentConnection_passwordssh" v-model="currentConnection.PasswordSSH" required></b-form-input>
                                    </b-form-group>
                                </b-col>
                                <!-- Never going to have a certificate password entered by the user -->
                                <!-- If a certificate password was going to be put, it would be entirely in the back-end -->
                                <b-col v-show="false && currentConnection.CertificateAuthentication">
                                    <b-form-group :label="$t('Certificate Passphrase')" label-for="currentConnection_passphrasessh">
                                        <b-form-input :disabled="saving || loading" type="password" id="currentConnection_passphrasessh" v-model="currentConnection.PassphraseSSH"></b-form-input>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                            <!-- Switch the public key input to readonly -->
                            <!-- Would be usefull to add a 'copy to clipboard' button -->
                            <b-row v-show="currentConnection.ExternalNetwork">
                                <b-col v-show="currentConnection.CertificateAuthentication">
                                    <b-form-group :label="$t('Public Key')" label-for="currentConnection_certificatessh">
                                        <b-form-input :disabled="saving || loading" readonly id="currentConnection_certificatessh" v-model="currentConnection.CertificateSSH" required></b-form-input>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                            <!-- Disabled file input: no use as the public key comes from the server. -->
                            <b-row v-show="false && currentConnection.ExternalNetwork">
                                <b-col v-show="currentConnection.CertificateAuthentication">
                                    <input type="file" @change="previewFile" />
                                </b-col>
                            </b-row>
                            <hr color="grey" />
                            <b-row>
                                <b-col>
                                    <b-form-group :label="$t('Database IP') + ' *'" label-for="currentConnection_ip">
                                        <b-form-input :maxlength="$store.state.settings.INPUT_MAX_LENGTH" :disabled="saving || loading" id="currentConnection_ip" v-model="currentConnection.ServerName" required></b-form-input>
                                    </b-form-group>
                                </b-col>
                                <b-col>
                                    <b-form-group :label="$t('Database Port') + ' *'" label-for="currentConnection_port">
                                        <b-form-input :disabled="saving || loading" type="number" id="currentConnection_port" v-model="currentConnection.PortNumber" min="1" max="65565" step="1" required></b-form-input>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col>
                                    <b-form-group :label="$t('User name') + ' *'" label-for="currentConnection_userName">
                                        <b-form-input :maxlength="$store.state.settings.INPUT_NAME_MAX_LENGTH" :disabled="saving || loading" id="currentConnection_userName" v-model="currentConnection.UserName" required></b-form-input>
                                    </b-form-group>
                                </b-col>
                                <b-col>
                                    <b-form-group :label="$t('Password') + ' *'" label-for="currentConnection_password">
                                        <b-form-input :maxlength="$store.state.settings.INPUT_MAX_LENGTH" :disabled="saving || loading" type="password" id="currentConnection_password" v-model="currentConnection.Password" required></b-form-input>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col class="white-space-nowrap">
                                    <b-form-group :label="$t('Database') + ' *'" label-for="currentConnection_databases">
                                        <b-form-select
                                            v-on:change="getDatabaseSchemas"
                                            :disabled="saving || loading || currentConnection.Id != 0"
                                            id="currentConnection_databases"
                                            v-on:focus.native="getDatabasesFromConnString"
                                            v-model="currentConnection.Database"
                                            class="mr-2"
                                            :options="databases"
                                            style="width: calc(100% - 55px)"
                                        ></b-form-select>
                                        <b-button :disabled="saving || loading || currentConnection.Id != 0" small v-on:click="getDatabasesFromConnString"><font-awesome-icon icon="sync" class="fa-lg" /></b-button>
                                    </b-form-group>
                                </b-col>
                                <b-col v-if="currentConnection.Provider == 'postgresql' || currentConnection.Provider == 'sql server'">
                                    <b-form-group :label="$t('Database scheme') + ' *'" label-for="currentConnection_securityScheme">
                                        <b-form-select :disabled="saving || loading || currentConnection.Id != 0" id="currentConnection_securityScheme" v-on:focus.native="getDatabaseSchemas" v-model="currentConnection.DbSchema" :options="schemas"></b-form-select>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                            <FormErrors :propFormErrors="formErrors" />
                        </b-form>
                    </b-tab>
                    <b-tab :title="$t('Limits')">
                        <b-row>
                            <b-col>
                                <b-form-group :label="$t('SQL command timeout')" label-for="currentConnection_CommandTimeout">
                                    <b-form-input type="number" id="currentConnection_CommandTimeout" v-model="currentConnection.CommandTimeout" min="1" max="300" step="1"></b-form-input>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row class="mt-3">
                            <b-col sm="12" class="mb-2">{{ $t('Max rows number') }}</b-col>
                            <b-col>
                                <b-form-checkbox class="mb-1" v-model="currentConnection.LimitRowsOnDataGrid" switch>
                                    {{ $t('dataGrid') }}
                                </b-form-checkbox>
                                <b-form-input v-show="currentConnection.LimitRowsOnDataGrid" type="number" id="currentConnection_LimitRowsOnDataGrid" v-model="currentConnection.DataGridRowsLimit" min="1" max="100000000" step="1"></b-form-input>
                            </b-col>
                            <b-col>
                                <b-form-checkbox class="mb-1" v-model="currentConnection.LimitRowsOnForm" switch>
                                    {{ $t('form') }}
                                </b-form-checkbox>
                                <b-form-input v-show="currentConnection.LimitRowsOnForm" type="number" id="currentConnection_FormRowsLimit" v-model="currentConnection.FormRowsLimit" min="1" max="100000000" step="1"></b-form-input>
                            </b-col>
                            <b-col>
                                <b-form-checkbox class="mb-1" v-model="currentConnection.LimitRowsOnPivotGrid" switch>
                                    {{ $t('pivotGrid') }}
                                </b-form-checkbox>
                                <b-form-input v-show="currentConnection.LimitRowsOnPivotGrid" type="number" id="currentConnection_PivotGridRowsLimit" v-model="currentConnection.PivotGridRowsLimit" min="1" max="100000000" step="1"></b-form-input>
                            </b-col>
                        </b-row>
                        <b-row class="mt-3">
                            <b-col>
                                <b-form-checkbox class="mb-1" v-model="currentConnection.LimitRowsOnSerieChart" switch>
                                    {{ $t('serieChart') }}
                                </b-form-checkbox>
                                <b-form-input v-show="currentConnection.LimitRowsOnSerieChart" type="number" id="currentConnection_SerieChartRowsLimit" v-model="currentConnection.SerieChartRowsLimit" min="1" max="100000000" step="1"></b-form-input>
                            </b-col>
                            <b-col>
                                <b-form-checkbox class="mb-1" v-model="currentConnection.LimitRowsOnParetoChart" switch>
                                    {{ $t('paretoChart') }}
                                </b-form-checkbox>
                                <b-form-input v-show="currentConnection.LimitRowsOnParetoChart" type="number" id="currentConnection_ParetoChartRowsLimit" v-model="currentConnection.ParetoChartRowsLimit" min="1" max="100000000" step="1"></b-form-input>
                            </b-col>
                            <b-col>
                                <b-form-checkbox class="mb-1" v-model="currentConnection.LimitRowsOnPieChart" switch>
                                    {{ $t('pieChart') }}
                                </b-form-checkbox>
                                <b-form-input v-show="currentConnection.LimitRowsOnPieChart" type="number" id="currentConnection_PieChartRowsLimit" v-model="currentConnection.PieChartRowsLimit" min="1" max="100000000" step="1"></b-form-input>
                            </b-col>
                        </b-row>
                    </b-tab>
                    <b-tab v-on:click="getDatabaseTables(false)" :disabled="!currentConnection.Database || currentConnection.Id == 0">
                        <template #title>
                            {{ $t('Tables') }} <font-awesome-icon icon="sync" spin v-if="getDatabaseTables_running" class="mr-1" />
                            <b-badge pill variant="info" v-if="databasesTables['csid_' + currentConnection.Id] != undefined">{{ selectedTables }} / {{ databasesTables['csid_' + currentConnection.Id].length }}</b-badge>
                        </template>
                        <b-row class="m-0 border-bottom mb-2" v-if="databasesTables['csid_' + currentConnection.Id] != undefined">
                            <b-col class="pl-0 mb-2">
                                <b-link class="text-decoration-none mr-3" v-on:click="changeDatabasesTablesSelectionMode('tables')">
                                    <font-awesome-icon v-if="databasesTablesSelectionMode == 0" icon="check-square" style="color: var(--text-color)" />
                                    <font-awesome-icon v-if="databasesTablesSelectionMode == 1" icon="square" style="color: var(--text-color)" />
                                    <font-awesome-icon v-if="databasesTablesSelectionMode == 2" icon="minus-square" style="color: var(--text-color)" />
                                </b-link>
                            </b-col>
                            <b-col class="pr-0 text-right mb-2">
                                <font-awesome-icon icon="sync" class="cursor-pointer" v-on:click="getDatabaseTables(true)" />
                            </b-col>
                        </b-row>
                        <div class="tables-views-list">
                            <div v-for="table in databasesTables['csid_' + currentConnection.Id]" :key="table.Name">
                                <b-form-checkbox v-model="table.chosen">
                                    <font-awesome-icon
                                        v-bind:class="{ disabled: !table.chosen }"
                                        v-if="databaseTableColumnsOpened.findIndex((t) => t == table.Name) == -1"
                                        v-on:click.stop.prevent="showColumns(table.Name)"
                                        icon="plus"
                                        class="display-inline mr-2 cursor-pointer"
                                        style="color: var(--text-color)"
                                    />
                                    <font-awesome-icon
                                        v-bind:class="{ disabled: !table.chosen }"
                                        v-if="databaseTableColumnsOpened.findIndex((t) => t == table.Name) != -1"
                                        v-on:click.stop.prevent="hideColumns(table.Name)"
                                        icon="minus"
                                        class="display-inline mr-2 cursor-pointer"
                                        style="color: var(--text-color)"
                                    />
                                    <span :title="table.Count > 0 ? table.Count : ''"
                                        >{{ table.Name }} <font-awesome-icon v-if="table.Size == -1" class="text-danger" :key="'error' + table.Name" icon="exclamation-triangle" /><span v-if="table.Size > 0"
                                            ><font-awesome-icon v-for="id in table.Size" :key="'startable' + table.Name + id" icon="star" class="text-yellow" /></span
                                    ></span>
                                </b-form-checkbox>
                                <div v-if="databaseTableColumnsOpened.findIndex((t) => t == table.Name) != -1 && databaseTableColumns.find((c) => c.tableName == table.Name)">
                                    <div class="font-italic ml-4">{{ $t('Select a column to hide it') }}</div>
                                    <b-form-checkbox-group stacked class="column-striker ml-4" text-field="name" value-field="name" v-model="table.HiddenColumns" :options="databaseTableColumns.find((c) => c.tableName == table.Name).columns" />
                                </div>
                            </div>
                        </div>
                    </b-tab>
                    <b-tab v-on:click="getDatabaseViews(false)" :disabled="!currentConnection.Database || currentConnection.Id == 0">
                        <template #title>
                            {{ $t('Views') }} <font-awesome-icon icon="sync" spin v-if="getDatabaseViews_running" class="mr-1" />
                            <b-badge pill variant="info" v-if="databasesViews['csid_' + currentConnection.Id] != undefined">{{ selectedViews }} /{{ databasesViews['csid_' + currentConnection.Id].length }}</b-badge>
                        </template>
                        <b-row class="m-0 border-bottom mb-2" v-if="databasesViews['csid_' + currentConnection.Id] != undefined">
                            <b-col class="pl-0 mb-2">
                                <b-link class="text-decoration-none mr-3" v-on:click="changeDatabasesTablesSelectionMode('views')">
                                    <font-awesome-icon v-if="databasesViewsSelectionMode == 0" icon="check-square" style="color: var(--text-color)" />
                                    <font-awesome-icon v-if="databasesViewsSelectionMode == 1" icon="square" style="color: var(--text-color)" />
                                    <font-awesome-icon v-if="databasesViewsSelectionMode == 2" icon="minus-square" style="color: var(--text-color)" />
                                </b-link>
                            </b-col>
                            <b-col class="pr-0 text-right mb-2">
                                <font-awesome-icon icon="sync" class="cursor-pointer" v-on:click="getDatabaseViews(true)" />
                            </b-col>
                        </b-row>
                        <div class="tables-views-list">
                            <div v-for="view in databasesViews['csid_' + currentConnection.Id]" :key="view.Name">
                                <b-form-checkbox v-model="view.chosen">
                                    <font-awesome-icon
                                        v-bind:class="{ disabled: !view.chosen || loading }"
                                        v-if="databaseTableColumnsOpened.findIndex((t) => t == view.Name) == -1"
                                        v-on:click.stop.prevent="showColumns(view.Name)"
                                        icon="plus"
                                        class="display-inline mr-2 cursor-pointer"
                                        style="color: var(--text-color)"
                                    />
                                    <font-awesome-icon
                                        v-bind:class="{ disabled: !view.chosen || loading }"
                                        v-if="databaseTableColumnsOpened.findIndex((t) => t == view.Name) != -1"
                                        v-on:click.stop.prevent="hideColumns(view.Name)"
                                        icon="minus"
                                        class="display-inline mr-2 cursor-pointer"
                                        style="color: var(--text-color)"
                                    />
                                    <span :title="view.Count > 0 ? view.Count : ''"
                                        >{{ view.Name }} <font-awesome-icon v-if="view.Size == -1" :key="'error' + view.Name" class="text-danger" icon="exclamation-triangle" /><span v-if="view.Size > 0"
                                            ><font-awesome-icon v-for="id in view.Size" :key="'starview' + view.Name + id" icon="star" class="text-yellow" /></span
                                    ></span>
                                </b-form-checkbox>
                                <div v-if="databaseTableColumnsOpened.findIndex((t) => t == view.Name) != -1 && databaseTableColumns.find((c) => c.tableName == view.Name)">
                                    <div class="font-italic ml-4">{{ $t('Select a column to hide it') }}</div>
                                    <b-form-checkbox-group stacked class="column-striker ml-4" text-field="name" value-field="name" v-model="view.HiddenColumns" :options="databaseTableColumns.find((c) => c.tableName == view.Name).columns" />
                                </div>
                            </div>
                        </div>
                    </b-tab>
                </b-tabs>
            </b-card>
        </b-col>
    </b-row>
</template>
<script>
import ToastAlert from '@/utils/ToastAlert';
import FormErrors from '@/components/FormErrors.vue';
import DatabaseService from '@/services/database.service.js';

export default {
    name: 'DatabaseTabPanel',
    components: {
        FormErrors,
    },
    data() {
        return {
            tabIndex: 0,
            saving: false,
            connectionStrings: [],
            // providers: ['postgresql', 'sql server', 'mariadb', 'sqlite'],
            providers: ['postgresql', 'sql server'],
            currentConnection: {
                Id: null,
                Alias: null,
                Provider: null,
                ServerName: null,
                PortNumber: null,
                ExternalNetwork: false,
                CertificateAuthentication: false,
                ServerSSH: null,
                PortSSH: null,
                UserSSH: null,
                PasswordSSH: null,
                CertificateSSH: null,
                PassphraseSSH: null,
                UserName: null,
                Password: null,
                DbSchema: null,
                Database: null,
                CommandTimeout: 30,
                LimitRowsOnDataGrid: true,
                DataGridRowsLimit: 200000,
                LimitRowsOnForm: true,
                FormRowsLimit: 200000,
                LimitRowsOnPivotGrid: true,
                PivotGridRowsLimit: 200000,
                LimitRowsOnSerieChart: true,
                SerieChartRowsLimit: 200000,
                LimitRowsOnParetoChart: true,
                ParetoChartRowsLimit: 200000,
                LimitRowsOnPieChart: true,
                PieChartRowsLimit: 200000,
            },
            databases: [],
            schemas: [],
            databasesTables: {},
            databasesTablesSelectionMode: 2, // 0 = all ; 1 = none; 2 = count > 0
            getDatabaseTables_running: false,
            databasesViews: {},
            databasesViewsSelectionMode: 2, // 0 = all ; 1 = none; 2 = count > 0
            getDatabaseViews_running: false,
            formErrors: [],
            statusText: null,
            databaseTableColumnsOpened: [],
            databaseTableColumns: [],
            loading: false,
            needSave: false,
        };
    },
    watch: {
        loading(val) {
            if (val) this.$store.commit('increaseLoadingCount');
            else this.$store.commit('decreaseLoadingCount');
        },
        saving(val) {
            if (val) this.$store.commit('increaseLoadingCount');
            else this.$store.commit('decreaseLoadingCount');
        },
        'currentConnection.Provider': function (val) {
            const ports = {
                postgresql: 5432,
                'sql server': 1433,
                mariadb: 3306,
                sqlite: 1433,
            };
            const userNames = {
                postgresql: 'postgres',
                'sql server': 'sa',
                mariadb: 'user',
                sqlite: 'user',
            };
            if (this.currentConnection.Id == 0) {
                this.currentConnection.PortNumber = ports[val];
                this.currentConnection.UserName = userNames[val];
            }
        },
    },
    async mounted() {
        await this.refresh('DatabaseTabPanel');
        this.$EventBus.$on('refresh-admin-panel-tab', this.refresh);
    },
    computed: {
        selectedTables: function () {
            return this.databasesTables['csid_' + this.currentConnection.Id].filter((table) => table.chosen).length;
        },
        selectedViews: function () {
            return this.databasesViews['csid_' + this.currentConnection.Id].filter((view) => view.chosen).length;
        },
    },
    methods: {
        async showColumns(tableName) {
            this.loading = true;
            // event.stopPropagation();
            // event.preventDefault();
            if (this.databaseTableColumns.findIndex((c) => c.tableName == tableName) == -1) {
                const data = await DatabaseService.getAllDatabaseTableColums(this.currentConnection.Id, tableName);
                if (data.success == 'n') this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(data.ret, data.retParams), 'warning'));
                else {
                    this.databaseTableColumns.push({ tableName: tableName, columns: data.columns });
                }
            }
            this.databaseTableColumnsOpened.push(tableName);
            this.loading = false;
        },
        hideColumns(tableName) {
            // event.stopPropagation();
            // event.preventDefault();
            const idx = this.databaseTableColumnsOpened.findIndex((t) => t == tableName);
            if (idx != -1) this.databaseTableColumnsOpened.splice(idx, 1);
        },
        checkFormValidity() {
            this.formErrors = [];
            if(this.currentConnection.Provider == "idv" && 
                (!this.currentConnection.Alias ||
                !this.currentConnection.Provider ||
                !this.currentConnection.ServerName ||
                !this.currentConnection.Database)){
                    this.formErrors.push(this.$t('Check required fields'));
            }
            else if (this.currentConnection.Provider != "idv" &&
                (!this.currentConnection.Alias ||
                !this.currentConnection.Provider ||
                !this.currentConnection.ServerName ||
                !this.currentConnection.PortNumber ||
                !this.currentConnection.UserName ||
                !this.currentConnection.Password ||
                !this.currentConnection.Database ||
                !this.currentConnection.DbSchema)
            )
                this.formErrors.push(this.$t('Check required fields'));

            return this.formErrors.length == 0;
        },
        async refresh(tabRef) {
            if (tabRef == 'DatabaseTabPanel') {
                this.formErrors = [];
                this.currentConnection = {
                    Id: null,
                    Alias: null,
                    Provider: null,
                    ServerName: null,
                    PortNumber: null,
                    ExternalNetwork: false,
                    CertificateAuthentication: false,
                    ServerSSH: null,
                    PortSSH: null,
                    UserSSH: null,
                    PasswordSSH: null,
                    CertificateSSH: null,
                    PassphraseSSH: null,
                    UserName: null,
                    Password: null,
                    DbSchema: null,
                    Database: null,
                    CommandTimeout: 30,
                    LimitRowsOnDataGrid: true,
                    DataGridRowsLimit: 200000,
                    LimitRowsOnForm: true,
                    FormRowsLimit: 200000,
                    LimitRowsOnPivotGrid: true,
                    PivotGridRowsLimit: 200000,
                    LimitRowsOnSerieChart: true,
                    SerieChartRowsLimit: 200000,
                    LimitRowsOnParetoChart: true,
                    ParetoChartRowsLimit: 200000,
                    LimitRowsOnPieChart: true,
                    PieChartRowsLimit: 200000,
                };
                await this.getAdminConnectionStrings();
            }
        },
        csLabel(cs) {
            if (cs.Alias != '' && cs.Alias != null) return cs.Alias;
            else return this.$t('Please enter Alias');
        },
        async createNewConnectionString() {
            if (this.connectionStrings.findIndex((cs) => cs.Id == 0) == -1) {
                this.formErrors = [];
                // Same here, to mode to a single call at start of app
                const dataSsh = await DatabaseService.getPublicSshKey();
                this.connectionStrings.unshift({
                    Id: 0,
                    Alias: this.$t('New database'),
                    Provider: 'postgresql',
                    ServerName: '127.0.0.1',
                    PortNumber: '5432',
                    ExternalNetwork: false,
                    CertificateAuthentication: false,
                    ServerSSH: '127.0.0.1',
                    PortSSH: '22',
                    UserSSH: null,
                    PasswordSSH: null,
                    CertificateSSH: dataSsh.ret,
                    PassphraseSSH: null,
                    UserName: 'postgres',
                    Password: null,
                    DbSchema: null,
                    Database: null,
                    CommandTimeout: 30,
                    LimitRowsOnDataGrid: true,
                    DataGridRowsLimit: 200000,
                    LimitRowsOnForm: true,
                    FormRowsLimit: 200000,
                    LimitRowsOnPivotGrid: true,
                    PivotGridRowsLimit: 200000,
                    LimitRowsOnSerieChart: true,
                    SerieChartRowsLimit: 200000,
                    LimitRowsOnParetoChart: true,
                    ParetoChartRowsLimit: 200000,
                    LimitRowsOnPieChart: true,
                    PieChartRowsLimit: 200000,
                });
                this.databases = [];
                this.schemas = [];
                this.databasesTables = [];
                this.databasesTablesSelectionMode = 2;
                this.databasesViews = [];
                this.databasesViewsSelectionMode = 2;

                await this.selectConnectionString(0);
                await this.$refs.currentConnection_Alias.focus();
                this.$refs.currentConnection_Alias.select();
                this.needSave = true;
            }
        },
        async getAdminConnectionStrings() {
            this.loading = true;
            // const settings = {
            //     method: 'POST',
            //     headers: {
            //         'Content-Type': 'application/json',
            //     },
            //     body: JSON.stringify({
            //         Method: 'databases.GetConnectionStrings',
            //         Token: this.$store.state.auth.user.accessToken,
            //         Parameters: {},
            //     }),
            // };

            // const res = await fetch(this.$store.state.settings.API_URL, settings);
            // const data = await res.json();
            const data = await DatabaseService.getAdminConnectionStrings();
            if (data.success == 'n') this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(data.ret, data.retParams), 'warning'));
            else this.connectionStrings = data.ret;
            this.$emit('cs-count', Array.isArray(this.connectionStrings) ? this.connectionStrings.filter((cs) => cs.Id != 0).length : 0);
            this.loading = false;
        },
        async selectConnectionString(id) {
            this.statusText = this.$t('Fetching info...');
            this.formErrors = [];
            this.tabIndex = 0;
            this.currentConnection = this.connectionStrings.find((cs) => cs.Id == id);
            if (id != 0) {
                var result = await this.getDatabasesFromConnString();
                if (result) result = await this.getDatabaseSchemas();
                if (result) result = await this.getDatabaseTables(false);
                if (result) result = await this.getDatabaseViews(false);
            }
            this.statusText = null;
        },
        async getDatabasesFromConnString() {
            this.loading = true;
            const settings = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    Method: 'databases.GetDatabasesFromConnString',
                    Token: this.$store.state.auth.user.accessToken,
                    Parameters: {
                        Provider: this.currentConnection.Provider,
                        ServerName: this.currentConnection.ServerName,
                        PortNumber: this.currentConnection.PortNumber,
                        ExternalNetwork: this.currentConnection.ExternalNetwork,
                        CertificateAuthentication: this.currentConnection.CertificateAuthentication,
                        ServerSSH: this.currentConnection.ServerSSH,
                        PortSSH: this.currentConnection.PortSSH,
                        UserSSH: this.currentConnection.UserSSH,
                        PasswordSSH: this.currentConnection.PasswordSSH,
                        PassphraseSSH: this.currentConnection.PassphraseSSH,
                        UserName: this.currentConnection.UserName,
                        Password: this.currentConnection.Password,
                        DbSchema: this.currentConnection.DbSchema,
                    },
                }),
            };

            // Get the public ssh key. This one is static throughout the application
            // so it might be usefull as a single call at start of the web application
            // instead of every request.
            const dataSsh = await DatabaseService.getPublicSshKey();
            this.currentConnection.CertificateSSH = dataSsh.ret;

            this.statusText = this.$t('Fetching databases...');

            const res = await fetch(this.$store.state.settings.API_URL, settings);
            const data = await res.json();

            if (data.success == 'n') {
                this.databases = [];
                this.schemas = [];
                this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(data.ret, data.retParams), 'warning'));
                this.loading = false;
                this.statusText = null;
                return false;
            } else {
                this.databases = data.ret ? data.ret : [];
                if (this.databases.length == 0) this.currentConnection.Database = '';
                else if (this.databases.length > 0 && (this.currentConnection.Database == null || this.databases.findIndex((db) => db == this.currentConnection.Database) == -1)) this.currentConnection.Database = this.databases[0];
                this.loading = false;
                this.statusText = null;
                return true;
            }
        },
        async getDatabaseSchemas() {
            if ((this.currentConnection.Provider != 'postgresql' && this.currentConnection.Provider != 'sql server') || this.currentConnection.Database == null) return false;
            const settings = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    Method: 'databases.GetDatabaseSchemas',
                    Token: this.$store.state.auth.user.accessToken,
                    Parameters: {
                        Provider: this.currentConnection.Provider,
                        Alias: this.currentConnection.Alias,
                        ServerName: this.currentConnection.ServerName,
                        PortNumber: this.currentConnection.PortNumber,
                        ExternalNetwork: this.currentConnection.ExternalNetwork,
                        CertificateAuthentication: this.currentConnection.CertificateAuthentication,
                        ServerSSH: this.currentConnection.ServerSSH,
                        PortSSH: this.currentConnection.PortSSH,
                        UserSSH: this.currentConnection.UserSSH,
                        PasswordSSH: this.currentConnection.PasswordSSH,
                        PassphraseSSH: this.currentConnection.PassphraseSSH,
                        Database: this.currentConnection.Database,
                        UserName: this.currentConnection.UserName,
                        Password: this.currentConnection.Password,
                        DbSchema: this.currentConnection.DbSchema,
                        CommandTimeout: this.currentConnection.CommandTimeout,
                        LimitRowsOnDataGrid: this.currentConnection.LimitRowsOnDataGrid,
                        DataGridRowsLimit: this.currentConnection.DataGridRowsLimit,
                        LimitRowsOnForm: this.currentConnection.LimitRowsOnForm,
                        FormRowsLimit: this.currentConnection.FormRowsLimit,
                        LimitRowsOnPivotGrid: this.currentConnection.LimitRowsOnPivotGrid,
                        PivotGridRowsLimit: this.currentConnection.PivotGridRowsLimit,
                        LimitRowsOnSerieChart: this.currentConnection.LimitRowsOnSerieChart,
                        SerieChartRowsLimit: this.currentConnection.SerieChartRowsLimit,
                        LimitRowsOnParetoChart: this.currentConnection.LimitRowsOnParetoChart,
                        ParetoChartRowsLimit: this.currentConnection.ParetoChartRowsLimit,
                        LimitRowsOnPieChart: this.currentConnection.LimitRowsOnPieChart,
                        PieChartRowsLimit: this.currentConnection.PieChartRowsLimit,
                    },
                }),
            };

            this.loading = true;
            this.statusText = this.$t('Fetching database schemas...');

            const res = await fetch(this.$store.state.settings.API_URL, settings);
            const data = await res.json();

            if (data.success == 'n') {
                this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(data.ret, data.retParams), 'warning'));
                this.loading = false;
                this.statusText = null;
                return false;
            } else {
                this.schemas = data.ret;
                if (this.schemas.findIndex((s) => s == this.currentConnection.DbSchema) == -1) {
                    if (this.currentConnection.Provider == 'postgresql') {
                        const sc = this.schemas.find((s) => s == 'public');
                        if (sc) {
                            this.currentConnection.DbSchema = sc;
                        } else {
                            this.currentConnection.DbSchema = this.schemas[0];
                        }
                    } else if (this.currentConnection.Provider == 'sql server') {
                        const sc = this.schemas.find((s) => s == 'dbo');
                        if (sc) {
                            this.currentConnection.DbSchema = sc;
                        } else {
                            this.currentConnection.DbSchema = this.schemas[0];
                        }
                    }
                }
                this.loading = false;
                this.statusText = null;
                return true;
            }
        },
        async saveConnectionString() {
            if (!this.checkFormValidity()) return false;
            this.saving = true;
            let settings = null;
            if (this.currentConnection.Id == 0) {
                this.statusText = this.$t('Computing tables and views...');
                // New connection string
                settings = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        Method: 'databases.CreateConnectionString',
                        Token: this.$store.state.auth.user.accessToken,
                        Parameters: {
                            Provider: this.currentConnection.Provider,
                            Alias: this.currentConnection.Alias,
                            ServerName: this.currentConnection.ServerName,
                            PortNumber: this.currentConnection.PortNumber,
                            ExternalNetwork: this.currentConnection.ExternalNetwork,
                            CertificateAuthentication: this.currentConnection.CertificateAuthentication,
                            ServerSSH: this.currentConnection.ServerSSH,
                            PortSSH: this.currentConnection.PortSSH,
                            UserSSH: this.currentConnection.UserSSH,
                            PasswordSSH: this.currentConnection.PasswordSSH,
                            PassphraseSSH: this.currentConnection.PassphraseSSH,
                            Database: this.currentConnection.Database,
                            UserName: this.currentConnection.UserName,
                            Password: this.currentConnection.Password,
                            DbSchema: this.currentConnection.DbSchema,
                            CommandTimeout: this.currentConnection.CommandTimeout,
                            LimitRowsOnDataGrid: this.currentConnection.LimitRowsOnDataGrid,
                            DataGridRowsLimit: this.currentConnection.DataGridRowsLimit,
                            LimitRowsOnForm: this.currentConnection.LimitRowsOnForm,
                            FormRowsLimit: this.currentConnection.FormRowsLimit,
                            LimitRowsOnPivotGrid: this.currentConnection.LimitRowsOnPivotGrid,
                            PivotGridRowsLimit: this.currentConnection.PivotGridRowsLimit,
                            LimitRowsOnSerieChart: this.currentConnection.LimitRowsOnSerieChart,
                            SerieChartRowsLimit: this.currentConnection.SerieChartRowsLimit,
                            LimitRowsOnParetoChart: this.currentConnection.LimitRowsOnParetoChart,
                            ParetoChartRowsLimit: this.currentConnection.ParetoChartRowsLimit,
                            LimitRowsOnPieChart: this.currentConnection.LimitRowsOnPieChart,
                            PieChartRowsLimit: this.currentConnection.PieChartRowsLimit,
                        },
                    }),
                };
            } else {
                this.statusText = this.$t('Saving...');
                // Update connection string
                settings = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        Method: 'databases.UpdateConnectionString',
                        Token: this.$store.state.auth.user.accessToken,
                        Parameters: {
                            Id: this.currentConnection.Id,
                            Provider: this.currentConnection.Provider,
                            Alias: this.currentConnection.Alias,
                            ServerName: this.currentConnection.ServerName,
                            PortNumber: this.currentConnection.PortNumber,
                            ExternalNetwork: this.currentConnection.ExternalNetwork,
                            CertificateAuthentication: this.currentConnection.CertificateAuthentication,
                            ServerSSH: this.currentConnection.ServerSSH,
                            PortSSH: this.currentConnection.PortSSH,
                            UserSSH: this.currentConnection.UserSSH,
                            PasswordSSH: this.currentConnection.PasswordSSH,
                            PassphraseSSH: this.currentConnection.PassphraseSSH,
                            Database: this.currentConnection.Database,
                            UserName: this.currentConnection.UserName,
                            Password: this.currentConnection.Password,
                            DbSchema: this.currentConnection.DbSchema,
                            Tables: this.databasesTables['csid_' + this.currentConnection.Id],
                            Views: this.databasesViews['csid_' + this.currentConnection.Id],
                            CommandTimeout: this.currentConnection.CommandTimeout,
                            LimitRowsOnDataGrid: this.currentConnection.LimitRowsOnDataGrid,
                            DataGridRowsLimit: this.currentConnection.DataGridRowsLimit,
                            LimitRowsOnForm: this.currentConnection.LimitRowsOnForm,
                            FormRowsLimit: this.currentConnection.FormRowsLimit,
                            LimitRowsOnPivotGrid: this.currentConnection.LimitRowsOnPivotGrid,
                            PivotGridRowsLimit: this.currentConnection.PivotGridRowsLimit,
                            LimitRowsOnSerieChart: this.currentConnection.LimitRowsOnSerieChart,
                            SerieChartRowsLimit: this.currentConnection.SerieChartRowsLimit,
                            LimitRowsOnParetoChart: this.currentConnection.LimitRowsOnParetoChart,
                            ParetoChartRowsLimit: this.currentConnection.ParetoChartRowsLimit,
                            LimitRowsOnPieChart: this.currentConnection.LimitRowsOnPieChart,
                            PieChartRowsLimit: this.currentConnection.PieChartRowsLimit,
                        },
                    }),
                };
            }

            const res = await fetch(this.$store.state.settings.API_URL, settings);
            const data = await res.json();

            if (data.success == 'n') {
                this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(data.ret, data.retParams), 'warning'));
            } else {
                this.currentConnection.Id = data.ret;
                await this.getDatabaseTables(false);
                await this.getDatabaseViews(false);
            }
            this.$emit('cs-count', this.connectionStrings.filter((cs) => cs.Id != 0).length);
            this.saving = false;
            this.statusText = null;
            this.needSave = false;
        },
        async removeConnectionString() {
            const value = await this.$bvModal.msgBoxConfirm(this.$t('Are you sure ?'), {
                okTitle: this.$t('yes'),
                cancelTitle: this.$t('no'),
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true,
                buttonSize: 'sm',
            });
            if (value) {
                const settings = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        Method: 'databases.RemoveConnectionString',
                        Token: this.$store.state.auth.user.accessToken,
                        Parameters: {
                            Id: this.currentConnection.Id,
                        },
                    }),
                };
                const res = await fetch(this.$store.state.settings.API_URL, settings);
                const data = await res.json();

                if (data.success == 'n') this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(data.ret, data.retParams), 'warning'));
                else {
                    this.databases = [];
                    this.$set(this.databasesTables, 'csid_' + this.currentConnection.Id, []);
                    this.$set(this.databasesViews, 'csid_' + this.currentConnection.Id, []);
                    this.currentConnection = {
                        Id: null,
                        Alias: null,
                        Provider: null,
                        ServerName: null,
                        PortNumber: null,
                        UserName: null,
                        Password: null,
                        DbSchema: null,
                        Database: null,
                    };
                    await this.refresh('DatabaseTabPanel');
                }
            }
            this.needSave = false;
        },
        async getDatabaseTables(force) {
            // force = force ? force : !this.databasesTables['csid_' + this.currentConnection.Id];
            if ((!force && this.databasesTables['csid_' + this.currentConnection.Id]) || this.getDatabaseTables_running) return;
            if (force) this.needSave = true;
            this.loading = true;
            this.getDatabaseTables_running = true;
            const settings = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    Method: 'databases.GetDatabaseTables',
                    Token: this.$store.state.auth.user.accessToken,
                    Parameters: {
                        Id: this.currentConnection.Id,
                        forceRefresh: force,
                    },
                }),
            };

            const res = await fetch(this.$store.state.settings.API_URL, settings);
            const data = await res.json();
            if (data.success == 'n') {
                this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(data.ret, data.retParams), 'warning'));
                this.getDatabaseTables_running = false;
                this.databasesTablesSelectionMode = 2;
                this.loading = false;
                return false;
            } else this.$set(this.databasesTables, 'csid_' + this.currentConnection.Id, data.ret);
            this.getDatabaseTables_running = false;
            this.databasesTablesSelectionMode = 2;
            this.loading = false;
            return true;
        },
        async getDatabaseViews(force) {
            // force = force ? force : !this.databasesViews['csid_' + this.currentConnection.Id];
            if ((!force && this.databasesViews['csid_' + this.currentConnection.Id]) || this.getDatabaseViews_running) return;
            if (force) this.needSave = true;
            this.loading = true;
            this.getDatabaseViews_running = true;
            const settings = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    Method: 'databases.GetDatabaseViews',
                    Token: this.$store.state.auth.user.accessToken,
                    Parameters: {
                        Id: this.currentConnection.Id,
                        forceRefresh: force,
                    },
                }),
            };

            const res = await fetch(this.$store.state.settings.API_URL, settings);
            const data = await res.json();
            if (data.success == 'n') {
                this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(data.ret, data.retParams), 'warning'));
                this.getDatabaseViews_running = false;
                this.loading = false;
                return false;
            } else this.$set(this.databasesViews, 'csid_' + this.currentConnection.Id, data.ret);
            this.getDatabaseViews_running = false;
            this.loading = false;
            return true;
        },
        async changeDatabasesTablesSelectionMode(type) {
            this.loading = true;
            if (type == 'tables') {
                this.databasesTablesSelectionMode = this.databasesTablesSelectionMode == 2 ? 0 : this.databasesTablesSelectionMode + 1;
                this.databasesTablesViewsSelect(type, this.databasesTablesSelectionMode);
            } else {
                this.databasesViewsSelectionMode = this.databasesViewsSelectionMode == 2 ? 0 : this.databasesViewsSelectionMode + 1;
                this.databasesTablesViewsSelect(type, this.databasesViewsSelectionMode);
            }
            this.needSave = true;
            this.loading = false;
        },
        async databasesTablesViewsSelect(type, mode) {
            this.needSave = true;
            var items = type == 'tables' ? this.databasesTables : this.databasesViews;
            switch (mode) {
                case 0:
                    items['csid_' + this.currentConnection.Id].forEach((element) => {
                        element.chosen = true;
                    });
                    break;
                case 1:
                    items['csid_' + this.currentConnection.Id].forEach((element) => {
                        element.chosen = false;
                    });
                    break;
                case 2:
                    items['csid_' + this.currentConnection.Id].forEach((element) => {
                        element.chosen = element.Count > 0;
                    });
                    break;
            }
        },
        async previewFile(event) {
            // FileList object.
            var files = event.target.files;
            var file = files[0];
            var fileReader = new FileReader();

            fileReader.onload = function (progressEvent) {
                var stringData = fileReader.result;
                //this.currentConnection.CertificateSSH = stringData;
                document.getElementById('currentConnection_certificatessh').value = stringData;
            };
            // Read file asynchronously.
            fileReader.readAsText(file); // fileReader.result -> String.
        },
        afficher() {
            var input = document.getElementById('currentConnection_passwordssh');
            if (input.type === 'password') {
                input.type = 'text';
            } else {
                input.type = 'password';
            }
        },
        afficher2() {
            var input = document.getElementById('currentConnection_certificatessh');
            if (input.type === 'password') {
                input.type = 'text';
            } else {
                input.type = 'password';
            }
            input = document.getElementById('currentConnection_passphrasessh');
            if (input.type === 'password') {
                input.type = 'text';
            } else {
                input.type = 'password';
            }
        },
    },
};
</script>
<style>
.tables-views-list {
    max-height: 344px;
    overflow: auto;
    position: relative;
}
.column-striker input[type='checkbox']:checked + label > span {
    text-decoration: line-through;
}
.column-striker {
    filter: grayscale(1);
}
</style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',[_c('b-row',[_c('b-col',[_c('b-form-group',{class:{ disabled: _vm.datasource.type == 'datasource' || !this.$store.state.auth.user.permissions.PermissionWrite },attrs:{"label":_vm.$t('Database IP'),"label-for":"serverIP"}},[_c('b-form-input',{attrs:{"type":"text","state":_vm.serverState,"autocomplete":"off"},model:{value:(_vm.server),callback:function ($$v) {_vm.server=$$v},expression:"server"}})],1)],1),_c('b-col',[_c('b-form-group',{class:{ disabled: _vm.datasource.type == 'datasource' || !this.$store.state.auth.user.permissions.PermissionWrite },attrs:{"label":_vm.$t('Database Port'),"label-for":"serverPort"}},[_c('b-form-input',{attrs:{"type":"number","min":"1","max":"65535","autocomplete":"off"},on:{"change":(value) => {
                                if (value == null || value == '') {
                                    this.port = 5432;
                                } else if (value > 65535) {
                                    this.port = 65535;
                                } else if (value < 1) {
                                    this.port = 1;
                                }
                            }},model:{value:(_vm.port),callback:function ($$v) {_vm.port=$$v},expression:"port"}})],1)],1)],1),_c('b-row',[_c('b-col',[_c('b-form-group',{class:{ disabled: _vm.datasource.type == 'datasource' || !this.$store.state.auth.user.permissions.PermissionWrite },attrs:{"label":_vm.$t('User name'),"label-for":"username"}},[_c('b-form-input',{attrs:{"type":"text","state":_vm.userNameState,"autocomplete":"off"},model:{value:(_vm.userName),callback:function ($$v) {_vm.userName=$$v},expression:"userName"}})],1)],1),_c('b-col',[_c('b-form-group',{class:{ disabled: _vm.datasource.type == 'datasource' || !this.$store.state.auth.user.permissions.PermissionWrite },attrs:{"label":_vm.$t('Password'),"label-for":"password"}},[_c('b-form-input',{attrs:{"type":"password","autocomplete":"off"},model:{value:(_vm.Password),callback:function ($$v) {_vm.Password=$$v},expression:"Password"}})],1)],1)],1),_c('b-row',[_c('b-col',[_c('b-form-group',{class:{ disabled: _vm.datasource.type == 'datasource' || !this.$store.state.auth.user.permissions.PermissionWrite },attrs:{"label":_vm.$t('Database'),"label-for":"database"}},[_c('b-form-input',{attrs:{"type":"text","state":_vm.databaseState,"autocomplete":"off"},model:{value:(_vm.database),callback:function ($$v) {_vm.database=$$v},expression:"database"}})],1)],1),_c('b-col',[_c('b-form-group',{class:{ disabled: _vm.datasource.type == 'datasource' || !this.$store.state.auth.user.permissions.PermissionWrite },attrs:{"label":_vm.$t('Database scheme'),"label-for":"schema"}},[_c('b-form-input',{attrs:{"type":"text","autocomplete":"off"},on:{"change":(value) => {
                                if (value == null || value == '') {
                                    this.schema = 'public';
                                }
                            }},model:{value:(_vm.schema),callback:function ($$v) {_vm.schema=$$v},expression:"schema"}})],1)],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }
const MONITOR_VALUEUPDATE = "ValueUpdate";
const MONITOR_ACTIONUPDATE = "ActionUpdate";
const MONITOR_GET_ITEMS = "LoadItems";
const MONITOR_RUNTIMESTATUS = "RuntimeStatus";
const MONITOR_RUNTIME_STOP_WARNING = "RuntimeStopWarning";
const MONITOR_RUNTIME_LICENSE_ERROR = "LicenseError";
const LOGGING_UPDATE = "LogUpdate";
const TAB_TYPE_DATASOURCETEMPLATE = "datasourcetemplate";
const TAB_TYPE_DATASOURCE = "datasource";
const TAB_TYPE_ACTION = "action";
const TAB_TYPE_VARIABLES = "variables";
const TAB_TYPE_COMMUNICATION = "communication";
const TAB_TYPE_ACTIONGLOBALVIEW = "actionglobalview";
const TVI_Valeur = 0;
const TVI_ValeurN1 = 1;
const TVI_Minimum = 2;
const TVI_Maximum = 3;
const TVI_CompteurHoraire1 = 4;
const TVI_CompteurHoraire1Cumule = 5;
const TVI_Nom = 52;
const TVI_Groupe = 53;
const TVI_Equipement = 54;
const TVI_HD = 63;
const TVI_Tendance = 84;

export default {
    MONITOR_VALUEUPDATE: MONITOR_VALUEUPDATE,
    MONITOR_ACTIONUPDATE: MONITOR_ACTIONUPDATE,
    MONITOR_GET_ITEMS: MONITOR_GET_ITEMS,
    MONITOR_RECEIVE_CLIENTS_COUNT: "clientsCount",
    MONITOR_RUNTIMESTATUS: MONITOR_RUNTIMESTATUS,
    MONITOR_RUNTIME_STOP_WARNING: MONITOR_RUNTIME_STOP_WARNING,
    MONITOR_RUNTIME_LICENSE_ERROR: MONITOR_RUNTIME_LICENSE_ERROR,
    MONITOR_CLIENT_TYPE_WEB_MONITORING: 0,
    MONITOR_CLIENT_TYPE_WEB_ACTION_MONITORING: 1,
    LOGGING_UPDATE: LOGGING_UPDATE,
    TAB_TYPE_ACTION: TAB_TYPE_ACTION,
    TAB_TYPE_DATASOURCE: TAB_TYPE_DATASOURCE,
    TAB_TYPE_DATASOURCETEMPLATE: TAB_TYPE_DATASOURCETEMPLATE,
    TAB_TYPE_VARIABLES: TAB_TYPE_VARIABLES,
    TAB_TYPE_COMMUNICATION: TAB_TYPE_COMMUNICATION,
    TAB_TYPE_ACTIONGLOBALVIEW: TAB_TYPE_ACTIONGLOBALVIEW,
    TVI_Valeur: TVI_Valeur,
    TVI_ValeurN1: TVI_ValeurN1,
    TVI_Minimum: TVI_Minimum,
    TVI_Maximum: TVI_Maximum,
    TVI_CompteurHoraire1: TVI_CompteurHoraire1,
    TVI_CompteurHoraire1Cumule : TVI_CompteurHoraire1Cumule,
    TVI_Nom: TVI_Nom,
    TVI_Groupe: TVI_Groupe,
    TVI_Equipement: TVI_Equipement,
    TVI_HD: TVI_HD,
    TVI_Tendance: TVI_Tendance,
};
import store from "@/store/index.js";

class SlideshowService {
    async getSlideshows() {
        const settings = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                Method: 'slideshows.get',
                Token: store.state.auth.user.accessToken,
                Parameters: {
                },
            }),
        };

        const res = await fetch(store.state.settings.API_URL, settings);
        const data = await res.json();
        return data;
    }
    async createSlideshow(slideShow) {
        const settings = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                Method: 'slideshows.create',
                Token: store.state.auth.user.accessToken,
                Parameters: slideShow,
            }),
        };

        const res = await fetch(store.state.settings.API_URL, settings);
        const data = await res.json();
        return data;
    }
    async updateSlideshow(slideShow) {
        const settings = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                Method: 'slideshows.update',
                Token: store.state.auth.user.accessToken,
                Parameters: slideShow,
            }),
        };

        const res = await fetch(store.state.settings.API_URL, settings);
        const data = await res.json();
        return data;
    }
    async removeSlideshow(id,) {
        const settings = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                Method: 'slideshows.delete',
                Token: store.state.auth.user.accessToken,
                Parameters: {
                    Id: id,
                },
            }),
        };

        const res = await fetch(store.state.settings.API_URL, settings);
        const data = await res.json();
        return data;
    }
}

export default new SlideshowService();
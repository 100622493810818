<template>
    <b-dropdown class="locale-chooser-dropdown" toggle-class="text-decoration-none" no-caret>
        <template #button-content><img class="chosen-locale" :src="require('@/assets/img/flags/' + currentLocale + '.png')" /></template>
        <b-dropdown-item class="background-transparent" v-if="currentLocale != 'fr'" value="fr" v-on:click="changeLocale('fr')"><img src="@/assets/img/flags/fr.png" /></b-dropdown-item>
        <b-dropdown-item class="background-transparent" v-if="currentLocale != 'en'" value="en" v-on:click="changeLocale('en')"><img src="@/assets/img/flags/en.png" /></b-dropdown-item>
        <b-dropdown-item class="background-transparent" v-if="currentLocale != 'de'" value="de" v-on:click="changeLocale('de')"><img src="@/assets/img/flags/de.png" /></b-dropdown-item>
        <b-dropdown-item class="background-transparent" v-if="currentLocale != 'es'" value="es" v-on:click="changeLocale('es')"><img src="@/assets/img/flags/es.png" /></b-dropdown-item>
        <b-dropdown-item class="background-transparent" v-if="currentLocale != 'it'" value="it" v-on:click="changeLocale('it')"><img src="@/assets/img/flags/it.png" /></b-dropdown-item>
        <b-dropdown-item class="background-transparent" v-if="currentLocale != 'pt'" value="pt" v-on:click="changeLocale('pt')"><img src="@/assets/img/flags/pt.png" /></b-dropdown-item>
        <b-dropdown-item class="background-transparent" v-if="currentLocale != 'zh'" value="zh" v-on:click="changeLocale('zh')"><img src="@/assets/img/flags/zh.png" /></b-dropdown-item>
    </b-dropdown>
</template>
<script>
import frMessages from 'devextreme/localization/messages/fr.json';
import deMessages from 'devextreme/localization/messages/de.json';
import itMessages from 'devextreme/localization/messages/it.json';
import esMessages from 'devextreme/localization/messages/es.json';
import ptMessages from 'devextreme/localization/messages/pt.json';
import zhMessages from 'devextreme/localization/messages/zh.json';
import { locale, loadMessages } from 'devextreme/localization';
import moment from "moment";
export default {
    name: 'LocalChooser',
    data() {
        return {
            currentLocale: 'fr',
        };
    },
    async created() {
        // check for active currentLocale
        const currentLocale = localStorage.getItem('locale');
        if (currentLocale) {
            this.currentLocale = currentLocale;
        } else {
            this.currentLocale = 'fr';
            localStorage.setItem('locale', this.currentLocale);
        }
        moment.locale(this.currentLocale);
        this.$i18n.locale = this.currentLocale;
        switch (this.currentLocale) {
            case 'fr':
                loadMessages(frMessages);
                break;
            case 'de':
                loadMessages(deMessages);
                break;
            case 'it':
                loadMessages(itMessages);
                break;
            case 'es':
                loadMessages(esMessages);
                break;
            case 'pt':
                loadMessages(ptMessages);
                break;
            case 'zh':
                loadMessages(zhMessages);
                break;
            default:
                break;
        }
        locale(this.currentLocale);
    },
    methods: {
        changeLocale(locale) {
            if (this.currentLocale != locale) {
                this.currentLocale = locale;
                localStorage.setItem('locale', this.currentLocale);
                document.location.reload();
            }
        },
    },
};
</script>
<style>
.chosen-locale {
    filter: brightness(1.5);
    transform: scale(1.2);
}
.locale-chooser-dropdown img {
    width: 36px;
}
.locale-chooser-dropdown .btn {
    background: none !important;
    border: none !important;
    padding: 0;
    outline: none !important;
    box-shadow: none;
}
.locale-chooser-dropdown .btn:active {
    background: none !important;
    border: none !important;
    outline: none !important;
    box-shadow: none;
}
.locale-chooser-dropdown .btn:focus {
    background: none !important;
    border: none !important;
    outline: none !important;
    box-shadow: none;
}
.locale-chooser-dropdown .dropdown-menu {
    background: none !important;
    border: none !important;
    padding: 0;
    width: 36px;
    min-width: 36px;
}
.locale-chooser-dropdown .dropdown-toggle:focus {
    background: none;
    border: none;
    padding: 0;
    width: 36px;
}
.locale-chooser-dropdown .dropdown-item {
    background: none;
    padding: 10px 0 0 0;
    width: 36px;
    transition: all 0.2s;
}
.locale-chooser-dropdown .dropdown-item:hover {
    background: none;
    filter: brightness(1.8);
    transform: scale(1.2);
    width: 36px;
    transition: all 0.3s;
}
</style>
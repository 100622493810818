<template>
    <div>
        <b-row>
            <b-col>
                <b-row>
                    <b-col>
                        <b-form-group :label="$t('Interval')" label-for="interval">
                            <b-form-input type="number" v-model="interval"
                                :class="{ 'disabled': !$store.state.auth.user.permissions.PermissionWrite }" min="1"
                                max="3600" :state="intervalState"></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col>
                        <b-form-checkbox v-model="roundedInterval" value="true" unchecked-value="false" switch
                            style="margin-top: 40px;"
                            :class="{ 'disabled': !$store.state.auth.user.permissions.PermissionWrite }">
                            {{ $t('Rounded Interval') }}
                        </b-form-checkbox>
                    </b-col>
                </b-row>
            </b-col>
            <b-col />

        </b-row>
    </div>
</template>
<script>

/**
 * The cyclic trigger: launches with every n seconds
 */
export default {
    props: ['action', 'equipment', 'propProject'],
    name: 'Cyclic',
    data() {
        return {
            interval: 1,
            roundedInterval: false
        }
    },
    computed: {
        intervalState() {
            if (this.interval == null || this.interval == '' || this.interval < 1) {
                return false;
            }
            return null;
        }
    },
    watch: {
        action: {
            handler: function (newVal, oldVal) {
                this.interval = newVal.DeclencheurConfiguration.Interval ?? 10;
                this.roundedInterval = newVal.DeclencheurConfiguration.IsRounded ?? false;
            },
            deep: true
        }
    },
    async mounted() {
        this.interval = this.action.DeclencheurConfiguration.Interval ?? 10;
        this.roundedInterval = this.action.DeclencheurConfiguration.IsRounded ?? false;
    },
    methods: {
        async validate() {
            if (this.intervalState === false) {
                return null;
            }
            return { Interval: parseInt(this.interval), IsRounded: this.roundedInterval };
        }
    }
}
</script>
<template>
    <b-modal id="criteria" v-b-modal.modal-multi-2 size="xl" @cancel="onCriteriaCancel" @ok="onCriteriaOk" :title="$t('Criteria')" :cancel-title="$t('Cancel')" :ok-title="$t('Ok')">
        <b-card>
            <b-form class="prevent-select">
                <b-row>
                    <b-col>
                        <b-form-group :label="$t('Name') + ' *'" label-for="criteriaName">
                            <b-form-input id="criteriaName" v-model="CriteriaName" :class="{ disabled: !$store.state.auth.user.permissions.PermissionWrite }" />
                        </b-form-group>
                    </b-col>
                    <b-col>
                        <b-form-group :label="$t('Field') + ' *'" label-for="field">
                            <b-form-select id="field" v-model="Field" :options="availableFields" :class="{ disabled: !$store.state.auth.user.permissions.PermissionWrite }" />
                        </b-form-group>
                    </b-col>
                    <b-col>
                        <b-form-group :label="$t('Type') + ' *'" label-for="type">
                            <b-form-select id="type" v-model="Type" :options="availableTypes" v-on:change="fieldChanged" :class="{ disabled: !$store.state.auth.user.permissions.PermissionWrite }" />
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row v-if="Type == 1">
                    <b-col cols="6">
                        <b-form-group :label="$t('Field') + ' *'" label-for="fieldChoice">
                            <b-form-select id="fieldChoice" v-model="FieldChoice" :options="availableFieldChoices" :class="{ disabled: !$store.state.auth.user.permissions.PermissionWrite }" />
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row v-else-if="Type == 2">
                    <b-col cols="6">
                        <b-form-group :label="$t('Group') + ' *'" label-for="valueGroup">
                            <b-form-select id="valueGroup" v-model="ValueGroup" :options="availableGroups" v-on:change="groupChanged" :class="{ disabled: !$store.state.auth.user.permissions.PermissionWrite }" />
                        </b-form-group>
                    </b-col>
                    <b-col cols="6">
                        <b-form-group :label="$t('Item') + ' *'" label-for="valueItem">
                            <b-form-select id="valueItem" v-model="ValueItem" :options="availableItems" :class="{ disabled: !$store.state.auth.user.permissions.PermissionWrite }" />
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row v-else-if="Type == 3">
                    <b-col cols="6">
                        <b-form-group :label="$t('Constant') + ' *'" label-for="constant">
                            <b-form-input
                                id="constant"
                                min="-9999999999999999999999999999"
                                max="9999999999999999999999999999"
                                @change="
                                    (value) => {
                                        if (value == null || value == '') {
                                            this.Constant = 0;
                                        } else if (value > 9999999999999999999999999999) {
                                            this.Constant = 9999999999999999999999999999;
                                        } else if (value < -9999999999999999999999999999) {
                                            this.Constant = -9999999999999999999999999999;
                                        }
                                    }
                                "
                                v-model="Constant"
                                type="number"
                                :class="{ disabled: !$store.state.auth.user.permissions.PermissionWrite }"
                            />
                        </b-form-group>
                    </b-col>
                </b-row>
            </b-form>
        </b-card>
    </b-modal>
</template>

<script>
import ProdComDataSourceService from '@/services/prodcom.datasources.service.js';
import ProdComEquipmentService from '@/services/prodcom.equipments.service.js';
export default {
    name: 'SourceCriteria',
    props: {
        Criteria: Object,
        OnCriteriaValidate: Function,
        OnCriteriaCancel: Function,
        Action: Object,
        Project: String,
        Table: String,
        ExistingCriterias: Array,
    },
    data() {
        return {
            CriteriaName: '',
            Field: '',
            Type: 1,
            availableFields: [],
            availableTypes: [
                { text: this.$t('Field'), value: 1 },
                { text: this.$t('Value'), value: 2 },
                { text: this.$t('Constant'), value: 3 },
            ],

            FieldChoice: 0,
            ValueGroup: '',
            ValueItem: '',
            Constant: 0,
            availableFieldChoices: [
                { text: this.$t('min'), value: 0 },
                { text: this.$t('max'), value: 1 },
            ],
            availableGroups: [],
            availableItems: [],

            criteriaNameFeedback: '',
            fieldFeedback: '',
            typeFeedback: '',
            fieldChoiceFeedback: '',
            valueGroupFeedback: '',
            valueItemFeedback: '',
            constantFeedback: '',
            valid: false,
        };
    },
    watch: {
        Criteria: {
            handler: async function (val) {
                if (val) await this.onCriteriaShow();
            },
            immediate: true,
            deep: true,
        },
    },
    methods: {
        async onCriteriaShow() {
            this.CriteriaName = this.Criteria?.Name == null ? '' : this.Criteria?.Name;
            this.Field = this.Criteria?.Field == null ? '' : this.Criteria?.Field;
            this.Type = this.Criteria?.Type == null ? this.availableFieldChoices[0]?.value : this.Criteria?.Type;
            this.FieldChoice = this.Criteria?.FieldChoice == null ? 0 : this.Criteria?.FieldChoice;
            this.Constant = this.Criteria?.Constant == null ? 0 : this.Criteria?.Constant;
            if (!this.$store.state.auth.user.permissions.PermissionRead) return;
            // Get list of table fields
            var data = await ProdComDataSourceService.getDataSourceTableFields(this.Action.Equipment, this.Table, this.Project);
            if (data.success == 'n') this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(data.ret), 'warning'));
            else {
                this.availableFields = data.ret;
            }
            // Get group list
            data = await ProdComEquipmentService.getGroups(this.Action.Equipment, this.Project);
            if (data.success == 'n') this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(data.ret), 'warning'));
            else {
                this.availableGroups = data.ret.List;
            }
            this.ValueGroup = this.Criteria?.ValueGroup == null ? this.availableGroups[0] : this.Criteria?.ValueGroup;
            await this.groupChanged();
            this.ValueItem = this.Criteria?.ValueItem == null ? this.availableItems[0] : this.Criteria?.ValueItem;
        },
        async onCriteriaCancel() {
            if (this.OnCriteriaCancel != null) await this.OnCriteriaCancel();
        },
        onCriteriaOk(e) {
            if (this.OnCriteriaValidate != null) {
                e.preventDefault();
                this.OnCriteriaValidate({
                    Name: this.CriteriaName,
                    Value: {
                        Name: this.CriteriaName,
                        Field: this.Field,
                        Type: this.Type,
                        FieldChoice: this.FieldChoice,
                        ValueGroup: this.ValueGroup,
                        ValueItem: this.ValueItem,
                        Constant: this.Constant,
                    },
                }).then((result) => {
                    if (result) this.$bvModal.hide('criteria');
                });
            }
        },
        async fieldChanged() {},
        async groupChanged() {
            if (!this.$store.state.auth.user.permissions.PermissionRead) return;
            const result = await ProdComEquipmentService.getItems(this.Action.Equipment, this.ValueGroup, this.Project);
            if (result.success == 'n') this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(result.ret, result.retParams), 'warning'));
            else {
                this.availableItems = [];
                for (const [key, val] of Object.entries(result.ret.List)) {
                    this.availableItems.push(val.Name);
                }
                if (this.availableItems.findIndex((val) => val == this.ValueItem) == -1) this.ValueItem = this.availableItems[0];
            }
        },
    },
};
</script>
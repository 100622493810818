<template>
    <div>
        <!-- Tab list -->
        <div class="card-header">
            <ul class="nav nav-pills">
                <li v-for="tab in tabs" :key="tab.id">
                    <a class="nav-link tabClick" v-bind:class="{'active': tab.active, 'disabled': !tab.enabled}" v-on:click="selectTab(tab)">
                        <font-awesome-icon v-if="tab.icon" :icon="tab.icon" class="mr-1 fa-lg" :color="tab.iconColor"  />
                        {{tab.text}}
                    </a>
                </li>
            </ul>
        </div>
        <!-- Tab content -->
        <div>
            <!-- Event Logger -->
            <div v-if="currentTab.id == 0">
                <EventLogger/>
            </div>
            <!-- Classical Logger -->
            <div v-if="currentTab.id == 1">
                <Logger/>
            </div>
            <!-- ProdCom_Events records -->
            <div v-if="currentTab.id == 2">
                <Table table="ProdCom_Events" :columns="columnsProdComEvents" :refreshPeriod="1000" :helpText="$t('help.history.events')"/>
            </div>
            <!-- ProdCom_Monitoring graph -->
            <div v-if="currentTab.id == 3">
                <ProdComMonitoringGraph/>
            </div>
        </div>
    </div>
</template>

<script>
import Logger from '@/components/history/Logger.vue'
import EventLogger from '@/components/history/EventLogger.vue'
import Table from '@/components/history/Table.vue'
import ProdComMonitoringGraph from '@/components/history/ProdComMonitoringGraph.vue'
export default {
    name: "History",
    components: {
        Logger, EventLogger, Table, ProdComMonitoringGraph
    },
    data(){
        return {
            tabs: [
                {id: 0, text: this.$t("history.loggerGeneral.title"), enabled: true, active: true, icon: 'fas fa-file'},
                {id: 1, text: this.$t("history.loggerDetailed.title"), enabled: true, active: false, icon: 'fas fa-file-alt'},
                {id: 2, text: this.$t("history.events.title"), enabled: true, active: false, icon: 'far fa-calendars'},
                {id: 3, text: this.$t("history.monitoring.title"), enabled: true, active: false, icon: 'fas fa-chart-line'},
            ],
            columnsProdComEvents: [
                {key: 0, dataField: 'ProdCom_Events_ID', dataType: 'number', caption: 'ProdCom_Events_ID', visible: false},
                {key: 1, dataField: 'HD', dataType: 'datetime', caption: 'HD', sortOrder: 'desc', visible: true, format: { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' }},
                {key: 4, dataField: 'Type', dataType: 'string', caption: 'Type', visible: true},
                {key: 2, dataField: 'Description', dataType: 'string', caption: 'Description', visible: true},
                {key: 3, dataField: 'Duration', dataType: 'number', caption: this.$t('Duration'), visible: true, format: { style: 'decimal', useGrouping: true, roundingMode: "ceil", maximumFractionDigits: 3 }},
                {key: 5, dataField: 'Value', dataType: 'string', caption: 'Value', visible: false},
                {key: 6, dataField: 'Attribut1', dataType: 'number', caption: 'Attribut1', visible: false},
            ]
        }
    },
    computed: {
        currentTab(){
            return this.tabs.find(tab => tab.active);
        }
    },
    async mounted(){
    },
    methods: {
        /**
         * Selects the given tab for display in the component.
         * @param {object} tab The tab to be selected
         */
        async selectTab(tab){
            for(const t of this.tabs){
                t.active = false;
            }
            tab.active = true;
        }
    }
}
</script>

<style lang="scss" scoped>
.tabClick {
    cursor: pointer;
}
</style>
<template>
    <div>
        <b-row>
            <b-col cols="6">
                <b-form-group :label="$t('Select action execute')" label-for="startStop">
                    <b-form-select v-model="CallType" :options="CallTypes" :class="{'disabled': !$store.state.auth.user.permissions.PermissionWrite}"></b-form-select>
                </b-form-group>
            </b-col>
        </b-row>
    </div>
</template>
<script>

/**
 * The start and stop trigger: launches with the action at start or at stop
 */
    export default {
        props: [ 'action', 'equipment', 'propProject' ],
        name: 'StartStop',
        data(){
            return {
                CallType: this.action.DeclencheurConfiguration.CallType ? this.action.DeclencheurConfiguration.CallType : 1,
                CallTypes: [
                    {value: 1, text: this.$t('Start')}, 
                    {value: 2, text: this.$t('Stop')}
                ]
            }
        },
        methods:{
            async validate(){
                return {CallType: this.CallType};
            }
        }
  }
</script>
<template>
    <b-container fluid>
        <div class="login-form mt-2">
            <img class="login-logo" src="@/assets/img/logo_big.png" />
            <b-form @submit="doLogin">
                <b-row>
                    <b-col>
                        <b-form-group :label="$t('Last name') + ' *'" label-for="input-last-name">
                            <b-form-input :maxlength="$store.state.settings.INPUT_NAME_MAX_LENGTH" id="input-last-name" v-model="form.lastName" :placeholder="$t('Last name')" required></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col>
                        <b-form-group :label="$t('First name')" label-for="input-first-name">
                            <b-form-input :maxlength="$store.state.settings.INPUT_NAME_MAX_LENGTH" id="input-first-name" v-model="form.firstName" :placeholder="$t('First name')"></b-form-input>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <b-form-group :label="$t('Email address') + ' *'" label-for="input-mail">
                            <b-form-input :maxlength="$store.state.settings.INPUT_EMAIL_MAX_LENGTH" id="input-mail" v-model="form.email" type="email" :placeholder="$t('Email address')" required></b-form-input>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <b-form-group :label="$t('Password') + ' *'" label-for="input-password">
                            <b-form-input :maxlength="$store.state.settings.INPUT_MAX_LENGTH" id="input-password" v-model="form.password" type="password" :placeholder="$t('Password')" required></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col>
                        <b-form-group :label="$t('Confirm password') + ' *'" label-for="input-confirm-password">
                            <b-form-input :maxlength="$store.state.settings.INPUT_MAX_LENGTH" id="input-confirm-password" v-model="form.confirmPassword" type="password" :placeholder="$t('Confirm password')" required></b-form-input>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-button type="submit" variant="primary" block><font-awesome-icon icon="sign-in-alt" /> {{ $t('Create user') }}</b-button>
                <FormErrors :propFormErrors="formErrors" class="mt-3" />
            </b-form>
        </div>
    </b-container>
</template>
<script>
import ToastAlert from '@/utils/ToastAlert';
import FormErrors from '@/components/FormErrors.vue';

export default {
    name: 'FirstLogin',
    components: {
        FormErrors,
    },
    data() {
        return {
            form: {
                email: 'admin@productys.com',
                firstName: '',
                lastName: 'Admin',
                password: '',
                confirmPassword: '',
            },
            formErrors: [],
        };
    },
    methods: {
        checkFormValidity() {
            this.formErrors = [];
            if (this.form.password != this.form.confirmPassword) this.formErrors.push(this.$t('Passwords must match'));
            return this.formErrors.length == 0;
        },
        async doLogin(event) {
            event.preventDefault();
            if (!this.checkFormValidity()) return false;
            const settings = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    Method: 'users.create',
                    Parameters: {
                        Email: this.form.email,
                        FirstName: this.form.firstName,
                        LastName: this.form.lastName,
                        Password: this.form.password,
                    },
                }),
            };

            const res = await fetch(this.$store.state.settings.API_URL, settings);
            const data = await res.json();

            if (data.success == 'n') this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(data.ret, data.retParams), 'warning'));
            else {
                await this.$store.dispatch('auth/login', this.form).then(
                    () => {
                        this.$router.push('/');
                        // this.$bvModal.show('adminPanel');
                    },
                    (error) => {
                        this.loading = false;
                        this.$EventBus.$emit('show-toast', new ToastAlert(error != null ? error : this.$t('Bad email or password'), 'warning'));
                    },
                );
            }
        },
    },
};
</script>
<style>
.login-form {
    width: 50%;
    margin: auto;
    max-width: 800px;
}
.login-form form {
    margin: auto;
}
.login-logo {
    width: 100%;
}
</style>
<template>
    <b-row class="text-center cursor-pointer full-width m-0">
        <b-col @click="zoomOut" class="mt-1 p-1">
            <font-awesome-icon icon="search-minus" class="fa-lg" v-bind:title="$t('Zoom out')" />
        </b-col>
        <b-col class="p-1">
            <b-dropdown size="sm" :text="zoom + '%'">
                <b-dropdown-item @click="changeZoom(z)" v-for="z in [25, 50, 75, 100, 125, 150, 175, 200]" :key="'zoom_' + z"
                    ><span :class="{ 'font-bold': z == 1 }">{{ z }}%</span></b-dropdown-item
                >
            </b-dropdown>
        </b-col>
        <b-col @click="zoomIn" class="mt-1 p-1">
            <font-awesome-icon icon="search-plus" class="fa-lg" v-bind:title="$t('Zoom in')" />
        </b-col>
    </b-row>
</template>

<script>
export default {
    name: 'ZommControl',
    data() {
        return {
            zoom: 100,
            zoomDelta: this.propZoomDelta ? this.propZoomDelta : 5,
            checkForMobileDevice: this.propcheckForMobileDevice ? this.propcheckForMobileDevice : false,
        };
    },
    props: ['propZoomDelta', 'propcheckForMobileDevice'],
    computed: {
        currentDashboardId() {
            return this.$store.state.currentDashboardId;
        },
    },
    created() {
        // check for active currentZoom
        let currentZoom = localStorage.getItem('zoom');
        if (currentZoom != null) currentZoom = parseInt(currentZoom);
        if (currentZoom && currentZoom != 100 && currentZoom >= 10) {
            this.changeZoom(currentZoom);
        } else {
            this.zoom = 100;
            localStorage.setItem('zoom', 100);
        }
    },
    methods: {
        changeZoom(z) {
            this.zoom = z;

            document.body.style.transformOrigin = 'left top';
            document.body.style.zoom = this.zoom + '%';

            // document.body.style.transformOrigin = 'left top';
            // document.body.style.transform = 'scale(' + this.zoom + ')';

            const dId = this.currentDashboardId;
            this.$store.commit('setCurrentDashboardId', null);
            this.$store.commit('setCurrentDashboardId', dId);
            localStorage.setItem('zoom', this.zoom);
        },
        initZoom() {
            this.zoom = 100;

            document.body.style.transformOrigin = 'left top';
            document.body.style.zoom = '100%';

            // document.body.style.transformOrigin = 'left top';
            // document.body.style.transform = 'scale(' + this.zoom + ')';

            const dId = this.currentDashboardId;
            this.$store.commit('setCurrentDashboardId', null);
            this.$store.commit('setCurrentDashboardId', dId);
            localStorage.setItem('zoom', this.zoom);
        },
        zoomIn() {
            if (this.zoom >= 250) return;
            this.zoom = this.zoom + this.zoomDelta;

            document.body.style.transformOrigin = 'left top';
            document.body.style.zoom = this.zoom + '%';

            // document.body.style.transformOrigin = 'left top';
            // document.body.style.transform = 'scale(' + this.zoom + ')';

            const dId = this.currentDashboardId;
            this.$store.commit('setCurrentDashboardId', null);
            this.$store.commit('setCurrentDashboardId', dId);
            localStorage.setItem('zoom', this.zoom);
        },
        zoomOut() {
            if (this.zoom <= 10) return;
            this.zoom = this.zoom - this.zoomDelta;

            document.body.style.transformOrigin = 'left top';
            document.body.style.zoom = this.zoom + '%';

            // document.body.style.transformOrigin = 'left top';
            // document.body.style.transform = 'scale(' + this.zoom + ')';

            const dId = this.currentDashboardId;
            this.$store.commit('setCurrentDashboardId', null);
            this.$store.commit('setCurrentDashboardId', dId);
            localStorage.setItem('zoom', this.zoom);
        },
    },
};
</script>

<template>
    <b-card no-body>
        <b-form style="margin: 1.25rem">
            <b-row>
                <b-col>
                    <b-form-group :label="$t('simulation.values') + ' (' + $t('simulation.valuesDescription') + ')'">
                        <!-- @remove="removeTag(tag)" -->
                        <b-form-input
                            input-id="tags-basic"
                            @change="
                                (value) => {
                                    if (value == null || value == '') {
                                        this.objectList = '1;0;1;0;1';
                                    }
                                }
                            "
                            v-model="objectList"
                        >
                        </b-form-input>
                    </b-form-group>
                </b-col>
            </b-row>
        </b-form>
    </b-card>
</template>

<script>
export default {
    name: 'Sequence',
    props: {
        simulationDetails: Object,
        type: String,
    },
    data() {
        return {
            objectList: '',
        };
    },
    watch: {
        async objectList(newValue, oldValue) {
            if (newValue.endsWith(';')) newValue = newValue.slice(0, -1);
            let result = newValue.split(';');
            // Manage numeric types
            if (
                this.type == 'System.UInt16' ||
                this.type == 'System.Int16' ||
                this.type == 'System.UInt32' ||
                this.type == 'System.Int32' ||
                this.type == 'System.Uint64' ||
                this.type == 'System.Int64' ||
                this.type == 'System.Single' ||
                this.type == 'System.Double' ||
                this.type == 'System.Decimal'
            ) {
                for (const [index, value] of result.entries()) {
                    result[index] = Number(value);
                }
            }
            // Manage booleans
            if (this.type == 'System.Boolean') {
                for (const [index, value] of result.entries()) {
                    if (value == '0' || value.toLowerCase() == 'false' || value.toLowerCase() == 'f') {
                        result[index] = false;
                    } else if (value == '1' || value.toLowerCase() == 'true' || value.toLowerCase() == 't') {
                        result[index] = true;
                    } else {
                        result[index] = false;
                    }
                }
            }
            // Manage DateTime
            if (this.type == 'System.DateTime') {
                // TODO
            }
            this.simulationDetails.Objects = result;
        },
    },
    mounted() {
        if (this.simulationDetails.Objects == null) this.$set(this.simulationDetails, 'Objects', [1, 0, 1, 0, 1]);
        this.objectList = '';
        for (const obj of this.simulationDetails.Objects) {
            this.objectList += `${obj};`;
        }
        if (this.objectList.endsWith(';')) this.objectList = this.objectList.slice(0, -1);
    },
};
</script>
<template>
    <b-row>
        <b-col cols="4">
            <b-list-group>
                <div class="text-center pb-3">
                    <font-awesome-icon icon="plus" class="cursor-pointer" v-on:click="createNewUser" v-bind:alt="$t('Add user')" v-bind:title="$t('Add user')" />
                    <font-awesome-icon icon="check" v-bind:class="{ disabled: currentUser.IsProtected && minLevelRole > $RoleLevels.SuperAdmin }" class="cursor-pointer ml-3" v-if="!saving && currentUser.Id != null" v-on:click="saveUser" v-bind:alt="$t('Save')" v-bind:title="$t('Save')" />
                    <font-awesome-icon icon="sync" class="cursor-pointer ml-3" spin v-if="saving" />
                    <font-awesome-icon icon="minus" v-bind:class="{ disabled: currentUser.IsProtected }" class="cursor-pointer ml-3" v-if="currentUser && currentUser.Id != null && currentUser.Id != 0" v-on:click="removeUser" v-bind:alt="$t('Remove')" v-bind:title="$t('Remove')" />
                </div>
                <b-list-group-item v-for="usr in users" :key="'usr' + usr.Id" :active="currentUser && usr.Id == currentUser.Id" v-on:click="selectUser(usr.Id)" button v-bind:class="{ 'font-italic': usr.Id == 0 }">{{ userLabel(usr) }}</b-list-group-item>
            </b-list-group>
        </b-col>
        <b-col cols="8">
            <b-card no-body v-if="currentUser.Id != null" class="p-3">
                <b-form>
                    <b-row>
                        <b-col>
                            <b-form-group :label="$t('Last name') + ' *'" label-for="currentUser_LastName">
                                <b-form-input :maxlength="$store.state.settings.INPUT_NAME_MAX_LENGTH" :readonly="currentUser.IsProtected && minLevelRole > $RoleLevels.SuperAdmin" class="sm" id="currentUser_LastName" v-on:keyup="computeEmail" ref="currentUser_LastName" v-model="currentUser.LastName" required></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col>
                            <b-form-group :label="$t('First name')" label-for="currentUser_FirstName">
                                <b-form-input :maxlength="$store.state.settings.INPUT_NAME_MAX_LENGTH" :readonly="currentUser.IsProtected && minLevelRole > $RoleLevels.SuperAdmin" class="sm" id="currentUser_FirstName" v-on:keyup="computeEmail" v-model="currentUser.FirstName"></b-form-input>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <b-form-group :label="$t('Email') + ' *'" label-for="currentUser_Email">
                                <b-form-input :maxlength="$store.state.settings.INPUT_EMAIL_MAX_LENGTH" :readonly="currentUser.IsProtected && minLevelRole > $RoleLevels.SuperAdmin" v-on:keyup="emailTouched = true" class="sm" id="currentUser_Email" ref="currentUser_Email" v-model="currentUser.Email" required></b-form-input>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <b-form-group :label="$t('Groups')" label-for="currentUser_Groups">
                                <DxTagBox :disabled="currentUser.IsProtected && minLevelRole > $RoleLevels.SuperAdmin" id="currentUser_Groups" :max-displayed-tags="5" :items="allGroups" v-model="groupsOfUser[currentUser.Id]" display-expr="Name" value-expr="Id" required></DxTagBox>
                            </b-form-group>
                        </b-col>
                        <b-col>
                            <b-form-group :label="$t('Roles')" label-for="currentUser_Roles">
                                <DxTagBox :disabled="currentUser.IsProtected && minLevelRole > $RoleLevels.SuperAdmin" id="currentUser_Roles" :max-displayed-tags="5" :items="allRoles" v-model="rolesOfUser[currentUser.Id]" display-expr="Name" value-expr="Id" required></DxTagBox>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <b-form-group :label="$t('Password') + (this.currentUser.Id == 0 ? ' *' : '')" label-for="currentUser_Password">
                                <b-form-input :maxlength="$store.state.settings.INPUT_MAX_LENGTH" :readonly="currentUser.IsProtected && minLevelRole > $RoleLevels.SuperAdmin" type="password" class="sm" id="currentUser_Password" v-model="currentUser.Password"></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col>
                            <b-form-group :label="$t('Confirm password') + (this.currentUser.Id == 0 ? ' *' : '')" label-for="currentUser_ConfirmPassword">
                                <b-form-input :maxlength="$store.state.settings.INPUT_MAX_LENGTH" :readonly="currentUser.IsProtected && minLevelRole > $RoleLevels.SuperAdmin" type="password" class="sm" id="currentUser_ConfirmPassword" v-model="currentUser.ConfirmPassword"></b-form-input>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </b-form>
                <FormErrors :propFormErrors="formErrors" />
            </b-card>
        </b-col>
    </b-row>
</template>
<script>
import UserService from '@/services/user.service.js';
import RoleService from '@/services/role.service.js';
import GroupService from '@/services/group.service.js';
import DxTagBox from 'devextreme-vue/tag-box';
import ToastAlert from '@/utils/ToastAlert';
import FormErrors from '@/components/FormErrors.vue';

export default {
    name: 'UserTabPanel',
    components: {
        DxTagBox,
        ToastAlert,
        FormErrors,
    },
    data() {
        return {
            saving: false,
            users: [],
            allRoles: [],
            rolesOfUser: {},
            groupsOfUser: {},
            allGroups: [],
            currentUser: {
                Id: null,
                Email: null,
                Password: null,
                ConfirmPassword: null,
                FirstName: null,
                LastName: null,
                IsProtected: false,
            },
            formErrors: [],
            emailTouched: false,
        };
    },
    computed: {
        minLevelRole() {
            return this.$store.state.auth.user == null ? 999 : this.$store.state.auth.user.minLevelRole;
        },
    },
    async mounted() {
        await this.refresh('UserTabPanel');
        this.$EventBus.$on('refresh-admin-panel-tab', this.refresh);
    },
    methods: {
        computeEmail() {
            if (this.currentUser.Id != 0 || (this.currentUser.Email && this.currentUser.Email != '' && this.emailTouched)) return;
            this.emailTouched = false;
            var email = this.currentUser.FirstName ? this.currentUser.FirstName : '';
            if (email != '' && this.currentUser.LastName && this.currentUser.LastName != '') email += '.' + this.currentUser.LastName;
            else if (this.currentUser.LastName && this.currentUser.LastName != '') email = this.currentUser.LastName;
            this.currentUser.Email = email != '' ? email.toLowerCase() + '@' : '';
        },
        checkFormValidity() {
            this.formErrors = [];
            // check email
            if (this.currentUser.Email && !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(this.currentUser.Email)) this.formErrors.push(this.$t('Check email address'));
            const Password = this.currentUser.Password ? this.currentUser.Password : '';
            const ConfirmPassword = this.currentUser.ConfirmPassword ? this.currentUser.ConfirmPassword : '';
            if (Password != ConfirmPassword) this.formErrors.push(this.$t('Passwords must match'));
            if (this.currentUser && ((this.currentUser.Id == 0 && Password == '') || !this.currentUser.Email || !this.currentUser.LastName)) this.formErrors.push(this.$t('Check required fields'));

            return this.formErrors.length == 0;
        },
        async refresh(tabRef) {
            if (tabRef == 'UserTabPanel') {
                this.formErrors = [];
                this.currentUser = {
                    Id: null,
                    Email: null,
                    Password: null,
                    ConfirmPassword: null,
                    FirstName: null,
                    LastName: null,
                };
                await this.getUsers();
                await this.getRoles();
                await this.getGroups();
            }
        },
        userLabel(user) {
            if (user.Id != 0 && user.Email != '' && user.Email != null) return user.Email;
            else if (user.Id == 0) return this.$t('New user');
            else return this.$t('Please enter email');
        },
        async createNewUser() {
            if (this.users.findIndex((usr) => usr.Id == 0) == -1) {
                this.formErrors = [];
                this.users.unshift({
                    Id: 0,
                    Email: null,
                    Password: null,
                    ConfirmPassword: null,
                    FirstName: null,
                    LastName: null,
                    IsProtected: false,
                    IsHidden: false,
                });
                await this.selectUser(0);
                await this.$refs.currentUser_LastName.focus();
                this.$refs.currentUser_LastName.select();
            }
        },
        async getUsers() {
            const data = await UserService.getAllUsers();
            if (data.success == 'n') this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(data.ret, data.retParams), 'warning'));
            else this.users = data.ret;
            this.$emit('usr-count', Array.isArray(this.users) ? this.users.filter((usr) => usr.Id != 0).length : 0);
        },
        async getRoles() {
            const data = await RoleService.getAllRoles();
            if (data.success == 'n') this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(data.ret, data.retParams), 'warning'));
            else this.allRoles = data.ret;
        },
        async getGroups() {
            const data = await GroupService.getAllGroups();
            if (data.success == 'n') this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(data.ret, data.retParams), 'warning'));
            else this.allGroups = data.ret;
        },
        async selectUser(id) {
            this.formErrors = [];
            this.currentUser = this.users.find((usr) => usr.Id == id);
            if (id != 0) {
                await this.getRolesOfUser();
                await this.getGroupsOfUser();
            }
        },
        async getRolesOfUser() {
            const data = await UserService.getRolesOfUser(this.currentUser.Id);
            if (data.success == 'n') this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(data.ret, data.retParams), 'warning'));
            else
                this.$set(
                    this.rolesOfUser,
                    this.currentUser.Id,
                    data.ret?.map((role) => role.Id),
                );
        },
        async getGroupsOfUser() {
            const data = await UserService.getGroupsOfUser(this.currentUser.Id);
            if (data.success == 'n') this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(data.ret, data.retParams), 'warning'));
            else
                this.$set(
                    this.groupsOfUser,
                    this.currentUser.Id,
                    data.ret?.map((group) => group.Id),
                );
        },
        async saveUser() {
            if (!this.checkFormValidity()) return false;
            this.saving = true;
            let settings = null;
            if (this.currentUser.Id == 0) {
                // New user
                settings = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        Method: 'users.create',
                        Token: this.$store.state.auth.user.accessToken,
                        Parameters: {
                            Email: this.currentUser.Email,
                            Password: this.currentUser.Password,
                            FirstName: this.currentUser.FirstName,
                            LastName: this.currentUser.LastName,
                            Roles: this.rolesOfUser[this.currentUser.Id],
                            Groups: this.groupsOfUser[this.currentUser.Id],
                        },
                    }),
                };
            } else {
                // Update user
                settings = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        Method: 'users.update',
                        Token: this.$store.state.auth.user.accessToken,
                        Parameters: {
                            Id: this.currentUser.Id,
                            Email: this.currentUser.Email,
                            Password: this.currentUser.Password,
                            FirstName: this.currentUser.FirstName,
                            LastName: this.currentUser.LastName,
                            Roles: this.rolesOfUser[this.currentUser.Id],
                            Groups: this.groupsOfUser[this.currentUser.Id],
                        },
                    }),
                };
            }

            const res = await fetch(this.$store.state.settings.API_URL, settings);
            const data = await res.json();

            if (data.success == 'n') {
                this.saving = false;
                this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(data.ret, data.retParams), 'warning'));
            } else if (this.currentUser.Id == 0) {
                this.rolesOfUser[this.currentUser.Id] = [];
                this.groupsOfUser[this.currentUser.Id] = [];
                await this.refresh('UserTabPanel');
            } else {
                this.currentUser.Password = null;
                this.currentUser.ConfirmPassword = null;
                await this.getRolesOfUser();
                await this.getGroupsOfUser();
            }

            this.saving = false;
            this.$emit('user-count', this.users.filter((user) => user.Id != 0).length);
        },
        async removeUser(id) {
            const value = await this.$bvModal.msgBoxConfirm(this.$t('Are you sure ?'), {
                okTitle: this.$t('yes'),
                cancelTitle: this.$t('no'),
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true,
                buttonSize: 'sm',
            });
            if (value) {
                const settings = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        Method: 'users.delete',
                        Token: this.$store.state.auth.user.accessToken,
                        Parameters: {
                            Id: this.currentUser.Id,
                        },
                    }),
                };
                const res = await fetch(this.$store.state.settings.API_URL, settings);
                const data = await res.json();

                if (data.success == 'n') this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(data.ret, data.retParams), 'warning'));
                else {
                    this.currentRole = {
                        Id: null,
                        Email: null,
                        Password: null,
                        ConfirmPassword: null,
                        FirstName: null,
                        LastName: null,
                    };
                    await this.refresh('UserTabPanel');
                }
            }
        },
    },
};
</script>
<style scoped>
.dx-tagbox {
    border-radius: 0;
    border-top: none;
    border-left: none;
    border-right: none;
}
</style>
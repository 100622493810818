<template>
    <div class="slideshows" :class="{ disabled: slideshows.length == 0 }">
        <b-row>
            <b-col class="px-0">
                <b-dropdown split>
                    <template #button-content>
                        <b-row style="min-width: 90px">
                            <b-col class="pr-1">
                                <font-awesome-layers class="cursor-pointer" v-bind:class="{ 'text-primary': playing }" v-on:click="controlsEnabled = !controlsEnabled">
                                    <font-awesome-icon icon="desktop" class="fa-lg" />
                                    <font-awesome-icon icon="play" transform="right-5 up-2 shrink-8" />
                                </font-awesome-layers>
                            </b-col>
                            <b-col class="pl-3 px-2">
                                <font-awesome-icon icon="sync" class="fa-lg cursor-pointer" transform="up-1 shrink-2" v-on:click="getSlideshows" />
                            </b-col>
                        </b-row>
                    </template>
                    <b-dropdown-item
                        v-bind:class="{ 'slideshow-active': currentSlideshow == s }"
                        v-on:click="
                            () => {
                                currentTimeout = null;
                                currentSlideIndex = 0;
                                playing = false;
                                currentSlideshow = s;
                            }
                        "
                        v-for="s in slideshows"
                        :key="'slideshow_' + s.Id"
                        >{{ s.Name }}</b-dropdown-item
                    >
                </b-dropdown>
            </b-col>
            <b-col v-if="controlsEnabled" class="px-0 slideshows-controls ml-3">
                <transition name="fade">
                    <div>
                        <font-awesome-icon v-bind:class="{ disabled: !playing || (currentSlideshow && currentSlideshow.Slides.length == 1) }" icon="caret-left" class="mr-3 cursor-pointer fa-lg" v-on:click="prevSlide" />
                        <font-awesome-icon icon="pause" v-if="playing" v-on:click="playing = !playing" class="mr-3 cursor-pointer" />
                        <font-awesome-icon icon="play" v-if="!playing" v-on:click="playing = !playing" class="mr-3 cursor-pointer" />
                        <font-awesome-icon v-bind:class="{ disabled: !playing || (currentSlideshow && currentSlideshow.Slides.length == 1) }" icon="caret-right" class="cursor-pointer fa-lg" v-on:click="nextSlide" />
                    </div>
                </transition>
            </b-col>
        </b-row>
    </div>
</template>
<script>
import DashboardService from '@/services/dashboard.service.js';
import SlideshowService from '@/services/slideshow.service.js';
export default {
    name: 'Slideshows',
    data() {
        return {
            controlsEnabled: false,
            playing: false,
            slideshows: [],
            currentSlideshow: null,
            currentTimeout: null,
            currentSlideIndex: 0,
        };
    },
    computed: {
        LICENSE() {
            return this.$store.state.settings.LICENSE;
        },
    },
    watch: {
        playing(val) {
            if (val) {
                this.playSlide();
            } else {
                this.pauseSlide();
            }
        },
    },
    async mounted() {
        await this.getSlideshows();
    },
    methods: {
        async getSlideshows() {
            this.playing = false;
            const data = await SlideshowService.getSlideshows();
            if (data.success == 'n') this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(data.ret, data.retParams), 'warning'));
            else this.slideshows = data.ret ? data.ret : [];
            if (this.slideshows.length > 0) this.currentSlideshow = this.slideshows[0];
        },
        async prevSlide() {
            if (this.currentTimeout != null) {
                clearTimeout(this.currentTimeout);
                this.currentTimeout = null;
            }
            if (this.currentSlideIndex <= 0) this.currentSlideIndex = this.currentSlideshow.Slides.length - 1;
            else this.currentSlideIndex--;
            await this.playSlide();
        },
        async playSlide() {
            var slide = this.currentSlideshow.Slides[this.currentSlideIndex];
            if (slide.DashboardId) {
                // Check for license permission
                if ((slide.Dashboard.Mode != 'DESIGNER' && slide.Dashboard.Mode != 'HMI' && this.LICENSE.Options[this.$LicenseOptions.Dashboards]) || slide.Dashboard.Mode == 'DESIGNER' || (slide.Dashboard.Mode == 'HMI' && this.LICENSE.Options[this.$LicenseOptions.Designer]))
                    await this.showDashboard(slide.DashboardId);
            } else {
                if (this.LICENSE.Options[this.$LicenseOptions.Reports]) await this.showReport(slide.Report.Name);
            }
            this.currentTimeout = setTimeout(async () => {
                if (this.currentSlideIndex >= this.currentSlideshow.Slides.length - 1) this.currentSlideIndex = 0;
                else this.currentSlideIndex++;
                this.playSlide();
            }, this.currentSlideshow.Slides[this.currentSlideIndex].Duration * 1000);
        },
        pauseSlide() {
            if (this.currentTimeout != null) {
                clearTimeout(this.currentTimeout);
                this.currentTimeout = null;
                this.currentSlideIndex = 0;
            }
        },
        async nextSlide() {
            if (this.currentTimeout != null) {
                clearTimeout(this.currentTimeout);
                this.currentTimeout = null;
            }
            if (this.currentSlideIndex >= this.currentSlideshow.Slides.length - 1) this.currentSlideIndex = 0;
            else this.currentSlideIndex++;
            await this.playSlide();
        },
        async showDashboard(id) {
            if (this.$router.currentRoute.name != 'dashboards') await this.$router.push('dashboards');
            const data = await DashboardService.getDashboard(id);
            if (data.success == 'n') this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(data.ret, data.retParams), 'warning'));
            else {
                await this.$store.commit('setDashboard', { id: id, data: data.ret });
                this.$store.commit('setCurrentDashboardId', id);
            }
        },
        async showReport(name) {
            if (this.$router.currentRoute.name != 'reportviewer') {
                await this.$router.push('reportviewer');
                await this.$store.commit('setCurrentReportName', null);
            }
            this.$store.commit('setCurrentReportName', name);
        },
    },
};
</script>
<style lang="scss">
.slideshows {
    margin-top: 2px;
}
.slideshows-controls {
    margin-top: 5px;
    display: inline-block;
}
.slideshows-controls {
    white-space: nowrap;
}
.slideshows .dropdown .btn {
    border: none;
    border-width: 0px;
    border-color: transparent;
    outline: none;
}
.slideshows .dropdown .btn:focus {
    box-shadow: none;
    border: none;
    border-width: 0px;
    border-color: transparent;
    outline: none;
}
.slideshows .slideshow-active a {
    color: var(--primary) !important;
}
</style>
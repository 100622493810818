<template>
    <b-tabs pills card h-100 d-flex flex-column style="width: 100%" :class="{ disabled: loadingConnectionCheck }">
        <!-- Connection configuration -->
        <b-tab>
            <template #title>
                <span class="tabHeaderText">
                    {{ $t('Connection') }}
                </span>
            </template>
            <div>
                <b-row>
                    <b-col cols="6">
                        <b-form-group :label="$t('equipment.EluCloud.RefreshDelay') + ' *'" label-for="refreshDelay">
                            <b-form-input
                                @change="
                                    (value) => {
                                        if (value > 60000) {
                                            this.refreshDelay = 60000;
                                        } else if (value < 500) {
                                            this.refreshDelay = 500;
                                        }
                                    }
                                "
                                type="number"
                                min="500"
                                max="60000"
                                id="refreshDelay"
                                v-model="refreshDelay"
                                :class="{ disabled: !this.$store.state.auth.user.permissions.PermissionWrite }"
                            />
                        </b-form-group>
                    </b-col>
                </b-row>
            </div>
        </b-tab>
    </b-tabs>
</template>

<script>
import { uuid } from 'vue-uuid';

export default {
    name: 'ConfigRessourcesDiagnostic',
    props: {
        equipment: Object,
        onValidate: Function,
        updateMode: Boolean,
    },
    async mounted() {},
    data() {
        return {
            loadingConnectionCheck: false,
            refreshDelay: this.equipment?.refreshDelay ?? 1000,
            itemColumns: [
                { key: 'Name', dataField: 'Name', dataType: 'string', caption: this.$t('Name') },
                { key: 'Type', dataField: 'Type', dataType: 'string', caption: this.$t('Type') },
                { key: 'Address', dataField: 'Address', dataType: 'string', caption: this.$t('Address') },
            ],
            groups: this.equipment?.Groups ?? [{ id: uuid.v4(), Name: `${this.$t('Group')} 1`, Active: true, RefreshRate: 500, Items: [] }],
            groupConfiguration: {
                rows: [
                    {
                        columns: [{ text: this.$t('Refresh rate'), variable: 'RefreshRate', inputType: 'number', cols: '6', min: '100', max: '99999', defaultValue: 500 }],
                    },
                ],
            },
        };
    },
    methods: {
        async saveEquipmentCommunication() {
            await this.$refs.itemConfiguration.saveEquipmentCommunication();
        },
        /**
         * Synchronizes the data changes from the wizard into the given equipment. This method should be called before updateing / saving / adding
         * the equipment in the parent control.
         * @public This method is public.
         */
        async synchronizeEquipment() {
            this.equipment.Groups = this.groups;
            this.equipment.refreshDelay = this.refreshDelay;
        },
        async refreshVariablesCounter(searchingForItems, variableCounter) {},
        /**
         * Checks if the given connection parameters result in a valid connection.
         */
        validate() {
            return {
                refreshDelay: this.refreshDelay,
                Groups: this.Groups,
                Name: this.Name,
            };
        },
    },
};
</script>
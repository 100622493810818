<template>
    <div style="width: 100%">
        <b-row>
            <b-col cols="3">
                <b-list-group v-if="!readonly">
                    <div class="text-center">
                        <font-awesome-icon icon="plus" class="cursor-pointer" v-if="(this.currentGroup != null && this.currentGroup.id != 0) || this.currentGroup == null" v-on:click="createNewGroup" v-bind:alt="$t('Add database')" v-bind:title="$t('Add database')" />
                        <font-awesome-icon icon="minus" class="cursor-pointer ml-3" v-if="this.currentGroup != null" v-on:click="removeGroup" v-bind:alt="$t('Remove')" v-bind:title="$t('Remove')" />
                        <!-- <font-awesome-icon icon="check" class="cursor-pointer ml-3 scale-animation"
                            v-if="this.currentGroup != null && this.currentGroup.id == 0 && this.valid"
                            v-on:click="saveGroup" v-bind:alt="$t('Save')" v-bind:title="$t('Save')" />
                        <font-awesome-icon icon="check" class="ml-3"
                            v-if="this.currentGroup != null && this.currentGroup.id == 0 && !this.valid"
                            v-bind:alt="$t('Save')" v-bind:title="$t('Save')" color="grey" /> -->
                    </div>
                </b-list-group>
                <b-list-group-item v-for="group in groups" :key="group.id" :active="currentGroup && group.id == currentGroup.id" v-on:click="selectGroup(group.id)" v-bind:class="{ 'font-italic': group.id == 0 }" button>
                    {{ group.Name }}
                </b-list-group-item>
            </b-col>
            <b-col cols="9">
                <b-card v-if="this.currentGroup != null && this.currentGroup.id != null">
                    <!-- Display 2 tabs: Group config and Items list  -->
                    <div v-if="separateGroupConfig">
                        <b-tabs pills card>
                            <b-tab aria-activedescendant="" :title="$t('Configuration')">
                                <b-form>
                                    <!-- Static group fields: Name and Active -->
                                    <b-row>
                                        <b-col>
                                            <b-form-group :label="$t('Name') + ' *'" label-for="groupName">
                                                <b-form-input id="groupName" v-model="currentGroup.Name" :state="validateName()" :class="{ disabled: readonly }" />
                                                <b-form-invalid-feedback id="groupName-feedback">{{ groupNameFeedback }}</b-form-invalid-feedback>
                                            </b-form-group>
                                        </b-col>
                                        <b-col cols="6">
                                            <b-form-checkbox id="groupActive" style="margin-top: 40px" v-model="currentGroup.Active" :class="{ disabled: readonly }"> {{ $t('Active') }} </b-form-checkbox>
                                        </b-col>
                                    </b-row>
                                    <!-- Dynamic group fields -->
                                    <b-row v-for="row in groupConfiguration.rows" :key="row.id">
                                        <b-col v-for="col in row.columns" :key="col.id" :cols="col.cols">
                                            <b-form-group :label="col.text" :label-for="col.text">
                                                <!-- Display a form-input if the field is a number, a text or a password -->
                                                <div v-if="col.inputType == 'number' || col.inputType == 'text' || col.inputType == 'password'">
                                                    <b-form-input :id="col.text" v-model="currentGroup[col.variable]" :type="col.inputType" :min="col.min" :max="col.max" :class="{ disabled: readonly }" />
                                                </div>
                                                <!-- Display a form-select if the field is a list or has options -->
                                                <div v-if="col.inputType == 'list'">
                                                    <b-form-select :id="col.text" v-model="currentGroup[col.variable]" :options="col.options" :class="{ disabled: readonly }" />
                                                </div>
                                            </b-form-group>
                                        </b-col>
                                    </b-row>
                                </b-form>
                            </b-tab>
                            <b-tab>
                                <template #title>
                                    {{ $t('Variables') }}
                                    <b-badge pill variant="info">{{ currentGroup.Items.length }}</b-badge>
                                </template>
                                <b-row>
                                    <b-col>
                                        <!-- Header: 'Add' and 'Search' (if needed) -->
                                        <b-list-group>
                                            <div class="text-center">
                                                <!-- <font-awesome-icon icon="plus" class="cursor-pointer"
                                                    v-on:click="createNewItem" v-bind:alt="$t('Add item')"
                                                    v-bind:title="$t('Add item')" v-if="!readonly" /> -->
                                                <font-awesome-icon icon="search" class="cursor-pointer ml-3" v-if="OnItemBrowse != null && !readonly" v-on:click="searchForItems" v-bind:alt="$t('Search items')" v-bind:title="$t('Search items')" />
                                            </div>
                                        </b-list-group>
                                        <!-- Item list -->
                                        <div class="card-items-scroll" :style="tabedItemsStyle">
                                            <DxDataGrid :dataSource="currentGroup.Items" :allow-column-reordering="true" :repaint-changes-only="false" :show-borders="true" :selection="{ mode: 'single' }" :column-auto-width="false" :allow-column-resizing="true" @init-new-row="onRowInserting" showScrollbar="true" :style="tabedItemsStyle" :on-editing-start="onEditingStart" :on-content-ready="onContentReady">
                                                <DxEditing v-if="!readonly" :allow-updating="!readonly" :allow-adding="!readonly" :allow-deleting="!readonly" mode="row" :use-icons="true" />

                                                <DxPaging :page-size="20" />
                                                <DxPager :visible="true" :allowed-page-sizes="[10, 20, 50, 100]" display-mode="full" :show-page-size-selector="true" :show-info="true" :show-navigation-buttons="true" />
                                                <DxColumn v-for="column in columns" :key="column.key" :data-field="column.dataField" :set-cell-value="column.setCellValue" :data-type="column.dataType" :caption="column.caption" :width="column.width ? column.width + 'px' : undefined" :alignment="column.alignment ? column.alignment : 'left'" :allow-editing="!readonly" :allow-resizing="true" :validation-rules="column.required ? [{ type: 'required' }] : null" :editor-options="column.editorOptions ? column.editorOptions : {}">
                                                    <DxLookup v-if="column.options && !readonly" :data-source="column.options" />
                                                </DxColumn>
                                            </DxDataGrid>
                                        </div>
                                    </b-col>
                                </b-row>
                            </b-tab>
                        </b-tabs>
                    </div>
                    <!-- Display a single view for group config and items list -->
                    <div v-else>
                        <!-- Static group fields: Name and Active -->
                        <b-row>
                            <b-col>
                                <b-form-group :label="$t('Name') + ' *'" label-for="groupName">
                                    <b-form-input id="groupName" v-model="currentGroup.Name" :state="validateName()" :class="{ disabled: readonly }" />
                                    <b-form-invalid-feedback id="groupName-feedback">{{ groupNameFeedback }}</b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>
                            <b-col cols="6">
                                <b-form-checkbox id="groupActive" style="margin-top: 40px" v-model="currentGroup.Active" :class="{ disabled: readonly }"> {{ $t('Active') }} </b-form-checkbox>
                            </b-col>
                        </b-row>
                        <!-- Dynamic group fields -->
                        <b-row v-for="row in groupConfiguration.rows" :key="row.id">
                            <b-col v-for="col in row.columns" :key="col.id" :cols="col.cols">
                                <b-form-group :label="col.text" :label-for="col.text">
                                    <!-- Display a form-input if the field is a number, a text or a password -->
                                    <div v-if="col.inputType == 'number' || col.inputType == 'text' || col.inputType == 'password'">
                                        <b-form-input :id="col.text" v-model="currentGroup[col.variable]" :type="col.inputType" :min="col.min" :max="col.max" />
                                    </div>
                                    <!-- Display a form-select if the field is a list or has options -->
                                    <div v-if="col.inputType == 'list'">
                                        <b-form-select :id="col.text" v-model="currentGroup[col.variable]" :options="col.options" />
                                    </div>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                                <!-- Header: 'Add' and 'Search' (if needed) -->
                                <b-list-group>
                                    <div class="text-center">
                                        <!-- <font-awesome-icon icon="plus" class="cursor-pointer" v-on:click="createNewItem"
                                            v-bind:alt="$t('Add item')" v-bind:title="$t('Add item')" v-if="!readonly" /> -->
                                        <font-awesome-icon icon="search" class="cursor-pointer ml-3" v-if="OnItemBrowse != null && !readonly" v-on:click="searchForItems" v-bind:alt="$t('Search items')" v-bind:title="$t('Search items')" />
                                    </div>
                                </b-list-group>
                                <!-- Item list -->
                                <div class="card-items-scroll" :style="itemsStyle">
                                    <DxDataGrid :dataSource="currentGroup.Items" :allow-column-reordering="true" :repaint-changes-only="false" :show-borders="true" :selection="{ mode: 'single' }" :column-auto-width="false" :allow-column-resizing="false" @init-new-row="onRowInserting" showScrollbar="true" :style="itemsStyle" :on-editing-start="onEditingStart" :on-content-ready="onContentReady">
                                        <DxEditing v-if="!readonly" :allow-updating="!readonly" :allow-adding="!readonly" :allow-deleting="!readonly" mode="row" :use-icons="true" />
                                        <DxPaging :page-size="20" />
                                        <DxPager :visible="true" :allowed-page-sizes="[10, 20, 50, 100]" display-mode="full" :show-page-size-selector="true" :show-info="true" :show-navigation-buttons="true" />
                                        <DxColumn v-for="column in columns" :key="column.key" :data-field="column.dataField" :set-cell-value="column.setCellValue" :data-type="column.dataType" :caption="column.caption" :width="column.width ? column.width + 'px' : undefined" :alignment="column.alignment ? column.alignment : 'left'" :allow-editing="!readonly" :allow-resizing="true" :validation-rules="column.required ? [{ type: 'required' }] : null" :calculate-display-value="function (data) {
                                                return calculateDisplayValue(data, column.dataField);
                                            }
                                            " :editor-options="column.editorOptions ? column.editorOptions : {}">
                                            <DxLookup v-if="column.options && !readonly" :data-source="column.options" />
                                        </DxColumn>
                                    </DxDataGrid>
                                </div>
                            </b-col>
                        </b-row>
                    </div>
                </b-card>
            </b-col>
        </b-row>
        <!-- Item selection wizard, displayed when the user clicks on the 'search' icon -->
        <SelectItemListWizardOld :createChildren="this.createChildren" :RefreshVarCounter="this.RefreshVarCounter" :OnLoad="onItemListWizardLoadOld" :OnOk="onItemOk" :id="'searchForItemsOld' + updateMode" />
        <SelectItemListWizard :createChildren="this.createChildren" :OnLoad="onItemListWizardLoad" :OnOk="onItemOk" :id="'searchForItems' + updateMode" />
    </div>
</template>

<script>
import ToastAlert from '@/utils/ToastAlert';
import VariableDetails from '@/components/configuration/Variables/VariableDetails.vue';
import SelectItemListWizardOld from '@/components/configuration/Equipments/SelectItemListWizardOld.vue';
import SelectItemListWizard from '@/components/configuration/Equipments/SelectItemListWizard.vue';
import { DxDataGrid, DxColumn, DxToolbar, DxItem, DxLookup, DxEditing, DxPaging, DxPager } from 'devextreme-vue/data-grid';
import { uuid } from 'vue-uuid';

export default {
    name: 'ItemConfiguration',
    components: {
        DxDataGrid,
        DxColumn,
        DxEditing,
        DxToolbar,
        DxItem,
        DxLookup,
        DxPaging,
        DxPager,
        VariableDetails,
        SelectItemListWizardOld,
        SelectItemListWizard,
    },
    props: {
        /**
         * Lists all the fields needed for a single item.
         */
        columns: Array,
        /**
         * Reference to the equipment's group.
         */
        groups: Array,
        /**
         * Callback called when an item is added to the list
         */
        onItemInserting: Function,
        /**
         * When true, you will not be able to edit the group config or add / delete / modify items in it.
         */
        readonly: Boolean,
        /**
         * If true, the group configuration will be in a separated tab.
         */
        separateGroupConfig: Boolean,
        /**
         * List of rows-columns to be displayed with their corresponding group fields.
         */
        groupConfiguration: Object,
        /**
         * When set, allows to search for items on the remote server. If not set, the loop icon will not be displayed.
         */
        OnItemBrowse: Function,
        createChildren: Function,
        updateMode: Boolean,
        RefreshVarCounter: Function,
        onEditingStartProp: Function,
        onContentReadyProp: Function,
        calculateCellDisplayValueProp: Function,
    },
    data() {
        return {
            currentGroup: null,
            groupNameFeedback: '',
            valid: false,
        };
    },
    computed: {
        itemsStyle() {
            return this.updateMode ? 'min-height: calc(100vh - 450px); max-height: calc(100vh - 450px); min-width: 100%; max-width: 100%;' : 'min-height: 420px; max-height: 420px; min-width: 100%; max-width: 100%;';
        },
        tabedItemsStyle() {
            return this.updateMode ? 'min-height: calc(100vh - 500px); max-height: calc(100vh - 500px); min-width: 100%; max-width: 100%;' : 'min-height: 400px; max-height: 400px; min-width: 100%; max-width: 100%;';
        },
    },
    async mounted() {
        this.currentGroup = this.groups[0];
    },
    methods: {
        calculateDisplayValue(data, dataField) {
            if (this.calculateCellDisplayValueProp == null) return data[dataField];
            return this.calculateCellDisplayValueProp(data, dataField);
        },
        async onContentReady(e) {
            if (this.onContentReadyProp == null) return;
            this.onContentReadyProp(e);
        },
        async onEditingStart(e) {
            if (this.onEditingStartProp == null) return;
            this.onEditingStartProp(e);
        },
        async saveEquipmentCommunication() {
            await this.saveGroup();
        },
        /**
         * Adds a new group to the list of groups
         */
        async createNewGroup() {
            var i = 1;
            var resultNames = this.groups.map((r) => r.Name);
            while (resultNames.findIndex((r) => r === `${this.$t('Group')} ${i}`) != -1) {
                i++;
            }
            const newGroupName = `${this.$t('Group')} ${i}`;
            var id = uuid.v4();
            var newGroup = { id: id, Name: newGroupName, Active: true, Items: [] };
            if (this.groupConfiguration != null && this.groupConfiguration.rows != null) {
                this.groupConfiguration.rows.forEach((row) => {
                    row.columns.forEach((col) => {
                        if (col.variable != null && col.defaultValue != null) {
                            newGroup[col.variable] = col.defaultValue;
                        }
                    });
                });
            }
            this.groups.push(newGroup);
            await this.selectGroup(id);
        },
        /**
         * Saves the currently selected group
         */
        async saveGroup() {
            if (this.currentGroup == null) {
                return;
            }
            if (this.currentGroup.id == 0) {
                // New group.
                if (this.groups.findIndex((it) => it.Name === this.currentGroup.Name && it.id != 0) != -1) {
                    // An item with the same name already exists.
                    this.$EventBus.$emit('show-toast', new ToastAlert(this.$t('equipment.groupNameTaken'), 'warning'));
                } else {
                    this.currentGroup.id = this.currentGroup.Name;
                }
            } else {
                // Existing group.
                this.currentGroup.id = this.currentGroup.Name;
            }
        },
        /**
         * Removes the currently selected group
         */
        async removeGroup(e) {
            const idx = this.groups.findIndex((it) => this.currentGroup.id === it.id);
            if (idx != -1) {
                this.groups.splice(idx, 1);
            }
            // Select group at the same index or the first one if exists.
            if (this.groups.length > 0) {
                if (idx < this.groups.length) {
                    await this.selectGroup(this.groups[idx].id);
                } else {
                    await this.selectGroup(this.groups[this.groups.length - 1].id);
                }
            } else {
                this.currentGroup = null;
            }
        },
        /**
         * Selects a group from the group list
         */
        async selectGroup(id) {
            const foundGroup = this.groups.find((gr) => gr.id == id);
            if (foundGroup != this.currentGroup) {
                this.currentGroup = foundGroup;
            }
        },
        /**
         * Validates the currently selected group's name
         */
        validateName() {
            if (this.currentGroup == null) return false;
            if (this.groups == null) {
                this.groupNameFeedback = '';
                this.valid = true;
                return true;
            }
            if (this.currentGroup.Name == null || this.currentGroup.Name == '') {
                this.groupNameFeedback = this.$t('equipment.groupNameEmpty');
                this.valid = false;
                return false;
            }
            if (this.currentGroup.id != 0) {
                const result = this.groups.findIndex((gr) => gr.id != this.currentGroup.Name && gr.id == this.currentGroup.Name);
                if (result != -1) {
                    this.groupNameFeedback = this.$t('equipment.groupNameTaken');
                }
                this.valid = result === -1;
                return this.valid;
            } else {
                const result = this.groups.findIndex((gr) => gr.id == this.currentGroup.Name);
                if (result != -1) {
                    this.groupNameFeedback = this.$t('equipment.groupNameTaken');
                }
                this.valid = result === -1;
                return this.valid;
            }
        },
        /**
         * Callbacks to the onItemInserting function given in props.
         */
        onRowInserting(e) {
            if (this.onItemInserting == null) return;
            this.onItemInserting(e, this.currentGroup);
        },
        /**
         * Adds a new item to the list of items of the currently selected group.
         */
        async createNewItem(e) {
            if (this.currentGroup == null) return;
            if (this.currentGroup.Items == null) this.currentGroup.Items = [];
            this.currentGroup.Items.push({ id: 0, Address: '', Name: '', Type: '', Active: false });
        },
        /**
         * Show the item chooser wizard.
         */
        async searchForItems(e) {
            //I have to add updateMode because if you have a config open and try to create an equipement it will call the modal of the equipment behind and the modal of the equipement you are creating
            if (this.createChildren == null) this.$bvModal.show('searchForItemsOld' + this.updateMode);
            else this.$bvModal.show('searchForItems' + this.updateMode);
        },
        /**
         * Called when the item chooser wizard validates.
         * +(Deleting Types unsuported by prodcom Not permanent)
         * @param data The list of chosen items
         */
        async onItemOk(data) {
            if (this.currentGroup == null) return;
            // this.currentGroup.Items = data.map((el) => {
            //     const dat = el.obj ? el.obj : el.itemData;
            //     dat.id = el.id;
            //     return dat;
            // });
            this.currentGroup.Items = [];
            data.forEach((el) => {
                const dat = el.obj ? el.obj : el.itemData;
                dat.id = el.id;
                // Check for duplicate Name property in currentGroup.Items
                // If found, add a number to the end of the name
                let name = dat.itemFullPathName || dat.Name;
                let i = 1;
                while (this.currentGroup.Items.some((it) => it.Name === name)) {
                    name = `${dat.itemFullPathName || dat.Name}_${i}`;
                    i++;
                }
                dat.Name = name;
                this.currentGroup.Items.push(dat);
            });
            for (let i = this.currentGroup.Items.length - 1; i >= 0; i--) {
                if (this.currentGroup.Items[i].Type === '') {
                    console.log(this.currentGroup.Items[i].Name + "'s type is not supported");
                    this.currentGroup.Items.splice(i, 1);
                }
            }
        },
        /**
         * Called when the item chooser wizard loads.
         * @param itemListClient The list of items already present in the wizard
         */
        async onItemListWizardLoadOld(itemListClient) {
            itemListClient.splice(0, itemListClient.length);
            // Push items already imported
            for (const it of this.currentGroup.Items) {
                itemListClient.push({ id: it.Address, text: it.Name, expanded: false, items: null, icon: 'fas fa-tag', iconColor: '#f57c00', obj: it });
            }

            try {
                if (this.OnItemBrowse == null) return [];
                const result = await this.OnItemBrowse();
                const items = [];
                this.browseItems(result, items);
                this.removeExistingItems(items, itemListClient);
                return items;
            } catch (error) {
                console.log(error);
                return [];
            }
        },
        async onItemListWizardLoad() {
            let itemListClient = [];
            // Push items already imported
            for (const it of this.currentGroup.Items) {
                itemListClient.push({ id: it.Address, text: it.Name, expanded: false, items: null, icon: 'fas fa-tag', iconColor: '#f57c00', itemData: it });
            }
            return itemListClient;
        },
        /**
         * Converts list of items from API back to items compatible with the treeview
         */
        browseItems(itemList, currentItem) {
            if (itemList == null) return;
            for (const it of itemList) {
                const newItem =
                    it.Children == null
                        ? { id: it.Address, text: it.Name, expanded: false, items: null, icon: 'fas fa-tag', iconColor: '#f57c00', obj: it, expanded: false, selected: false }
                        : { id: it.Address, text: it.Name, expanded: false, items: [], icon: 'fas fa-folder-open', iconColor: '#fdd835', obj: it, expanded: false, selected: false };
                currentItem.push(newItem);
                this.browseItems(it.Children, newItem.items);
            }
        },
        removeExistingItems(itemList, existingItems) {
            // NOT WORKING. TODO.
            if (itemList == null) return;
            for (const it of itemList) {
                const index = existingItems.findIndex((i) => i.id == it.id);
                if (index != -1) {
                    itemList.splice(index, 1);
                }
                this.removeExistingItems(it.Children, existingItems);
            }
        },
    },
};
</script>
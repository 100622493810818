import store from "@/store/index.js";

class RoleService {
    async getAllRoles() {
        const settings = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                Method: 'roles.get',
                Token: store.state.auth.user.accessToken,
                Parameters: {},
            }),
        };

        const res = await fetch(store.state.settings.API_URL, settings);
        const data = await res.json();
        return data;
    }
    async getUsersInRole(roleId) {
        const settings = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                Method: 'roles.getUsersInRole',
                Token: store.state.auth.user.accessToken,
                Parameters: {
                    Id: roleId,
                },
            }),
        };

        const res = await fetch(store.state.settings.API_URL, settings);
        const data = await res.json();
        return data;
    }
    async getGroupsInRole(roleId) {
        const settings = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                Method: 'roles.getGroupsInRole',
                Token: store.state.auth.user.accessToken,
                Parameters: {
                    Id: roleId,
                },
            }),
        };

        const res = await fetch(store.state.settings.API_URL, settings);
        const data = await res.json();
        return data;
    }
    async getDashboardsInRole(roleId) {
        const settings = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                Method: 'roles.getDashboardsInRole',
                Token: store.state.auth.user.accessToken,
                Parameters: {
                    Id: roleId,
                },
            }),
        };

        const res = await fetch(store.state.settings.API_URL, settings);
        const data = await res.json();
        return data;
    }
    async getReportsInRole(roleId) {
        const settings = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                Method: 'roles.getReportsInRole',
                Token: store.state.auth.user.accessToken,
                Parameters: {
                    Id: roleId,
                },
            }),
        };

        const res = await fetch(store.state.settings.API_URL, settings);
        const data = await res.json();
        return data;
    }
    async getConnectionsInRole(roleId) {
        const settings = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                Method: 'roles.getConnectionsInRole',
                Token: store.state.auth.user.accessToken,
                Parameters: {
                    Id: roleId,
                },
            }),
        };

        const res = await fetch(store.state.settings.API_URL, settings);
        const data = await res.json();
        return data;
    }
    async getWebSourcesInRole(roleId) {
        const settings = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                Method: 'roles.getWebSourcesInRole',
                Token: store.state.auth.user.accessToken,
                Parameters: {
                    Id: roleId,
                },
            }),
        };

        const res = await fetch(store.state.settings.API_URL, settings);
        const data = await res.json();
        return data;
    }
}

export default new RoleService();
<template>
    <div class="form-errors" v-if="propFormErrors.length > 0">
        <div v-for="error in propFormErrors" :key="'error' + error" class="full-width m-1">
            {{ error }}
        </div>
    </div>
</template>
<script>
export default {
    name: 'FormErrors',
    props: ['propFormErrors'],
}
</script>
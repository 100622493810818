import utils from "@/utils";

class ProdComGeneralService {
    async checkPath(path){
        return await utils.PostQuery('general.CheckPath', 
        {
            Path: path
        });
    }
}

export default new ProdComGeneralService();
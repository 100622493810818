<template>
    <div>
        <div v-if="currentEquipment != null">
            <b-navbar class="shadow" v-if="showToolbar">
                <b-container fluid class="navbar-container">
                    <b-navbar-nav>
                        <!-- Save -->
                        <b-nav-item v-on:click="onSaveCommunication" :title="$t('Save')" :class="{ disabled: !this.$store.state.auth.user.permissions.PermissionWrite }">
                            <font-awesome-layers class="top-menu-icon">
                                <font-awesome-icon icon="fas fa-floppy-disk" class="mr-1 fa-lg" />
                                <font-awesome-icon v-if="saving" icon="sync" class="fa-xs fa-spin-custom" transform="down-10.0 right-10.0" color="#c8b900" />
                            </font-awesome-layers>
                        </b-nav-item>
                        <!-- Cancel -->
                        <b-nav-item v-on:click="onCancel" :title="$t('Cancel')">
                            <font-awesome-icon icon="far fa-arrow-right-from-bracket" class="top-menu-icon mr-1 fa-lg" />
                        </b-nav-item>
                    </b-navbar-nav>
                    <b-navbar-nav class="font-bold"> {{ this.equipmentName }} </b-navbar-nav>
                    <b-navbar-nav>
                        <!-- YEEELP -->
                        <b-nav-item v-on:click="displayHelp" :title="$t('Help')">
                            <font-awesome-icon icon="fas fa-square-question" class="top-menu-icon mr-1 fa-lg" />
                        </b-nav-item>
                    </b-navbar-nav>
                </b-container>
            </b-navbar>
            <div class="m-3">
                <!-- Place here the different configuration components based on the selected type. Please use the same ref name for every component. -->
                <ConfigOPCUA v-if="currentEquipment.type == 'OPC UA'" :equipment="currentEquipment" ref="config" :onValidate="validateConfig" :updateMode="updateMode" />
                <ConfigMQTT v-if="currentEquipment.type == 'MQTT'" :equipment="currentEquipment" ref="config" :onValidate="validateConfig" :updateMode="updateMode" />
                <ConfigEluCloud v-if="currentEquipment.type == 'EluCloud'" :equipment="currentEquipment" ref="config" :onValidate="validateConfig" :updateMode="updateMode" />
                <ConfigRessourcesDiagnostic v-if="currentEquipment.type == 'RessourceDiagnostic'" :equipment="currentEquipment" ref="config" :onValidate="validateConfig" :updateMode="updateMode" />
                <ConfigOmronFins v-else-if="currentEquipment.type == 'Omron FINS'" :equipment="currentEquipment" ref="config" :onValidate="validateConfig" :updateMode="updateMode" />
                <ConfigOPCDA v-else-if="currentEquipment.type == 'OPC DA'" :equipment="currentEquipment" ref="config" :onValidate="validateConfig" :existingConfig="updateMode" :updateMode="updateMode" />
                <ConfigS7 v-else-if="currentEquipment.type == 'S7'" :equipment="currentEquipment" ref="config" :onValidate="validateConfig" :updateMode="updateMode" />
                <ConfigPatlite v-else-if="currentEquipment.type == 'Patlite'" :equipment="currentEquipment" ref="config" :onValidate="validateConfig" :updateMode="updateMode" />
                <ConfigVirtual v-else-if="currentEquipment.type == 'Virtual'" :equipment="currentEquipment" ref="config" :onValidate="validateConfig" :updateMode="updateMode" />
                <ConfigModbus v-else-if="currentEquipment.type == 'Modbus'" :equipment="currentEquipment" ref="config" :onValidate="validateConfig" :updateMode="updateMode" />
                <ConfigProductysManager v-else-if="currentEquipment.type == 'ProductysManager'" :equipment="currentEquipment" ref="config" :onValidate="validateConfig" :project="project" :updateMode="updateMode" />
                <ConfigVijeoDesigner v-else-if="currentEquipment.type == 'VijeoDesigner'" :equipment="currentEquipment" ref="config" :onValidate="validateConfig" :updateMode="updateMode" />
                <ConfigEspera v-else-if="currentEquipment.type == 'Espera'" :equipment="currentEquipment" ref="config" :onValidate="validateConfig" :updateMode="updateMode" />
                <ConfigStock v-else-if="currentEquipment.type == 'Stock'" :equipment="currentEquipment" ref="config" :onValidate="validateConfig" :project="project" :updateMode="updateMode" />


            </div>
        </div>
        <div v-else>
            <div
                class="loading-overlay"
                :style="{
                    width: 100 + 'vw !important',
                }"
            >
                <div
                    class="loading-spinner"
                    :style="{
                        left: 50 + 'vw !important',
                    }"
                >
                    <div class="loading-spinner-inner"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ProdComEquipmentService from '@/services/prodcom.equipments.service.js';
import ToastAlert from '@/utils/ToastAlert';
import ConfigOmronFins from '@/components/configuration/Equipments/Configuration_OmronFins.vue';
import ConfigOPCUA from '@/components/configuration/Equipments/Configuration_OPCUA.vue';
import ConfigOPCDA from '@/components/configuration/Equipments/Configuration_OPCDA.vue';
import ConfigS7 from '@/components/configuration/Equipments/Configuration_S7.vue';
import ConfigPatlite from '@/components/configuration/Equipments/Configuration_Patlite.vue';
import ConfigVirtual from '@/components/configuration/Equipments/Configuration_Virtual.vue';
import ConfigModbus from '@/components/configuration/Equipments/Configuration_Modbus.vue';
import ConfigVijeoDesigner from '@/components/configuration/Equipments/Configuration_VijeoDesigner.vue';
import ConfigProductysManager from '@/components/configuration/Equipments/Configuration_ProductysManager.vue';
import ConfigMQTT from '@/components/configuration/Equipments/Configuration_MQTT.vue';
import ConfigEluCloud from '@/components/configuration/Equipments/Configuration_EluCloud.vue';
import ConfigRessourcesDiagnostic from '@/components/configuration/Equipments/Configuration_RessourcesDiagnostic.vue';
import ConfigEspera from '@/components/configuration/Equipments/Configuration_Espera.vue';
import ConfigStock from '@/components/configuration/Equipments/Configuration_Stock.vue';
export default {
    name: 'Equipment',
    components: {
        ConfigOmronFins,
        ConfigOPCUA,
        ConfigOPCDA,
        ConfigS7,
        ConfigVirtual,
        ConfigModbus,
        ConfigVijeoDesigner,
        ConfigProductysManager,
        ConfigMQTT,
        ConfigEluCloud,
        ConfigRessourcesDiagnostic,
        ConfigPatlite,
        ConfigEspera,
        ConfigStock,
        ToastAlert,
    },
    props: {
        equipment: Object,
        equipmentName: String,
        project: String,
        onValidate: Function,
        updateMode: Boolean,
        showToolbar: Boolean,
        closeTab: Function,
    },
    data() {
        return {
            saving: false,
            currentEquipment: null,
        };
    },
    async mounted() {
        if (this.updateMode) {
            // On update mode, get back config from backend server.
            const result = await ProdComEquipmentService.getEquipmentConfiguration(this.project, this.equipmentName);
            if (result.success == 'n') this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(data.ret, data.retParams), 'warning'));
            else {
                this.currentEquipment = result.ret;
            }
        } else {
            this.currentEquipment = this.equipment;
        }
    },

    watch: {
        async equipment(newValue, oldValue) {
            if (this.updateMode && newValue == null) {
                // On update mode, get back config from backend server.
                const result = await ProdComEquipmentService.getEquipmentConfiguration(this.project, this.equipmentName);
                if (result.success == 'n') this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(data.ret, data.retParams), 'warning'));
                else {
                    this.currentEquipment = result.ret;
                }
            } else {
                this.currentEquipment = newValue;
            }
        },
    },

    methods: {
        async validateConfig(value) {
            if (this.onValidate == null) return;
            await this.onValidate(value);
        },
        async validate() {
            if (this.$refs.config != null && this.$refs.config.validate != null) return await this.$refs.config.validate();
            else return true;
        },
        async synchronizeEquipment() {
            if (this.$refs.config != null) await this.$refs.config.synchronizeEquipment();
        },
        async onSaveCommunication(e) {
            this.saving = true;
            if (this.$refs.config != null && this.$refs.config.validate != null) {
                const result = await this.$refs.config.validate();
                if (result == null) {
                    this.saving = false;
                    return;
                }
            }

            this.$EventBus.$emit('equip-set-refresh', { Name: this.equipmentName, ProjectId: this.project, toRefresh: true });
            await this.$refs.config.saveEquipmentCommunication();
            await this.$refs.config.synchronizeEquipment();
            this.currentEquipment.ProjectId = this.project;
            const data = await ProdComEquipmentService.updateEquipment(this.currentEquipment, this.project);
            if (data.success == 'n') {
                this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(data.ret, data.retParams), 'warning'));
            } else {
                this.$EventBus.$emit('show-toast', new ToastAlert(this.$t('Updated equipment'), 'info'));
                this.$EventBus.$emit('refresh-treeview-equipments');
            }
            this.saving = false;
        },
        async onCancel(e) {
            if (this.closeTab == null) return;
            await this.closeTab();
        },
        async displayHelp() {
            this.$store.commit('displayHelp', this.$t('help.communications.'+this.currentEquipment.type) );
            this.$store.commit('displayHelpTitle', this.$t('help.communications.'+this.currentEquipment.type+'.title'))
            this.$bvModal.show('bv-modal-help');
        },
    },
    computed: {
        /**
         * Returns true when the inner communication component implemented
         * a method to check the communication connection.
         */
        isCommunicationVerifiable() {
            if (this.$refs.config == null) return false;
            return typeof this.$refs.config.checkConnection == 'function';
        },
    },
};
</script>
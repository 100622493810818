<template>
    <div>
        <b-row>
            <b-col :class="{ 'disabled': selectedId == 0 }">
                <b-form-group :label="$t('Link')" label-for="link">
                    <b-form-select v-model="selectedLink" :options="availableLinks"
                        :class="{ 'disabled': !$store.state.auth.user.permissions.PermissionWrite }" />
                </b-form-group>
            </b-col>
            <!-- nomGroupe -->
            <b-col>
                <b-form-group :label="$t('Group')" label-for="triggerGroup">
                    <b-form-select v-model="selectedTriggerGroup" :state="selectedTriggerGroupState"
                        :options="triggerGroups"
                        :class="{ 'disabled': !$store.state.auth.user.permissions.PermissionWrite }"
                        />
                </b-form-group>
            </b-col>
            <!-- nomVariable -->
            <b-col>
                <b-form-group :label="$t('Item')" label-for="triggerItem">
                    <b-form-select v-model="selectedTriggerItem" :state="selectedTriggerItemState" :options="triggerItems"
                        :class="{ 'disabled': !$store.state.auth.user.permissions.PermissionWrite }"
                        @change="onItemChanged" />
                </b-form-group>
            </b-col>
        </b-row>
        <b-row v-if="!onlyCriteria">
            <!-- propVar -->
            <b-col>
                <b-form-group :label="$t('Attribute')" label-for="triggerAttribute">
                    <b-form-select v-model="selectedTriggerAttribute" :options="Properties"
                        :class="{ 'disabled': !$store.state.auth.user.permissions.PermissionWrite }" />
                </b-form-group>
            </b-col>
            <!-- typeCritere -->
            <b-col>
                <b-form-group :label="$t('Notification Type')" label-for="triggerType">
                    <b-form-select v-model="selectedTriggerType" :options="NotificationTypes"
                        :class="{ 'disabled': !$store.state.auth.user.permissions.PermissionWrite }" />
                </b-form-group>
            </b-col>
        </b-row>
        <hr color="grey" />
        <b-row v-if="selectedTriggerType == 1">
            <b-col cols="6">
                <b-form-group :label="$t('Edge')">
                    <b-form-select v-model="selectedEdge" :options="availableEdges"
                        :class="{ 'disabled': !$store.state.auth.user.permissions.PermissionWrite }" />
                    <!-- <b-form-radio-group id="radio-slots" v-model="selectedEdge" :options="availableEdges"/> -->
                </b-form-group>
            </b-col>
        </b-row>
        <!-- Value change -->
        <b-row v-if="selectedTriggerType == 0 && selectedTriggerVariableType != 'System.String' && !onlyCriteria">
            <b-col>
                <b-row>
                    <!-- haveDiffMin -->
                    <b-col cols="4">
                        <b-form-checkbox v-model="haveDiffMin" :value="true" :unchecked-value="false" switch
                            :class="{ 'disabled': !$store.state.auth.user.permissions.PermissionWrite }">
                            {{ $t('Dead band') }}
                        </b-form-checkbox>
                    </b-col>
                    <!-- diffMin -->
                    <b-col>
                        <b-form-input type="number" v-model="diffMin" :state="deathBandState"
                            @change="() => { if (diffMin == null || diffMin == '') { diffMin = 0; } }"
                            :class="{ 'disabled': !haveDiffMin || !$store.state.auth.user.permissions.PermissionWrite }"></b-form-input>
                    </b-col>
                </b-row>
            </b-col>
            <b-col />
        </b-row>
        <!-- Criteria -->
        <div v-if="selectedTriggerType == 2 || onlyCriteria">
            <b-row>
                <!-- critereComparaison -->
                <b-col>
                    <b-form-group :label="$t('triggers.unitaryNotification.compareCriteria')"
                        label-for="selectedCriteriaComparison">
                        <b-form-select v-model="selectedCriteriaComparison" :options="availableCriteriaComparisons"
                            :class="{ 'disabled': !$store.state.auth.user.permissions.PermissionWrite }" />
                    </b-form-group>
                </b-col>
                <!-- srcvalCrit -->
                <b-col>
                    <b-form-group :label="$t('triggers.unitaryNotification.compareType')" label-for="selectedCompareType">
                        <b-form-select v-model="selectedCompareType" :options="availableCompareTypes"
                            :class="{ 'disabled': !$store.state.auth.user.permissions.PermissionWrite }" />
                    </b-form-group>
                </b-col>
                <!-- alwaysExecute -->
                <b-col v-if="!onlyCriteria">
                    <b-form-checkbox v-model="alwaysExecute" :value="true" :unchecked-value="false" switch
                        style="margin-top: 35px;"
                        :class="{ 'disabled': !$store.state.auth.user.permissions.PermissionWrite }">
                        {{ $t('triggers.unitaryNotification.alwaysExecute') }}
                    </b-form-checkbox>
                </b-col>
            </b-row>
            <b-row v-if="selectedCompareType == 0">
                <!-- valCritere -->
                <b-col cols="4">
                    <b-form-group :label="$t('threshold')" label-for="criteriaValue">
                        <b-form-input v-model="criteriaValue" type="number" :state="criteriaValueState"
                            @change="() => { if (criteriaValue == null || criteriaValue == '') { criteriaValue = 0; } }"
                            :class="{ 'disabled': !$store.state.auth.user.permissions.PermissionWrite }" />
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row v-if="selectedCompareType == 1">
                <!-- nomGroupeCritere -->
                <b-col>
                    <b-form-group :label="$t('Value Group')" label-for="selectedCriteriaItemGroup">
                        <b-form-select v-model="selectedCriteriaItemGroup" :options="triggerGroups"
                            :state="selectedCriteriaItemGroupState"
                            :class="{ 'disabled': !$store.state.auth.user.permissions.PermissionWrite }" />
                    </b-form-group>
                </b-col>
                <!-- nomItemCritere -->
                <b-col>
                    <b-form-group :label="$t('Value Item')" label-for="selectedCriteriaItemName">
                        <b-form-select v-model="selectedCriteriaItemName" :options="criteriaItems"
                            :state="selectedCriteriaItemNameState"
                            :class="{ 'disabled': !$store.state.auth.user.permissions.PermissionWrite }" />
                    </b-form-group>
                </b-col>
                <!-- offset -->
                <b-col>
                    <b-form-group :label="$t('Offset')" label-for="offset">
                        <b-form-input v-model="offset" type="number"
                            @change="() => { if (offset == null || offset == '') { offset = 0; } }"
                            :class="{ 'disabled': !$store.state.auth.user.permissions.PermissionWrite }" />
                    </b-form-group>
                </b-col>
            </b-row>
        </div>
        <!-- Criteria comparison -->
        <div v-else-if="selectedTriggerType == 3">
            <b-row>
                <!-- nomGroupeCritComp -->
                <b-col>
                    <b-form-group :label="$t('Criteria Group')" label-for="selectedCriteriaGroupComparison">
                        <b-form-select v-model="selectedCriteriaGroupComparison" :options="triggerGroups"
                            :state="selectedCriteriaGroupComparisonState"
                            :class="{ 'disabled': !$store.state.auth.user.permissions.PermissionWrite }" />
                    </b-form-group>
                </b-col>
                <!-- nomVariableCritComp -->
                <b-col>
                    <b-form-group :label="$t('Criteria Item')" label-for="CriteriaVariableComparison">
                        <b-form-select v-model="selectedCriteriaVariableComparison" :options="criteriaVariableItems"
                            :state="selectedCriteriaVariableComparisonState"
                            :class="{ 'disabled': !$store.state.auth.user.permissions.PermissionWrite }" />
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <!-- nomGroupeCritere -->
                <b-col>
                    <b-form-group :label="$t('Value Group')" label-for="selectedCriteriaItemGroup">
                        <b-form-select v-model="selectedCriteriaItemGroup" :options="triggerGroups"
                            :state="selectedCriteriaItemGroupState3"
                            :class="{ 'disabled': !$store.state.auth.user.permissions.PermissionWrite }" />
                    </b-form-group>
                </b-col>
                <!-- nomItemCritere -->
                <b-col>
                    <b-form-group :label="$t('Value Item')" label-for="selectedCriteriaItemName">
                        <b-form-select v-model="selectedCriteriaItemName" :options="criteriaItems"
                            :state="selectedCriteriaItemNameState3"
                            :class="{ 'disabled': !$store.state.auth.user.permissions.PermissionWrite }" />
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <!-- alwaysExecute -->
                <b-col>
                    <b-form-checkbox v-model="alwaysExecute" :value="true" :unchecked-value="false" switch
                        :class="{ 'disabled': !$store.state.auth.user.permissions.PermissionWrite }">
                        {{ $t('triggers.unitaryNotification.alwaysExecute') }}
                    </b-form-checkbox>
                </b-col>
            </b-row>
        </div>

    </div>
</template>
<script>
import ProdComEquipmentService from "@/services/prodcom.equipments.service.js"
import ToastAlert from '@/utils/ToastAlert';
/**
 * The unitary notification: triggers with a single variable configured
 */
export default {
    props: {
        action: Object,
        propProject: String,
        equipment: String,
        /**
         * When true, forces the notification type to 'Criteria'
         * and bypasses 'Attribute' and 'Notification Type' choices.
         */
        onlyCriteria: Boolean,
    },
    name: 'UnitaryNotification',
    data() {
        return {
            selectedTriggerGroup: this.action.DeclencheurConfiguration.GroupName,
            triggerGroups: [],
            selectedTriggerItem: null,
            triggerItems: [],
            criteriaVariableItems: [],
            selectedCriteriaGroupComparison: null,
            selectedCriteriaVariableComparison: null,
            criteriaItems: [],
            selectedCriteriaItemGroup: null,
            selectedCriteriaItemName: null,
            haveDiffMin: this.action?.DeclencheurConfiguration?.HaveDiffMin ?? false,
            diffMin: this.action?.DeclencheurConfiguration?.DiffMin ?? 0,
            alwaysExecute: this.action?.DeclencheurConfiguration?.AlwaysExecute ?? false,
            Properties: [
                { value: 0, text: this.$t('Value') },
                { value: 1, text: this.$t('Value') + " N-1" },
                { value: 2, text: this.$t('min') },
                { value: 3, text: this.$t('max') },
                { value : 84, text: this.$t('Trend')}],
            NotificationTypes: [],
            selectedTriggerVariableType: null,
            selectedTriggerAttribute: this.action.DeclencheurConfiguration.VariableProperty ?? 0,
            selectedTriggerType: this.action.DeclencheurConfiguration.CriteriaType ?? 0,
            availableCriteriaComparisons: [
                { value: 0, text: '>' },
                { value: 1, text: '<' },
                { value: 2, text: '>=' },
                { value: 3, text: '<=' },
                { value: 4, text: '=' },
                { value: 5, text: '<>' },
            ],
            selectedCriteriaComparison: this.action?.DeclencheurConfiguration?.CriteriaComparison ?? 0,
            availableCompareTypes: [
                { value: 0, text: this.$t('triggers.unitaryNotification.compareType_0') },
                { value: 1, text: this.$t('triggers.unitaryNotification.compareType_1') }
            ],
            selectedCompareType: this.action?.DeclencheurConfiguration?.CriteriaSourceValue ?? 0,
            criteriaValue: this.action?.DeclencheurConfiguration?.CriteriaValue ?? 0,
            availableEdges: [
                { text: this.$t('Rising Edge'), value: 0 },
                { text: this.$t('Falling Edge'), value: 1 }
            ],
            selectedEdge: 0,
            availableLinks: [
                { value: 0, text: 'And' },
                { value: 1, text: 'Or' }
            ],
            selectedId: this.action?.id ?? 0,
            selectedLink: this.action?.DeclencheurConfiguration?.Link ?? 0,
            offset: this.action?.DeclencheurConfiguration?.Offset ?? 0,
            project: this.propProject,
        }
    },
    computed: {
        validState() {
            if (this.selectedTriggerType == 0 && this.haveDiffMin && (this.diffMin === null || this.diffMin === '')) return false;
            if ((this.selectedTriggerType == 2 || this.onlyCriteria) && this.selectedCompareType == 0 && (this.criteriaValue === null || this.criteriaValue === '')) return false;
            if ((this.selectedTriggerType == 2 || this.onlyCriteria) && this.selectedCompareType == 1 && (this.selectedCriteriaItemGroup == null || this.selectedCriteriaItemName == null)) return false;
            if (this.selectedTriggerType == 3 && (this.selectedCriteriaGroupComparison == null || this.selectedCriteriaVariableComparison == null || this.selectedCriteriaItemGroup == null || this.selectedCriteriaItemName == null)) return false;
            return this.selectedTriggerGroupState !== false && this.selectedTriggerItemState !== false;
        },
        selectedTriggerGroupState() {
            return this.selectedTriggerGroup == null ? false : null;
        },
        selectedTriggerItemState() {
            return this.selectedTriggerItem == null ? false : null;
        },
        criteriaValueState() {
            return (this.selectedTriggerType == 2 || this.onlyCriteria) && this.selectedCompareType == 0 && (this.criteriaValue === null || this.criteriaValue === '') ? false : null;
        },
        selectedCriteriaItemGroupState() {
            return (this.selectedTriggerType == 2 || this.onlyCriteria) && this.selectedCompareType == 1 && this.selectedCriteriaItemGroup == null ? false : null;
        },
        selectedCriteriaItemNameState() {
            return (this.selectedTriggerType == 2 || this.onlyCriteria) && this.selectedCompareType == 1 && this.selectedCriteriaItemName == null ? false : null;
        },
        deathBandState() {
            return this.selectedTriggerType == 0 && this.haveDiffMin && (this.diffMin === null || this.diffMin === '') ? false : null;
        },
        selectedCriteriaGroupComparisonState() {
            return this.selectedTriggerType == 3 && (this.selectedCriteriaGroupComparison == null) ? false : null;
        },
        selectedCriteriaVariableComparisonState() {
            return this.selectedTriggerType == 3 && (this.selectedCriteriaVariableComparison == null) ? false : null;
        },
        selectedCriteriaItemGroupState3() {
            return this.selectedTriggerType == 3 && (this.selectedCriteriaItemGroup == null) ? false : null;
        },
        selectedCriteriaItemNameState3() {
            return this.selectedTriggerType == 3 && (this.selectedCriteriaItemName == null) ? false : null;
        },
    },
    watch: {
        propProject(newVal, oldVal) {
            this.project = newVal;
        },
        /**
         * Update list of items when the selected group is changed
         */
        'selectedTriggerGroup': async function (val) {
            await this.getItemList();
        },
        'selectedCriteriaGroupComparison': async function (val) {
            await this.getCriteriaItems();
        },
        'selectedCriteriaItemGroup': async function (val) {
            await this.getCriteriaValueItems();
        },
        'selectedTriggerItem': async function (val) {
            if (val == null) return;
            if (this.onlyCriteria) {
                this.NotificationTypes = [{ value: 2, text: this.$t("Criteria") }];
                return;
            }
            this.selectedTriggerVariableType = val.Type;
            if (val.Type == "System.String") {
                this.NotificationTypes = [{ value: 0, text: this.$t("Value change") }]
            } else if (val.Type == "System.Boolean") {
                this.NotificationTypes = [{ value: 0, text: this.$t("Value change") }, { value: 1, text: this.$t("Comparison") }]
            } else {
                this.NotificationTypes = [{ value: 0, text: this.$t("Value change") }, { value: 2, text: this.$t("Criteria") }, { value: 3, text: this.$t("Criteria comparison") }]
            }
        },
    },
    async mounted() {
        // Get group list
        await this.getGroupList();
        await this.getItemList();
        if(this.selectedTriggerItem != null )this.configureArrayProperties();
    },
    methods: {
        async onItemChanged(){
            this.Properties= [
                { value: 0, text: this.$t('Value') },
                { value: 1, text: this.$t('Value') + " N-1" },
                { value: 2, text: this.$t('min') },
                { value: 3, text: this.$t('max') },
                { value : 84, text: this.$t('Trend')}];
                this.selectedTriggerAttribute= 0;
            if (!this.$store.state.auth.user.permissions.PermissionRead || this.selectedTriggerItem == "" || this.selectedTriggerItem == null) return;
            this.configureArrayProperties();
        },
        async getGroupList() {
            if (!this.$store.state.auth.user.permissions.PermissionRead) return;
            const data = await ProdComEquipmentService.getGroups(this.equipment, this.project);
            if (data.success == "n") this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(data.ret, data.retParams), 'warning'));
            else {
                this.triggerGroups = data.ret.List;
            }
        },

        /**
         * Gets the list of items available from the selected group.
         * Returns immediately if no group was selected.
         */
        async getItemList() {
            let ret = await this.getItems(this.selectedTriggerGroup, this.action.DeclencheurConfiguration.VariableName);
            if (ret == null) return;
            this.triggerItems = ret.result;
            this.selectedTriggerItem = ret.var;
            this.onItemChanged();
        },

        async getCriteriaItems() {
            let ret = await this.getItems(this.selectedCriteriaGroupComparison, this.action.DeclencheurConfiguration.CriteriaVariableComparison);
            if (ret == null) return;
            this.criteriaVariableItems = ret.result;
            this.selectedCriteriaVariableComparison = ret.var;
        },

        async getCriteriaValueItems() {
            let ret = await this.getItems(this.selectedCriteriaItemGroup, this.action.DeclencheurConfiguration.CriteriaItemName);
            if (ret == null) return;
            this.criteriaItems = ret.result;
            this.selectedCriteriaItemName = ret.var;
        },

        async getItems(groupName, existingItemName) {
            if (!this.$store.state.auth.user.permissions.PermissionRead) return;
            if (groupName == null || groupName == '') return;
            const data = await ProdComEquipmentService.getItems(this.equipment, groupName, this.project);
            if (data.success == "n") this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(data.ret, data.retParams), 'warning'));
            else {
                let result = [];
                let variableResult = null;
                for (const [key, val] of Object.entries(data.ret.List)) {
                    result.push({ value: val, text: val.Name })
                    if (val.Name == existingItemName) {
                        variableResult = val;
                    }
                }
                return { result: result, var: variableResult };
            }
        },

        async loadConfig(conf) {
            if (conf == null) return;
            this.criteriaValue = conf.CriteriaValue;
            this.selectedTriggerAttribute = conf.VariableProperty;
            this.selectedCriteriaComparison = conf.CriteriaComparison;
            this.selectedCompareType = conf.CriteriaSourceValue;
            this.selectedTriggerType = conf.CriteriaType;
            this.haveDiffMin = conf.HaveDiffMin;
            this.diffMin = conf.DiffMin;
            this.selectedTriggerGroup = conf.GroupName;
            this.selectedCriteriaGroupComparison = conf.CriteriaGroupComparison;
            this.selectedCriteriaItemGroup = conf.CriteriaItemGroup;
            this.alwaysExecute = conf.AlwaysExecute;
            this.selectedEdge = conf.Edge;
            this.selectedLink = conf.Link;
            this.offset = conf.Offset;
            await this.getGroupList();
            await this.getItemList();
            await this.getCriteriaItems();
            await this.getCriteriaValueItems();
        },

        async validate() {
            if (!this.validState) return null;
            return {
                CriteriaValue: this.criteriaValue ?? 0,
                VariableProperty: this.selectedTriggerAttribute ?? 0,
                CriteriaComparison: this.selectedCriteriaComparison ?? 0,
                CriteriaSourceValue: this.selectedCompareType ?? 0,
                CriteriaType: this.selectedTriggerType ?? 0,
                HaveDiffMin: this.haveDiffMin ?? false,
                DiffMin: this.diffMin ?? 0,
                GroupName: this.selectedTriggerGroup,
                VariableName: this.selectedTriggerItem?.Name,
                CriteriaGroupComparison: this.selectedCriteriaGroupComparison,
                CriteriaVariableComparison: this.selectedCriteriaVariableComparison?.Name,
                CriteriaItemName: this.selectedCriteriaItemName?.Name,
                CriteriaItemGroup: this.selectedCriteriaItemGroup,
                CriteriaGroupName: this.selectedCriteriaItemGroup,
                AlwaysExecute: this.alwaysExecute ?? false,
                Edge: this.selectedEdge ?? 0,
                Link: this.selectedLink ?? 0,
                Offset: this.offset ?? 0,
            }
        },
        async configureArrayProperties(){
            const data = await ProdComEquipmentService.getVariableDetails( this.equipment, this.project, this.selectedTriggerGroup, this.selectedTriggerItem.Name);
            if(data.success == "n") this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(data.ret, data.retParams), 'warning'));
            else{
                   if(data.ret.Event1 != null) {
                        this.Properties.push(
                            {value: 26, text: this.$t("Evenement1")},
                            {value: 4, text: this.$t("CompteurHoraire1")},
                            {value: 5, text: this.$t("CompteurHoraire1Cumulé")},
                            {value: 73, text: this.$t("Occurence1")},
                            {value: 39, text: this.$t("Evenement1N1")}
                        );
                    }
                    if(data.ret.Event2 != null) {
                        this.Properties.push(
                            {value: 27, text: this.$t("Evenement2")},
                            {value: 6, text: this.$t("CompteurHoraire2")},
                            {value: 7, text: this.$t("CompteurHoraire2Cumulé")},
                            {value: 74, text: this.$t("Occurence2")},
                            {value: 40, text: this.$t("Evenement2N1")}
                        );
                    }
                    if(data.ret.Event3 != null) {
                        this.Properties.push(
                            {value: 28, text: this.$t("Evenement3")},
                            {value: 8, text: this.$t("CompteurHoraire3")},
                            {value: 9, text: this.$t("CompteurHoraire3Cumulé")},
                            {value: 75, text: this.$t("Occurence3")},
                            {value: 41, text: this.$t("Evenement3N1")}
                        );
                    }
                    if(data.ret.Event4 != null) {
                        this.Properties.push(
                            {value: 29, text: this.$t("Evenement4")},
                            {value: 10, text: this.$t("CompteurHoraire4")},
                            {value: 11, text: this.$t("CompteurHoraire4Cumulé")},
                            {value: 76, text: this.$t("Occurence4")},
                            {value: 42, text: this.$t("Evenement4N1")}
                        );
                    }
                    if(data.ret.Event5 != null) {
                        this.Properties.push(
                            {value: 30, text: this.$t("Evenement5")},
                            {value: 12, text: this.$t("CompteurHoraire5")},
                            {value: 13, text: this.$t("CompteurHoraire5Cumulé")},
                            {value: 77, text: this.$t("Occurence5")},
                            {value: 43, text: this.$t("Evenement5N1")}
                        );
                    }
                    if(data.ret.Event6 != null) {
                        this.Properties.push(
                            {value: 31, text: this.$t("Evenement6")},
                            {value: 14, text: this.$t("CompteurHoraire6")},
                            {value: 15, text: this.$t("CompteurHoraire6Cumulé")},
                            {value: 78, text: this.$t("Occurence6")},
                            {value: 44, text: this.$t("Evenement6N1")}
                        );
                    }
                    if(data.ret.Event7 != null) {
                        this.Properties.push(
                            {value: 32, text: this.$t("Evenement7")},
                            {value: 16, text: this.$t("CompteurHoraire7")},
                            {value: 17, text: this.$t("CompteurHoraire7Cumulé")},
                            {value: 79, text: this.$t("Occurence7")},
                            {value: 45, text: this.$t("Evenement7N1")}
                        );
                    }
                    if(data.ret.Event8 != null) {
                        this.Properties.push(
                            {value: 33, text: this.$t("Evenement8")},
                            {value: 18, text: this.$t("CompteurHoraire8")},
                            {value: 19, text: this.$t("CompteurHoraire8Cumulé")},
                            {value: 80, text: this.$t("Occurence8")},
                            {value: 46, text: this.$t("Evenement8N1")}
                        );
                    }
                    if(data.ret.Event9 != null) {
                        this.Properties.push(
                            {value: 34, text: this.$t("Evenement9")},
                            {value: 20, text: this.$t("CompteurHoraire9")},
                            {value: 21, text: this.$t("CompteurHoraire9Cumulé")},
                            {value: 81, text: this.$t("Occurence9")},
                            {value: 47, text: this.$t("Evenement9N1")}
                        );
                    }
                    if(data.ret.Event10 != null) {
                        this.Properties.push(
                            {value: 35, text: this.$t("Evenement10")},
                            {value: 22, text: this.$t("CompteurHoraire10")},
                            {value: 23, text: this.$t("CompteurHoraire10Cumulé")},
                            {value: 82, text: this.$t("Occurence10")},
                            {value: 48, text: this.$t("Evenement10N1")}
                        );
                    }
                    if(data.ret.MaxNorm) {
                        this.Properties.push(
                            {value: 58, text: this.$t("ALMax")},
                            {value: 60, text: this.$t("ALMaxN1")},
                            {value: 36, text: this.$t("HorsMaximum")},
                            {value: 49, text: this.$t("HorsMaximumN1")}
                        );
                    }
                    if(data.ret.MinNorm) {
                        this.Properties.push(
                            {value: 57, text: this.$t("ALMin")},
                            {value: 59, text: this.$t("ALMinN1")},
                            {value: 37, text: this.$t("HorsMinimum")},
                            {value: 50, text: this.$t("HorsMinimumN1")}
                        );
                    }

            }
        }
    }
}
</script>
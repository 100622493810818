<template>
    <div class="mb-3">
        <div>
            <b-button size="sm" squared v-b-toggle.period-filter class="mb-2 d-inline-block full-width px-0 py-1">{{ currentFilterLabel }}</b-button>
        </div>
        <div class="text-center small mb-2" v-if="currentFilterDatesLabel != ''">{{ currentFilterDatesLabel }}</div>
        <b-collapse id="period-filter" class="full-width">
            <div class="d-inline-block border rounded pt-2 px-2 pb-0 full-width">
                <b-form-select :options="filterTypes" v-model="filterType" v-on:change="filterDelta = 0" />
                <DxDateBox type="datetime" :show-analog-clock="false" :show-clear-button="true" :max="endDate" class="small my-2" v-model="startDate" v-on:value-changed="datesChanged" />
                <DxDateBox type="datetime" :show-analog-clock="false" :show-clear-button="true" :min="startDate" class="small mb-3" v-model="endDate" v-on:value-changed="datesChanged" />
                <b-row class="mx-0 mb-2">
                    <b-col class="text-center"
                        ><b-button :disabled="filterType == 'custom'" size="sm" v-on:click="filterDelta--"><font-awesome-icon icon="step-backward" /></b-button
                    ></b-col>
                    <b-col class="text-center"
                        ><b-button :disabled="filterType == 'custom'" size="sm" v-on:click="filterDelta = 0"><font-awesome-icon icon="equals" /></b-button
                    ></b-col>
                    <b-col class="text-center"
                        ><b-button :disabled="filterType == 'custom'" size="sm" v-on:click="filterDelta++"><font-awesome-icon icon="step-forward" /></b-button
                    ></b-col>
                </b-row>
                <b-form-checkbox :disabled="filterType == 'custom'" class="mt-2 mb-1 text-left" v-model="slipperyPeriod">{{ $t('Slippery period') }}</b-form-checkbox>
                <b-form-checkbox class="mt-2 mb-1 text-left" v-model="autoApply">{{ $t('Auto apply') }}</b-form-checkbox>
            </div>
        </b-collapse>
    </div>
</template>
<script>
import { DxDateBox } from 'devextreme-vue/date-box';
export default {
    name: 'PeriodFilter',
    components: {
        DxDateBox,
    },

    data() {
        return {
            filterTypes: [
                { value: 'day', text: this.$t('day') },
                { value: 'week', text: this.$t('week') },
                { value: 'month', text: this.$t('month') },
                { value: 'quarter', text: this.$t('quarter') },
                { value: 'semester', text: this.$t('semester') },
                { value: 'year', text: this.$t('year') },
                { value: 'custom', text: this.$t('custom') },
            ],
            startDate: null,
            endDate: null,
            filterType: null,
            filterDelta: 0,
            slipperyPeriod: false,
            autoApply: true,
            periodTimer: null,
        };
    },
    watch: {
        filterType(val) {
            this.calculateDates(val);
            this.storeCurrentOptions();
        },
        filterDelta() {
            this.calculateDates(this.filterType);
            this.storeCurrentOptions();
        },
        slipperyPeriod(val) {
            this.calculateDates(this.filterType);
            this.storeCurrentOptions();
        },
        startDate() {
            this.$store.commit('updatePeriodFilter', { StartDate: this.startDate, EndDate: this.endDate, autoApply: this.autoApply, periodFilterLabel: this.currentFilterLabel });
            this.storeCurrentOptions();
        },
        endDate() {
            this.$store.commit('updatePeriodFilter', { StartDate: this.startDate, EndDate: this.endDate, autoApply: this.autoApply, periodFilterLabel: this.currentFilterLabel });
            this.storeCurrentOptions();
        },
        autoApply() {
            this.$store.commit('updatePeriodFilter', { StartDate: this.startDate, EndDate: this.endDate, autoApply: this.autoApply, periodFilterLabel: this.currentFilterLabel });
            this.storeCurrentOptions();
        },
    },
    mounted() {
        // create timer for period refresh
        if (this.periodTimer == null)
            this.periodTimer = setInterval(() => {
                this.calculateDates(this.filterType);
            }, 30000);

        var periodFilterOptions = localStorage.getItem('periodFilterOptions');
        if (periodFilterOptions) {
            periodFilterOptions = JSON.parse(periodFilterOptions);
            if (periodFilterOptions.filterType == 'custom') {
                this.slipperyPeriod = periodFilterOptions.slipperyPeriod;
                this.startDate = periodFilterOptions.startDate ? new Date(periodFilterOptions.startDate) : null;
                this.endDate = periodFilterOptions.endDate ? new Date(periodFilterOptions.endDate) : null;
                this.filterType = 'custom';
                this.autoApply = periodFilterOptions.autoApply;
            } else {
                this.slipperyPeriod = periodFilterOptions.slipperyPeriod;
                this.filterType = periodFilterOptions.filterType;
                this.filterDelta = periodFilterOptions.filterDelta;
                this.autoApply = periodFilterOptions.autoApply;
            }
        } else {
            this.filterType = 'month';
        }
    },
    unmounted() {
        // stop timer if exists
        if (this.periodTimer != null) clearInterval(this.periodTimer);
    },
    computed: {
        currentFilterDatesLabel() {
            if (this.filterType == 'custom') return '';
            if ((!this.startDate && !this.endDate) || (this.startDate == null && this.endDate == null)) return '';
            if (!this.startDate || this.startDate == null) return this.$t('Until') + ' ' + this.endDate.toLocaleDateString(this.$i18n.locale) + ' ' + this.endDate.toLocaleTimeString(this.$i18n.locale, { timeStyle: 'short' });
            if (!this.endDate || this.endDate == null) return this.$t('Since') + ' ' + this.startDate.toLocaleDateString(this.$i18n.locale) + ' ' + this.startDate.toLocaleTimeString(this.$i18n.locale, { timeStyle: 'short' });
            return (
                this.startDate.toLocaleDateString(this.$i18n.locale) + ' ' + this.startDate.toLocaleTimeString(this.$i18n.locale, { timeStyle: 'short' }) + ' - ' + this.endDate.toLocaleDateString(this.$i18n.locale) + ' ' + this.endDate.toLocaleTimeString(this.$i18n.locale, { timeStyle: 'short' })
            );
        },
        currentFilterLabel() {
            if (this.filterType == 'custom') {
                if ((!this.startDate && !this.endDate) || (this.startDate == null && this.endDate == null)) return this.$t('No filter');
                if (!this.startDate || this.startDate == null) return this.$t('Until') + ' ' + this.endDate.toLocaleDateString(this.$i18n.locale) + ' ' + this.endDate.toLocaleTimeString(this.$i18n.locale, { timeStyle: 'short' });
                if (!this.endDate || this.endDate == null) return this.$t('Since') + ' ' + this.startDate.toLocaleDateString(this.$i18n.locale) + ' ' + this.startDate.toLocaleTimeString(this.$i18n.locale, { timeStyle: 'short' });
                return (
                    this.startDate.toLocaleDateString(this.$i18n.locale) +
                    ' ' +
                    this.startDate.toLocaleTimeString(this.$i18n.locale, { timeStyle: 'short' }) +
                    ' - ' +
                    this.endDate.toLocaleDateString(this.$i18n.locale) +
                    ' ' +
                    this.endDate.toLocaleTimeString(this.$i18n.locale, { timeStyle: 'short' })
                );
            } else {
                if (this.slipperyPeriod && this.filterDelta < 0) {
                    if (this.filterType == 'year') return this.$t('Since') + ' ' + (Math.abs(this.filterDelta) + 1) + ' ' + this.$t('since years');
                    else return this.$t('Since') + ' ' + (Math.abs(this.filterDelta) + 1) + ' ' + this.$t(this.filterType + 's');
                }
                if (this.slipperyPeriod && this.filterDelta == 1) {
                    if (this.filterType == 'week' || this.filterType == 'year') return this.$t(this.filterType) + ' ' + this.$t('Nexte');
                    return this.$t(this.filterType) + ' ' + this.$t('Next');
                }
                if (this.slipperyPeriod && this.filterDelta > 1) {
                    if (this.filterType == 'week' || this.filterType == 'year') return this.filterDelta + ' ' + this.$t('Nextse') + ' ' + this.$t(this.filterType + 's');
                    return this.filterDelta + ' ' + this.$t('Nexts') + ' ' + this.$t(this.filterType + 's');
                }
                if (this.slipperyPeriod && this.filterDelta == 0) {
                    if (this.filterType == 'week' || this.filterType == 'year') return this.$t('Last {period}e', { period: this.$t(this.filterType) });
                    return this.$t('Last {period}', { period: this.$t(this.filterType) });
                }
                if (this.filterType == 'day' && this.filterDelta == 0) return this.$t('Today');
                if (this.filterDelta == 0) {
                    if (this.$i18n.locale == 'en')
                        return this.$t('Current') + ' ' + this.$t(this.filterType);
                    else
                        return this.$t(this.filterType) + ' ' + this.$t('in progress');
                }

                return this.$t('short_' + this.filterType) + (this.filterDelta > 0 ? '+' : '') + this.filterDelta;
            }
        },
    },
    methods: {
        storeCurrentOptions() {
            localStorage.setItem(
                'periodFilterOptions',
                JSON.stringify({
                    startDate: this.startDate,
                    endDate: this.endDate,
                    filterType: this.filterType,
                    filterDelta: this.filterDelta,
                    slipperyPeriod: this.slipperyPeriod,
                    autoApply: this.autoApply,
                }),
            );
        },
        datesChanged(e) {
            if (e.event && (e.event.type == 'dxclick' || e.event.type == 'change')) this.filterType = 'custom';
        },
        calculateDates(val) {
            switch (val) {
                case 'day':
                    if (this.slipperyPeriod && this.filterDelta == 0) {
                        var start = new Date();
                        start.setDate(new Date().getDate() - 1);
                        this.startDate = start;
                        this.endDate = new Date();
                    } else if (this.slipperyPeriod && this.filterDelta > 0) {
                        this.startDate = new Date();
                        var end = new Date();
                        end.setDate(new Date().getDate() + this.filterDelta);
                        this.endDate = end;
                    } else if (this.slipperyPeriod && this.filterDelta < 0) {
                        this.endDate = new Date();
                        var start = new Date();
                        start.setDate(new Date().getDate() - 1 + this.filterDelta);
                        this.startDate = start;
                    } else {
                        this.startDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + this.filterDelta, 0, 0, 0);
                        this.endDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + this.filterDelta, 23, 59, 59, 999);
                    }
                    break;
                case 'week':
                    if (this.slipperyPeriod && this.filterDelta == 0) {
                        var start = new Date();
                        start.setDate(new Date().getDate() - 7);
                        this.startDate = start;
                        this.endDate = new Date();
                    } else if (this.slipperyPeriod && this.filterDelta > 0) {
                        this.startDate = new Date();
                        var end = new Date();
                        end.setDate(new Date().getDate() + 7 * this.filterDelta);
                        this.endDate = end;
                    } else if (this.slipperyPeriod && this.filterDelta < 0) {
                        this.endDate = new Date();
                        var start = new Date();
                        start.setDate(new Date().getDate() - 7 + 7 * this.filterDelta);
                        this.startDate = start;
                    } else {
                        var date = new Date();
                        date.setDate(new Date().getDate() + 7 * this.filterDelta);
                        this.startDate = date.getStartOfCurrentWeek();
                        this.endDate = date.getEndOfCurrentWeek();
                    }
                    break;
                case 'month':
                    if (this.slipperyPeriod && this.filterDelta == 0) {
                        var start = new Date();
                        start.setMonth(new Date().getMonth() - 1);
                        this.startDate = start;
                        this.endDate = new Date();
                    } else if (this.slipperyPeriod && this.filterDelta > 0) {
                        this.startDate = new Date();
                        var end = new Date();
                        end.setMonth(new Date().getMonth() + this.filterDelta);
                        this.endDate = end;
                    } else if (this.slipperyPeriod && this.filterDelta < 0) {
                        this.endDate = new Date();
                        var start = new Date();
                        start.setMonth(new Date().getMonth() - 1 + this.filterDelta);
                        this.startDate = start;
                    } else {
                        var date = new Date();
                        date.setMonth(date.getMonth() + this.filterDelta);
                        this.startDate = date.getFirstDayOfCurrentMonth();
                        this.endDate = date.getLastDayOfCurrentMonth();
                    }
                    break;
                case 'quarter':
                    if (this.slipperyPeriod && this.filterDelta == 0) {
                        var start = new Date();
                        start.setMonth(new Date().getMonth() - 3);
                        this.startDate = start;
                        this.endDate = new Date();
                    } else if (this.slipperyPeriod && this.filterDelta > 0) {
                        this.startDate = new Date();
                        var end = new Date();
                        end.setMonth(new Date().getMonth() + this.filterDelta * 3);
                        this.endDate = end;
                    } else if (this.slipperyPeriod && this.filterDelta < 0) {
                        this.endDate = new Date();
                        var start = new Date();
                        start.setMonth(new Date().getMonth() - 3 + this.filterDelta * 3);
                        this.startDate = start;
                    } else {
                        var date = new Date();
                        date.setMonth(new Date().getMonth() + this.filterDelta * 3);
                        this.startDate = date.getFirstDayOfCurrentQuarter();
                        this.endDate = date.getLastDayOfCurrentQuarter();
                    }
                    break;
                case 'semester':
                    if (this.slipperyPeriod && this.filterDelta == 0) {
                        var start = new Date();
                        start.setMonth(new Date().getMonth() - 6);
                        this.startDate = start;
                        this.endDate = new Date();
                    } else if (this.slipperyPeriod && this.filterDelta > 0) {
                        this.startDate = new Date();
                        var end = new Date();
                        end.setMonth(new Date().getMonth() + this.filterDelta * 6);
                        this.endDate = end;
                    } else if (this.slipperyPeriod && this.filterDelta < 0) {
                        this.endDate = new Date();
                        var start = new Date();
                        start.setMonth(new Date().getMonth() - 6 + this.filterDelta * 6);
                        this.startDate = start;
                    } else {
                        var date = new Date();
                        date.setMonth(new Date().getMonth() + this.filterDelta * 6);
                        this.startDate = date.getFirstDayOfCurrentSemester();
                        this.endDate = date.getLastDayOfCurrentSemester();
                    }
                    break;
                case 'year':
                    if (this.slipperyPeriod && this.filterDelta == 0) {
                        var start = new Date();
                        start.setFullYear(new Date().getFullYear() - 1);
                        this.startDate = start;
                        this.endDate = new Date();
                    } else if (this.slipperyPeriod && this.filterDelta > 0) {
                        this.startDate = new Date();
                        var end = new Date();
                        end.setFullYear(new Date().getFullYear() + this.filterDelta);
                        this.endDate = end;
                    } else if (this.slipperyPeriod && this.filterDelta < 0) {
                        this.endDate = new Date();
                        var start = new Date();
                        start.setFullYear(new Date().getFullYear() - 1 + this.filterDelta);
                        this.startDate = start;
                    } else {
                        var date = new Date();
                        date.setFullYear(new Date().getFullYear() + this.filterDelta);
                        this.startDate = date.getFirstDayOfCurrentYear();
                        this.endDate = date.getLastDayOfCurrentYear();
                    }
                    break;
                default:
                    break;
            }
        },
    },
};
</script>
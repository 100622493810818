<template>
    <b-row>
        <b-col cols="4">
            <b-list-group>
                <div class="text-center pb-3">
                    <font-awesome-icon icon="plus" class="cursor-pointer" v-on:click="createNewMessage" v-bind:alt="$t('Add message')" v-bind:title="$t('Add message')" />
                    <font-awesome-icon icon="check" class="cursor-pointer ml-3" v-if="!saving && this.currentMessage.Id != null" v-on:click="saveMessage" v-bind:alt="$t('Save')" v-bind:title="$t('Save')" />
                    <font-awesome-icon icon="sync" class="cursor-pointer ml-3" spin v-if="saving" />
                    <font-awesome-icon icon="minus" class="cursor-pointer ml-3" v-if="currentMessage && currentMessage.Id != null && currentMessage.Id != 0" v-on:click="removeMessage" v-bind:alt="$t('Remove')" v-bind:title="$t('Remove')" />
                </div>
                <b-list-group-item
                    style="overflow: hidden; white-space: nowrap; text-overflow: ellipsis"
                    v-for="message in messages"
                    :key="'message' + message.Id"
                    :active="currentMessage && message.Id == currentMessage.Id"
                    v-on:click="selectMessage(message.Id)"
                    button
                    v-bind:class="{ 'font-italic': message.Id == 0 }"
                    >{{ messageLabel(message) }}</b-list-group-item
                >
            </b-list-group>
        </b-col>
        <b-col cols="8">
            <b-card no-body v-if="currentMessage.Id != null" class="p-3">
                <b-form>
                    <b-row>
                        <b-col>
                            <b-form-group :label="$t('Text') + ' *'" label-for="currentMessage_Text">
                                <b-form-input class="sm" ref="currentMessage_Text" id="currentMessage_Text" v-model="currentMessage.Text" required maxlength="160"></b-form-input>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <b-form-group :label="$t('Order') + ' *'" label-for="currentMessage_order">
                                <b-form-input type="number" id="currentMessage_order" v-model="currentMessage.Order" min="1" max="65565" step="1" required></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col>
                            <b-form-group :label="$t('Duration') + ' *'" label-for="currentMessage_duration">
                                <b-form-input type="number" id="currentMessage_duration" v-model="currentMessage.Duration" min="1" max="3600" step="1" required></b-form-input>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </b-form>
                <FormErrors :propFormErrors="formErrors" />
            </b-card>
        </b-col>
    </b-row>
</template>
<script>
import MessageService from '@/services/message.service.js';
import ToastAlert from '@/utils/ToastAlert';
import FormErrors from '@/components/FormErrors.vue';

export default {
    Text: 'MessageTabPanel',
    components: {
        ToastAlert,
        FormErrors,
    },
    data() {
        return {
            saving: false,
            messages: [],
            currentMessage: {
                Id: null,
                LanguageCode: this.$i18n.locale,
                Text: null,
                Duration: null,
                Order: null,
            },
            formErrors: [],
        };
    },
    async mounted() {
        await this.refresh('MessageTabPanel');
        this.$EventBus.$on('refresh-admin-panel-tab', this.refresh);
    },
    methods: {
        checkFormValidity() {
            this.formErrors = [];
            if (!this.currentMessage.Text || !this.currentMessage.Duration || !this.currentMessage.Order) this.formErrors.push(this.$t('Check required fields'));
            return this.formErrors.length == 0;
        },
        async refresh(tabRef) {
            if (tabRef == 'MessageTabPanel') {
                this.formErrors = [];
                this.currentMessage = {
                    Id: null,
                    LanguageCode: this.$i18n.locale,
                    Text: null,
                    Duration: null,
                    Order: null,
                };
                await this.getMessages();
            }
        },
        getMaxOrder() {
            return Math.max(...this.messages.map((m) => m.Order), 0);
        },
        reorderMessages() {
            this.messages = this.messages.sort((a, b) => a.Order - b.Order);
        },
        messageLabel(message) {
            if (message.Id != 0 && message.Text != '' && message.Text != null) return message.Text;
            else if (message.Id == 0) return this.$t('New message');
            else return this.$t('Please enter Text');
        },
        async createNewMessage() {
            if (this.messages.findIndex((message) => message.Id == 0) == -1) {
                this.formErrors = [];
                this.messages.unshift({
                    Id: 0,
                    LanguageCode: this.$i18n.locale,
                    Text: this.$t('New message'),
                    Duration: 10,
                    Order: this.getMaxOrder() + 1,
                });
                await this.selectMessage(0);
                await this.$refs.currentMessage_Text.focus();
                this.$refs.currentMessage_Text.select();
            }
        },
        async getMessages() {
            const data = await MessageService.getAllMessages(this.$i18n.locale);
            if (data.success == 'n') this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(data.ret, data.retParams), 'warning'));
            else this.messages = data.ret ? data.ret : [];
            this.$emit('message-count', Array.isArray(this.messages) ? this.messages.filter((message) => message.Id != 0).length : 0);
        },
        async selectMessage(id) {
            this.formErrors = [];
            this.currentMessage = this.messages.find((message) => message.Id == id);
        },
        async saveMessage() {
            if (!this.checkFormValidity()) return false;
            this.saving = true;
            let settings = null;
            if (this.currentMessage.Id == 0) {
                // New message
                settings = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        Method: 'messages.create',
                        Token: this.$store.state.auth.user.accessToken,
                        Parameters: {
                            LanguageCode: this.currentMessage.LanguageCode,
                            Text: this.currentMessage.Text,
                            Duration: this.currentMessage.Duration,
                            Order: this.currentMessage.Order,
                        },
                    }),
                };
            } else {
                // Update message
                settings = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        Method: 'messages.update',
                        Token: this.$store.state.auth.user.accessToken,
                        Parameters: {
                            Id: this.currentMessage.Id,
                            LanguageCode: this.currentMessage.LanguageCode,
                            Text: this.currentMessage.Text,
                            Duration: this.currentMessage.Duration,
                            Order: this.currentMessage.Order,
                        },
                    }),
                };
            }

            const res = await fetch(this.$store.state.settings.API_URL, settings);
            const data = await res.json();

            if (data.success == 'n') {
                this.saving = false;
                this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(data.ret, data.retParams), 'warning'));
            } else if (this.currentMessage.Id == 0) {
                await this.refresh('MessageTabPanel');
            } else {
                await this.reorderMessages();
            }
            this.saving = false;
            this.$emit('message-count', this.messages.filter((message) => message.Id != 0).length);
            this.$EventBus.$emit('refresh-messages');
        },
        async removeMessage(id) {
            const value = await this.$bvModal.msgBoxConfirm(this.$t('Are you sure ?'), {
                okTitle: this.$t('yes'),
                cancelTitle: this.$t('no'),
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true,
                buttonSize: 'sm',
            });
            if (value) {
                const settings = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        Method: 'messages.delete',
                        Token: this.$store.state.auth.user.accessToken,
                        Parameters: {
                            Id: this.currentMessage.Id,
                        },
                    }),
                };
                const res = await fetch(this.$store.state.settings.API_URL, settings);
                const data = await res.json();

                if (data.success == 'n') this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(data.ret, data.retParams), 'warning'));
                else {
                    this.currentMessage = {
                        Id: null,
                        LanguageCode: this.$i18n.locale,
                        Text: null,
                        Duration: null,
                        Order: null,
                    };
                    await this.refresh('MessageTabPanel');
                    this.$EventBus.$emit('refresh-messages');
                }
            }
        },
    },
};
</script>
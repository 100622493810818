<template>
    <div>
        <!-- <b-modal id="bv-modal-ask-license" v-if="minLevelRole <= $RoleLevels.Admin" :title="$t('Ask license')" ok-only centered size="lg" hide-footer no-close-on-esc no-close-on-backdrop> -->
        <b-modal id="bv-modal-ask-license" :title="$t('Ask license')" ok-only centered size="lg" hide-footer no-close-on-esc no-close-on-backdrop>
            <b-form v-on:submit="onSubmitAskLicense">
                <b-row>
                    <b-col>
                        <b-form-group :label="$t('License type') + ' *'" label-for="askLicense_Mode">
                            <b-form-select :disabled="saving || loading" class="sm" ref="askLicense_Mode" id="askLicense_Mode" v-model="askLicenseInformations.Mode" required>
                                <option v-for="(mode, idx) in availableLicenseModes" :key="'licenseMode' + idx" :value="mode.Name" :title="$t($LicenseModesLabel[mode.Name])">
                                    {{ `${$t($LicenseModesLabel[mode.Name])} ${mode.DurationLimit > 0 ? `(${mode.DurationLimit == 366 ? mode.DurationLimit - 1 : mode.DurationLimit} ${$t('DaysShort')})`: ''}` }}
                                </option>
                            </b-form-select>
                        </b-form-group>
                    </b-col>
                    <b-col>
                        <b-form-group :label="$t('Options') + ' *'" label-for="askLicense_Options">
                            <b-row>
                                <b-col sm="4" v-for="(option, idx) in availableLicenseOptions" :key="'licenseOption' + idx">
                                    <b-form-checkbox v-on:change="updateSelectedOptions(idx)" :checked="askLicenseInformations.Options[idx]">{{ $t(option.Name) }}</b-form-checkbox>
                                </b-col>
                            </b-row>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <b-form-group :label="$t('Company') + ' *'" label-for="askLicense_Company">
                            <b-form-input :maxlength="$store.state.settings.INPUT_NAME_MAX_LENGTH" :disabled="saving || loading" class="sm" ref="askLicense_Company" id="askLicense_Company" v-model="askLicenseInformations.Company" required></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col>
                        <b-form-group :label="$t('LicenseName') + ' *'" label-for="askLicense_Name">
                            <b-form-input :maxlength="$store.state.settings.INPUT_NAME_MAX_LENGTH" :disabled="saving || loading" class="sm" ref="askLicense_Name" id="askLicense_Name" v-model="askLicenseInformations.Name" required></b-form-input>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <b-form-group :label="$t('Email') + ' *'" label-for="askLicense_EMail">
                            <b-form-input :maxlength="$store.state.settings.INPUT_EMAIL_MAX_LENGTH" :disabled="saving || loading" type="email" class="sm" ref="askLicense_EMail" id="askLicense_EMail" v-model="askLicenseInformations.EMail" required></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col>
                        <b-form-group :label="$t('Phone') + ' *'" label-for="askLicense_Phone">
                            <b-form-input :maxlength="$store.state.settings.INPUT_NAME_MAX_LENGTH" :disabled="saving || loading" type="tel" class="sm" ref="askLicense_Phone" id="askLicense_Phone" v-model="askLicenseInformations.Phone" required></b-form-input>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <b-form-group :label="$t('Address')" label-for="askLicense_Address">
                            <b-form-textarea rows="3" :disabled="saving || loading" class="sm" ref="askLicense_Address" id="askLicense_Address" v-model="askLicenseInformations.Address"></b-form-textarea>
                        </b-form-group>
                    </b-col>
                </b-row>
                <FormErrors :propFormErrors="formErrors" />
                <hr />
                <div class="text-right">
                    <b-button type="submit" variant="primary" class="mr-3">{{ $t('Ok') }}</b-button>
                    <b-button type="button" v-on:click="$bvModal.hide('bv-modal-ask-license')">{{ $t('Close') }}</b-button>
                </div>
            </b-form>
        </b-modal>
        <b-modal id="bv-modal-about-box" :title="$store.state.settings.PRODUCT_FULL_NAME" ok-only centered>
            <div style="min-height: 325px">
                <b-tabs>
                    <b-tab :title="$t('Current license')" class="pt-3">
                        <b-row class="mb-1">
                            <b-col class="font-bold" cols="8"> {{ $t('License type') }} </b-col>
                            <b-col> {{ $t($LicenseModesLabel[$store.state.settings.LICENSE.Mode]) }} </b-col>
                        </b-row>
                        <b-row class="mb-1" v-if="LICENSE.DurationLimit > 0">
                            <b-col class="font-bold" cols="8"> {{ $t('Time limit') }} </b-col>
                            <b-col> {{ LICENSE.DurationLimit }} {{ $t('DaysShort') }} </b-col>
                        </b-row>
                        <b-row class="mb-1" v-if="LICENSE.DurationLimit > 0">
                            <b-col class="font-bold" cols="8"> {{ $t('Expiration') }} </b-col>
                            <b-col v-bind:class="{ 'text-red': (new Date(LICENSE.Expiration).getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24) <= 30 }"> {{ new Date(LICENSE.Expiration).toLocaleDateString() }} </b-col>
                        </b-row>
                        <b-row class="mb-1 mt-3">
                            <b-col class="font-bold"> {{ $t('Options') }} </b-col>
                        </b-row>
                        <b-row v-for="optionKey in 4" :key="optionKey">
                            <b-col class="font-bold" cols="8">
                                <span class="ml-3" v-bind:class="{ disabled: !LICENSE.Options[optionKey - 1] }">{{ $t($LicenseOptionsLabel[optionKey - 1]) }}</span>
                            </b-col>
                            <b-col>
                                <font-awesome-icon icon="check" v-if="LICENSE.Options[optionKey - 1]" />
                                <font-awesome-icon icon="times" class="disabled" v-else />
                            </b-col>
                        </b-row>
                        <!-- <b-row v-if="minLevelRole <= $RoleLevels.Admin"> -->
                        <b-row >
                            <b-col class="text-center mt-5 border-top pt-3">
                                <b-button size="sm" v-b-modal.bv-modal-ask-license>{{ $t('Ask license') }}</b-button>
                            </b-col>
                            <b-col class="text-center mt-5 border-top pt-3">
                                <b-button size="sm" v-on:click="openLicFile">{{ $t('Register product') }}</b-button>
                                <b-form-file ref="licFileUpload" class="licFileUpload" placeholder="..." :disabled="loading" v-model="licFile" accept=".lic" :browse-text="$t('Register product')"></b-form-file>
                            </b-col>
                        </b-row>
                    </b-tab>
                    <b-tab :title="$t('Customer informations')" class="pt-3" v-if="LICENSE.Company">
                        <b-row class="mb-2">
                            <b-col sm="4" class="font-bold"> {{ $t('Company') }} </b-col>
                            <b-col sm="8"> {{ LICENSE.Company }} </b-col>
                        </b-row>
                        <b-row class="mb-2">
                            <b-col sm="4" class="font-bold"> {{ $t('LicenseName') }} </b-col>
                            <b-col sm="8"> {{ LICENSE.Name }} </b-col>
                        </b-row>
                        <b-row class="mb-2">
                            <b-col sm="4" class="font-bold"> {{ $t('Email') }} </b-col>
                            <b-col sm="8"> {{ LICENSE.EMail }} </b-col>
                        </b-row>
                        <b-row class="mb-2">
                            <b-col sm="4" class="font-bold"> {{ $t('Phone') }} </b-col>
                            <b-col sm="8"> {{ LICENSE.Phone }} </b-col>
                        </b-row>
                        <b-row>
                            <b-col sm="4" class="font-bold"> {{ $t('Address') }} </b-col>
                            <b-col sm="8"> {{ LICENSE.Address }} </b-col>
                        </b-row>
                    </b-tab>
                    <b-tab :title="$t('Support')" class="pt-3">
                        <b-row class="mb-2">
                            <b-col sm="4" class="font-bold"> {{ $t('Website') }} </b-col>
                            <b-col sm="8" clas="text-left">
                                <a :href="$t('support_website')" target="_blank">{{ $t('support_website') }}</a>
                            </b-col>
                        </b-row>
                        <b-row class="mb-2">
                            <b-col sm="4" class="font-bold"> {{ $t('Email') }} </b-col>
                            <b-col sm="8" clas="text-left">
                                <a :href="'mailto:' + $t('support_email')">{{ $t('support_email') }}</a>
                            </b-col>
                        </b-row>
                        <b-row class="mb-2">
                            <b-col sm="4" class="font-bold"> {{ $t('Phone') }} </b-col>
                            <b-col sm="8" clas="text-left">
                                <a :href="'tel:' + $t('support_phone')">{{ $t('support_phone') }}</a>
                            </b-col>
                        </b-row>
                    </b-tab>
                </b-tabs>
            </div>
        </b-modal>
    </div>
</template>
<script>
import FormErrors from '@/components/FormErrors.vue';
import LicenseService from '@/services/license.service.js';
import ToastAlert from '@/utils/ToastAlert';

export default {
    name: 'AboutBox',
    components: {
        FormErrors,
    },
    data() {
        return {
            licFile: null,
            saving: false,
            availableLicenseModes: [],
            availableLicenseOptions: [],
            askLicenseInformations: {
                Company: null,
                Name: null,
                EMail: null,
                Phone: null,
                Address: null,
                Mode: 0,
                Options: [true, true, true],
            },
            formErrors: [],
            loading: false,
        };
    },
    async mounted() {
        // License information init
        var data = await LicenseService.getAvailableModes();
        if (data.success == 'n') this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(data.ret, data.retParams), 'warning'));
        else this.availableLicenseModes = data.ret;
        data = await LicenseService.getAvailableOptions();
        if (data.success == 'n') this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(data.ret, data.retParams), 'warning'));
        else this.availableLicenseOptions = data.ret;
        this.askLicenseInformations.EMail = this.$store.state.auth.user.email;
    },
    watch: {
        licFile: function (val) {
            if (val) {
                this.loading = true;
                const fileReader = new FileReader();
                fileReader.onload = async (e) => {
                    const data = await LicenseService.uploadLicense(e.target.result);
                    if (data.success == 'n') {
                        this.licFile = null;
                        this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(data.ret, data.retParams), 'warning'));
                        this.loading = false;
                    } else {
                        const data = await LicenseService.getCurrentLicense();
                        if (data.success == 'n') this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(data.ret, data.retParams), 'warning'));
                        else {
                            await this.$store.commit('setCurrentLicense', data.ret);
                            this.$EventBus.$emit('refresh-dashboard-list');
                            this.$EventBus.$emit('refresh-report-list');
                        }
                        this.licFile = null;
                        this.loading = false;
                    }
                };
                fileReader.readAsText(val);
            }
        },
    },
    computed: {
        minLevelRole() {
            return this.$store.state.auth.user == null ? 999 : this.$store.state.auth.user.minLevelRole;
        },
        mainMode() {
            return this.$store.state.mainMode;
        },
        LICENSE() {
            return this.$store.state.settings.LICENSE;
        },
    },
    methods: {
        openLicFile() {
            this.$refs.licFileUpload.$el.children[0].click();
        },
        updateSelectedOptions(idx) {
            this.askLicenseInformations.Options[idx] = !this.askLicenseInformations.Options[idx];
        },
        checkFormValidity() {
            this.formErrors = [];
            if (this.askLicenseInformations.Mode == null || this.askLicenseInformations.Options.filter((o) => o).length == 0 || !this.askLicenseInformations.Company || !this.askLicenseInformations.Name || !this.askLicenseInformations.EMail || !this.askLicenseInformations.Phone)
                this.formErrors.push(this.$t('Check required fields'));
            return this.formErrors.length == 0;
        },
        async onSubmitAskLicense(event) {
            event.preventDefault();
            if (this.checkFormValidity()) {
                const data = await LicenseService.askLicense(this.askLicenseInformations);
                if (data.success == 'n') this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(data.ret, data.retParams), 'warning'));
                else {
                    let blob = new Blob([data.ret.alic], { type: 'application/xml' }),
                        url = window.URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.href = url;
                    a.setAttribute('download', data.ret.fileName);
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                    this.$bvModal.hide('bv-modal-ask-license');
                }
            }
        },
    },
};
</script>
<style scoped>
.licFileUpload {
    display: none;
}
</style>
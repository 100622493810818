<template>
    <div>
        <b-navbar class="fixed-top shadow top-navbar">
            <b-container fluid class="navbar-container">
                <b-navbar-brand>
                    <b-link class="brand-logo d-none d-md-inline-block" v-on:click="home">
                        <img v-if="mainTheme == 'dark'" src="@/assets/img/logo_neg.png" alt="Productys ProdCom" />
                        <img v-else src="@/assets/img/logo.png" alt="Productys ProdCom" />
                    </b-link>
                    <Clock class="d-none d-md-inline-block" />
                </b-navbar-brand>
                <!-- It's hugly, but it works: make this element really centered on screen. (otherwise it's centered on the navbar, which we don't want.) -->
                <b-navbar-brand>
                    <b-nav-text class="text-right ml-4 font-weight-bold" style="text-align: center; margin-right: 90px">{{ $t(this.$route.name) }}</b-nav-text>
                </b-navbar-brand>
                <b-navbar-nav>
                    <b-nav-item v-on:click="home">
                        <font-awesome-icon icon="fas fa-wrench" class="top-menu-icon mr-1 fa-lg" :color="this.$route.name == 'Build' ? '#1ca8dd' : ''" :title="$t('Configuration')" />
                    </b-nav-item>
                    <b-nav-item v-on:click="Run" :class="{ 'cursor-default disabled': !loggedIn || $store.state.runtimeStatus != 1 || !this.$store.state.auth.user.permissions.PermissionDisplayRun }">
                        <font-awesome-icon icon="industry" class="top-menu-icon mr-1 fa-lg" :color="this.$route.name == 'Run' ? '#1ca8dd' : ''" :title="$store.state.runtimeStatus == 1 ? $t('Monitoring') : $t('runtime.status.stopped')" />
                    </b-nav-item>
                    <b-nav-item v-on:click="logger" :class="{ 'cursor-default disabled': !loggedIn || !this.$store.state.auth.user.permissions.PermissionDisplayHistory }">
                        <font-awesome-icon icon="fas fa-file-alt" class="top-menu-icon mr-1 fa-lg" :color="this.$route.name == 'History' ? '#1ca8dd' : ''" :title="$t('Logger')" />
                    </b-nav-item>
                    <b-nav-item v-if="loggedIn && minLevelRole <= $RoleLevels.Admin" v-on:click="checkMinLevelRole">
                        <font-awesome-icon icon="cog" class="fa-lg top-menu-icon" v-bind:alt="$t('Admin panel')" v-bind:title="$t('Admin panel')" />
                    </b-nav-item>
                    <b-nav-item v-else class="cursor-default disabled">
                        <font-awesome-icon icon="cog" class="fa-lg disabled" v-bind:alt="$t('Admin panel')" v-bind:title="$t('Admin panel')" />
                    </b-nav-item>
                    <b-nav-item v-if="loggedIn" @click="signOut">
                        <font-awesome-icon icon="sign-out-alt" class="fa-lg top-menu-icon" v-bind:alt="$t('signout')" v-bind:title="$t('signout')" />
                    </b-nav-item>
                    <b-nav-item v-else to="login">
                        <font-awesome-icon icon="sign-in-alt" class="fa-lg top-menu-icon" v-bind:alt="$t('signin')" v-bind:title="$t('signin')" />
                    </b-nav-item>
                    <UserAvatar v-if="loggedIn" class="ml-2" />
                    <!-- <Slideshows class="mx-4" /> -->

                    <LocaleChooser class="ml-3 mr-2" />
                    <b-nav-item v-on:click="toggleFullscreen">
                        <font-awesome-icon icon="expand" class="top-menu-icon mr-1 fa-lg" :title="$t('ExpandScreen')" />
                    </b-nav-item>
                    <b-nav-item v-on:click="refreshPage">
                        <font-awesome-icon icon="sync" class="top-menu-icon fa-lg" :title="$t('Refresh')" />
                    </b-nav-item>
                    <b-nav-item>
                        <font-awesome-icon v-b-modal.bv-modal-about-box icon="question-circle" class="fa-lg top-menu-icon" v-bind:alt="$t('About')" :title="$t('About')" />
                    </b-nav-item>
                    <FontSizeCtrl class="mt-2 mr-2 ml-1" />
                    <PrimaryColorCtrl v-show="$store.state.settings.PRIMARY_COLOR_CHANGE_ALLOWED" class="pt-1 mr-2" />
                    <ThemeSwitcher class="mt-2 mr-2" />
                    <div class="ml-3 mt-2">
                        <font-awesome-layers class="fa-lg mr-1">
                            <font-awesome-icon icon="fa-solid fa-user" />
                            <font-awesome-icon icon="fa-solid fa-wifi" transform="shrink-9 up-5 right-8" />
                        </font-awesome-layers>
                    </div>
                    <div class="small mr-2" style="margin-top: 13px">{{ connectionsCount }}</div>
                </b-navbar-nav>
            </b-container>
        </b-navbar>
    </div>
</template>
<script>
import ToastAlert from '@/utils/ToastAlert';
import Clock from '@/components/Clock.vue';
import Slideshows from '@/components/Slideshows.vue';
import ThemeSwitcher from '@/components/ThemeSwitcher.vue';
import FontSizeCtrl from '@/components/FontSizeCtrl.vue';
import PrimaryColorCtrl from '@/components/PrimaryColorCtrl.vue';
import LocaleChooser from '@/components/LocaleChooser.vue';
import UserAvatar from '@/components/UserAvatar.vue';
// import UserService from '@/services/user.service.js';
import ProdComEquipmentService from '@/services/prodcom.equipments.service.js';
import { HubConnectionBuilder, HttpTransportType, LogLevel } from '@microsoft/signalr';

export default {
    name: 'TopMenu',
    components: {
        Clock,
        Slideshows,
        FontSizeCtrl,
        PrimaryColorCtrl,
        ThemeSwitcher,
        LocaleChooser,
        UserAvatar,
    },
    data() {
        return {
            hubConnection: null,
            connectionsCount: 0,
        };
    },
    computed: {
        minLevelRole() {
            return this.$store.state.auth.user == null ? 999 : this.$store.state.auth.user.minLevelRole;
        },
        loggedIn() {
            return this.$store.state.auth.status.loggedIn && this.$store.state.auth.user != null && this.$store.state.auth.user.email != '';
        },
        mainTheme() {
            return this.$store.state.theme;
        },
    },
    async created() {
        // Create the SignalR connection hub
        this.hubConnection = new HubConnectionBuilder()
            .withUrl(this.$store.state.settings.API_URL + '/connectionHub', {
                skipNegotiation: true,
                transport: HttpTransportType.WebSockets,
            })
            .withAutomaticReconnect()
            .configureLogging(LogLevel.Error)
            .build();
        this.hubConnection?.on('ConnectionsCount', async (data) => {
            this.$EventBus.$emit('connectionsCountHasChanged', data);
            this.connectionsCount = data;
            var connectionId = await this.hubConnection.invoke('GetConnectionId');
            this.$store.commit('setConnectionId', connectionId);
        });
        // Start connection
        await this.hubConnection?.start();
        // Save the connection id to store
        var connectionId = await this.hubConnection.invoke('GetConnectionId');
        this.$store.commit('setConnectionId', connectionId);
    },
    methods: {
        toggleFullscreen(event) {
            var element = document.body;

            if (event instanceof HTMLElement) {
                element = event;
            }

            var isFullscreen = document.webkitIsFullScreen || document.mozFullScreen || false;

            element.requestFullScreen =
                element.requestFullScreen ||
                element.webkitRequestFullScreen ||
                element.mozRequestFullScreen ||
                function () {
                    return false;
                };
            document.cancelFullScreen =
                document.cancelFullScreen ||
                document.webkitCancelFullScreen ||
                document.mozCancelFullScreen ||
                function () {
                    return false;
                };

            //if (!isFullscreen) this.$EventBus.$emit('hide-side-bar');
            isFullscreen ? document.cancelFullScreen() : element.requestFullScreen();
        },
        async refreshPage() {
            const data = await ProdComEquipmentService.reloadConfiguration();
            if (data.success == 'n') this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(data.ret, data.retParams), 'warning'));
            else window.history.go(0);
        },
        async home() {
            this.$router.push('/');
        },
        async Run() {
            this.$router.push('run');
        },
        async logger() {
            this.$router.push('history');
        },
        async signOut() {
            await this.$store.dispatch('auth/logout');
            this.$EventBus.$emit('refresh-dashboard-list');
            this.$EventBus.$emit('refresh-report-list');
            this.$router.push('/login');
            // if (this.$router.currentRoute.name != 'home') this.$router.push('/');
        },
        async checkMinLevelRole() {
            this.$bvModal.show('adminPanel');
            // const data = await UserService.getMinLevelRole();
            // if (data.success == 'n') this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(data.ret, data.retParams), 'warning'));
            // else if (data.ret.toString() != this.minLevelRole.toString()) {
            //     this.$EventBus.$emit('show-toast', new ToastAlert(this.$t('Not authorized'), 'warning'));
            //     return false;
            // } else {
            //     this.$bvModal.show('adminPanel');
            // }
        },
    },
};
</script>

<style scoped lang="scss">
.brand-logo {
    margin-left: 10px;
}
.with-slide-menu {
    margin-left: 60px;
}
.brand-logo img {
    height: 48px;
}
.top-navbar {
    padding: 0;
}
.top-menu-icon {
    transition: all 0.3s;
}
.top-menu-icon:hover {
    transform: scale(1.3);
    transition: all 0.3s;
}
.sidebar-toggler {
    color: var(--primary);
    opacity: 0.8;
    position: absolute;
    border: none !important;
}
.sidebar-toggler:hover {
    opacity: 1;
    position: absolute;
    border: none !important;
}
.sidebar-toggler:focus {
    outline: none !important;
    box-shadow: none;
}
.zoom-button .nav-link {
    padding-right: 0 !important;
    padding-left: 0 !important;
}
</style>
<template>
    <div>
        <!-- This empty div is used to handle sidebar resize. Do not delete it even if empty. -->
        <div class="resizer" draggable="true" ref="dragElement" @mousedown="dragMouseDown" :style="{ left: $store.state.sideMenuSize + 'px' }"></div>
        <SideMenu />
        <DxLoadPanel :position="{ my: 'center', at: 'center', of: 'window' }" :visible="Loading" />
        <b-container
            fluid
            class="home"
            v-bind:style="{
                height: isIframe ? 'calc(100% - 28px)!important' : 'calc(100% - 88px)!important',
                width: 'calc(100% - ' + $store.state.sideMenuSize + 'px) !important',
                'margin-left': $store.state.sideMenuSize + 'px !important',
            }"
        >
            <!-- Tab component. TODO: move to a reusable component ? -->
            <div class="tabGlobal" v-if="$store.state.tabs.length > 0">
                <!-- Tab header bar -->
                <div style="box-sizing: border-box; display: flex; position: relative; height: 35px; border-bottom: 1px solid var(--tab-border-color)">
                    <!-- Tab list -->
                    <div style="background-color: var(--sidemenu-background-color); width: calc(100% - var(--tab-action-length))">
                        <div class="tabList">
                            <div draggable="true" class="tab" v-bind:class="{ activeTab: selectedAction?.uuid == action?.uuid }" v-for="action in $store.state.tabs" :key="action.uuid" v-on:click="selectTab(action)">
                                <div @contextmenu.prevent="(e) => openTabTitleMenu(e, action)">
                                    <font-awesome-icon :icon="getActionDisplayIcon(action)" style="margin-right: 5px; margin-top: 10px" :color="getActionDisplayIconColor(action)" />
                                    <span>{{ getActionDisplayText(action) }}</span>
                                    <div v-on:click="closeTab(action)" class="tabHeaderIcon" style="margin-left: 2px; padding-right: 5px; margin-top: 3px" :title="$t('Close')">
                                        <svg viewBox="0 0 16 16" width="1.3em" height="1.3em" focusable="false" role="img" aria-label="x" xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi-x b-icon bi mt-1">
                                            <g>
                                                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"></path>
                                            </g>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Tab actions. On click, displays the tab selection popup. -->
                    <div class="tabAction" style="text-align: center">
                        <font-awesome-icon class="button cursor-pointer" icon="fa-solid fa-caret-down" size="lg" style="margin-top: 5px" role="button" v-on:click="showTabPopup" />
                    </div>
                </div>
                <!-- Tab content. -->
                <div class="tabBody">
                    <div v-if="selectedAction != null">
                        <!-- Action configuration -->
                        <Action v-if="selectedAction.type == $constants.TAB_TYPE_ACTION" :key="selectedAction.uuid" :action="selectedAction" :propProject="selectedAction.project" :showToolbar="true" :closeTab="async () => await closeTab(selectedAction)" />
                        <!-- Data Source configuration -->
                        <DataSource v-else-if="selectedAction.type == $constants.TAB_TYPE_DATASOURCE || selectedAction.type == $constants.TAB_TYPE_DATASOURCETEMPLATE" :key="selectedAction.uuid" :datasource="selectedAction" :showToolbar="true" :closeTab="async () => await closeTab(selectedAction)" />
                        <!-- Variables configuration -->
                        <Variables v-else-if="selectedAction.type == $constants.TAB_TYPE_VARIABLES" :key="selectedAction.uuid" :equipment="selectedAction.Equipment" :project="selectedAction.ProjectId" />
                        <!-- Communication configuration -->
                        <Equipment
                            v-else-if="selectedAction.type == $constants.TAB_TYPE_COMMUNICATION"
                            :key="selectedAction.uuid"
                            :equipmentName="selectedAction.Equipment"
                            :project="selectedAction.ProjectId"
                            :updateMode="true"
                            :showToolbar="true"
                            :closeTab="async () => await closeTab(selectedAction)"
                        />
                        <!-- Put here other configuration panels -->
                        <ActionGlobalView v-else-if="selectedAction.type == $constants.TAB_TYPE_ACTIONGLOBALVIEW" :key="selectedAction.uuid" :action="selectedAction" :propProject="selectedAction.project" :showToolbar="true" />
                    </div>
                </div>
            </div>
            <!-- Tab selection popup. TODO: use the ContextMenu component ? -->
            <OnClickOutside @trigger="clickOutsideEvent">
                <div class="tabActionPopup" v-bind:class="{ noneDisplay: !displayTabPopup }">
                    <div class="tabActionPopupContent">
                        <!-- Display list of tabs ordered by name instead of display order. -->
                        <div v-for="action in getOrderedTabs()" :key="action.uuid" class="tabActionPopupElement" v-on:click="openTab(action)">
                            <font-awesome-icon :icon="getActionDisplayIcon(action)" style="margin-right: 5px; margin-top: 4px" :color="getActionDisplayIconColor(action)" />
                            <span class="tabActionPopupElementText">{{ getActionDisplayText(action) }}</span>
                            <div v-on:click="closeTab(action)" class="tabHeaderIcon" style="float: right" :title="$t('Close')">
                                <svg viewBox="0 0 16 16" width="1.3em" height="1.3em" focusable="false" role="img" aria-label="x" xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi-x b-icon bi mt-1">
                                    <g>
                                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"></path>
                                    </g>
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
            </OnClickOutside>
        </b-container>
    </div>
</template>

<script>
import Action from '@/components/configuration/Actions/Action.vue';
import ActionGlobalView from '@/components/configuration/Actions/ActionGlobalView.vue';
import DataSource from '@/components/configuration/DataSources/DataSource.vue';
import Variables from '@/components/configuration/Variables/Variables.vue';
import Equipment from '@/components/configuration/Equipments/Equipment.vue';
import SideMenu from '@/components/SideMenu.vue';
import { OnClickOutside } from '@vueuse/components';
import { DxLoadPanel } from 'devextreme-vue/load-panel';
export default {
    name: 'Home',
    components: {
        Action,
        ActionGlobalView,
        DataSource,
        Variables,
        Equipment,
        SideMenu,
        OnClickOutside,
        DxLoadPanel,
    },
    data() {
        return {
            isIframe: window.location !== window.parent.location,
            triggerModes: ['Notification Unitaire', 'Cyclique', 'Séquence', 'Démarrage', 'Planification', 'Notification multi-critère'],
            selectedTriggerMode: null,
            selectedAction: null,
            localTabLength: 0,
            displayTabPopup: false,
            Loading: false,
        };
    },
    watch: {
        '$store.state.tabs': function () {
            if (this.$store.state.tabs.length > this.localTabLength) {
                this.selectedAction = this.$store.state.tabs[this.$store.state.tabs.length - 1];
            }
            this.localTabLength = this.$store.state.tabs.length;
        },
        '$store.state.activeTab': function () {
            this.selectedAction = this.$store.state.tabs[this.$store.state.activeTab];
        },
    },
    async mounted() {
        if (this.$store.state.tabs.length > 0) {
            this.selectedAction = this.$store.state.tabs[this.$store.state.activeTab];
        }
    },
    created() {
        this.$EventBus.$on('Loading', this.switchLoading);
    },
    destroyed() {
        this.$EventBus.$off('Loading');
    },
    methods: {
        switchLoading() {
            this.Loading = !this.Loading;
        },
        onTabChanged(currentTab, previousTab) {
            // Only move to last tab if a new tab was added.
            if (currentTab.length >= previousTab.length) {
                this.$store.state.activeTab = currentTab.length - 1;
            }
        },
        async closeTab(e) {
            try {
                this.$store.commit('closeTab', e);
                if (e.uuid == this.selectedAction.uuid) {
                    // If closing current tab, open first tab.
                    this.$store.state.activeTab = 0;
                    this.selectedAction = this.$store.state.tabs[0];
                }
            } catch (ex) {
                console.error(ex);
            }
        },
        async openTabTitleMenu(e, action) {
            this.$store.state.contextMenu.items = [];
            this.$store.state.contextMenu.positionX = e.clientX;
            this.$store.state.contextMenu.positionY = e.clientY + 10;
            await this.onTabTitleContextMenu(action);
        },
        async onTabTitleContextMenu(action) {
            if (action == null) return;
            this.$store.state.contextMenu.items = [
                {
                    id: '1',
                    text: this.$t('tabs.contextmenu.close'),
                    onClick: async () => {
                        await this.close(action);
                    },
                },
                {
                    id: '2',
                    text: this.$t('tabs.contextmenu.closeOthers'),
                    onClick: async () => {
                        await this.closeOthers(action);
                    },
                },
                {
                    id: '3',
                    text: this.$t('tabs.contextmenu.closeRight'),
                    onClick: async () => {
                        await this.closeRight(action);
                    },
                },
                {
                    id: '4',
                    text: this.$t('tabs.contextmenu.closeAll'),
                    onClick: async () => {
                        await this.closeAll();
                    },
                },
            ];
            this.$store.state.contextMenu.display = true;
        },
        async close(action) {
            this.$store.commit('closeTab', action);
        },
        async closeOthers(action) {
            this.$store.commit('closeOtherTabs', action);
        },
        async closeRight(action) {
            this.$store.commit('closeRightTabs', action);
        },
        async closeAll() {
            this.$store.commit('closeAllTabs');
        },
        async selectTab(action) {
            this.selectedAction = action;
        },
        async openTab(action) {
            this.$store.commit('selectTabAsFirst', action);
            this.selectedAction = this.$store.state.tabs[this.$store.state.activeTab];
            this.displayTabPopup = false;
        },
        async showTabPopup(e) {
            this.displayTabPopup = !this.displayTabPopup;
        },
        /**
         * Called when user clicks outside of the tab popup.
         */
        async clickOutsideEvent() {
            this.displayTabPopup = false;
        },
        /**
         * Returns the list of tabs opened ordered by their display text. Used in the tab selection popup.
         */
        getOrderedTabs() {
            return [...(this.$store.state.tabs ?? [])].sort((a, b) => this.getActionDisplayText(a).localeCompare(this.getActionDisplayText(b))) ?? [];
        },
        /**
         * Returns the formatted text to be displayed in the header of a tab.
         * @param {object} action The tab data.
         */
        getActionDisplayText(action) {
            if (action == null) return '';
            switch (action.type) {
                case this.$constants.TAB_TYPE_ACTION:
                    return action.Name;
                case this.$constants.TAB_TYPE_DATASOURCE:
                    return action.Equipment + ' - ' + this.$t('Data Source');
                case this.$constants.TAB_TYPE_DATASOURCETEMPLATE:
                    return action.Name + ' - ' + this.$t('Data Source Template');
                case this.$constants.TAB_TYPE_VARIABLES:
                    return action.Equipment + ' - ' + this.$t('Variables');
                case this.$constants.TAB_TYPE_COMMUNICATION:
                    return action.Equipment + ' - ' + this.$t('Communication');
                case this.$constants.TAB_TYPE_ACTIONGLOBALVIEW:
                    return action.Equipment + ' - ' + this.$t('Actions');
                default:
                    return '';
            }
        },
        /**
         * Returns the icon to be displayed in the header of a tab.
         * @param {object} action The tab data.
         */
        getActionDisplayIcon(action) {
            if (action == null) return '';
            switch (action.type) {
                case this.$constants.TAB_TYPE_ACTION:
                    return 'fas fa-arrows-rotate';
                case this.$constants.TAB_TYPE_DATASOURCE:
                    return 'fas fa-database';
                case this.$constants.TAB_TYPE_DATASOURCETEMPLATE:
                    return 'fas fa-database';
                case this.$constants.TAB_TYPE_VARIABLES:
                    return 'fas fa-microchip';
                case this.$constants.TAB_TYPE_COMMUNICATION:
                    return 'far fa-ethernet';
                case this.$constants.TAB_TYPE_ACTIONGLOBALVIEW:
                    return 'far fa-ethernet';
                default:
                    return '';
            }
        },
        /**
         * Returns the color of the icon to be displayed in the header of a tab.
         * @param {object} action The tab data.
         */
        getActionDisplayIconColor(action) {
            if (action == null) return '';
            switch (action.type) {
                case this.$constants.TAB_TYPE_ACTION:
                    return '#8dc149';
                case this.$constants.TAB_TYPE_DATASOURCE:
                    return '#f55385';
                case this.$constants.TAB_TYPE_DATASOURCETEMPLATE:
                    return '#f55385';
                case this.$constants.TAB_TYPE_VARIABLES:
                    return '#5492dc';
                case this.$constants.TAB_TYPE_COMMUNICATION:
                    return '#a1887f';
                case this.$constants.TAB_TYPE_ACTIONGLOBALVIEW:
                    return '#a1887f';
                default:
                    return '';
            }
        },
        dragMouseDown(e) {
            e.preventDefault();
            document.onmousemove = this.elementDrag;
            document.onmouseup = this.dragEnd;
        },
        elementDrag(e) {
            e.preventDefault();
            this.$store.commit('setSideMenuSize', e.clientX);
        },

        dragEnd(e) {
            document.onmouseup = null;
            document.onmousemove = null;
        },
    },
    computed: {
        loggedIn() {
            return this.$store.state.auth.status.loggedIn && this.$store.state.auth.user != null && this.$store.state.auth.user.email != '';
        },
    },
};
</script>

<style lang="scss">
.tabHeaderIcon {
    display: inline-block;
}

.tabHeaderText {
    width: 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.tabGlobal {
    padding: 0;
    background-color: --main-background-color;
    height: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
}

div[name='test1'] .card-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: green;
}

div[name='test1'] .tab-content {
    height: 100%;
    background-color: blue;
    flex: 1;
    flex-direction: row;
    align-items: flex-start;
    overflow-y: auto;
    max-height: calc(100% - 28px);
}

.resizer {
    position: absolute;
    z-index: 1000;
    width: 4px;
    cursor: ew-resize;
    top: 0;
    height: 100%;
    background: none;
}

.resizer:hover {
    background: var(--primary);
}

.tabList {
    display: flex;
}

.tab {
    position: relative;
    display: flex;
    cursor: pointer;
    padding-left: 10px;
    max-width: 120px !important;
    min-width: fit-content;
    width: 120px;
    flex-shrink: 0;
    height: 35px;
    white-space: nowrap;
    box-sizing: border-box;
    background-color: grey;
    margin-top: auto;
    margin-bottom: auto;
    text-overflow: ellipsis;
    line-height: 35px;
    font-size: 13px;
    border-right: 1px solid var(--tab-border-color);
    border-bottom: 1px solid var(--tab-border-color);
    background-color: var(--tab-unselected-background);
}

.tab.activeTab {
    background-color: var(--tab-selected-background) !important;
}

.tabBody {
    height: calc(100% - 35px);
    flex: 1;
    overflow-y: auto;
    overflow-x: none;
    max-height: calc(100% - 35px);
    //padding: 1rem;
    padding-left: 0rem;
    padding-top: 0rem;
}

.tabAction {
    z-index: 1000;
    background-color: var(--sidemenu-background-color);
    width: var(--tab-action-length);
}

.tabActionPopup {
    top: 28px;
    right: 10px;
    z-index: 2575;
    position: absolute;
    width: initial;
    border: 0.01em solid #cccccc33;
    border-radius: 5px;
    background-color: var(--navbar-background-color);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    max-height: 400px;
    overflow-y: auto;
}

.noneDisplay {
    display: none;
}

.tabActionPopupElement {
    margin: 4px;
    padding-left: 3px;
    padding-right: 3px;
    font-size: 13px;
    cursor: pointer;
    white-space: nowrap;
}

.tabActionPopupElement:hover {
    background-color: var(--selection-background-color);
}

.tabActionPopupElementText {
    margin-left: 7px;
}
</style>
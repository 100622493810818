<template>
    <b-modal id="projectWizard" ref="projectWizard" v-b-modal.modal-multi-2 size="xl" @cancel="onItemCancel" @ok="onItemOk" @show="onItemShow" :title="$t('project.configuration') + ' - ' + this.projectName" :cancel-title="$t('Cancel')" :ok-title="$t('Ok')">
        <template #modal-header>
            <div class="prevent-select">
                <h5>{{ $t('project.configuration') + ' - ' + projectName }}</h5>
            </div>
        </template>
        <b-form>
            <b-card no-body class="prevent-select">
                <b-tabs pills card>
                    <b-tab active>
                        <template #title><font-awesome-icon icon="fa-cog" /> {{ $t('Configuration') }} </template>
                        <b-row>
                            <b-col cols="2">
                                <span>{{ $t('project.name') + ' :' }}</span>
                            </b-col>
                            <b-col>
                                <b-form-input id="projectName" v-model="modifProjectName" :state="validateName()" :class="{ 'cursor-default disabled': !this.$store.state.auth.user.permissions.PermissionWrite }" />
                                <b-form-invalid-feedback id="projectVersion-feedback">{{ feedbackName }}</b-form-invalid-feedback>
                            </b-col>
                        </b-row>
                        <b-row style="margin-top: 5px">
                            <b-col cols="2">
                                <span>{{ $t('project.version') + ' :' }}</span>
                            </b-col>
                            <b-col>
                                <b-form-input id="projectVersion" v-model="projectVersion" :state="validateVersion()" :class="{ 'cursor-default disabled': !this.$store.state.auth.user.permissions.PermissionWrite }" />
                                <b-form-invalid-feedback id="projectVersion-feedback">{{ feedbackVersion }}</b-form-invalid-feedback>
                            </b-col>
                        </b-row>
                        <b-row style="margin-top: 5px">
                            <b-col cols="2">
                                <span>{{ $t('project.path') + ' :' }}</span>
                            </b-col>
                            <b-col>
                                <span>{{ this.projectPath }}</span>
                            </b-col>
                        </b-row>

                        <hr color="grey" />

                        <b-row style="margin-top: 5px">
                            <b-col cols="6">
                                <b-row>
                                    <b-col cols="5">
                                        <span>{{ $t('project.creationDate') + ' :' }}</span>
                                    </b-col>
                                    <b-col cols="7">
                                        <span>{{ this.projectCreationDate }}</span>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col cols="5">
                                        <span>{{ $t('project.creationVersion') + ' :' }}</span>
                                    </b-col>
                                    <b-col cols="7">
                                        <span>{{ this.projectCreationVersion }}</span>
                                    </b-col>
                                </b-row>
                            </b-col>
                            <b-col cols="6">
                                <b-row>
                                    <b-col cols="5">
                                        <span>{{ $t('project.updateDate') + ' :' }}</span>
                                    </b-col>
                                    <b-col cols="7">
                                        <span>{{ this.projectLastUpdateDate }}</span>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col cols="5">
                                        <span>{{ $t('project.updateVersion') + ' :' }}</span>
                                    </b-col>
                                    <b-col cols="7">
                                        <span>{{ this.projectLastUpdateVersion }}</span>
                                    </b-col>
                                </b-row>
                            </b-col>
                        </b-row>
                        <hr color="grey" />
                        <b-row>
                            <b-col>
                                <b-form-group :label="$t('project.notes')" label-for="projectNotes">
                                    <b-form-textarea id="projectNotes" v-model="projectNotes" :class="{ 'cursor-default disabled': !this.$store.state.auth.user.permissions.PermissionWrite }" />
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </b-tab>
                    <b-tab @click="repaintBackupsDatagrid">
                        <template #title
                            ><font-awesome-icon icon="fa-save" /> {{ $t('project.tab.backups') }} <b-badge pill variant="info">{{ backups.length }}</b-badge>
                        </template>

                        <b-row>
                            <b-col>
                                <DxDataGrid
                                    :dataSource="backups"
                                    :show-borders="true"
                                    :selection="{ mode: 'single' }"
                                    @selection-changed="onBackupSelectionChanged"
                                    @row-removed="onBackupRemoved"
                                    :customize-columns="
                                        (columns) => {
                                            columns[columns.length - 1].width = 100;
                                        }
                                    "
                                    width="100%"
                                    ref="backupsDataGrid"
                                >
                                    <DxEditing :allow-updating="false" :allow-adding="false" :allow-deleting="$store.state.auth.user.permissions.PermissionWrite" :use-icons="true" mode="row" />

                                    <DxToolbar>
                                        <DxItem location="after" template="restoreTemplate" />
                                    </DxToolbar>

                                    <template #restoreTemplate>
                                        <b-button class="mt-1" @click="restoreBackup" :disabled="selectedBackup == null || !$store.state.auth.user.permissions.PermissionWrite">
                                            <font-awesome-icon icon="fas fa-floppy-disk-circle-arrow-right" />
                                            {{ $t('project.backup.restore') }}
                                        </b-button>
                                        <!-- <DxButton icon="revert" @click="restoreBackup"
                                            :disabled="selectedBackup == null || !$store.state.auth.user.permissions.PermissionWrite" /> -->
                                    </template>

                                    <DxColumn data-field="IsAutomatic" :calculate-group-value="isAutomaticGroupValueCalculator" :group-index="0" group-cell-template="groupCellTemplate" width="200"> </DxColumn>
                                    <template #groupCellTemplate="{ data }">
                                        <div>{{ data.value == 'A' ? $t('project.backup.automatic') : $t('project.backup.manual') }}</div>
                                    </template>
                                    <DxColumn data-field="Name" :visible="true" :caption="$t('project.name')" alignment="left" width="200" />
                                    <DxColumn
                                        data-field="Date"
                                        :visible="true"
                                        :caption="$t('project.backupDate')"
                                        alignment="center"
                                        :calculate-cell-value="
                                            (rowData) => {
                                                return formatDate(new Date(rowData.Date * 1000));
                                            }
                                        "
                                        width="160"
                                    />
                                    <DxColumn data-field="Path" :visible="true" :caption="$t('project.path')" alignment="left" width="400" />
                                    <DxColumn data-field="Notes" :visible="true" :caption="$t('project.notes')" alignment="left" width="200" />
                                </DxDataGrid>
                            </b-col>
                        </b-row>
                    </b-tab>
                </b-tabs>
            </b-card>
        </b-form>
    </b-modal>
</template>

<script>
import ProdComProjectService from '@/services/prodcom.projects.service.js';
import dayjs from 'dayjs';
import ToastAlert from '@/utils/ToastAlert';
import { DxDataGrid, DxColumn, DxToolbar, DxItem, DxLookup, DxEditing } from 'devextreme-vue/data-grid';
import { DxButton } from 'devextreme-vue/button';
import { custom } from 'devextreme/ui/dialog';

export default {
    name: 'ProjectConfigurationWizard',
    components: {
        DxDataGrid,
        DxColumn,
        DxEditing,
        DxToolbar,
        DxItem,
        DxLookup,
        DxButton,
    },
    props: {
        propProject: Object,
        /**
         * List of existing projects, for name validation.
         */
        propExistingProjects: Array,
        /**
         * Function called when loading the component. Must be asynchronous.
         */
        OnLoad: Function,
        onOk: Function,
        OnCancel: Function,
    },
    watch: {
        async propProject(newValue, oldValue) {
            this.projectName = newValue?.itemData.text;
            this.modifProjectName = newValue?.itemData.text;
            this.projectId = newValue?.itemData.internalId;
            await this.onItemShow();
        },
    },
    data() {
        return {
            projectId: '',
            projectName: '',
            modifProjectName: '',
            projectNameFeedback: '',
            projectPath: '',
            projectVersion: '',
            projectCreationDate: '',
            projectCreationVersion: '',
            projectLastUpdateDate: '',
            projectLastUpdateVersion: '',
            projectMirrorFrequency: 10000,
            projectNotes: '',
            feedbackName: '',
            feedbackVersion: '',
            backups: [],
            selectedBackup: null,
            existingProjects: [],
        };
    },
    methods: {
        isAutomaticGroupValueCalculator(rowData) {
            return rowData.IsAutomatic ? 'A' : 'M';
        },
        repaintBackupsDatagrid() {
            this.$refs.backupsDataGrid.instance.refresh();
        },
        /**
         * Checks that the project name is correct: not empty, and does not
         * already exists.
         */
        validateName() {
            if (this.modifProjectName == null || this.modifProjectName == '') {
                this.feedbackName = this.$t('project.feedbackName.empty');
                return false;
            }
            if (this.propExistingProjects != null) {
                if (this.modifProjectName != this.projectName && this.propExistingProjects.findIndex((proj) => proj == this.modifProjectName) != -1) {
                    this.feedbackName = this.$t('project.feedbackName.exists');
                    return false;
                }
            }
            this.feedbackName = '';
            return true;
        },
        /**
         * Checks that the project version is of type W.X.Y.Z, with
         * each value being a numeric value (example: 1.0.0.0).
         */
        validateVersion() {
            // var version = this.projectVersion
            var splitted = this.projectVersion.split('.');
            if (splitted.length != 4) {
                this.feedbackVersion = this.$t('project.feedbackVersion');
                return false;
            }
            for (var i = 0; i < 4; i++) {
                if (!this.isNumeric(splitted[i])) {
                    this.feedbackVersion = this.$t('project.feedbackVersion');
                    return false;
                }
            }
            this.feedbackVersion = '';
            return true;
        },
        isNumeric(value) {
            return /^-?\d+$/.test(value);
        },
        async onItemCancel(e) {
            if (this.OnCancel != null) this.OnCancel();
        },
        async onItemOk(e) {
            if (!this.$store.state.auth.user.permissions.PermissionWrite) return;
            // Save the current edits.
            const data = await ProdComProjectService.updateProject(this.projectId, this.modifProjectName, this.projectVersion, this.projectMirrorFrequency, this.projectNotes);
            if (data.success == 'n') this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(data.ret, data.retParams), 'warning'));
            else {
                // If save successful, call the onOk method.
                if (this.onOk != null) this.onOk(this.projectId);
            }
        },
        /**
         * Refreshes the list of snapshots linked to the selected project.
         */
        async onItemShow(e) {
            if (!this.$store.state.auth.user.permissions.PermissionRead) return;
            if (this.projectId == null || this.projectId == '') return;
            const data = await ProdComProjectService.projectDetails(this.projectId);
            if (data.success == 'n') this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(data.ret, data.retParams), 'warning'));
            else {
                this.projectPath = data.ret.project.Path;
                this.projectVersion = data.ret.project.ProjectVersion;
                this.projectCreationDate = this.formatDate(new Date(data.ret.project.CreationDate * 1000));
                this.projectCreationVersion = data.ret.project.CreationProdComVersion;
                this.projectLastUpdateDate = this.formatDate(new Date(data.ret.project.LastModifiedDate * 1000));
                this.projectLastUpdateVersion = data.ret.project.LastModifiedProdComVersion;
                this.projectNotes = data.ret.project.Notes;
                this.projectMirrorFrequency = data.ret?.project?.MirroringFrequency ?? 10000;
                this.backups = data.ret.backups;
            }
            //if(this.OnLoad != null) this.itemListServer = await this.OnLoad(this.projectId, this.backupNotes);
        },
        formatDate(e) {
            return dayjs(e).format('YYYY-MM-DD HH:mm:ss');
        },
        /**
         * Display popup to confirm restoring the selected backup. Does nothing if no backup
         * is selected.
         */
        async restoreBackup(e) {
            if (!this.$store.state.auth.user.permissions.PermissionWrite) return;
            if (this.selectedBackup == null) return;
            let dlg = custom({
                title: this.$t('project.backup.confirmRestoreTitle'),
                messageHtml: `<div style="width: 450px;">${this.$t('project.backup.confirmRestore')}</div>`, // Make sure the title is fully displayed
                buttons: [
                    {
                        text: this.$t('Ok'),
                        onClick: function () {
                            return true;
                        },
                    },
                    {
                        text: this.$t('Cancel'),
                        onClick: function () {
                            return false;
                        },
                    },
                ],
            });
            let resultDialog = await dlg.show();

            if (resultDialog) {
                let result = await ProdComProjectService.restoreBackup(this.projectId, this.selectedBackup.Id);

                if (result.success == 'n') {
                    this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(result.ret, result.retParams), 'warning'));
                } else {
                    dlg = custom({
                        title: this.$t('project.backup.restoreReloadConfigTitle'),
                        messageHtml: `<div style="width: 450px;">${this.$t('project.backup.restoreReloadConfig')}</div>`, // Make sure the title is fully displayed
                        buttons: [
                            {
                                text: this.$t('Ok'),
                                onClick: function () {
                                    return true;
                                },
                            },
                            {
                                text: this.$t('Cancel'),
                                onClick: function () {
                                    return false;
                                },
                            },
                        ],
                    });
                    resultDialog = await dlg.show();

                    if (resultDialog && this.onOk != null) {
                        this.$refs['projectWizard']?.hide();
                        this.onOk();
                    }
                }
            }
        },
        async onBackupSelectionChanged(e) {
            this.selectedBackup = e.selectedRowsData?.[0];
        },
        /**
         * Calls the API to remove the selected backup.
         */
        async onBackupRemoved(e) {
            if (!this.$store.state.auth.user.permissions.PermissionWrite) return;
            await ProdComProjectService.deleteBackup(this.projectId, e?.data?.Id);
            await this.onItemShow();
        },
    },
};
</script>
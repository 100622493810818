<template>
    <b-modal v-on:hide="resetLoadingCounts" id="adminPanel" hide-footer :title="$t('Admin panel')" size="xxl" no-close-on-backdrop no-close-on-esc  v-on:close="destroy">
        <b-card no-body class="prevent-select">
            <b-tabs pills card>
                <b-tab v-on:click="refreshTab('GroupTabPanel')" active>
                    <template #title
                        ><font-awesome-icon icon="users" /> {{ $t('Groups') }} <b-badge pill variant="info">{{ groupsCount }}</b-badge></template
                    >
                    <GroupTabPanel v-on:group-count="changeGroupsCount" />
                </b-tab>
                <b-tab v-on:click="refreshTab('UserTabPanel')">
                    <template #title>
                        <font-awesome-icon icon="user" />
                        {{ $t('Users') }}
                        <b-badge pill variant="info">{{ usersCount }}</b-badge>
                    </template>
                    <UserTabPanel v-on:usr-count="changeUsersCount" />
                </b-tab>
                <b-tab v-on:click="refreshTab('RoleTabPanel')">
                    <template #title
                        ><font-awesome-icon icon="user-tag" /> {{ $t('Roles') }} <b-badge pill variant="info">{{ rolesCount }}</b-badge>
                    </template>
                    <RoleTabPanel v-on:role-count="changeRolesCount" />
                </b-tab>
                <!-- <b-tab v-on:click="refreshTab('DatabaseTabPanel')">
                    <template #title
                        ><font-awesome-icon icon="database" /> {{ $t('Databases') }} <b-badge pill variant="info">{{ connectionStringsCount }}</b-badge></template
                    >
                    <DatabaseTabPanel v-on:cs-count="changeConnectionStringsCount" />
                </b-tab>
                <b-tab v-on:click="refreshTab('RTWebConnectionsTabPanel')">
                    <template #title
                        ><font-awesome-icon icon="cogs" /> {{ $t('RTWebConnections') }} <b-badge pill variant="info">{{ rtWebConnectionsCount }}</b-badge></template
                    >
                    <RTWebConnectionTabPanel v-on:rtwc-count="changeRTWebConnectionsCount" />
                </b-tab>
                <b-tab v-on:click="refreshTab('WebSourcesTabPanel')">
                    <template #title
                        ><font-awesome-icon icon="globe" /> {{ $t('WebSources') }} <b-badge pill variant="info">{{ webSourcesCount }}</b-badge></template
                    >
                    <WebSourceTabPanel v-on:websource-count="changeWebSourcesCount" />
                </b-tab>
                <b-tab v-on:click="refreshTab('SlideshowsTabPanel')">
                    <template #title
                        ><font-awesome-icon icon="comment-alt" /> {{ $t('Slideshows') }} <b-badge pill variant="info">{{ slideshowsCount }}</b-badge></template
                    >
                    <SlideshowTabPanel v-on:slideshow-count="changeSlideshowsCount" />
                </b-tab>
                <b-tab v-on:click="refreshTab('MessagesTabPanel')">
                    <template #title
                        ><font-awesome-icon icon="comment-alt" /> {{ $t('Messages') }} <b-badge pill variant="info">{{ messagesCount }}</b-badge></template
                    >
                    <MessageTabPanel v-on:message-count="changeMessagesCount" />
                </b-tab> -->
                <b-tab v-on:click="refreshTab('ConnectionsTabPannel')" :disabled="connectionsCount == 0">
                    <template #title>
                        <font-awesome-layers>
                            <font-awesome-icon icon="fa-solid fa-user" />
                            <font-awesome-icon icon="fa-solid fa-wifi" transform="shrink-9 up-5 right-8" />
                        </font-awesome-layers>
                        {{ $t('Connections') }}
                        <b-badge pill variant="info">{{ connectionsCount }}</b-badge>
                    </template>
                    <ConnectionsTabPanel v-on:message-count="changeConnectionsCount" />
                </b-tab>
                <b-tab v-on:click="refreshTab('SettingsTabPAnel')">
                    <template #title><font-awesome-icon icon="cogs" /> {{ $t('Settings') }}</template>
                    <SettingsTabPanel />
                </b-tab>
            </b-tabs>
        </b-card>
    </b-modal>
</template>
<script>
import UserTabPanel from '@/components/admin/UserTabPanel.vue';
import GroupTabPanel from '@/components/admin/GroupTabPanel.vue';
import RoleTabPanel from '@/components/admin/RoleTabPanel.vue';
import DatabaseTabPanel from '@/components/admin/DatabaseTabPanel.vue';
import RTWebConnectionTabPanel from '@/components/admin/RTWebConnectionTabPanel.vue';
import MessageTabPanel from '@/components/admin/MessageTabPanel.vue';
import WebSourceTabPanel from '@/components/admin/WebSourceTabPanel.vue';
import SlideshowTabPanel from '@/components/admin/SlideshowTabPanel.vue';
import SettingsTabPanel from '@/components/admin/SettingsTabPanel.vue';
import ConnectionsTabPanel from '@/components/admin/ConnectionsTabPanel.vue';

export default {
    name: 'AdminPanel',
    components: {
        UserTabPanel,
        GroupTabPanel,
        RoleTabPanel,
        DatabaseTabPanel,
        RTWebConnectionTabPanel,
        WebSourceTabPanel,
        MessageTabPanel,
        SlideshowTabPanel,
        SettingsTabPanel,
        ConnectionsTabPanel,
    },
    data() {
        return {
            usersCount: 0,
            groupsCount: 0,
            rolesCount: 0,
            connectionStringsCount: 0,
            rtWebConnectionsCount: 0,
            webSourcesCount: 0,
            messagesCount: 0,
            slideshowsCount: 0,
            connectionsCount: 0,
        };
    },
    methods: {
        destroy() {
            this.$EventBus.$emit("AdminPanelClose")
        },
        resetLoadingCounts() {
            this.$store.commit('resetLoadingCount');
        },
        async refreshTab(tabRef) {
            this.$EventBus.$emit('refresh-admin-panel-tab', tabRef);
        },
        changeUsersCount(value) {
            this.usersCount = value;
        },
        changeGroupsCount(value) {
            this.groupsCount = value;
        },
        changeRolesCount(value) {
            this.rolesCount = value;
        },
        changeConnectionStringsCount(value) {
            this.connectionStringsCount = value;
        },
        changeRTWebConnectionsCount(value) {
            this.rtWebConnectionsCount = value;
        },
        changeWebSourcesCount(value) {
            this.webSourcesCount = value;
        },
        changeMessagesCount(value) {
            this.messagesCount = value;
        },
        changeSlideshowsCount(value) {
            this.slideshowsCount = value;
        },
        changeConnectionsCount(value) {
            this.connectionsCount = value;
        },
    },
};
</script>
<template>
    <div class="app-theme prevent-select" @contextmenu.prevent="openContextMenu">
        <TopMenu v-show="!isIframe" />
        <div
            id="app"
            v-bind:style="{
                'padding-top': isIframe ? 0 : '60px',
            }"
        >
            <b-progress class="progress-bar-reverse" v-if="loading" height="4px" style="position: absolute; top: 0; width: 100%; z-index: 99999" :value="100" striped :animated="true"></b-progress>
            
            <div v-on:click="$EventBus.$emit('hide-side-bar')">
                <router-view class="main-view" />
            </div>
            <Footer />
            <AdminPanel v-if="loggedIn && minLevelRole <= $RoleLevels.Admin" />
            <b-toast id="toastError" v-bind:variant="toastType" solid :auto-hide-delay="autoHideDelay">
                <template #toast-title>
                    <div class="d-flex flex-grow-1 align-items-baseline">
                        <b-img v-bind:class="toastType" blank class="mr-2" width="12" height="12"></b-img>
                        <strong class="mr-auto">{{ toastTitle }}</strong>
                        <small class="text-muted mr-2">{{ toastExtraTitle }}</small>
                    </div>
                </template>
                {{ toastErrorMessage }}
            </b-toast>
            <AboutBox />
            <Help/>
            <!-- Display the context menu globally so that it is available to all components of the app. -->
            <ContextMenu 
                :locationX="this.$store.state.contextMenu.positionX" 
                :locationY="this.$store.state.contextMenu.positionY" />
        </div>
    </div>
</template>
<script>
import SideMenu from '@/components/SideMenu.vue';
import TopMenu from '@/components/TopMenu.vue';
import AdminPanel from '@/components/admin/AdminPanel.vue';
import Footer from '@/components/Footer.vue';
import ToastAlert from '@/utils/ToastAlert';
import AboutBox from '@/components/AboutBox.vue';
import Help from '@/components/Help.vue';
import ContextMenu from "@/components/ContextMenu.vue";

import { BToast } from 'bootstrap-vue';

export default {
    data() {
        return {
            toastType: 'danger',
            toastErrorMessage: '',
            toastTitle: this.$t('danger'),
            toastExtraTitle: '',
            autoHideDelay: 6000,
            isIframe: window.location !== window.parent.location,
        };
    },
    components: {
        SideMenu,
        TopMenu,
        AdminPanel,
        Footer,
        BToast,
        AboutBox,
        ContextMenu,
        Help,
    },
    computed: {
        loading() {
            return this.$store.state.loadingCount > 0;
        },
        minLevelRole() {
            return this.$store.state.auth.user == null ? 999 : this.$store.state.auth.user.minLevelRole;
        },
        loggedIn() {
            return this.$store.state.auth.status.loggedIn && this.$store.state.auth.user != null && this.$store.state.auth.user.email != '';
        },
        expired() {
            return this.$store.state.expired;
        }
    },
    watch: {
        async expired(newValue, oldValue){
            // When a new expiration occurs, force user logout so that he is forced to be authenticated as guest
            if(newValue == 1){
                this.$EventBus.$emit('show-toast', new ToastAlert(this.$t('license.expired'), 'warning'));
                await this.$store.dispatch('auth/logout');
                this.$store.state.expired = 2;
                this.$router.push('/login');
            }
            if(newValue == 2){
            }
        }
    },
    async mounted() {
        // get user informations
        if (this.$store.state.auth.user && this.$store.state.auth.user.accessToken) await this.getUserInfos();
        this.$EventBus.$on('show-toast', this.showToast);
    },
    methods: {
        async getUserInfos() {
            try {
                const settings = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        Method: 'users.getUserInfos',
                        Token: this.$store.state.auth.user.accessToken,
                        Parameters: {},
                    }),
                };

                const res = await fetch(this.$store.state.settings.API_URL, settings);
                if (res.status == 401) {
                    await this.$store.dispatch('auth/logout');
                    if (this.$router.currentRoute.name == 'home') this.$router.push('home');
                    return;
                }
                const data = await res.json();

                if (data.success == 'n') this.showToast(new ToastAlert(this.$t(data.ret, data.retParams), 'warning'));

                this.$store.commit('auth/updateUserInfos', data.ret);
            } catch (error) {
                this.showToast(new ToastAlert(this.$t('Server does not respond'), 'danger'));
            }
        },
        showToast(error) {
            if (error.type == undefined) {
                this.toastType = 'danger';
                this.toastErrorMessage = error;
                this.toastTitle = this.$t('danger');
                this.toastExtraTitle = '';
            } else {
                this.toastType = error.type == '' ? 'danger' : error.type;
                this.toastErrorMessage = error.needTranslantion ? this.$t(error.message, error.translationParameters) : error.message;
                this.toastTitle = error.title == '' ? this.$t(this.toastType) : error.title;
                this.toastExtraTitle = error.extraTitle;
            }
            this.autoHideDelay = error.autoHideDelay ? error.autoHideDelay : 6000;
            this.$bvToast.show('toastError');
        },
        async openContextMenu(e){
            // Prevent context menu from being shown
        }
    },
    errorCaptured(error) {
        this.showToast(this.$t('There was an unhandled error, please try again later'));
        return process.env.NODE_ENV === 'development'; // stop error propagation
    },
};
</script>
<style lang="scss">
.main-view {
    width: 100%;
    height: 100%;
}
.progress-bar-animated {
    // transform: rotate(180deg);
    background-color: var(--primary)!important;
}
#app {
    padding-top: 60px;
}
</style>

<template>
    <div>
        <!-- Tab list -->
        <div class="card-header">
            <ul class="nav nav-pills">
                <li v-for="tab in tabs" :key="tab.id">
                    <a class="nav-link tabClick" v-bind:class="{'active': tab.active}" v-on:click="selectTab(tab)">
                        <font-awesome-icon v-if="tab.icon" :icon="tab.icon" class="top-menu-icon mr-1 fa-lg" :color="tab.iconColor"  />
                        {{tab.text}}
                    </a>
                </li>
            </ul>
        </div>
        <!-- Tab content -->
        <div v-if="currentTab.id == 0">
            <Monitor/>
        </div>
        <div v-else-if="currentTab.id == 1">
            <ActionMonitor/>
        </div>
        <!-- ProdCom_Mirroring records -->
        <div v-if="currentTab.id == 2">
            <Table table="ProdCom_Mirroring" :columns="columnsProdComEvents" :helpText="$t('help.run.mirror')"/>
        </div>
    </div>
</template>

<script>
import Monitor from '@/components/realTime/Monitor.vue'
import Table from '@/components/history/Table.vue'
import ActionMonitor from '@/components/realTime/ActionMonitor.vue'
export default {
    name: "RealTime",
    components: {
        Monitor, ActionMonitor,Table
    },
    data(){
        return {
            tabs: [
                {id: 0, text: this.$t("monitor.values.title"), active: true, icon: 'fas fa-chart-line', iconColor: "#5492dc"},
                {id: 1, text: this.$t("monitor.actions.title"), active: false, icon: 'fa-arrows-rotate', iconColor: "#8dc149"},
                {id: 2, text: this.$t("monitor.mirror.title"), active: false, icon: 'fa-copy', iconColor: "#ffa726"},
            ],
            columnsProdComEvents: [
                {key: 0, dataField: 'ProdCom_Mirroring_ID', dataType: 'number', caption: 'ProdCom_Mirroring_ID', visible: false},
                {key: 2, dataField: 'EQUIP', dataType: 'string', caption: this.$t('Equipment'), visible: true, groupIndex: 1, sortOrder: 'asc'},
                {key: 3, dataField: 'GROUP', dataType: 'string', caption: this.$t('Group'), visible: true, groupIndex: 2, sortOrder: 'asc'},
                {key: 4, dataField: 'ITEM', dataType: 'string', caption: this.$t('Name'), visible: true, sortOrder: 'asc'},
                {key: 10, dataField: 'VALUE', dataType: 'string', caption: this.$t('Value'), visible: true},
                {key: 1, dataField: 'HD', dataType: 'datetime', caption: 'HD', visible: true, format: { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' }},
                {key: 11, dataField: 'VALUEN1', dataType: 'string', caption: this.$t('ValueN1'), visible: true},
                {key: 8, dataField: 'MIN', dataType: 'string', caption: this.$t('min'), visible: true},
                {key: 5, dataField: 'HDMIN', dataType: 'datetime', caption: 'HD Min', visible: true, format: { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' }},
                {key: 7, dataField: 'MAX', dataType: 'string', caption: this.$t('max'), visible: true},
                {key: 6, dataField: 'HDMAX', dataType: 'datetime', caption: 'HD Max', visible: true, format: { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' }},
                {key: 9, dataField: 'PRODCOM', dataType: 'string', caption: this.$t('Computer'), visible: true},
            ]
        }
    },
    computed: {
        currentTab(){
            return this.tabs.find(tab => tab.active);
        }
    },
    async mounted(){
    },
    methods: {
        async selectTab(tab){
            for(const t of this.tabs){
                t.active = false;
            }
            tab.active = true;
        }
    }
}
</script>

<style lang="scss" scoped>
.tabClick {
    cursor: pointer;
}
</style>
<template>
    <b-tabs pills card h-100 d-flex flex-column style="width: 100%">
        <b-tab>
            <template #title>
                <span class="tabHeaderText">
                    {{ $t('Connection') }}
                    <font-awesome-icon icon="fa fa-warning" class="ml-1 text-danger" v-if="urlManagerState == false" />
                </span>
            </template>

            <b-form>
                <b-row>
                    <b-col cols="4">
                        <span>{{ $t('equipment.prodManager.managerUrl') }} *</span>
                    </b-col>
                    <b-col cols="4">
                        <b-form-input id="urlManager" :state="urlManagerState" v-model="urlManager" v-if="connectedToManager != 2" />
                        <span v-else> {{ this.urlManager }} </span>
                    </b-col>
                    <b-col cols="4">
                        <div v-on:click="selectManager" v-if="connectedToManager == 0">
                            <font-awesome-icon icon="check" class="top-menu-icon mr-1 fa-lg" color="#1ca8dd" />
                        </div>
                        <div v-on:click="selectManager" v-else-if="connectedToManager == 1">
                            <font-awesome-icon icon="spinner-third" class="fa-spin-custom fa-lg" color="#1ca8dd" />
                        </div>
                        <div v-on:click="selectManager" v-else-if="connectedToManager == 2">
                            <font-awesome-icon icon="pen" class="top-menu-icon mr-1 fa-lg" color="#1ca8dd" />
                        </div>
                    </b-col>
                </b-row>
                <b-row style="margin-top: 5px">
                    <b-col cols="4">
                        <span>Ligne :</span>
                    </b-col>
                    <b-col cols="4">
                        <b-form-input
                            id="pkLigne"
                            type="number"
                            v-model="pkLigne"
                            min="0"
                            max="999999999"
                            @change="
                                (value) => {
                                    if (value == null || value == '') {
                                        this.pkLigne = 1;
                                    } else if (value > 999999999) {
                                        this.pkLigne = 999999999;
                                    } else if (value < 0) {
                                        this.pkLigne = 0;
                                    }
                                }
                            "
                        />
                    </b-col>
                </b-row>
                <b-row style="margin-top: 5px">
                    <b-col cols="4">
                        <span>Equipement :</span>
                    </b-col>
                    <b-col cols="4">
                        <b-form-input
                            id="pkEquipment"
                            type="number"
                            v-model="pkEquipment"
                            min="0"
                            max="999999999"
                            @change="
                                (value) => {
                                    if (value == null || value == '') {
                                        this.pkEquipment = 1;
                                    } else if (value > 999999999) {
                                        this.pkEquipment = 999999999;
                                    } else if (value < 0) {
                                        this.pkEquipment = 0;
                                    }
                                }
                            "
                        />
                    </b-col>
                </b-row>
            </b-form>
        </b-tab>

        <b-tab>
            <template #title>
                <span class="tabHeaderText">{{ $t('equipment.prodManager.realTimeLink') }}</span>
            </template>

            <b-form>
                <b-row>
                    <b-col cols="3">
                        <span>Cible temps réel source: </span>
                    </b-col>
                    <b-col cols="6">
                        <b-form-select id="sourceTarget" v-model="sourceEquipment" :options="availableEquipments" v-on:change="sourceEquipmentChanged" />
                    </b-col>
                </b-row>
                <b-row style="margin-top: 10px" v-if="sourceEquipment != this.$t('None')">
                    <DxDataGrid :dataSource="links" :allow-column-reordering="true" :repaint-changes-only="true" :show-borders="false" :selection="{ mode: 'single' }" :columnAutoWidth="true" show-scrollbar="onHover" width="100%" :allow-column-resizing="true">
                        <DxColumn data-field="ActionName" data-type="string" :allow-editing="true" :caption="$t('Action Name')" alignment="left"> </DxColumn>
                        <DxColumn data-field="SourceGroup" data-type="string" :allow-editing="true" :caption="$t('Source Group')" alignment="left">
                            <DxLookup :data-source="sourceGroups" />
                        </DxColumn>
                        <DxColumn data-field="SourceVariable" data-type="string" :allow-editing="true" :caption="$t('Source Variable')" alignment="left">
                            <DxLookup :data-source="getSourceItems" @value-changed="onSourceItemValueChanged" />
                        </DxColumn>
                        <DxColumn data-field="DestinationGroup" data-type="string" :allow-editing="true" :caption="$t('Destination Group')" alignment="left">
                            <DxLookup :data-source="destinationGroups" />
                        </DxColumn>
                        <DxColumn data-field="DestinationVariable" data-type="string" :allow-editing="true" :caption="$t('Destination Variable')" alignment="left">
                            <DxLookup :data-source="getDestinationItems" @value-changed="onDestinationItemValueChanged" />
                        </DxColumn>
                        <DxColumn data-field="TriggerGroup" data-type="string" :allow-editing="true" :caption="$t('Trigger Group')" alignment="left">
                            <DxLookup :data-source="sourceGroups" />
                        </DxColumn>
                        <DxColumn data-field="TriggerVariable" data-type="string" :allow-editing="true" :caption="$t('Trigger Variable')" alignment="left">
                            <DxLookup :data-source="getTriggerItems" @value-changed="onTriggerItemValueChanged" />
                        </DxColumn>
                    </DxDataGrid>
                </b-row>
            </b-form>
        </b-tab>

        <b-tab>
            <template #title>
                <span class="tabHeaderText">{{ $t('Actions') }}</span>
            </template>

            <b-card class="card-items-scroll">
                <TreeView id="data-treeview-items" :item="itemListActions" :itemSelect="onSelectItemActions" :multiSelect="false" />
            </b-card>
        </b-tab>

        <b-tab>
            <template #title>
                <span class="tabHeaderText">{{ $t('equipment.prodManager.groupsAndVariables') }}</span>
            </template>
            <b-card class="card-items-scroll">
                <TreeView id="data-treeview-items" :item="itemListVariables" :itemSelect="onSelectItemActions" :multiSelect="false" />
            </b-card>
        </b-tab>

        <b-tab>
            <template #title>
                <span class="tabHeaderText">{{ $t('Data Source') }}</span>
            </template>
            <DataSource :onEntityChange="onEntityChange" :datasource="dataSource" ref="managerDataSource" />
            <div class="tabContent" v-if="dataSource.Provider != 'ProductysManager'">
                <b-form class="triggerForm">
                    <b-row style="margin-top: 5px">
                        <b-col cols="4">
                            <b-form-group :label="$t('Entity')" label-for="entity">
                                <b-form-input id="pkEntity" type="number" v-model="pkEntity" min="0" max="999999999" />
                            </b-form-group>
                        </b-col>
                    </b-row>
                </b-form>
            </div>
        </b-tab>
    </b-tabs>
</template>

<script>
import ToastAlert from '@/utils/ToastAlert';
import DataSource from '@/components/configuration/DataSources/DataSource.vue';
import ProdComEquipmentService from '@/services/prodcom.equipments.service.js';
import TreeView from '@/components/TreeView.vue';
import { uuid } from 'vue-uuid';
import { DxDataGrid, DxColumn, DxToolbar, DxItem, DxLookup } from 'devextreme-vue/data-grid';
export default {
    name: 'ConfigProductysManager',
    components: {
        DataSource,
        DxDataGrid,
        DxColumn,
        DxToolbar,
        DxItem,
        DxLookup,
        TreeView,
    },
    props: {
        equipment: Object,
        project: String,
        updateMode: Boolean,
    },
    computed: {
        invalidState() {
            return this.urlManagerState === false;
        },
        urlManagerState() {
            return this.urlManager == '' || this.urlManager == null ? false : null;
        },
    },
    data() {
        return {
            pkLigne: 1,
            pkEquipment: 1,
            pkEntity: 1,
            dataSource: { type: 'datasource', ProjectId: this.project, Provider: 'PostgreSQL', EntityList: [] },
            sourceEquipment: null,
            availableEquipments: [],
            links: [],
            sourceGroups: [],
            sourceItems: [],
            template: null,
            destinationGroups: [],
            destinationItems: [],
            itemListActions: [],
            itemListVariables: [],
            connectedToManager: 0,
            urlManager: 'http://127.0.0.1:8083/',
            lineList: null,
            equipmentList: null,
        };
    },
    async mounted() {
        // Get template details
        var data = await ProdComEquipmentService.getAllEquipments(this.project);
        if (data.success == 'n') this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(data.ret), 'warning'));
        else {
            this.availableEquipments.push(this.$t('None'));
            for (const i of data.ret.map((r) => r.Name)) {
                this.availableEquipments.push(i);
            }
            this.sourceEquipment = this.availableEquipments[0];
        }

        data = await ProdComEquipmentService.getTemplate('ProductysManager');
        if (data.success == 'n') this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(data.ret), 'warning'));
        else {
            this.template = data.ret;
        }

        this.template.Actions = this.template.Actions.sort((a, b) => a.Group.localeCompare(b.Group));

        for (const act of this.template.Actions) {
            var existingGroup = this.itemListActions.find((ac) => ac.text == act.Group);
            if (existingGroup == null) {
                this.itemListActions.push({ id: act.Group, text: act.Group, expanded: false, items: [], contextMenuSelected: false, selected: false });
                existingGroup = this.itemListActions.find((ac) => ac.text == act.Group);
            }
            existingGroup.items.push({ id: act.Name, text: act.Name, expanded: false, items: null, icon: 'fas fa-function', iconColor: '#8dc149', obj: act, contextMenuSelected: false, selected: false });
        }

        for (const variable of this.template.Variables) {
            var existingGroup = this.itemListVariables.find((ac) => ac.text == variable.Group);
            if (existingGroup == null) {
                this.itemListVariables.push({ id: variable.Group, text: variable.Group, expanded: false, items: [], contextMenuSelected: false, selected: false });
                existingGroup = this.itemListVariables.find((ac) => ac.text == variable.Group);
            }
            existingGroup.items.push({ id: variable.Name, text: variable.Name, expanded: false, items: null, icon: 'fas fa-tag', iconColor: '#f57c00', obj: variable, contextMenuSelected: false, selected: false });
        }
    },
    methods: {
        validate() {
            if (this.invalidState) return null;
            else return true;
        },
        async sourceEquipmentChanged(e) {
            if (e == this.$t('None')) return;
            const data = await ProdComEquipmentService.getGroups(e, this.project);
            if (data.success == 'n') this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(data.ret), 'warning'));
            else {
                this.sourceGroups = data.ret.List;
                for (const grp of this.sourceGroups) {
                    const result = await ProdComEquipmentService.getItems(e, grp, this.project);
                    if (result.success == 'n') this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(result.ret, result.retParams), 'warning'));
                    else {
                        for (const [key, val] of Object.entries(result.ret.List)) {
                            this.sourceItems.push({ group: grp, item: val.Name, value: val });
                        }
                    }
                }

                this.destinationGroups = [];
                for (const variable of this.template.Variables) {
                    if (this.destinationGroups.findIndex((v) => v == variable.Group) == -1) this.destinationGroups.push(variable.Group);
                }

                this.links = [
                    {
                        ActionName: `${e} - 1 - ECHG - CptPrincipal`,
                        SourceGroup: this.sourceGroups[0],
                        SourceVariable: this.sourceItems.find((it) => it.group == this.sourceGroups[0]).item,
                        DestinationGroup: 'Compteurs',
                        DestinationVariable: 'CptPrincipal',
                        TriggerGroup: this.sourceGroups[0],
                        TriggerVariable: this.sourceItems.find((it) => it.group == this.sourceGroups[0]).item,
                    },
                    {
                        ActionName: `${e} - 2 - ECHG - CptSecondaire`,
                        SourceGroup: this.sourceGroups[0],
                        SourceVariable: this.sourceItems.find((it) => it.group == this.sourceGroups[0]).item,
                        DestinationGroup: 'Compteurs',
                        DestinationVariable: 'CptSecondaire',
                        TriggerGroup: this.sourceGroups[0],
                        TriggerVariable: this.sourceItems.find((it) => it.group == this.sourceGroups[0]).item,
                    },
                    {
                        ActionName: `${e} - 3 - ECHG - RAZCompteurs`,
                        SourceGroup: this.sourceGroups[0],
                        SourceVariable: this.sourceItems.find((it) => it.group == this.sourceGroups[0]).item,
                        DestinationGroup: 'Compteurs',
                        DestinationVariable: 'RazCompteurs',
                        TriggerGroup: this.sourceGroups[0],
                        TriggerVariable: this.sourceItems.find((it) => it.group == this.sourceGroups[0]).item,
                    },
                    {
                        ActionName: `${e} - 4 - ECHG - EtatEquipement`,
                        SourceGroup: this.sourceGroups[0],
                        SourceVariable: this.sourceItems.find((it) => it.group == this.sourceGroups[0]).item,
                        DestinationGroup: 'LIGNE',
                        DestinationVariable: 'Marche_Arret',
                        TriggerGroup: this.sourceGroups[0],
                        TriggerVariable: this.sourceItems.find((it) => it.group == this.sourceGroups[0]).item,
                    },
                    {
                        ActionName: `${e} - 5 - ECHG - SousCadence`,
                        SourceGroup: this.sourceGroups[0],
                        SourceVariable: this.sourceItems.find((it) => it.group == this.sourceGroups[0]).item,
                        DestinationGroup: 'Parametres',
                        DestinationVariable: 'EmulationSousCadence',
                        TriggerGroup: this.sourceGroups[0],
                        TriggerVariable: this.sourceItems.find((it) => it.group == this.sourceGroups[0]).item,
                    },
                ];
            }
        },
        getSourceItems(e) {
            if (e.data == null) return [];
            return this.sourceItems.filter((it) => it.group === e.data.SourceGroup).map((it) => it.item);
        },
        getDestinationItems(e) {
            if (e.data == null) return [];
            return this.template.Variables.filter((it) => it.Group === e.data.DestinationGroup).map((it) => it.Name);
        },
        getTriggerItems(e) {
            if (e.data == null) return [];
            return this.sourceItems.filter((it) => it.group === e.data.TriggerGroup).map((it) => it.item);
        },
        onSourceItemValueChanged(e) {},
        onDestinationItemValueChanged(e) {},
        onTriggerItemValueChanged(e) {},
        async onSelectItemActions(e) {},
        async selectManager(e) {
            if (this.connectedToManager == 0) {
                // Initiate a new connection to manager
                this.connectedToManager = 1;
                // Simulate waiting instead of real query. Will do real query after.
                // await new Promise(r => setTimeout(r, 200));

                var result = await ProdComEquipmentService.getManagerConfiguration(this.urlManager);
                if (result.success == 'n') {
                    this.connectedToManager = 0;
                    this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(result.ret, result.retParams), 'warning'));
                } else {
                    if ((result.ret.Server == '127.0.0.1' || result.ret.Server == 'localhost') && new URL(this.urlManager).hostname != '127.0.0.1' && new URL(this.urlManager).hostname != 'localhost') {
                        // Replace "127.0.0.1" by public ip if needed
                        result.ret.Server = new URL(this.urlManager).hostname;
                    }
                    this.connectedToManager = 2;
                    this.dataSource.Provider = 'ProductysManager';
                    this.dataSource.type = 'datasource_manager';
                    this.dataSource.ProjectId = this.project;
                    (this.dataSource.Server = result.ret.Server), (this.dataSource.Port = result.ret.PortNumber), (this.dataSource.Username = result.ret.UserName), (this.dataSource.Password = result.ret.Password), (this.dataSource.Database = result.ret.Database), (this.dataSource.Entity = 1);

                    result = await ProdComEquipmentService.getProductysManagerLines(this.urlManager, this.dataSource.Entity);
                    if (result.success == 'n') {
                        this.connectedToManager = 0;
                        this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(result.ret, result.retParams), 'warning'));
                    } else {
                        this.dataSource.EntityList = result.ret.entities;
                        this.lineList = result.ret.lines;
                        this.equipmentList = result.ret.equipments;
                    }
                }
            } else if (this.connectedToManager == 2) {
                // Reset to validate view
                this.connectedToManager = 0;
                this.dataSource = { type: 'datasource', ProjectId: this.project, Provider: 'PostgreSQL' };
            }
        },
        async synchronizeEquipment() {
            this.equipment.Entity = this.dataSource.type == 'datasource_manager' ? this.dataSource.Entity : this.pkEntity;
            this.equipment.Line = this.pkLigne;
            this.equipment.Equipment = this.pkEquipment;
            this.equipment.RealTime = this.links;
            this.equipment.RealTimeTarget = this.sourceEquipment;
            if (this.dataSource.type == 'datasource_manager') {
                // data source obtained from Productys Manager
                var config = await this.$refs['managerDataSource']?.getConfiguration();
                this.equipment.DataSourceManager = config;
                this.equipment.DataSourceManager.Name = 'Productys Manager';
                this.equipment.DataSourceManager.Id = uuid.v4();
                this.equipment.Entity = config.Entity;
            } else if (this.dataSource.type == 'datasource') {
                // data source obtained from this project's configigured data source
                var templateId = this.$refs['managerDataSource']?.getTemplateId();
                this.equipment.DataSource = templateId;
            }
        },
        async onEntityChange(e) {
            result = await ProdComEquipmentService.getProductysManagerLines(this.urlManager, e);
            if (result.success == 'n') {
                this.connectedToManager = 0;
                this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(result.ret, result.retParams), 'warning'));
            } else {
                //this.dataSource.EntityList = result.ret.entities;
                this.lineList = result.ret.lines;
                this.equipmentList = result.ret.equipments;
            }
        },
    },
};
</script>
<template>
    <div>
        <b-tab>
            <template #title>{{$t('actions.cartexr.title1')}}</template>
            <b-card>
                <b-form>
                    <b-row>
                        <b-col cols="6">
                            <b-form-group :label="$t('actions.cartexr.inputEffectif')">
                                <b-form-select id="activeEffectif" v-model="InputEffectif" :options="availableEffectifs" :class="{'disabled': !$store.state.auth.user.permissions.PermissionWrite}"/>
                            </b-form-group>
                        </b-col>
                        <b-col cols="6" v-if="InputEffectif == 0">
                            <b-form-group :label="$t('Constant')">
                                <b-form-input id="ConstantEffectifActif" v-model="ConstantEffectifInput" type="number" min="0" max="999999" :class="{'disabled': !$store.state.auth.user.permissions.PermissionWrite}"/>
                            </b-form-group>
                        </b-col>
                        <b-col cols="3" v-if="InputEffectif == 1">
                            <b-form-group :label="$t('Group') + ' *'" label-for="inputGroup">
                                <b-form-select id="inputGroup" v-model="InputGroup" :options="availableGroups" v-on:change="inputGroupChanged" :class="{'disabled': !$store.state.auth.user.permissions.PermissionWrite}"/>
                            </b-form-group>
                        </b-col>
                        <b-col cols="3" v-if="InputEffectif == 1">
                            <b-form-group :label="$t('Item') + ' *'" label-for="inputItem">
                                <b-form-select id="inputItem" v-model="InputItem" :options="availableInputItems" :class="{'disabled': !$store.state.auth.user.permissions.PermissionWrite}"/>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="3" style="margin-top: 35px;">
                            <b-form-checkbox v-model="ExtentCard" :class="{'disabled': !$store.state.auth.user.permissions.PermissionWrite}">{{$t('actions.cartexr.extent')}}</b-form-checkbox>
                        </b-col>
                        <b-col cols="3">
                            <b-form-group :label="$t('Group')" label-for="sourceGroup" :class="{'disabled': !ExtentCard}">
                                <b-form-select id="sourceGroup" v-model="ExtentGroup" :options="availableGroups" v-on:change="extentGroupChanged" :class="{'disabled': !$store.state.auth.user.permissions.PermissionWrite}"/>
                            </b-form-group>
                        </b-col>
                        <b-col cols="3">
                            <b-form-group :label="$t('Item')" label-for="sourceItem" :class="{'disabled': !ExtentCard}">
                                <b-form-select id="sourceItem" v-model="ExtentItem" :options="availableExtentItems" :class="{'disabled': !$store.state.auth.user.permissions.PermissionWrite}"/>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="3" style="margin-top: 35px;">
                            <b-form-checkbox v-model="AverageCard" :class="{'disabled': !$store.state.auth.user.permissions.PermissionWrite}">{{$t('actions.cartexr.average')}}</b-form-checkbox>
                        </b-col>
                        <b-col cols="3">
                            <b-form-group :label="$t('Group')" label-for="sourceGroup" :class="{'disabled': !AverageCard}">
                                <b-form-select id="sourceGroup" v-model="AverageGroup" :options="availableGroups" v-on:change="averageGroupChanged" :class="{'disabled': !$store.state.auth.user.permissions.PermissionWrite}"/>
                            </b-form-group>
                        </b-col>
                        <b-col cols="3">
                            <b-form-group :label="$t('Item')" label-for="sourceItem" :class="{'disabled': !AverageCard}">
                                <b-form-select id="sourceItem" v-model="AverageItem" :options="availableAverageItems" :class="{'disabled': !$store.state.auth.user.permissions.PermissionWrite}"/>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </b-form>
            </b-card>
        </b-tab>
        <b-tab>
            <template #title>{{$t('actions.cartexr.title2')}}</template>
            <b-form>
                <b-card :header="$t('actions.cartexr.desiredLimits')" style="margin-top: 10px; margin-bottom: 10px;">
                    <b-row>
                        <b-col cols="6">
                            <b-form-group :label="$t('actions.cartexr.limitType')">
                                <b-form-select id="activeEffectif" v-model="OutputDesiredLimit" :options="availableEffectifs1" :class="{'disabled': !$store.state.auth.user.permissions.PermissionWrite}"/>
                            </b-form-group>
                        </b-col>
                        <b-col cols="6" v-if="OutputDesiredLimit == -1" style="margin-top: 30px;">
                            <span >{{$t('actions.cartexr.discreteLimitAuto')}}</span>
                        </b-col>
                        <b-col cols="3" v-if="OutputDesiredLimit == 0">
                            <b-form-group label="LIC">
                                <b-form-input id="ConstantEffectifActif" v-model="ConstantOutputDesiredLimitLic" type="number" min="0" max="999999" :class="{'disabled': !$store.state.auth.user.permissions.PermissionWrite}"/>
                            </b-form-group>
                        </b-col>
                        <b-col cols="3" v-if="OutputDesiredLimit == 0">
                            <b-form-group label="LSC">
                                <b-form-input id="ConstantEffectifActif" v-model="ConstantOutputDesiredLimitLsc" type="number" min="0" max="999999" :class="{'disabled': !$store.state.auth.user.permissions.PermissionWrite}"/>
                            </b-form-group>
                        </b-col>
                        <b-col cols="6" v-if="OutputDesiredLimit == 1">
                            <b-row>
                                <b-col>
                                    <b-form-group :label="$t('Group') + ' LIC *'" label-for="inputGroup">
                                        <b-form-select id="inputGroup" v-model="OutputDesiredLimitGroupLic" :options="availableGroups" v-on:change="outputDesiredLimitGroupLicChanged" :class="{'disabled': !$store.state.auth.user.permissions.PermissionWrite}"/>
                                    </b-form-group>
                                </b-col>
                                <b-col>
                                    <b-form-group :label="$t('Item') + ' LIC *'" label-for="inputItem">
                                        <b-form-select id="inputItem" v-model="OutputDesiredLimitItemLic" :options="availableOuptutDesiredLimitLicItems" :class="{'disabled': !$store.state.auth.user.permissions.PermissionWrite}"/>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col>
                                    <b-form-group :label="$t('Group') + ' LSC *'" label-for="inputGroup">
                                        <b-form-select id="inputGroup" v-model="OutputDesiredLimitGroupLsc" :options="availableGroups" v-on:change="outputDesiredLimitGroupLscChanged" :class="{'disabled': !$store.state.auth.user.permissions.PermissionWrite}"/>
                                    </b-form-group>
                                </b-col>
                                <b-col>
                                    <b-form-group :label="$t('Item') + ' LSC *'" label-for="inputItem">
                                        <b-form-select id="inputItem" v-model="OutputDesiredLimitItemLsc" :options="availableOuptutDesiredLimitLscItems" :class="{'disabled': !$store.state.auth.user.permissions.PermissionWrite}"/>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                </b-card>
                <b-card style="margin-top: 10px; margin-bottom: 10px;" :header="$t('actions.cartexr.returnedLimits')">
                    <b-row>
                        <b-col>
                            <b-form-group :label="$t('Group') + ' LIC *'" label-for="inputGroup">
                                <b-form-select id="inputGroup" v-model="OutputReturnedLimitGroupLic" :options="availableGroups" v-on:change="outputReturnedLimitGroupLicChanged" :class="{'disabled': !$store.state.auth.user.permissions.PermissionWrite}"/>
                            </b-form-group>
                        </b-col>
                        <b-col>
                            <b-form-group :label="$t('Item') + ' LIC *'" label-for="inputItem">
                                <b-form-select id="inputItem" v-model="OutputReturnedLimitItemLic" :options="availableOuptutReturnedLimitLicItems" :class="{'disabled': !$store.state.auth.user.permissions.PermissionWrite}"/>
                            </b-form-group>
                        </b-col>
                        <b-col>
                            <b-form-group :label="$t('Group') + ' LSC *'" label-for="inputGroup">
                                <b-form-select id="inputGroup" v-model="OutputReturnedLimitGroupLsc" :options="availableGroups" v-on:change="outputReturnedLimitGroupLscChanged" :class="{'disabled': !$store.state.auth.user.permissions.PermissionWrite}"/>
                            </b-form-group>
                        </b-col>
                        <b-col>
                            <b-form-group :label="$t('Item') + ' LSC *'" label-for="inputItem">
                                <b-form-select id="inputItem" v-model="OutputReturnedLimitItemLsc" :options="availableOuptutReturnedLimitLscItems" :class="{'disabled': !$store.state.auth.user.permissions.PermissionWrite}"/>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </b-card>
                <b-card :header="$t('actions.cartexr.desiredAverage')" style="margin-top: 10px; margin-bottom: 10px;">
                    <b-row>
                        <b-col cols="6">
                            <b-form-group :label="$t('actions.cartexr.averageType')">
                                <b-form-select id="activeEffectif" v-model="AverageDesired" :options="availableEffectifs" :class="{'disabled': !$store.state.auth.user.permissions.PermissionWrite}"/>
                            </b-form-group>
                        </b-col>
                        <b-col cols="6" v-if="AverageDesired == 0">
                            <b-form-group :label="$t('Constant')">
                                <b-form-input id="ConstantEffectifActif" v-model="AverageDesiredConstant" type="number" min="0" max="999999" :class="{'disabled': !$store.state.auth.user.permissions.PermissionWrite}"/>
                            </b-form-group>
                        </b-col>
                        <b-col cols="3" v-if="AverageDesired == 1">
                            <b-form-group :label="$t('Group') + ' *'" label-for="inputGroup">
                                <b-form-select id="inputGroup" v-model="AverageDesiredGroup" :options="availableGroups" v-on:change="averageDesiredGroupChanged" :class="{'disabled': !$store.state.auth.user.permissions.PermissionWrite}"/>
                            </b-form-group>
                        </b-col>
                        <b-col cols="3" v-if="AverageDesired == 1">
                            <b-form-group :label="$t('Item') + ' *'" label-for="inputItem">
                                <b-form-select id="inputItem" v-model="AverageDesiredItem" :options="availableAverageDesiredItems" :class="{'disabled': !$store.state.auth.user.permissions.PermissionWrite}"/>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </b-card>
                <b-card :header="$t('actions.cartexr.processTrend')" style="margin-top: 10px; margin-bottom: 10px;">
                    <b-row>
                        <b-col>
                            <b-form-group :label="$t('Group') + ' *'" label-for="inputGroup">
                                <b-form-select id="inputGroup" v-model="TrendGroup" :options="availableGroups" v-on:change="trendGroupChanged" :class="{'disabled': !$store.state.auth.user.permissions.PermissionWrite}"/>
                            </b-form-group>
                        </b-col>
                        <b-col>
                            <b-form-group :label="$t('Item') + ' *'" label-for="inputItem">
                                <b-form-select id="inputItem" v-model="TrendItem" :options="availableTrendItems" :class="{'disabled': !$store.state.auth.user.permissions.PermissionWrite}"/>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </b-card>
            </b-form>
        </b-tab>
        <b-tab>
            <template #title>{{$t('actions.cartexr.title3')}}</template>
                <b-form>
                    <b-card :header="$t('actions.cartexr.desiredLimits')" style="margin-bottom: 15px;">
                        <b-row>
                            <b-col cols="6">
                                <b-form-group :label="$t('actions.cartexr.limitType')">
                                    <b-form-select id="activeEffectif" v-model="ExtentDesiredLimit" :options="availableEffectifs" :class="{'disabled': !$store.state.auth.user.permissions.PermissionWrite}"/>
                                </b-form-group>
                            </b-col>
                            <b-col cols="6" v-if="ExtentDesiredLimit == 0">
                                <b-form-group :label="$t('Constant')">
                                    <b-form-input id="ConstantEffectifActif" v-model="ExtentDesiredLimitConstant" type="number" min="0" max="999999" :class="{'disabled': !$store.state.auth.user.permissions.PermissionWrite}"/>
                                </b-form-group>
                            </b-col>
                            <b-col cols="3" v-if="ExtentDesiredLimit == 1">
                                <b-form-group :label="$t('Group') + ' *'" label-for="inputGroup">
                                    <b-form-select id="inputGroup" v-model="ExtentDesiredLimitGroup" :options="availableGroups" v-on:change="extentDesiredLimitGroupChanged" :class="{'disabled': !$store.state.auth.user.permissions.PermissionWrite}"/>
                                </b-form-group>
                            </b-col>
                            <b-col cols="3" v-if="ExtentDesiredLimit == 1">
                                <b-form-group :label="$t('Item') + ' *'" label-for="inputItem">
                                    <b-form-select id="inputItem" v-model="ExtentDesiredLimitItem" :options="availableExtentDesiredLimitItems" :class="{'disabled': !$store.state.auth.user.permissions.PermissionWrite}"/>
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </b-card>
                    <b-row>
                        <b-col>
                            <b-card :header="$t('actions.cartexr.processVariability')">
                                <b-row>
                                    <b-col>
                                        <b-form-group :label="$t('Group') + ' *'" label-for="inputGroup">
                                            <b-form-select id="inputGroup" v-model="ProcessVariabilityGroup" :options="availableGroups" v-on:change="processVariabilityGroupChanged" :class="{'disabled': !$store.state.auth.user.permissions.PermissionWrite}"/>
                                        </b-form-group>
                                    </b-col>
                                    <b-col>
                                        <b-form-group :label="$t('Item') + ' *'" label-for="inputItem">
                                            <b-form-select id="inputItem" v-model="ProcessVariabilityItem" :options="availableProcessVariabilityItems" :class="{'disabled': !$store.state.auth.user.permissions.PermissionWrite}"/>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                            </b-card>
                        </b-col>
                        <b-col>
                            <b-card :header="$t('actions.cartexr.returnedLimits')">
                                <b-row>
                                    <b-col>
                                        <b-form-group :label="$t('Group') + ' *'" label-for="inputGroup">
                                            <b-form-select id="inputGroup" v-model="ExtentReturnedLimitGroup" :options="availableGroups" v-on:change="extentReturnedLimitGroupChanged" :class="{'disabled': !$store.state.auth.user.permissions.PermissionWrite}"/>
                                        </b-form-group>
                                    </b-col>
                                    <b-col>
                                        <b-form-group :label="$t('Item') + ' *'" label-for="inputItem">
                                            <b-form-select id="inputItem" v-model="ExtentReturnedLimitItem" :options="availableExtentReturnedLimitItems" :class="{'disabled': !$store.state.auth.user.permissions.PermissionWrite}"/>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                            </b-card>
                        </b-col>
                    </b-row>
                </b-form>
        </b-tab>
    </div>
</template>

<script>
import ProdComEquipmentService from "@/services/prodcom.equipments.service.js";
export default {
    name: "CarteXr",
    props:{
        /**
         * Action object from parent component. Contains the equipment concerned,
         * and details about the action if opening an existing action.
         */
        action: Object,
        /**
         * Current project's Guid
         */
        propProject: String,
    },
    data(){
        return{
            InputEffectif: 0,
            InputGroup: null,
            InputItem: null,
            ConstantEffectifInput: 1,
            ExtentCard: false,
            AverageCard: false,
            ExtentGroup: null,
            ExtentItem: null,
            AverageGroup: null,
            AverageItem: null,
            OutputDesiredLimit: 0,
            ConstantOutputDesiredLimitLic: 0,
            ConstantOutputDesiredLimitLsc: 0,
            OutputDesiredLimitGroupLic: null,
            OutputDesiredLimitItemLic: null,
            OutputDesiredLimitGroupLsc: null,
            OutputDesiredLimitItemLsc: null,
            OutputReturnedLimitGroupLic: null,
            OutputReturnedLimitItemLic: null,
            OutputReturnedLimitGroupLsc: null,
            OutputReturnedLimitItemLsc: null,
            AverageDesired: 0,
            AverageDesiredConstant: 0,
            AverageDesiredGroup: null,
            AverageDesiredItem: null,
            TrendGroup: null,
            TrendItem: null,
            ExtentDesiredLimit: 0,
            ExtentDesiredLimitConstant: 0,
            ExtentDesiredLimitGroup: null,
            ExtentDesiredLimitItem: null,
            ProcessVariabilityGroup: null,
            ProcessVariabilityItem: null,
            ExtentReturnedLimitGroup: null,
            ExtentReturnedLimitItem: null,
            availableEffectifs: [
                {text: this.$t('Constant'), value: 0}, 
                {text: 'Dynamique', value: 1}],
            availableEffectifs1: [
                {text: 'Auto', value: -1},
                {text: this.$t('Constant'), value: 0}, 
                {text: 'Dynamique', value: 1}],
            availableGroups: [],
            availableInputItems: [],
            availableExtentItems: [],
            availableAverageItems: [],
            availableOuptutDesiredLimitLicItems: [],
            availableOuptutDesiredLimitLscItems: [],
            availableOuptutReturnedLimitLicItems: [],
            availableOuptutReturnedLimitLscItems: [],
            availableAverageDesiredItems: [],
            availableTrendItems: [],
            availableExtentDesiredLimitItems: [],
            availableProcessVariabilityItems: [],
            availableExtentReturnedLimitItems: [],
        }
    },
    async mounted(){
        if(!this.$store.state.auth.user.permissions.PermissionRead) return;
        // Get equipment's group list (only once for the full component, no need to query it for every combo box...)
        var data = await ProdComEquipmentService.getGroups(this.action.Equipment, this.propProject);
        if(data.success == "n") this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(data.ret), 'warning'));
        else {
            this.availableGroups = data.ret.List;
        }

        this.InputEffectif = this.action.ActionConfiguration?.InputEffectif ?? 0;
        if(this.InputEffectif == 0){
            this.ConstantEffectifInput = this.action.ActionConfiguration?.ConstantEffectifInput ?? 1;
        }
        else if(this.InputEffectif == 1){
            this.InputGroup = this.action.ActionConfiguration?.InputGroup ?? this.availableGroups?.[0];
            await this.inputGroupChanged();
            this.InputItem = this.action.ActionConfiguration?.InputItem ?? this.availableInputItems?.[0];
        }
        this.AverageCard = this.action.ActionConfiguration?.AverageCard ?? false;
        if(this.AverageCard){
            this.AverageGroup = this.action.ActionConfiguration?.AverageGroup ?? this.availableGroups?.[0];
            await this.averageGroupChanged();
            this.AverageItem = this.action.ActionConfiguration?.AverageItem ?? this.availableAverageItems?.[0];
        }
        this.ExtentCard = this.action.ActionConfiguration?.ExtentCard ?? false;
        if(this.ExtentCard){
            this.ExtentGroup = this.action.ActionConfiguration?.ExtentGroup ?? this.availableGroups?.[0];
            await this.extentGroupChanged();
            this.ExtentItem = this.action.ActionConfiguration?.ExtentItem ?? this.availableExtentItems?.[0];
        }
        this.OutputDesiredLimit = this.action.ActionConfiguration?.OutputDesiredLimit ?? -1;
        if(this.OutputDesiredLimit == 0){
            this.ConstantOutputDesiredLimitLic = this.action.ActionConfiguration?.ConstantOutputDesiredLimitLic ?? 0;
            this.ConstantOutputDesiredLimitLsc = this.action.ActionConfiguration?.ConstantOutputDesiredLimitLsc ?? 0;
        }
        else if(this.OutputDesiredLimit == 1){
            this.OutputDesiredLimitGroupLic = this.action.ActionConfiguration?.OutputDesiredLimitGroupLic ?? this.availableGroups?.[0];
            await this.outputDesiredLimitGroupLicChanged();
            this.OutputDesiredLimitItemLic = this.action.ActionConfiguration?.OutputDesiredLimitItemLic ?? this.availableOuptutDesiredLimitLicItems?.[0];
            
            this.OutputDesiredLimitGroupLsc = this.action.ActionConfiguration?.OutputDesiredLimitGroupLsc ?? this.availableGroups?.[0];
            await this.outputDesiredLimitGroupLscChanged();
            this.OutputDesiredLimitItemLsc = this.action.ActionConfiguration?.OutputDesiredLimitItemLsc ?? this.availableOuptutDesiredLimitLscItems?.[0];
        }
        this.OutputReturnedLimitGroupLic = this.action.ActionConfiguration?.OutputReturnedLimitGroupLic ?? this.availableGroups?.[0];
        await this.outputReturnedLimitGroupLicChanged();
        this.OutputReturnedLimitItemLic = this.action.ActionConfiguration?.OutputReturnedLimitItemLic ?? this.availableOuptutReturnedLimitLicItems;
        this.OutputReturnedLimitGroupLsc = this.action.ActionConfiguration?.OutputReturnedLimitGroupLsc ?? this.availableGroups?.[0];
        await this.outputReturnedLimitGroupLscChanged();
        this.OutputReturnedLimitItemLsc = this.action.ActionConfiguration?.OutputReturnedLimitItemLsc ?? this.availableOuptutReturnedLimitLscItems;
        this.AverageDesired = this.action.ActionConfiguration?.AverageDesired ?? 0;
        if(this.AverageDesired == 0){
            this.AverageDesiredConstant = this.action.ActionConfiguration?.AverageDesiredConstant ?? 0;
        }
        else if(this.AverageDesired == 1){
            this.AverageDesiredGroup = this.action.ActionConfiguration?.AverageDesiredGroup ?? this.availableGroups?.[0];
            await this.averageDesiredGroupChanged();
            this.AverageDesiredItem = this.action.ActionConfiguration?.AverageDesiredItem ?? this.availableAverageDesiredItems?.[0];
        }
        this.TrendGroup = this.action.ActionConfiguration?.TrendGroup ?? this.availableGroups?.[0];
        await this.trendGroupChanged();
        this.TrendItem = this.action.ActionConfiguration?.TrendItem ?? this.availableTrendItems?.[0];
        this.ExtentDesiredLimit = this.action.ActionConfiguration?.ExtentDesiredLimit ?? 0;
        if(this.ExtentDesiredLimit == 0){
            this.ExtentDesiredLimitConstant = this.action.ActionConfiguration?.ExtentDesiredLimitConstant ?? 0;
        }
        else if(this.ExtentDesiredLimit == 1){
            this.ExtentDesiredLimitGroup = this.action.ActionConfiguration?.ExtentDesiredLimitGroup ?? this.availableGroups?.[0];
            await this.extentDesiredLimitGroupChanged();
            this.ExtentDesiredLimitItem = this.action.ActionConfiguration?.ExtentDesiredLimitItem ?? this.availableExtentDesiredLimitItems?.[0];
        }
        this.ProcessVariabilityGroup = this.action.ActionConfiguration?.ProcessVariabilityGroup ?? this.availableGroups?.[0];
        await this.processVariabilityGroupChanged();
        this.ProcessVariabilityItem = this.action.ActionConfiguration?.ProcessVariabilityItem ?? this.availableProcessVariabilityItems?.[0];
        this.ExtentReturnedLimitGroup = this.action.ActionConfiguration?.ExtentReturnedLimitGroup ?? this.availableGroups?.[0];
        await this.extentReturnedLimitGroupChanged();
        this.ExtentReturnedLimitItem = this.action.ActionConfiguration?.ExtentReturnedLimitItem ?? this.availableExtentReturnedLimitItems?.[0];
        
    },
    methods: {
        /**
         * Called by parent when validating the configuration.
         * Needs to return the current configuration of the action. 
         */
        async validate(){
            var result = {
                InputEffectif: this.InputEffectif,
                InputGroup: this.InputGroup,
                InputItem: this.InputItem,
                ConstantEffectifInput: this.ConstantEffectifInput,
                ExtentCard: this.ExtentCard,
                AverageCard: this.AverageCard,
                ExtentGroup: this.ExtentGroup,
                ExtentItem: this.ExtentItem,
                AverageGroup: this.AverageGroup,
                AverageItem: this.AverageItem,
                OutputDesiredLimit: this.OutputDesiredLimit,
                ConstantOutputDesiredLimitLic: this.ConstantOutputDesiredLimitLic,
                ConstantOutputDesiredLimitLsc: this.ConstantOutputDesiredLimitLsc,
                OutputDesiredLimitGroupLic: this.OutputDesiredLimitGroupLic,
                OutputDesiredLimitItemLic: this.OutputDesiredLimitItemLic,
                OutputDesiredLimitGroupLsc: this.OutputDesiredLimitGroupLsc,
                OutputDesiredLimitItemLsc: this.OutputDesiredLimitItemLsc,
                OutputReturnedLimitGroupLic: this.OutputReturnedLimitGroupLic,
                OutputReturnedLimitItemLic: this.OutputReturnedLimitItemLic,
                OutputReturnedLimitGroupLsc: this.OutputReturnedLimitGroupLsc,
                OutputReturnedLimitItemLsc: this.OutputReturnedLimitItemLsc,
                AverageDesired: this.AverageDesired,
                AverageDesiredConstant: this.AverageDesiredConstant,
                AverageDesiredGroup: this.AverageDesiredGroup,
                AverageDesiredItem: this.AverageDesiredItem,
                TrendGroup: this.TrendGroup,
                TrendItem: this.TrendItem,
                ExtentDesiredLimit: this.ExtentDesiredLimit,
                ExtentDesiredLimitConstant: this.ExtentDesiredLimitConstant,
                ExtentDesiredLimitGroup: this.ExtentDesiredLimitGroup,
                ExtentDesiredLimitItem: this.ExtentDesiredLimitItem,
                ProcessVariabilityGroup: this.ProcessVariabilityGroup,
                ProcessVariabilityItem: this.ProcessVariabilityItem,
                ExtentReturnedLimitGroup: this.ExtentReturnedLimitGroup,
                ExtentReturnedLimitItem: this.ExtentReturnedLimitItem,
            };
            return result;
        },
        async inputGroupChanged(e){
            await this.setItems(this.InputGroup, this.availableInputItems);
        },
        async extentGroupChanged(e){
            await this.setItems(this.ExtentGroup, this.availableExtentItems);
        },
        async averageGroupChanged(e){
            await this.setItems(this.AverageGroup, this.availableAverageItems);
        },
        async outputDesiredLimitGroupLicChanged(e){
            await this.setItems(this.OutputDesiredLimitGroupLic, this.availableOuptutDesiredLimitLicItems);
        },
        async outputDesiredLimitGroupLscChanged(e){
            await this.setItems(this.OutputDesiredLimitGroupLsc, this.availableOuptutDesiredLimitLscItems);
        },
        async outputReturnedLimitGroupLicChanged(e){
            await this.setItems(this.OutputReturnedLimitGroupLic, this.availableOuptutReturnedLimitLicItems);
        },
        async outputReturnedLimitGroupLscChanged(e){
            await this.setItems(this.OutputReturnedLimitGroupLsc, this.availableOuptutReturnedLimitLscItems);
        },
        async averageDesiredGroupChanged(e){
            await this.setItems(this.AverageDesiredGroup, this.availableAverageDesiredItems);
        },
        async trendGroupChanged(e){
            await this.setItems(this.TrendGroup, this.availableTrendItems);
        },
        async extentDesiredLimitGroupChanged(e){
            await this.setItems(this.ExtentDesiredLimitGroup, this.availableExtentDesiredLimitItems);
        },
        async processVariabilityGroupChanged(e){
            await this.setItems(this.ProcessVariabilityGroup, this.availableProcessVariabilityItems);
        },
        async extentReturnedLimitGroupChanged(e){
            await this.setItems(this.ExtentReturnedLimitGroup, this.availableExtentReturnedLimitItems);
        },
        /**
         * Resets a list of items with items contained in the given group.
         * @param {string} groupName The name of the group to get items from.
         * @param {array} itemList The list of items to set.
         */
        async setItems(groupName, itemList){
            if(!this.$store.state.auth.user.permissions.PermissionRead) return;
            if(groupName == null || groupName == '') return;
            const result = await ProdComEquipmentService.getItems(this.action.Equipment, groupName, this.propProject);
            if(result.success == "n") this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(result.ret, result.retParams), 'warning'));
            else{
                itemList.splice(0, itemList.length);
                for(const [key, val] of Object.entries(result.ret.List)){
                    itemList.push(val.Name);
                }
            }
        }
    }
}
</script>
import utils from "@/utils";

class ProdComMonitoringService {
    async getEquipments() {
        return await utils.PostQuery('monitoring.GetEquipments');
    }

    async getItems(equipmentName, typeVarInternes) {
        return await utils.PostQuery('monitoring.GetItems', 
        {
            Equipment: equipmentName,
            TypeVarInternes: typeVarInternes
        });
    }

    async getActions(equipmentName) {
        return await utils.PostQuery('monitoring.GetActions', 
        {
            Equipment: equipmentName,
        });
    }

    async setItem(equipmentName, key, value){
        return await utils.PostQuery('monitoring.SetItem1', 
        {
            Equipment: equipmentName,
            Key: key,
            Value: value
        });
    }

    async restartRuntime(){
        return await utils.PostQuery('monitoring.RestartRuntime');
    }

    async startRuntime(){
        return await utils.PostQuery('monitoring.StartRuntime');
    }

    async stopRuntime(){
        return await utils.PostQuery('monitoring.StopRuntime');
    }

    async restartEquipment(equipmentName){
        return await utils.PostQuery('monitoring.RestartEquipment',
        {
            EquipmentName: equipmentName,
        });
    }

    async runtimeStatus(){
        return await utils.PostQuery('monitoring.RuntimeStatus');
    }

    async getLogs(top){
        return await utils.PostQuery('monitoring.GetLogs', 
        {
            Top: top
        });
    }

    async getEventLogs(top){
        return await utils.PostQuery('monitoring.GetEventLogs', 
        {
            Top: top
        });
    }
}

export default new ProdComMonitoringService();
<template>
    <div>
        <b-row>
            <b-col>
                <b-form-group :label="$t('triggers.planning.startTime')" label-for="startDate">
                    <b-form-timepicker v-model="startDate" locale="fr" show-seconds :class="{'disabled': !$store.state.auth.user.permissions.PermissionWrite}"/>
                </b-form-group>
            </b-col>
            <b-col>
                <b-form-group :label="$t('triggers.planning.recurrencePeriod')" label-for="selectedRecurencePeriod">
                    <b-form-select v-model="selectedRecurencePeriod" :options="availableRecurrencePeriods" :class="{'disabled': !$store.state.auth.user.permissions.PermissionWrite}"/>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="4">
                <b-form-group :label="$t('triggers.planning.repeatEvery')" label-for="repeatEvery">
                    <b-row>
                        <b-col cols="6">
                            <b-form-input v-model="repeatEvery" type="number" min="1" max="999" :class="{'disabled': !$store.state.auth.user.permissions.PermissionWrite}"/>
                        </b-col>
                        <b-col cols="6"> 
                            {{$t(`triggers.planning.repeatEvery_${this.selectedRecurencePeriod}`)}}
                        </b-col>
                    </b-row>
                </b-form-group>
            </b-col>
            <!-- Weekly -->
            <b-col cols="8" v-if="selectedRecurencePeriod == 1">
                <b-form-group :label="$t('triggers.planning.repeatOn')" label-for="repeatOn">
                    <div class="week">
                        <div v-for="day in days" :key="day.value" class="day" v-bind:class="{'selected': day.selected}" @click="onSelectDay(day)" :class="{'disabled': !$store.state.auth.user.permissions.PermissionWrite}">
                            {{day.text}}
                        </div>
                    </div>
                </b-form-group>
            </b-col>
            <!-- Monthly -->
            <b-col cols="6" v-if="selectedRecurencePeriod == 2">
                <b-form-group :label="$t('triggers.planning.repeatOn')" label-for="repeatOn">
                    <b-form-input v-model="repeatOn" type="number" min="1" max="31" :class="{'disabled': !$store.state.auth.user.permissions.PermissionWrite}"/>
                </b-form-group>
            </b-col>
        </b-row>
    </div>
</template>

<script>
export default {
    name: "Planning",
    props: {
        action: Object,
        propProject: String,
        equipment: String,
    },
    data(){
        return {
            startDate: this.action?.DeclencheurConfiguration?.StartTime ?? '00:00:00',
            availableRecurrencePeriods: [
                {value: 0, text: this.$t('triggers.planning.daily')},
                {value: 1, text: this.$t('triggers.planning.weekly')},
                {value: 2, text: this.$t('triggers.planning.monthly')},
            ],
            selectedRecurencePeriod: this.action?.DeclencheurConfiguration?.Type ?? 0,
            repeatEvery: this.action?.DeclencheurConfiguration?.Periodicity ?? 1,
            repeatOn: this.action?.DeclencheurConfiguration?.RepeatOn ?? 1,
            days: [
                {value: 2, text: this.$t('triggers.planning.days.monday'), selected: false},
                {value: 4, text: this.$t('triggers.planning.days.tuesday'), selected: false},
                {value: 8, text: this.$t('triggers.planning.days.wednesday'), selected: false},
                {value: 16, text: this.$t('triggers.planning.days.thursday'), selected: false},
                {value: 32, text: this.$t('triggers.planning.days.friday'), selected: false},
                {value: 64, text: this.$t('triggers.planning.days.saturday'), selected: false},
                {value: 1, text: this.$t('triggers.planning.days.sunday'), selected: false},
            ]
        }
    },
    async mounted(){
        try{
            this.days.find(it => it.value == this.action?.DeclencheurConfiguration?.WeekDay ?? 0).selected = true;
        }catch(error){
            //ignored
        }
    },
    methods: {
        /**
         * Called when a new day is selected. Automatically unselect any other day that would have been selected previously.
         * (only allow 1 selection for the moment)
         */
        onSelectDay(day){
            if(!day.selected){
                for(const d of this.days){
                    d.selected = false;
                }
            }
            day.selected = !day.selected;
        },
        async validate(){
            return {
                StartTime: this.startDate,
                Type: this.selectedRecurencePeriod,
                Periodicity: this.repeatEvery,
                RepeatOn: this.repeatOn,
                WeekDay: this.days.find(d => d.selected)?.value
            };
        }
    }
}
</script>

<style lang="scss">
.week {
    width: 100%;
    display: inline-flex;
}
.day {
    display: inline-block;
    border-width: 1px;
    border-style: solid;
    border-color: var(--border-list-color);
    border-right-width: 0px;
    padding: 16px;
    cursor: pointer;
    flex: 1 100%;
    height: 100%;
    text-align: center;
}
.day.selected{
    background-color: var(--selection-background-color);
}
.week .day:first-of-type{
    display: inline-block;
    border-width: 1px;
    border-style: solid;
    border-color: var(--border-list-color);
    border-right-width: 0px;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}
.week .day:last-of-type{
    display: inline-block;
    border-width: 1px;
    border-style: solid;
    border-color: var(--border-list-color);
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}
.day:hover{
    background-color: var(--selection-hover-background-color);
}
</style>
export default class ProdJsTools {
    constructor() {
    }

    static GetFileInfosFromB64(base64String) {
        var data = base64String.Substring(0, 5);

        switch (data.ToUpper()) {
            case "IVBOR":
                return { type: 'image', mime: 'image/png', ext: 'png' };
            case "/9J/4":
                return { type: 'image', mime: 'image/jpg', ext: 'jpg' };
            case "R0lGODdh":
            case "R0lGODlh":
                return { type: 'image', mime: 'image/gif', ext: 'gif' };
            case "AAAAF":
                return { type: 'video', mime: 'video/mp4', ext: 'mp4' };
            case "JVBER":
                return { type: 'pdf', mime: 'application/pdf', ext: 'pdf' };
            case "AAABA":
                return { type: 'icon', mime: 'image/x-icon', ext: 'ico' };
            case "UMFYI":
                return { type: 'binary', mime: 'application/x-rar', ext: 'rar' };
            case "E1XYD":
                return { type: 'binary', mime: 'application/rtf', ext: 'rtf' };
            case "U1PKC":
                return { type: 'binary', mime: 'text/plain', ext: 'txt' };
            default:
                return string.Empty;
        }
    }

    static rgbToYIQ({ r, g, b }) {
        return ((r * 299) + (g * 587) + (b * 114)) / 1000;
    }

    static hexToRgb(hex) {
        if (!hex || hex === undefined || hex === '') {
            return undefined;
        }

        const result =
            /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

        return result ? {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16)
        } : undefined;
    }

    // Usage const nonDuplicateFields = ProdJsTools.uniqByKeepLast(fields, (f) => f.dataField);
    static uniqByKeepLast(data, key) {
        return [...new Map(data.map((x) => [key(x), x])).values()];
    }

    static uniqByKeepFirst(data, key) {
        let seen = new Set();
        return data.filter(item => {
            let k = key(item);
            return seen.has(k) ? false : seen.add(k);
        });
    }

    static ifNull(value, returnValue) {
        return value ? value : returnValue;
    }

    static removeElement(array, elem) {
        var index = array.indexOf(elem);
        if (index > -1) {
            array.splice(index, 1);
        }
    }

    static deepEqual(object1, object2) {
        const keys1 = Object.keys(object1);
        const keys2 = Object.keys(object2);
        if (keys1.length !== keys2.length) {
            return false;
        }
        for (const key of keys1) {
            const val1 = object1[key];
            const val2 = object2[key];

            const areObjects = this.isObject(val1) && this.isObject(val2);
            if (
                areObjects && !this.deepEqual(val1, val2) ||
                !areObjects && val1 !== val2
            ) {
                return false;
            }
        }
        return true;
    }

    static isObject(object) {
        return object != null && typeof object === 'object';
    }

    static contrastColor(colorHex, threshold = 128) {
        if (colorHex === undefined) {
            return '#000';
        }
        const rgb = this.hexToRgb(colorHex);
        if (rgb === undefined) {
            return '#000';
        }
        return this.rgbToYIQ(rgb) >= threshold ? '#000' : '#fff';
    }

    static formatBytes(bytes, decimals = 2) {
        if (bytes === 0) return '0 Bytes';

        const k = 1000;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

        const i = Math.floor(Math.log(bytes) / Math.log(k));

        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }
};
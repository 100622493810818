import AuthService from '@/services/auth.service';

var user = JSON.parse(localStorage.getItem('user'));
const initialState = user
    ? {
        status: { loggedIn: true },
        user: {
            accessToken: user.accessToken,
            id: "",
            email: "",
            firstName: "",
            lastName: "",
            minLevelRole: 999,
            permissions: {}
        }
    }
    : {
        status: { loggedIn: false },
        user: {
            accessToken: "",
            id: "",
            email: "",
            firstName: "",
            lastName: "",
            minLevelRole: 999,
            permissions: {}
        }
    };

export const auth = {
    namespaced: true,
    state: initialState,
    actions: {
        login({ commit }, userCredentials) {
            return AuthService.login(userCredentials).then(
                user => {
                    commit('loginSuccess', user);
                    return Promise.resolve(user);
                },
                error => {
                    commit('loginFailure');
                    return Promise.reject(error);
                }
            );
        },
        loginAsGuest({ commit }){
            return AuthService.loginAsGuest().then(
                user => {
                    commit('loginSuccess', user);
                    return Promise.resolve(user);
                },
                error => {
                    commit('loginFailure');
                    return Promise.reject(error);
                }
            );
        },
        logout({ commit }) {
            AuthService.logout();
            commit('logout');
        },
    },
    mutations: {
        loginSuccess(state, user) {
            state.status.loggedIn = true;
            state.user = {
                accessToken: user.accessToken,
                id: user.id,
                minLevelRole: user.minLevelRole,
                email: user.email,
                firstName: user.firstName,
                lastName: user.lastName,
                permissions: user.permissions
            };
        },
        loginFailure(state) {
            state.status.loggedIn = false;
            state.user = {
                accessToken: "",
                id: "",
                email: "",
                firstName: "",
                lastName: "",
                minLevelRole: 999,
                permissions: {}
            };
        },
        logout(state) {
            state.status.loggedIn = false;
            state.user = {
                accessToken: "",
                id: "",
                email: "",
                firstName: "",
                lastName: "",
                minLevelRole: 999,
                permissions: {}
            };
        },
        updateUserInfos(state, userInfos) {
            state.user.id = userInfos.id;
            state.user.email = userInfos.email;
            state.user.firstName = userInfos.firstName;
            state.user.lastName = userInfos.lastName;
            state.user.minLevelRole = userInfos.minLevelRole;
            state.user.permissions = userInfos.permissions;
        }
    }
};
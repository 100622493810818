<template>
    <b-container
        fluid
        class="home_monitoring"
        v-bind:style="{
            height: 'calc(100% - 150px)!important',
        }"
        v-if="loggedIn"
    >
    <b-row>
        <b-col style="margin-top: 10px; margin-bottom: 15px">
            <div>
                <b-row style=" height: 49px;">
                    <b-col cols="4" style="float:none;margin:auto;">
                            <font-awesome-icon 
                                class="button cursor-pointer rotate"
                                v-bind:class="{'down': equipmentListVisible}"
                                icon="fa-solid fa-caret-right" 
                                size="lg" 
                                :title="$t('Select equipment')"
                                style="margin-left: 10px; margin-right: 10px;" 
                                role="button"
                                href="#collapseExample"
                                v-b-toggle.collapse-1/>
                    </b-col>
                    <b-col cols="4" style="float:none;margin:auto;">
                        <div style="text-align: center; vertical-align: middle;" v-if="selectedEquipment != null">
                            {{selectedEquipment.Project}}  -  {{ selectedEquipment.Equipment }}
                        </div>
                    </b-col>
                    <b-col cols="4" style="float:none;margin:auto;">
                        <div style="text-align: right; vertical-align: middle;">
                            <font-awesome-icon icon="fas fa-square-question" class="top-menu-icon mr-1 fa-lg" v-on:click="displayHelp" :title="$t('Help')"  />
                        </div>
                    </b-col>
                </b-row>
            </div>
        </b-col>
    </b-row>
    <b-collapse id="collapse-1" class="mt-2" visible v-model="equipmentListVisible">
    <b-row>
        <b-col>
        <!-- Equipment list grid -->
            <DxDataGrid 
            :dataSource="dsEquipments"
            :allow-column-reordering="true"
            :repaint-changes-only="true"
            :show-borders="true"
            :selection="{ mode: 'single' }"
            :columnAutoWidth="false"
            :allow-column-resizing="true"
            v-on:initialized="onEquipmentListInitialized"
            @selection-changed="onSelectionChangedEquipmentList">
                <template #status-equipment="{data}">
                    <div class="text-center">
                        <!-- ServerState.Stopped -->
                        <font-awesome-icon v-if="data.data.StatusEquipment == 0" icon="stop" class="fa-lg" color="#ffca28" />
                        <!-- ServerState.Started -->
                        <font-awesome-icon v-else-if="data.data.StatusEquipment == 1" icon="play" class="fa-lg" color="#4dc615"/>
                        <!-- ServerState.StartPending -->
                        <font-awesome-layers v-else-if="data.data.StatusEquipment == 2">
                            <font-awesome-icon icon="play" class="fa-lg" color="#4dc615"/>
                            <font-awesome-icon icon="sync" class="fa-xs fa-spin-custom" transform="down-10.0 right-10.0"/>
                        </font-awesome-layers>
                        <!-- ServerState.StopPending -->
                        <font-awesome-layers v-else-if="data.data.StatusEquipment == 3">
                            <font-awesome-icon icon="stop" class="fa-lg" color="#c72614"/>
                            <font-awesome-icon icon="sync" class="fa-xs fa-spin-custom" transform="down-10.0 right-10.0"/>
                        </font-awesome-layers>
                    </div>
                </template>
                <template #status-communication="{data}">
                    <div class="text-center">
                        <!-- ComState.Disconnected -->
                        <font-awesome-icon v-if="data.data.StatusEquipment == 0" icon="times-circle" class="fa-lg" color="#c72614" />
                        <!-- ComState.Connected -->
                        <font-awesome-icon v-else-if="data.data.StatusEquipment == 1" icon="check-circle" class="fa-lg" color="#4dc615"/>
                        <!-- ComState.Reconnecting -->
                        <font-awesome-icon v-else-if="data.data.StatusEquipment == 2" icon="spinner-third" class="fa-spin-custom fa-lg" color="#c8b900"/>
                        <!-- ComState.SubscribeError -->
                        <font-awesome-icon v-else-if="data.data.StatusEquipment == 3" icon="exclamation-circle" class="fa-lg" color="#c66900"/>
                    </div>
                </template>
                <template #status-datasource="{data}">
                    <div class="text-center">
                        <!-- DBState.NotConfigured -->
                        <font-awesome-icon v-if="data.data.StatusDataSource == 0" icon="empty-set" class="fa-lg"/>
                        <!-- DBState.Disconnected -->
                        <font-awesome-icon v-else-if="data.data.StatusDataSource == 1" icon="times-circle" class="fa-lg" color="#c72614"/>
                        <!-- DBState.Connected -->
                        <font-awesome-icon v-else-if="data.data.StatusDataSource == 2" icon="check-circle" class="fa-lg" color="#4dc615"/>
                        <!-- DBState.BackupConnected -->
                        <font-awesome-icon v-else-if="data.data.StatusDataSource == 3" icon="exclamation-circle" class="fa-lg" color="#c66900"/>
                    </div>
                </template>
                <template #active="{data}">
                    <div class="text-center">
                        <b-form-checkbox v-model="data.data.Active" disabled></b-form-checkbox>
                    </div>
                </template>
                <template #data-left="{data}">
                    <div class="text-left">
                        {{ data.displayValue + ' (' + data.data.ItemCount + ')' }}
                    </div>
                </template>

                <DxColumnChooser :enabled="true" mode="select" />
                <DxColumn data-field="Project" :group-index="0" :visible="true" :caption="$t('projects')" alignment="left"/>
                <DxColumn data-field="ProjectId" :visible="false" :caption="$t('projects')" alignment="left"/>
                <DxColumn data-field="Equipment" :visible="true" :caption="$t('Equipment')" alignment="left"/>
                <DxColumn data-field="Active" :visible="true" cell-template="active" :caption="$t('Active')" alignment="center"/>
            </DxDataGrid>
        </b-col>
    </b-row>
    </b-collapse>
    <!-- Only display data source if connection has started -->
    <b-row style="margin-top: 7px;" v-if="connectionStarted">
        <b-col>
            
            <DxDataGrid 
                ref="gridTable"
                :dataSource="dataSourceDb"
                :allow-column-reordering="true"
                :repaint-changes-only="true"
                :show-borders="true"
                :selection="{ mode: 'single' }"
                :columnAutoWidth="true"
                :allow-column-resizing="true">
                    <template #status-equipment="{data}">
                        <div class="text-center">
                            <!-- ServerState.Stopped -->
                            <font-awesome-icon v-if="data.data.StatusEquipment == 0" icon="stop" class="fa-lg" color="#ffca28" />
                            <!-- ServerState.Started -->
                            <font-awesome-icon v-else-if="data.data.StatusEquipment == 1" icon="play" class="fa-lg" color="#4dc615"/>
                            <!-- ServerState.StartPending -->
                            <font-awesome-layers v-else-if="data.data.StatusEquipment == 2">
                                <font-awesome-icon icon="play" class="fa-lg" color="#4dc615"/>
                                <font-awesome-icon icon="sync" class="fa-xs fa-spin-custom" transform="down-10.0 right-10.0"/>
                            </font-awesome-layers>
                            <!-- ServerState.StopPending -->
                            <font-awesome-layers v-else-if="data.data.StatusEquipment == 3">
                                <font-awesome-icon icon="stop" class="fa-lg" color="#c72614"/>
                                <font-awesome-icon icon="sync" class="fa-xs fa-spin-custom" transform="down-10.0 right-10.0"/>
                            </font-awesome-layers>
                        </div>
                    </template>
                    <template #status-communication="{data}">
                        <div class="text-center">
                            <!-- ComState.Disconnected -->
                            <font-awesome-icon v-if="data.data.StatusEquipment == 0" icon="times-circle" class="fa-lg" color="#c72614" />
                            <!-- ComState.Connected -->
                            <font-awesome-icon v-else-if="data.data.StatusEquipment == 1" icon="check-circle" class="fa-lg" color="#4dc615"/>
                            <!-- ComState.Reconnecting -->
                            <font-awesome-icon v-else-if="data.data.StatusEquipment == 2" icon="spinner-third" class="fa-spin-custom fa-lg" color="#c8b900"/>
                            <!-- ComState.SubscribeError -->
                            <font-awesome-icon v-else-if="data.data.StatusEquipment == 3" icon="exclamation-circle" class="fa-lg" color="#c66900"/>
                        </div>
                    </template>
                    <template #status-datasource="{data}">
                        <div class="text-center">
                            <!-- DBState.NotConfigured -->
                            <font-awesome-icon v-if="data.data.StatusDataSource == 0" icon="empty-set" class="fa-lg"/>
                            <!-- DBState.Disconnected -->
                            <font-awesome-icon v-else-if="data.data.StatusDataSource == 1" icon="times-circle" class="fa-lg" color="#c72614"/>
                            <!-- DBState.Connected -->
                            <font-awesome-icon v-else-if="data.data.StatusDataSource == 2" icon="check-circle" class="fa-lg" color="#4dc615"/>
                            <!-- DBState.BackupConnected -->
                            <font-awesome-icon v-else-if="data.data.StatusDataSource == 3" icon="exclamation-circle" class="fa-lg" color="#c66900"/>
                        </div>
                    </template>
                    <template #active="{data}">
                        <div class="text-center">
                            <b-form-checkbox v-model="data.data.Active" disabled></b-form-checkbox>
                        </div>
                    </template>
                    <template #data-left="{data}">
                        <div class="text-left">
                            {{ data.displayValue + ' (' + data.data.ItemCount + ')' }}
                        </div>
                    </template>
                    <template #checkTemplate>
                        <DxButton icon="refresh" @click="refresh"/>
                    </template>
                    <template #lookupRefresh>
                        <b-form-select
                            id="tableConfigurationGroup"
                            v-model="selectedRefresh"
                            :options="dataSourceRefresh"
                            @change="onRefreshValueChanged">
                        </b-form-select>
                    </template>
                    <DxFilterRow :visible="false" />
                    <DxSorting mode="multiple"/>
                    <DxLoadPanel :enabled="firstLoading"/>
                    <DxPaging :enabled="true" :page-size="pageSize" :visible="true" />
                    <DxPager :allowed-page-sizes="[10, 20, 40, 100, 250, 500]" :show-page-size-selector="true" :show-info="true" :visible="true" />
                    <DxRemoteOperations :filtering="true" :paging="true" :sorting="true"/>
                    <DxColumnChooser :enabled="true" mode="select" />
                    <DxToolbar>
                        <DxItem location="after" template="lookupRefresh"/>
                        <DxItem location="after" template="checkTemplate"/>
                        <DxItem
                            name="columnChooserButton"
                            locate-in-menu="auto"
                            location="after"
                        />
                    </DxToolbar>
                    <DxColumn v-for="col in columns.filter(col => col.groupIndex != null)"
                        :group-index="col.groupIndex"
                        :key="col.key" 
                        :data-field="col.dataField"
                        :data-type="col.dataType"
                        :caption="col.caption"
                        :sort-order="col.sortOrder"
                        :format="col.format"
                        :visible="col.visible"/>
                    <DxColumn v-for="col in columns.filter(col => col.groupIndex == null)" 
                        :key="col.key" 
                        :data-field="col.dataField"
                        :data-type="col.dataType"
                        :caption="col.caption"
                        :sort-order="col.sortOrder"
                        :format="col.format"
                        :visible="col.visible"/>
                </DxDataGrid>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
import Action from "@/components/configuration/Actions/Action.vue";
import DataSource from "@/components/configuration/DataSources/DataSource.vue";
import ProdComProjectService from "@/services/prodcom.projects.service.js";
import ProdComDataSourceService from "@/services/prodcom.datasources.service.js";
import ProdComEquipmentService from "@/services/prodcom.equipments.service.js";
import ToastAlert from '@/utils/ToastAlert';
import ChartCell from "@/components/ChartCell.vue"
import DxChart, 
{ DxSeries, DxPoint, DxAnimation, DxValueAxis, DxSize} 
from 'devextreme-vue/chart';

import {
  DxDataGrid,
  DxColumn,
  DxGrouping,
  DxGroupPanel,
  DxSearchPanel,
  DxPaging,
  DxPager,
  DxToolbar,
  DxItem,
  DxScrolling,
  DxColumnChooser,
  DxRemoteOperations,
  DxHeaderFilter,
  DxFilterRow,
  DxSorting,
  DxLoadPanel,
} from 'devextreme-vue/data-grid';
import { DxButton } from 'devextreme-vue/button';
import { DxLookup } from 'devextreme-vue/lookup';
import CustomStore from 'devextreme/data/custom_store';

export default {
    name: 'Table',
    components: {
        Action,
        DataSource,
        DxColumn,
        DxGroupPanel,
        DxGrouping,
        DxPaging,
        DxScrolling,
        DxPager,
        DxSearchPanel,
        DxDataGrid,
        DxToolbar,
        DxItem,
        DxColumnChooser,
        DxSeries,
        DxPoint,
        DxChart,
        DxAnimation,
        DxValueAxis,
        DxSize,
        DxRemoteOperations,
        DxHeaderFilter,
        DxFilterRow,
        DxSorting,
        ChartCell,
        DxLoadPanel,
        DxButton,
        DxLookup
    },
    props: {
        table: String,
        columns: Array,
        helpText: String
    },
    data() {
        return {
            dsEquipments: null,
            dataSourceDb: null,
            dataGridEquipmentList: null,
            isIframe: window.location !== window.parent.location,
            timerRefreshEquipments: null,
            timerRefreshTable: null,
            equipmentListVisible: true,
            hubConnection: null,
            dataSource: null,
            connectionStarted: false,
            selectedEquipment: null,
            loading: false,
            firstLoading: true,
            dataSourceRefresh: [
                { value: 1000, text: "1 sec"},
                { value: 10000, text: "10 sec"},
                { value: 60000, text: "1 min"},
            ],
            selectedRefresh: 10000,
            pageSize : 40
        };
    },
    async mounted(){
        // Don't do anything if not logged in
        if(!this.loggedIn) return;
        // Get the list of equipments at startup of component, and automatically select the first one.
        await this.fetchEquipmentList();
        // Select the first active equipment and retract the list of equipments.
        var selectedRow = this.dsEquipments.find((val) => val.Active);
        this.dataGridEquipmentList.selectRows(selectedRow);
        this.equipmentListVisible = false
    },
    beforeDestroy(){
        // Make sure we correctly stop the different background tasks here so that
        // no API calls are done where none should be made.
        clearInterval(this.timerRefreshTable);
    },
    methods: {
        
        /**
         * Stores the reference to the Equipment data grid
         */
        async onEquipmentListInitialized(e){
            this.dataGridEquipmentList = e.component;
        },
        
        /**
         * Event fired when changing the selected equipment
         */
        async onSelectionChangedEquipmentList({selectedRowsData}){
            this.selectedEquipment = selectedRowsData[0];

            // Refresh the data source
            this.dataSourceDb = this.createDataSource(this.selectedEquipment.ProjectId, this.selectedEquipment.Equipment);

            this.timerRefreshTable = setInterval(async () => {
                this.$refs.gridTable.instance.getDataSource().reload()
            }, this.selectedRefresh);

            this.connectionStarted = true;
        },

        /**
         * Creates a new CustomStore querying
         * the api for records of the specified table.
         * @param {string} projectId The project in which we are querying data
         * @param {string} equipmentName The equipment containing the datasource that will be used
         * @return {CustomStore} The CustomStore created with the API endpoint
         */
        createDataSource(projectId, equipmentName){
            return new CustomStore({
                load: async (options) => {
                    // Return empty value if input values are wrong.
                    if (projectId == '' || projectId == null || equipmentName == '' || equipmentName == null) {
                        var that = this;
                        return new Promise(function (resolve) {
                            resolve({
                                data: [{ ' ': that.$t('No data') }],
                                totalCount: 0,
                            });
                        });
                    }
                    this.loading = true;
                    const skip = options.skip ?? 0;
                    const take = options.take ?? 20;
                    const top = 10000;
                    let sorts = [];
                    if(options.sort != null){
                        for(const sort of options.sort){
                            sorts.push({Name: sort.selector, Order: sort.desc ? "DESC" : "ASC"});
                        }
                    }
                    const data = await ProdComDataSourceService.getRows(equipmentName, projectId, this.table, skip, take, top, this.columns.map(col => col.dataField), sorts)
                    if(data.success == "n"){
                        // TODO toast
                        this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(data.ret, data.retParams), 'warning'));
                        var that = this;
                        return { data: [{ ' ': that.$t('No data') }], totalCount: 0, };
                    }else{
                        this.firstLoading = false;
                        return {totalCount : data.ret.Count, data: data.ret.Result};
                    }
                    this.loading = false;
                }
            })
        },

        /**
         * Periodically fetch the equipment status from REST API (move to SignalR... Later...)
         */
        async fetchEquipmentList(){
            const data = await ProdComProjectService.getProjects();
            if(this.dsEquipments == null ){
                this.dsEquipments = []
                for(const project of data.ret) {
                    const equipData = await ProdComEquipmentService.getAllEquipments(project.Id);
                    for(const equipment of equipData.ret){
                        this.dsEquipments.push({Project: project.Name, ProjectId: project.Id, Equipment: equipment.Name, Active: equipment.Active})
                    }
                }
            }
        },
        async displayHelp(){
            this.$store.commit("displayHelp", this.helpText)
            this.$bvModal.show("bv-modal-help");
        },
        async refresh(e){
            this.$refs.gridTable.instance.getDataSource().reload();
        },
        async onRefreshValueChanged(e){
            clearInterval(this.timerRefreshTable);
            this.timerRefreshTable = setInterval(async () => {
                this.$refs.gridTable.instance.getDataSource().reload()
            }, this.selectedRefresh);
        }
    },
    computed: {
        loggedIn() {
            return this.$store.state.auth.status.loggedIn && this.$store.state.auth.user != null && this.$store.state.auth.user.email != '';
        },
    },
};
</script>

<style lang="scss">
@keyframes spinner_anim {
    to {transform: rotate(360deg);}
}
.fa-spin-custom {
animation: spinner_anim 2s linear infinite;
}
.tabHeaderIcon{
    display: inline-block;
}
.tabHeaderText{
    width: 5px;
    white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis;
}
.tabGlobal{
    padding: 0;
    height: calc(100% - 28px);
    background-color: --main-background-color;
}
.tabContent{
    min-height: 100%;
    height: 100%
}
.dx-datagrid-rowsview .order {  
    text-align: center!important;  
}
.rotate {
    -moz-transition: all .2s linear;
    -webkit-transition: all .2s linear;
    transition: all .2s linear;
}
.rotate.down {
    -moz-transform:rotate(90deg);
    -webkit-transform:rotate(90deg);
    transform:rotate(90deg);
}

</style>
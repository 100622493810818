<template>
    <div class="user-avatar rounded-circle text-center ">
        {{ userInitials }}
    </div>
</template>
<script>
export default {
    name: 'UserAvatar',
    computed: {
        userInitials() {
            if (this.$store.state.auth.user.firstName && this.$store.state.auth.user.firstName != '') return (this.$store.state.auth.user.firstName[0] + this.$store.state.auth.user.lastName[0]).toUpperCase();
            else return this.$store.state.auth.user.lastName.substr(0, 2).toUpperCase();
        },
    },
};
</script>
<style lang="scss">
.user-avatar {
    height: 40px;
    line-height: 38px;
    width: 40px;
    font-weight: 600;
    background-color: var(--primary);
    border: 1px solid var(--text-color);
    color: var(--primary-button-font-color);
}
</style>
<template>
    <b-tabs pills card vertical>
        <b-tab>
            <template #title><font-awesome-icon icon="palette" fixed-width /> {{ $t('settings.applicationProperties')
            }}</template>
            <b-row>
                <b-col>
                    <b-form-group :label="$t('settings.significantDigits')"
                        :label-for="'adminSettings_app_significantDigits'">
                        <b-form-input type="number" v-model="adminSettings.app.significantDigits"
                            :id="'adminSettings_app_significantDigits'" :min="0" :max="100" />
                    </b-form-group>
                </b-col>
                <b-col>
                    <b-form-group :label="$t('settings.startupTempo') + ' (ms)'"
                        :label-for="'adminSettings_app_startupTempo'">
                        <b-form-input type="number" v-model="adminSettings.app.startupTempo"
                            :id="'adminSettings_app_startupTempo'" :min="0" :max="99999" />
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <b-form-group :label="$t('settings.intervalBeforeLogEvents') + ' (ms)'"
                        :label-for="'adminSettings_app_intervalBeforeLogEvents'">
                        <b-form-input type="number" v-model="adminSettings.app.intervalBeforeLogEvents"
                            :id="'adminSettings_app_intervalBeforeLogEvents'" :min="0" :max="9999" />
                    </b-form-group>
                </b-col>
                <b-col>
                    <b-form-group :label="$t('settings.automaticBackups')"
                        :label-for="'adminSettings_app_automaticBackups'">
                        <b-form-checkbox style="margin-top: 13px" v-model="adminSettings.app.automaticBackups" switch>
                        </b-form-checkbox>
                    </b-form-group>
                </b-col>
            </b-row>
            <hr color="grey" />
            <b-row>
                <b-col>
                    <b-form-group :label="$t('settings.smtpHost')" :label-for="'adminSettings_app_smtpHost'">
                        <b-form-input type="text" v-model="adminSettings.app.smtpHost" :id="'adminSettings_app_smtpHost'" />
                    </b-form-group>
                </b-col>
                <b-col>
                    <b-form-group :label="$t('settings.smtpPort')" :label-for="'adminSettings_app_smtpPort'">
                        <b-form-input type="number" v-model="adminSettings.app.smtpPort" :id="'adminSettings_app_smtpPort'"
                            :min="0" :max="65536" />
                    </b-form-group>
                </b-col>
                <b-col style="margin-top: 35px;">
                    <b-form-checkbox v-model="adminSettings.app.smtpUseSsl" :id="'adminSettings_app_smtpUseSsl'">{{
                        $t('settings.smtpUseSsl') }}</b-form-checkbox>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <b-form-group :label="$t('settings.smtpUsername')" :label-for="'adminSettings_app_smtpUsername'">
                        <b-form-input type="text" v-model="adminSettings.app.smtpUsername"
                            :id="'adminSettings_app_smtpUsername'" />
                    </b-form-group>
                </b-col>
                <b-col>
                    <b-form-group :label="$t('settings.smtpPassword')" :label-for="'adminSettings_app_smtpPassword'">
                        <b-form-input type="password" v-model="adminSettings.app.smtpPassword"
                            :id="'adminSettings_app_smtpPassword'" />
                    </b-form-group>
                </b-col>
            </b-row>

            <b-row>
                <b-col class="text-right">
                    <b-button @click="applyColorSettings">{{ $t('Apply') }}</b-button>
                </b-col>
            </b-row>
        </b-tab>
        <b-tab>
            <template #title><font-awesome-icon icon="right-left" fixed-width /> {{ $t('settings.KafkaSettings')
            }}</template>
            <b-row class="mb-2">
                <b-col>
                    <b-button @click="refreshKafkaConfig"><font-awesome-icon icon="refresh"></font-awesome-icon> {{
                        $t('settings.KafkaRestart') }}</b-button>
                </b-col>
                <b-col>
                    <b-form-group class="containerSettingsKafka" :label="$t('settings.configuration')" :label-for="'adminSettings_Kafka_configurationState'">
                        <font-awesome-icon v-if="!loadKafkaState  && kafkaStateConfiguration" class="iconSettingsKafka greenIcon" id="adminSettings_Kafka_configurationState" icon="fas fa-check" />
                        <font-awesome-icon v-if="!loadKafkaState && !kafkaStateConfiguration" class="iconSettingsKafka redIcon" id="adminSettings_Kafka_configurationState"  icon="fas fa-xmark" />
                        <font-awesome-icon v-if="loadKafkaState"  id="adminSettings_Kafka_configurationState"  icon="fas fa-spinner-third" class="fa-lg fa-spin-custom iconSettingsKafka" />
                    </b-form-group>
                </b-col>
                <b-col>
                     <b-form-group class="containerSettingsKafka" :label="$t('settings.runtime')" :label-for="'adminSettings_Kafka_runtimeState'">
                        <font-awesome-icon v-if="!loadKafkaState && kafkaStateRuntime" class="iconSettingsKafka greenIcon" id="adminSettings_Kafka_runtimeState"  icon="fas fa-check" />
                        <font-awesome-icon v-if="!loadKafkaState && !kafkaStateRuntime" class="iconSettingsKafka redIcon" id="adminSettings_Kafka_runtimeState"  icon="fas fa-xmark" />
                        <font-awesome-icon v-if="loadKafkaState"  id="adminSettings_Kafka_runtimeState"  icon="fas fa-spinner-third" class="fa-lg fa-spin-custom iconSettingsKafka" />
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <b-form-group :label="$t('settings.KafkaAddress')" :label-for="'adminSettings_Kafka_KafkaAddress'">
                        <b-form-input type="text" v-model="adminSettings.Kafka.KafkaAddress"
                            :id="'adminSettings_Kafka_KafkaAddress'" />
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col class="text-right">
                    <b-button @click="updateKafkaAddress">{{ $t('Apply') }}</b-button>
                </b-col>
            </b-row>
        </b-tab>
        <b-tab>
            <template #title><font-awesome-icon icon="palette" fixed-width /> {{ $t('Colors') }}</template>
            <b-row>
                <b-col>
                    <b-form-group :label="$t('Primary color') + ' (' + adminSettings.colors.primaryColor + ')'"
                        :label-for="'adminSettings_colors_primaryColor'">
                        <b-form-input type="color" v-model="adminSettings.colors.primaryColor"
                            :id="'adminSettings_colors_primaryColor'" />
                    </b-form-group>
                </b-col>
                <b-col>
                    <b-form-group :label="$t('Allow change by user')"
                        :label-for="'adminSettings_colors_primaryColorChangeAllowed'">
                        <b-form-checkbox style="margin-top: 13px" v-model="adminSettings.colors.primaryColorChangeAllowed"
                            switch> </b-form-checkbox>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col class="text-right">
                    <b-button @click="applyColorSettings">{{ $t('Apply') }}</b-button>
                </b-col>
            </b-row>
        </b-tab>
    </b-tabs>
</template>
<script>
// import MessageService from '@/services/settings.service.js';
import ToastAlert from '@/utils/ToastAlert';
import FormErrors from '@/components/FormErrors.vue';
import SettingService from '@/services/setting.service.js';

export default {
    Text: 'SettingsTabPanel',
    components: {
        ToastAlert,
        FormErrors,
    },
    data() {
        return {
            intervalId_Check_State : null,
            loadKafkaState : true,
            kafkaStateConfiguration: false,
            kafkaStateRuntime : false,
            saving: false,
            adminSettings: {
                colors: {
                    primaryColor: this.$store.state.settings.PRIMARY_COLOR,
                    primaryColorChangeAllowed: this.$store.state.settings.PRIMARY_COLOR_CHANGE_ALLOWED,
                },
                app: {
                    startupTempo: this.$store.state.settings.STARTUP_TEMPO,
                    significantDigits: this.$store.state.settings.SIGNIFICANT_DIGITS,
                    intervalBeforeLogEvents: this.$store.state.settings.INTERVAL_BEFORE_LOG_EVENTS,
                    automaticBackups: true,
                    smtpHost: '',
                    smtpPort: 587,
                    smtpUseSsl: true,
                    smtpUsername: "user@example.com",
                    smtpPassword: '',
                },
                Kafka: {
                    KafkaAddress: 'localhost:9092',
                },
            },
            formErrors: [],
        };
    },
    async mounted() {
        await this.refresh('SettingsTabPanel');
        this.intervalId_Check_State = setInterval(()=> {
            if(this.loadKafkaState)setTimeout(5000);
            this.clockKafkaCheckStates();
        },2000)
        this.$EventBus.$on('AdminPanelClose', ()=>{
            clearInterval(this.intervalId_Check_State)
        });
        this.$EventBus.$on('refresh-admin-panel-tab', this.refresh);
    },
    methods: {
        async clockKafkaCheckStates(){
            const result = await SettingService.getKafkaState();
            if(result.success == "y"){
                this.kafkaStateConfiguration = result.ret[0];
                this.kafkaStateRuntime =  result.ret[1];
            }else{
                this.kafkaStateConfiguration = false;
                this.kafkaStateRuntime = false;
            }
            this.loadKafkaState = false;
        },
        checkFormValidity() {
            this.formErrors = [];
            // if (!this.currentMessage.Text || !this.currentMessage.Duration || !this.currentMessage.Order) this.formErrors.push(this.$t('Check required fields'));
            return this.formErrors.length == 0;
        },
        async refresh(tabRef) {
            if (tabRef == 'SettingsTabPanel') {
                this.formErrors = [];
                await this.getSettings();
            }
        },
        async getSettings() {
            // this.adminSettings.colors.primaryColor = getComputedStyle(document.documentElement).getPropertyValue('--primary').trim();
            let result = await SettingService.getSettings([
                { Name: "settings.startupTempo", Type: "System.Int32" },
                { Name: "settings.significantDigits", Type: "System.Int32" },
                { Name: "settings.intervalBeforeLogEvents", Type: "System.Int32" },
                { Name: "settings.automaticBackups", Type: "System.Boolean" },
                { Name: "settings.smtpHost", Type: "System.String" },
                { Name: "settings.smtpPort", Type: "System.Int32" },
                { Name: "settings.smtpUseSsl", Type: "System.Boolean" },
                { Name: "settings.smtpUsername", Type: "System.String" },
                { Name: "settings.smtpPassword", Type: "System.String" },
                { Name: "primary-color", Type: "System.String" },
                { Name: "primary-color-change-allowed", Type: "System.Boolean" },
            ]);
            if (result.success == 'n') this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(data.ret, data.retParams), 'warning'));
            else {
                this.adminSettings.app.startupTempo = result.ret["settings.startupTempo"];
                this.adminSettings.app.significantDigits = result.ret["settings.significantDigits"];
                this.adminSettings.app.intervalBeforeLogEvents = result.ret["settings.intervalBeforeLogEvents"];
                this.adminSettings.app.automaticBackups = result.ret["settings.automaticBackups"] == undefined ? true : result.ret["settings.automaticBackups"];
                this.adminSettings.app.smtpHost = result.ret["settings.smtpHost"];
                this.adminSettings.app.smtpPort = result.ret["settings.smtpPort"];
                this.adminSettings.app.smtpUseSsl = result.ret["settings.smtpUseSsl"];
                this.adminSettings.app.smtpUsername = result.ret["settings.smtpUsername"];
                this.adminSettings.app.smtpPassword = result.ret["settings.smtpPassword"];
                this.adminSettings.colors.primaryColor = result.ret["primary-color"];
                this.adminSettings.colors.primaryColorChangeAllowed = result.ret["primary-color-change-allowed"];
                if (!localStorage.getItem('primary-color') || !this.$store.state.settings.PRIMARY_COLOR_CHANGE_ALLOWED) {
                    this.$store.commit('updatePrimaryColor', this.adminSettings.colors.primaryColor);
                    document.documentElement.style.setProperty('--primary', this.adminSettings.colors.primaryColor);
                    document.documentElement.style.setProperty('--primary-button-font-color', this.$ProdJsTools.contrastColor(this.adminSettings.colors.primaryColor));
                }
                this.$store.commit('updatePrimaryColorChangeAllowed', this.adminSettings.colors.primaryColorChangeAllowed);
            }

            result = await SettingService.getKafkaAddress();
            if (result.success == 'n') this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(data.ret, data.retParams), 'warning'));
            else this.adminSettings.Kafka.KafkaAddress = result.ret;

            // var data = await SettingService.getPrimaryColor();
            // if (data.success == 'n') this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(data.ret, data.retParams), 'warning'));
            // else {
            //     this.adminSettings.colors.primaryColor = data.ret;
            //     // if (!localStorage.getItem('primary-color') || !this.$store.state.settings.PRIMARY_COLOR_CHANGE_ALLOWED) {
            //     //     this.$store.commit('updatePrimaryColor', data.ret);
            //     //     document.documentElement.style.setProperty('--primary', data.ret);
            //     //     document.documentElement.style.setProperty('--primary-button-font-color', this.$ProdJsTools.contrastColor(data.ret));
            //     // }
            // }
            // data = await SettingService.getPrimaryColorChangeAllowed();
            // if (data.success == 'n') this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(data.ret, data.retParams), 'warning'));
            // else {
            //     this.adminSettings.colors.primaryColorChangeAllowed = data.ret == 'true';
            //     this.$store.commit('updatePrimaryColorChangeAllowed', data.ret == 'true');
            // }
        },
        async saveSettings() {
            if (!this.checkFormValidity()) return false;
        },
        async applyColorSettings() {

            const result = await SettingService.setSettings([
                { Name: "settings.startupTempo", Value: this.adminSettings.app.startupTempo },
                { Name: "settings.significantDigits", Value: this.adminSettings.app.significantDigits },
                { Name: "settings.intervalBeforeLogEvents", Value: this.adminSettings.app.intervalBeforeLogEvents },
                { Name: "settings.automaticBackups", Value: this.adminSettings.app.automaticBackups },
                { Name: "settings.smtpHost", Value: this.adminSettings.app.smtpHost },
                { Name: "settings.smtpPort", Value: this.adminSettings.app.smtpPort },
                { Name: "settings.smtpUseSsl", Value: this.adminSettings.app.smtpUseSsl },
                { Name: "settings.smtpUsername", Value: this.adminSettings.app.smtpUsername },
                { Name: "settings.smtpPassword", Value: this.adminSettings.app.smtpPassword },
                { Name: "primary-color", Value: this.adminSettings.colors.primaryColor },
                { Name: "primary-color-change-allowed", Value: this.adminSettings.colors.primaryColorChangeAllowed },
            ]);
            if (result.success == 'n') this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(data.ret, data.retParams), 'warning'));
            else {
                document.documentElement.style.setProperty('--primary', this.adminSettings.colors.primaryColor);
                document.documentElement.style.setProperty('--primary-button-font-color', this.$ProdJsTools.contrastColor(this.adminSettings.colors.primaryColor));
                this.$store.commit('updatePrimaryColorChangeAllowed', this.adminSettings.colors.primaryColorChangeAllowed);
                this.$store.commit('updatePrimaryColor', this.adminSettings.colors.primaryColor);
                this.$EventBus.$emit('show-toast', new ToastAlert(this.$t('settings.saved'), 'info'));
            }
            //TODO: commit settings to store and make appropriate changes.


            // var data = await SettingService.setPrimaryColor(this.adminSettings.colors.primaryColor);
            // if (data.success == 'n') this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(data.ret, data.retParams), 'warning'));
            // data = await SettingService.setPrimaryColorChangeAllowed(this.adminSettings.colors.primaryColorChangeAllowed);
            // if (data.success == 'n') this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(data.ret, data.retParams), 'warning'));
            // document.documentElement.style.setProperty('--primary', this.adminSettings.colors.primaryColor);
            // document.documentElement.style.setProperty('--primary-button-font-color', this.$ProdJsTools.contrastColor(this.adminSettings.colors.primaryColor));
            // this.$store.commit('updatePrimaryColor', this.adminSettings.colors.primaryColor);
            // this.$store.commit('updatePrimaryColorChangeAllowed', this.adminSettings.colors.primaryColorChangeAllowed);
        },
        async refreshKafkaConfig() {
            this.loadKafkaState = true;
            const result = await SettingService.refreshKafkaConfig();
            if (result.success == 'n') this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(result.ret, result.retParams), 'warning'));
            else this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(result.ret, result.retParams), 'info'));
            await this.clockKafkaCheckStates();
            this.loadKafkaState = false;
        },
        async updateKafkaAddress() {
            this.loadKafkaState = true;
            const result = await SettingService.updateKafkaConfig(this.adminSettings.Kafka.KafkaAddress);
            if (result.success == 'n') this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(result.ret, result.retParams), 'warning'));
            else this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(result.ret, result.retParams), 'info'));
            await this.clockKafkaCheckStates();
            this.loadKafkaState = false;
        },
    },
};
</script>
<style scoped lang="scss">
    .containerSettingsKafka {
        display: flex; 
    }
    .iconSettingsKafka {
        padding-left:1rem;
    }
    .redIcon{
        color : #ff0000;
    }
    .greenIcon{
        color : #38c610
    }
</style>
<template>
    <OnClickOutside @trigger="clickOutsideEvent">
        <div id="contextMenu" class="popup-menu" :style="stylePopup"
            v-if="this.$store.state.contextMenu.items.length > 0 && this.$store.state.contextMenu.display">
            <div class="popup-menu-container">
                <div v-for="item in this.$store.state.contextMenu.items" :key="item.id" v-on:click="onItemClick(item)">
                    <div v-if="item.icon != null" class="menu-item-icon" :class="{ 'disabled': item.disabled }">
                        <font-awesome-icon :icon="item.icon" style="margin-right: 5px;" color="#1ca8dd" fixed-width />{{
                            item.text }}
                    </div>
                    <div v-else class="menu-item" :class="{ 'disabled': item.disabled }">{{ item.text }}</div>
                </div>
            </div>
        </div>
    </OnClickOutside>
</template>

<script>
import Vue from 'vue';
import { OnClickOutside } from '@vueuse/components'

export default {
    name: "ContextMenu",
    props: {
        locationX: Number,
        locationY: Number
    },
    components: {
        OnClickOutside,
    },
    computed: {
        stylePopup() {
            return {
                left: `${this.locationX}px`,
                top: `${this.locationY}px`,
                'z-index': `5000`, // High z-index to force it to be displayed on top of everything
            }
        },
    },
    data() {
        return {
            enabled: false,
        }
    },
    methods: {
        async onItemClick(item) {
            if (item.disabled) return;
            if (item.onClick != null) {
                this.$store.state.contextMenu.display = false;
                await item.onClick();
                this.$store.state.contextMenu.items = []
            }
        },
        clickOutsideEvent(e) {
            this.$store.state.contextMenu.display = false;
            this.$store.state.contextMenu.items = []
        }
    }
}
</script>

<style lang="scss">
.popup-menu {
    z-index: 100;
    background: var(--navbar-background-color);
    position: absolute;
    max-width: 500px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    white-space: nowrap;
    border-radius: 3px;
    border: 0.5px solid #cccccc33;
}

.popup-menu-container {
    margin-top: 7px;
    margin-bottom: 7px;
}

.menu-item {
    padding-left: 30px;
    padding-right: 30px;
    cursor: pointer;
    user-select: "none";
    font-size: 13px;
    font-family: Segoe WPC, Segoe UI, sans-serif;
    line-height: 22px;
}

.menu-item:hover {
    background-color: var(--selection-background-color);
    user-select: "none";
}

.menu-item-icon {
    padding-left: 10px;
    padding-right: 30px;
    cursor: pointer;
    user-select: "none";
    font-size: 13px;
    font-family: Segoe WPC, Segoe UI, sans-serif;
    line-height: 22px;
}

.menu-item-icon:hover {
    background-color: var(--selection-background-color);
    user-select: "none";

}</style>
import utils from "@/utils";

class ProdComProjectService {
    async getProjects() {
        return await utils.PostQuery('project.GetProjects');
    }

    async getFullTree(selectedVariables) {
        return await utils.PostQuery('project.GetFullTree',
            {
                SelectedVariables: selectedVariables
            });
    }

    async setProjectActive(projectId) {
        return await utils.PostQuery('project.SetProjectActive',
            {
                Id: projectId
            });
    }

    async deleteProject(projectId) {
        return await utils.PostQuery('project.DeleteProject',
            {
                Id: projectId
            });
    }

    async deleteAllProjects() {
        return await utils.PostQuery('project.DeleteAllProjects');
    }

    async createProject(projectName) {
        return await utils.PostQuery('project.CreateProject',
            {
                Name: projectName,
                Path: `Projects/${projectName}`
            });
    }

    async updateProject(projectId, newName, newVersion, mirrorFrequency, newNotes) {
        return await utils.PostQuery('project.UpdateProject',
            {
                Id: projectId,
                Name: newName,
                Version: newVersion,
                MirroringFrequency: mirrorFrequency,
                Notes: newNotes
            });
    }

    async setActive(projectId) {
        return await utils.PostQuery('project.SetProjectActive',
            {
                Id: projectId
            });
    }

    async rename(projectId, newName) {
        return await utils.PostQuery('project.Rename',
            {
                Id: projectId,
                Name: newName
            });
    }

    async backup(projectId, projectNotes) {
        return await utils.PostQuery('project.Backup',
            {
                Id: projectId,
                Notes: projectNotes
            });
    }

    async projectDetails(projectId) {
        return await utils.PostQuery('project.GetDetails',
            {
                Id: projectId
            });
    }

    async restoreBackup(projectId, backupId) {
        return await utils.PostQuery('project.RestoreBackup',
            {
                Id: projectId,
                BackupId: backupId
            });
    }

    async deleteBackup(projectId, backupId) {
        return await utils.PostQuery('project.DeleteBackup',
            {
                Id: projectId,
                BackupId: backupId
            });
    }

    async importProject(projectName, compatibilityMode, contentPtys, contentKey) {
        return await utils.PostQuery('project.Import',
            {
                ProjectName: projectName,
                CompatibilityMode: compatibilityMode,
                ContentPtys: contentPtys,
                ContentKey: contentKey
            });
    }

    async initialize() {
        return await utils.PostQuery('project.Initialize');
    }

    async export(projectId) {
        return await utils.PostQuery('project.Export',
            {
                Id: projectId
            });
    }
    async upgradeAllManager(projectId) {
        return await utils.PostQuery('project.UpgradeAllManager',
            {
                Id: projectId
            });
    }
}

export default new ProdComProjectService();
<template>
    <b-row>
        <b-col cols="4">
            <b-list-group>
                <div class="text-center pb-3">
                    <font-awesome-icon icon="plus" class="cursor-pointer" v-on:click="createNewSlideshow" v-bind:alt="$t('Add slideshow')" v-bind:title="$t('Add slideshow')" />
                    <font-awesome-icon icon="check" class="cursor-pointer ml-3" v-if="!saving && this.currentSlideshow.Id != null" v-on:click="saveSlideshow" v-bind:alt="$t('Save')" v-bind:title="$t('Save')" />
                    <font-awesome-icon icon="sync" class="cursor-pointer ml-3" spin v-if="saving" />
                    <font-awesome-icon icon="minus" class="cursor-pointer ml-3" v-if="currentSlideshow && currentSlideshow.Id != null && currentSlideshow.Id != 0" v-on:click="removeSlideshow" v-bind:alt="$t('Remove')" v-bind:title="$t('Remove')" />
                </div>
                <b-list-group-item
                    style="overflow: hidden; white-space: nowrap; text-overflow: ellipsis"
                    v-for="slideshow in slideshows"
                    :key="'slideshow' + slideshow.Id"
                    :active="currentSlideshow && slideshow.Id == currentSlideshow.Id"
                    v-on:click="selectSlideshow(slideshow.Id)"
                    button
                    v-bind:class="{ 'font-italic': slideshow.Id == 0 }"
                    >{{ slideshowLabel(slideshow) }}</b-list-group-item
                >
            </b-list-group>
        </b-col>
        <b-col cols="8">
            <b-card no-body v-if="currentSlideshow.Id != null" class="p-3">
                <b-form>
                    <b-row>
                        <b-col>
                            <b-form-group :label="$t('Name') + ' *'" label-for="currentSlideshow_Name">
                                <b-form-input :maxlength="$store.state.settings.INPUT_NAME_MAX_LENGTH" class="sm" ref="currentSlideshow_Name" id="currentSlideshow_Name" v-model="currentSlideshow.Name" required></b-form-input>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <b-form-group :label="$t('Dashboards')" label-for="slideSelectedDashboard">
                                <b-form-select v-model="slideSelectedDashboard" v-on:change="slideSelectedReport = null">
                                    <b-form-select-option v-for="dash in allDashboards" :value="dash" :key="'slideSelectedDashboard_' + dash.Id">
                                        {{ dash.Name }}
                                    </b-form-select-option>
                                </b-form-select>
                            </b-form-group>
                        </b-col>
                        <b-col>
                            <b-form-group :label="$t('Reports')" label-for="slideSelectedReport">
                                <b-form-select v-model="slideSelectedReport" v-on:change="slideSelectedDashboard = null">
                                    <b-form-select-option v-for="rep in allReports" :value="rep" :key="'slideSelectedReport_' + rep.Id">
                                        {{ rep.Name }}
                                    </b-form-select-option>
                                </b-form-select>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <b-form-group :label="$t('Duration')" label-for="slideDuration">
                                <b-form-input type="number" id="slideDuration" v-model="slideDuration" min="1" max="3600" step="1" required></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col>
                            <b-form-group :label="$t('Order')" label-for="slideOrder">
                                <b-form-input type="number" id="slideOrder" v-model="slideOrder" min="1" max="65565" step="1" required></b-form-input>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <b-button :disabled="!slideSelectedDashboard && !slideSelectedReport" class="mt-3 full-width" v-on:click="addSlide"><font-awesome-icon icon="plus" class="mr-2" /> {{ $t('Add') }}</b-button>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <b-list-group class="mt-1">
                                <b-row class="mb-1" v-if="currentSlideshow.Slides && currentSlideshow.Slides.length > 0">
                                    <b-col class="col-7"></b-col>
                                    <b-col class="col-2">{{ $t('Duration') }}</b-col>
                                    <b-col class="col-2">{{ $t('Order') }}</b-col>
                                    <b-col class="col-1 text-right"></b-col>
                                </b-row>
                                <b-list-group-item button v-for="(slide, idx) in currentSlideshow.Slides" :key="'slide_' + idx">
                                    <b-row>
                                        <b-col class="col-7 text-ellipsis pt-2">{{ slideLabel(slide) }} </b-col>
                                        <b-col class="col-2"><b-form-input type="number" v-model="slide.Duration" min="20" max="3600" step="1" required></b-form-input></b-col>
                                        <b-col class="col-2"><b-form-input type="number" v-model="slide.Order" min="1" max="65565" step="1" required></b-form-input></b-col>
                                        <b-col class="col-1 text-right"><font-awesome-icon icon="trash" class="fa-lg mt-2" v-on:click="removeSlide(slide)" /></b-col>
                                    </b-row>
                                </b-list-group-item>
                            </b-list-group>
                        </b-col>
                    </b-row>
                </b-form>
                <FormErrors :propFormErrors="formErrors" />
            </b-card>
        </b-col>
    </b-row>
</template>
<script>
import SlideshowService from '@/services/slideshow.service.js';
import ToastAlert from '@/utils/ToastAlert';
import FormErrors from '@/components/FormErrors.vue';
import DashboardService from '@/services/dashboard.service.js';
import ReportService from '@/services/report.service.js';

export default {
    name: 'SlideshowTabPanel',
    components: {
        ToastAlert,
        FormErrors,
    },
    data() {
        return {
            saving: false,
            slideshows: [],
            currentSlideshow: {
                Id: null,
                Name: null,
            },
            selectedDashboard: null,
            allDashboards: [],
            allReports: [],
            formErrors: [],
            slideSelectedDashboard: null,
            slideSelectedReport: null,
            slideOrder: 1,
            slideDuration: 30,
        };
    },
    async mounted() {
        await this.refresh('SlideshowTabPanel');
        this.$EventBus.$on('refresh-admin-panel-tab', this.refresh);
    },
    methods: {
        async getDashboards() {
            const data = await DashboardService.getAllDashboards();
            if (data.success == 'n') this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(data.ret, data.retParams), 'warning'));
            else {
                this.allDashboards = data.ret.filter((d) => d.IsPublic);
            }
        },
        async getReports() {
            const data = await ReportService.getAllReports();
            if (data.success == 'n') this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(data.ret, data.retParams), 'warning'));
            else {
                this.allReports = data.ret.filter((r) => r.IsPublic);
            }
        },
        checkFormValidity() {
            this.formErrors = [];
            if (!this.currentSlideshow.Name) this.formErrors.push(this.$t('Check required fields'));
            return this.formErrors.length == 0;
        },
        async refresh(tabRef) {
            if (tabRef == 'SlideshowTabPanel') {
                this.formErrors = [];
                this.currentSlideshow = {
                    Id: null,
                    Name: null,
                };
                await this.getSlideshows();
                await this.getDashboards();
                await this.getReports();
            }
        },
        slideshowLabel(slideshow) {
            if (slideshow.Id != 0 && slideshow.Name != '' && slideshow.Name != null) return slideshow.Name;
            else if (slideshow.Id == 0) return this.$t('New slideshow');
            else return this.$t('Please enter Name');
        },
        async createNewSlideshow() {
            if (this.slideshows.findIndex((slideshow) => slideshow.Id == 0) == -1) {
                this.formErrors = [];
                this.slideshows.unshift({
                    Id: 0,
                    Name: this.$t('New slideshow'),
                });
                await this.selectSlideshow(0);
                await this.$refs.currentSlideshow_Name.focus();
                this.$refs.currentSlideshow_Name.select();
            }
        },
        async getSlideshows() {
            const data = await SlideshowService.getSlideshows();
            if (data.success == 'n') this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(data.ret, data.retParams), 'warning'));
            else this.slideshows = data.ret ? data.ret : [];
            this.$emit('slideshow-count', Array.isArray(this.slideshows) ? this.slideshows.filter((slideshow) => slideshow.Id != 0).length : 0);
        },
        async selectSlideshow(id) {
            this.formErrors = [];
            this.currentSlideshow = this.slideshows.find((slideshow) => slideshow.Id == id);
        },
        async saveSlideshow() {
            if (!this.checkFormValidity()) return false;
            this.saving = true;
            let settings = null;
            let data = null;
            if (this.currentSlideshow.Id == 0) {
                // New slideshow
                data = await SlideshowService.createSlideshow(this.currentSlideshow);
            } else {
                // Update slideshow
                data = await SlideshowService.updateSlideshow(this.currentSlideshow);
            }

            if (data.success == 'n') {
                this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(data.ret, data.retParams), 'warning'));
            } else if (this.currentSlideshow.Id == 0) {
                await this.refresh('SlideshowTabPanel');
            } else {
                let id = this.currentSlideshow.Id;
                await this.refresh('SlideshowTabPanel');
                this.selectSlideshow(id);
            }
            this.saving = false;
            this.$emit('slideshow-count', this.slideshows.filter((slideshow) => slideshow.Id != 0).length);

            this.$EventBus.$emit('refresh-slideshows');
        },
        async removeSlideshow(id) {
            const value = await this.$bvModal.msgBoxConfirm(this.$t('Are you sure ?'), {
                okTitle: this.$t('yes'),
                cancelTitle: this.$t('no'),
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true,
                buttonSize: 'sm',
            });
            if (value) {
                const data = await SlideshowService.removeSlideshow(this.currentSlideshow.Id);

                if (data.success == 'n') this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(data.ret, data.retParams), 'warning'));
                else {
                    this.currentSlideshow = {
                        Id: null,
                        Name: null,
                    };
                    await this.refresh('SlideshowTabPanel');
                    this.$EventBus.$emit('refresh-websources');
                }
            }
        },
        slideLabel(slide) {
            return slide.Dashboard ? slide.Dashboard.Name : slide.Report.Name;
        },
        async removeSlide(slide) {
            const value = await this.$bvModal.msgBoxConfirm(this.$t('Are you sure ?'), {
                okTitle: this.$t('yes'),
                cancelTitle: this.$t('no'),
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true,
                buttonSize: 'sm',
            });
            if (value) {
                this.currentSlideshow.Slides = this.currentSlideshow.Slides.filter((s) => s != slide);
            }
        },
        async addSlide() {
            if (!this.currentSlideshow.Slides) this.$set(this.currentSlideshow, 'Slides', []);

            const dashboardId = this.slideSelectedDashboard ? this.slideSelectedDashboard.Id : null;
            const retportId = this.slideSelectedReport ? this.slideSelectedReport.Id : null;

            if (this.currentSlideshow.Slides.findIndex((s) => s.DashboardId == dashboardId && s.ReportId == retportId) != -1) return;

            this.currentSlideshow.Slides.push({
                DashboardId: dashboardId,
                Dashboard: this.slideSelectedDashboard,
                ReportId: retportId,
                Report: this.slideSelectedReport,
                Order: this.slideOrder,
                Duration: this.slideDuration,
            });
        },
    },
};
</script>
export default class ToastAlert {
    constructor(message, type = "danger", title = "", extraTitle = "", needTranslation, translationParams) {
        this.message = message;
        this.type = type;
        this.title = title;
        this.extraTitle = extraTitle;
        this.needTranslation = needTranslation;
        this.translationParams = translationParams;
    }
}

// Uses
/*
    this.$EventBus.$emit("show-toast", new ToastAlert("error text description"));
    //throw (new ToastAlert("error text description"));
*/
var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-tabs',{class:{ disabled: _vm.loadingConnectionCheck },staticStyle:{"width":"100%"},attrs:{"pills":"","card":"","h-100":"","d-flex":"","flex-column":""}},[_c('b-tab',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('span',{staticClass:"tabHeaderText"},[_vm._v(" "+_vm._s(_vm.$t('Connection'))+" ")])]},proxy:true}])},[_c('div',[_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('equipment.EluCloud.RefreshDelay') + ' *',"label-for":"refreshDelay"}},[_c('b-form-input',{class:{ disabled: !this.$store.state.auth.user.permissions.PermissionWrite },attrs:{"type":"number","min":"500","max":"60000","id":"refreshDelay"},on:{"change":(value) => {
                                    if (value > 60000) {
                                        this.refreshDelay = 60000;
                                    } else if (value < 500) {
                                        this.refreshDelay = 500;
                                    }
                                }},model:{value:(_vm.refreshDelay),callback:function ($$v) {_vm.refreshDelay=$$v},expression:"refreshDelay"}})],1)],1)],1)],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
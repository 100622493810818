import store from "@/store/index.js";

class AuthService {
    async login(userCredentials) {
        const settings = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                Method: 'users.login',
                Parameters: {
                    Email: userCredentials.email,
                    Password: userCredentials.password,
                },
            }),
        };

        const res = await fetch(store.state.settings.API_URL, settings);
        const data = await res.json();

        if (data.success != "n") {
            const user = {
                accessToken: data.ret.accessToken,
            }
            localStorage.setItem('user', JSON.stringify(user));
            return Promise.resolve(data.ret);
        } else {
            return Promise.reject(data.ret != undefined ? data.ret : null);
        }
    }

    async loginAsGuest() {
        const settings = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                Method: 'users.loginAsGuest',
                Parameters: {},
            }),
        };

        const res = await fetch(store.state.settings.API_URL, settings);
        const data = await res.json();

        if (data.success != "n") {
            const user = {
                accessToken: data.ret.accessToken,
            }
            localStorage.setItem('user', JSON.stringify(user));
            return Promise.resolve(data.ret);
        } else {
            return Promise.reject(data.ret != undefined ? data.ret : null);
        }
    }

    logout() {
        localStorage.removeItem('user');
    }

    getaccessToken() {
        let user = JSON.parse(localStorage.getItem('user'));
        if (user && user.accessToken) {
            return user.accessToken;
        } else {
            return null;
        }
    }
}

export default new AuthService();
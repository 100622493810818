import Vue from 'vue'
import VueRouter from 'vue-router'
import FirstLogin from '@/views/FirstLogin.vue'
import Login from '@/views/Login.vue'
import Home from '@/views/Home.vue'
import RealTime from '@/views/RealTime.vue'
import History from '@/views/History.vue'
import ToastAlert from '@/utils/ToastAlert'
import store from '@/store/index.js';

Vue.use(VueRouter)

const routes = [
    {
        path: '/firstlogin',
        name: 'firstlogin',
        component: FirstLogin
    },
    {
        path: '/login',
        name: 'login',
        component: Login
    },
    {
        path: '/',
        name: 'Build',
        component: Home,
        meta: { requiresAuth: true },
    },
    {
        path: '/run',
        name: 'Run',
        component: RealTime,
        meta: { requiresAuth: true },
    },
    {
        path: '/history',
        name: 'History',
        component: History,
        meta: { requiresAuth: true },
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

const checkForFirstUser = async () => {
    const settings = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            Method: 'users.isFirstUser',
            Parameters: {},
        }),
    };

    const res = await fetch(store.state.settings.API_URL, settings);
    const data = await res.json();

    if (data.success == 'n') throw new ToastAlert(data.ret, 'warning');

    return data.ret;
}

router.beforeEach(async (to, from, next) => {
    try {
        const firstUser = await checkForFirstUser();
        if (firstUser && to.name != 'firstlogin') next('/firstlogin');
        const loggedIn = localStorage.getItem('user');
        const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
        // Prevent user from going to 'Run' if he doesn't have the permission
        if(to.name == "Run" && !store.state.auth.user.permissions.PermissionDisplayRun){
            next('/');
        }
        // Prevent user from going to 'History' if he doesn't have the permission
        if(to.name == "History" && !store.state.auth.user.permissions.PermissionDisplayHistory){
            next('/');
        }
        if (requiresAuth && !loggedIn) {
            next('/login');
        } else {
            next();
        }
    } catch (error) {
        // todo : exception managment
        throw error
    }
});

export default router

<template>
    <b-tabs pills card h-100 d-flex flex-column style="width: 100%" :class="{ disabled: loadingConnectionCheck }">
        <!-- Connection configuration -->
        <b-tab>
            <template #title>
                <span class="tabHeaderText">
                    {{ $t('Connection') }}
                    <font-awesome-icon icon="fa fa-warning" class="ml-1 text-danger" v-if="settingsState == false" />
                </span>
            </template>

            <div>
                <b-row>
                    <b-col cols="6">
                        <b-form-group :label="$t('PLC') + ' *'" label-for="selectedPlc">
                            <b-form-select id="selectedPlc" v-model="selectedPlc" :options="availablePlcs" :class="{ disabled: !this.$store.state.auth.user.permissions.PermissionWrite }" />
                        </b-form-group>
                    </b-col>
                    <b-col cols="6">
                        <b-card>
                            <div>
                                {{ $t('equipment.s7.description_' + selectedPlc) }}
                            </div>
                        </b-card>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="6">
                        <b-form-group :label="$t('ipAddress') + ' *'" label-for="ipAddress">
                            <b-form-input id="ipAddress" :state="ipAddressState" v-model="ipAddress" :class="{ disabled: !this.$store.state.auth.user.permissions.PermissionWrite }" />
                        </b-form-group>
                    </b-col>
                    <b-col cols="3" :class="{ disabled: selectedPlc !== 0 }">
                        <b-form-group :label="$t('equipment.s7.rackNumber') + ' *'" label-for="rackNumber">
                            <b-form-input
                                id="rackNumber"
                                min="0"
                                max="1000000"
                                @change="
                                    (value) => {
                                        if (value == null || value == '') {
                                            this.rackNumber = 0;
                                        } else if (value > 1000000) {
                                            this.rackNumber = 1000000;
                                        } else if (value < 0) {
                                            this.rackNumber = 0;
                                        }
                                    }
                                "
                                v-model="rackNumber"
                                type="number"
                                :class="{ disabled: !this.$store.state.auth.user.permissions.PermissionWrite }"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col cols="3" :class="{ disabled: selectedPlc !== 0 }">
                        <b-form-group :label="$t('equipment.s7.rackSlot') + ' *'" label-for="rackSlot">
                            <b-form-input
                                id="rackSlot"
                                min="0"
                                max="1000000"
                                @change="
                                    (value) => {
                                        if (value == null || value == '') {
                                            this.rackSlot = 0;
                                        } else if (value > 1000000) {
                                            this.rackSlot = 1000000;
                                        } else if (value < 0) {
                                            this.rackSlot = 0;
                                        }
                                    }
                                "
                                v-model="rackSlot"
                                type="number"
                                :class="{ disabled: !this.$store.state.auth.user.permissions.PermissionWrite }"
                            />
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="6">
                        <b-form-group :label="$t('ConnectionType') + ' *'" label-for="connectionType">
                            <b-form-select id="connectionType" v-model="selectedConnectionType" :options="availableConnectionTypes" :class="{ disabled: !this.$store.state.auth.user.permissions.PermissionWrite }" />
                        </b-form-group>
                    </b-col>
                    <b-col cols="3">
                        <b-form-group :label="$t('equipment.s7.localTSAP') + ' *'" label-for="localTSAP">
                            <b-form-input id="localTSAP" :state="localTSAPState" v-model="localTSAP" :class="{ disabled: !this.$store.state.auth.user.permissions.PermissionWrite }" />
                        </b-form-group>
                    </b-col>
                    <b-col cols="3">
                        <b-form-group :label="$t('equipment.s7.remoteTSAP') + ' *'" label-for="remoteTSAP">
                            <b-form-input id="remoteTSAP" :state="remoteTSAPState" v-model="remoteTSAP" :class="{ disabled: !this.$store.state.auth.user.permissions.PermissionWrite }" />
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="6">
                        <b-form-group :label="$t('Refresh rate') + ' * (ms)'" label-for="pollingPeriod">
                            <b-form-input
                                id="pollingPeriod"
                                min="200"
                                max="60000"
                                @change="
                                    (value) => {
                                        if (value == null || value == '') {
                                            this.pollingPeriod = 500;
                                        } else if (value > 60000) {
                                            this.pollingPeriod = 60000;
                                        } else if (value < 200) {
                                            this.pollingPeriod = 200;
                                        }
                                    }
                                "
                                v-model="pollingPeriod"
                                type="number"
                                :class="{ disabled: !this.$store.state.auth.user.permissions.PermissionWrite }"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col cols="6">
                        <b-form-group :label="$t('Timeout') + ' * (ms)'" label-for="timeout">
                            <b-form-input
                                id="timeout"
                                min="0"
                                max="600000"
                                @change="
                                    (value) => {
                                        if (value == null || value == '') {
                                            this.timeout = 2000;
                                        } else if (value > 600000) {
                                            this.timeout = 600000;
                                        } else if (value < 0) {
                                            this.timeout = 0;
                                        }
                                    }
                                "
                                v-model="timeout"
                                type="number"
                                :class="{ disabled: !this.$store.state.auth.user.permissions.PermissionWrite }"
                            />
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="6">
                        <b-button @click="onCheckConnection" :class="{ disabled: !this.$store.state.auth.user.permissions.PermissionRead }">
                            {{ $t('equipment.opcua.checkConnection') }}
                            <font-awesome-icon v-if="loadingConnectionCheck" icon="fas fa-spinner-third" class="fa-lg fa-spin-custom" />
                        </b-button>
                    </b-col>
                </b-row>
            </div>
        </b-tab>
        <!-- Item configuration -->
        <b-tab>
            <template #title>
                {{ $t('Variables') }}
                <b-badge pill variant="info">{{
                    groups
                        .map((gr) => gr.Items.length)
                        .reduce(function (a, b) {
                            return a + b;
                        }, 0)
                }}</b-badge>
            </template>
            <ItemConfiguration
                ref="itemConfiguration"
                :columns="itemColumns"
                :groups="groups"
                :separateGroupConfig="false"
                :groupConfiguration="groupConfiguration"
                :readonly="!this.$store.state.auth.user.permissions.PermissionWrite"
                :updateMode="updateMode"
                :RefreshVarCounter="refreshVariablesCounter"
                :onEditingStartProp="onEditingStart"
                :onContentReadyProp="onContentReady"
                :calculateCellDisplayValueProp="calculateCellDisplayValue"
            />
        </b-tab>
    </b-tabs>
</template>

<script>
import ProdComEquipmentService from '@/services/prodcom.equipments.service.js';
import ItemConfiguration from '@/components/configuration/Equipments/ItemConfiguration.vue';
import ToastAlert from '@/utils/ToastAlert';
import { uuid } from 'vue-uuid';

export default {
    name: 'ConfigS7',
    props: {
        equipment: Object,
        onValidate: Function,
        updateMode: Boolean,
    },
    components: {
        ItemConfiguration,
    },
    watch: {
        async selectedSecurityMode(newVal, oldVal) {
            if (newVal == 1) {
                // None
                this.selectedSecurityPolicy = 'None';
            } else {
                // every other
                this.selectedSecurityPolicy = 'Basic128RSA15';
            }
            this.availableSecurityPolicies = [
                { value: 'None', text: 'None', disabled: newVal !== 1 },
                { value: 'Basic128RSA15', text: 'Basic128RSA15', disabled: newVal === 1 },
                { value: 'Basic256', text: 'Basic256', disabled: newVal === 1 },
                { value: 'Basic256Sha256', text: 'Basic256Sha256', disabled: newVal === 1 },
            ];
        },
    },
    async mounted() {},
    computed: {
        invalidState() {
            return this.settingsState === false;
        },
        settingsState() {
            return this.ipAddressState !== false && this.localTSAPState !== false && this.remoteTSAPState !== false;
        },
        ipAddressState() {
            return this.ipAddress == '' || this.ipAddress == null ? false : null;
        },
        localTSAPState() {
            return this.localTSAP == '' || this.localTSAP == null ? false : null;
        },
        remoteTSAPState() {
            return this.remoteTSAP == '' || this.remoteTSAP == null ? false : null;
        },
    },
    data() {
        const self = this;
        return {
            availablePlcs: [
                { value: 0, text: 'S7-300/S7-400/WinAC' },
                { value: 1, text: 'S7-1200' },
                { value: 2, text: 'S7-1500' },
                { value: 3, text: 'LOGO! 0BA7/0BA8' },
                { value: 4, text: 'S7-200 (CP243)' },
            ],
            selectedPlc: this.equipment?.SelectedPlc ?? 2,
            availableConnectionTypes: [
                { value: 1, text: this.$t('equipment.s7.connectionType.pg') },
                { value: 2, text: this.$t('equipment.s7.connectionType.op') },
                { value: 3, text: this.$t('equipment.s7.connectionType.basic') },
            ],
            selectedConnectionType: this.equipment?.SelectedConnectionType ?? 1,
            ipAddress: this.equipment?.IpAddress ?? '127.0.0.1',
            rackNumber: this.equipment?.RackNumber ?? 0,
            rackSlot: this.equipment?.RackSlot ?? 0,
            localTSAP: this.equipment?.LocalTSAP ?? '00.00',
            remoteTSAP: this.equipment?.RemoteTSAP ?? '00.00',
            pollingPeriod: this.equipment?.PollingPeriod ?? 500,
            timeout: this.equipment?.Timeout ?? 2000,
            loadingConnectionCheck: false,
            varGridView: null,
            onContentReady: function (e) {
                self.varGridView = e.component;
            },
            calculateCellDisplayValue: function (rowData, dataField) {
                let value = rowData[dataField];
                if (dataField == 'ValueLength' && rowData.Type != 'String') {
                    value = null;
                }
                if (dataField == 'ValueIndex' && rowData.Type != 'Bool' && rowData.Type != 'Bit') {
                    value = null;
                }
                if (dataField == 'ZoneIndex' && rowData.ZoneName != 'DB') {
                    value = null;
                }
                return value;
            },
            onEditingStart: function (e) {
                // Manage ZoneIndex column options
                if (e.data.ZoneName != 'DB') {
                    e.component.columnOption('ZoneIndex', 'allowEditing', false);
                    e.data.ZoneIndex = 0;
                } else {
                    e.component.columnOption('ZoneIndex', 'allowEditing', true);
                    if (e.data.ZoneIndex == null || e.data.ZoneIndex == '') {
                        e.data.ZoneIndex = 0;
                    }
                }
                // Manage ValueLength column options
                if (e.data.Type != 'String' && e.data.Type != 'WString') {
                    e.component.columnOption('ValueLength', 'allowEditing', false);
                    if (e.data.ValueLength == null || e.data.ValueLength == '') {
                        e.data.ValueLength = 0;
                    }
                } else {
                    e.component.columnOption('ValueLength', 'allowEditing', true);
                }
                // Manage Bit column options
                if (e.data.Type == 'Bool') {
                    e.component.columnOption('ValueIndex', 'allowEditing', true);
                    if (e.data.ValueIndex == null || e.data.ValueIndex == '') {
                        e.data.ValueIndex = 0;
                    }
                } else {
                    e.component.columnOption('ValueIndex', 'allowEditing', false);
                    e.data.ValueIndex = 0;
                }
            },
            itemColumns: [
                { key: 'Name', dataField: 'Name', dataType: 'string', caption: this.$t('Name'), required: true },
                {
                    key: 'Zone',
                    dataField: 'ZoneName',
                    dataType: 'string',
                    caption: this.$t('Zone'),
                    required: true,
                    options: ['DB', 'AB', 'EB', 'MB'],
                    setCellValue: function (newData, value, currentRowData) {
                        newData.ZoneName = value;
                        if (value != 'DB') {
                            newData.ZoneIndex = 0;
                            self.varGridView.columnOption('ZoneIndex', 'allowEditing', false);
                        } else {
                            self.varGridView.columnOption('ZoneIndex', 'allowEditing', true);
                            if (newData.ZoneIndex == null || newData.ZoneIndex == '') {
                                newData.ZoneIndex = 0;
                            }
                        }
                    },
                },
                { key: 'ZoneIndex', dataField: 'ZoneIndex', dataType: 'integer', caption: this.$t('ZoneNumber'), required: true },
                {
                    key: 'Type',
                    dataField: 'Type',
                    dataType: 'string',
                    caption: this.$t('Type'),
                    required: true,
                    options: ['Bool', 'Byte', 'Word', 'DWord', 'Int', 'DInt', 'Real', 'Time', 'Date', 'DT', 'TOD', 'Char', 'String', 'Counter', 'SInt', 'USInt', 'UInt', 'UDInt', 'LReal', 'DTL', 'ULInt', 'LInt', 'LTime', 'LTOD', 'LDT', 'WChar', 'WString', 'LWord'],
                    setCellValue: function (newData, value, currentRowData) {
                        newData.Type = value;
                        // Manage ValueLength column options
                        if (value != 'String' && value != 'WString') {
                            if (newData.ValueLength == null || newData.ValueLength == '') {
                                newData.ValueLength = 0;
                            }
                            self.varGridView.columnOption('ValueLength', 'allowEditing', false);
                        } else {
                            self.varGridView.columnOption('ValueLength', 'allowEditing', true);
                        }
                        // Manage Bit column options
                        if (value == 'Bool') {
                            self.varGridView.columnOption('ValueIndex', 'allowEditing', true);
                            if (newData.ValueIndex == null || newData.ValueIndex == '') {
                                newData.ValueIndex = 0;
                            }
                        } else {
                            self.varGridView.columnOption('ValueIndex', 'allowEditing', false);
                            newData.ValueIndex = 0;
                        }
                    },
                },
                { key: 'Address', dataField: 'Address', dataType: 'string', caption: this.$t('Address'), required: true },
                { key: 'ValueIndex', dataField: 'ValueIndex', dataType: 'integer', caption: this.$t('Bit'), required: true },
                { key: 'ValueLength', dataField: 'ValueLength', dataType: 'integer', caption: this.$t('Size'), required: true },
            ],
            groups: this.equipment?.Groups ?? [{ id: uuid.v4(), Name: `${this.$t('Group')} 1`, Active: true, Items: [] }],
            groupConfiguration: {
                rows: [],
            },
        };
    },
    methods: {
        async saveEquipmentCommunication() {
            await this.$refs.itemConfiguration.saveEquipmentCommunication();
        },
        /**
         * Synchronizes the data changes from the wizard into the given equipment. This method should be called before updateing / saving / adding
         * the equipment in the parent control.
         * @public This method is public.
         */
        async synchronizeEquipment() {
            this.equipment.Groups = this.groups;
            this.equipment.SelectedPlc = this.selectedPlc;
            this.equipment.SelectedConnectionType = this.selectedConnectionType;
            this.equipment.IpAddress = this.ipAddress;
            this.equipment.RackNumber = this.rackNumber;
            this.equipment.RackSlot = this.rackSlot;
            this.equipment.LocalTSAP = this.localTSAP;
            this.equipment.RemoteTSAP = this.remoteTSAP;
            this.equipment.PollingPeriod = this.pollingPeriod;
            this.equipment.Timeout = this.timeout;
        },
        async refreshVariablesCounter(searchingForItems, variableCounter) {},
        /**
         * Checks if the given connection parameters result in a valid connection.
         */
        async onCheckConnection(e) {
            this.loadingConnectionCheck = true;
            const result = await ProdComEquipmentService.s7CheckConnection(this.validate());
            if (result.success == 'n') {
                this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(result.ret, result.retParams), 'warning'));
            } else {
                if (!result.ret.Connected) {
                    if (result.ConnectionFailedMessage !== '') {
                        this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(result.ret.ConnectionFailedMessage), 'warning'));
                    }
                } else {
                    this.$EventBus.$emit('show-toast', new ToastAlert(this.$t('equipment.s7.connectionSuccess') + '(' + result.ret.ConnectionFailedMessage + ')', 'success', this.$t('ConnectionSuccess')));
                }
            }
            this.loadingConnectionCheck = false;
        },
        /**
         * Returns the correctly formated object of connection parameters
         */
        validate() {
            if (this.invalidState) return null;
            return {
                SelectedPlc: this.selectedPlc,
                SelectedConnectionType: this.selectedConnectionType,
                IpAddress: this.ipAddress,
                RackNumber: this.rackNumber,
                RackSlot: this.rackSlot,
                LocalTSAP: this.localTSAP,
                RemoteTSAP: this.remoteTSAP,
                PollingPeriod: this.pollingPeriod,
                Timeout: this.timeout,
            };
        },
    },
};
</script>
<template>
    <div class="m-3">
        <DxDiagram
            id="diagram"
            :read-only="true"
            height="80vh"
            :ref="id">
            <DxNodes
                :data-source="flowNodesDataSource"
                :text-expr="'text'"
                :type-expr="'type'"
            >
                <DxAutoLayout :type="'layered'"/>
            </DxNodes>
            <DxEdges
                :data-source="flowEdgesDataSource"
                :text-expr="'text'"
                :from-expr="'fromId'"
                :to-expr="'toId'"
            />
        </DxDiagram>
    </div>
</template>

<script>
import {
  DxDiagram, DxNodes, DxAutoLayout, DxEdges, DxToolbox, DxGroup
} from 'devextreme-vue/diagram';
import ArrayStore from 'devextreme/data/array_store';
import { confirm } from 'devextreme/ui/dialog';
import 'whatwg-fetch';
import { uuid } from 'vue-uuid';
import ProdComActionService from "@/services/prodcom.actions.service.js"
import ToastAlert from '@/utils/ToastAlert';
export default {
    name: "ActionGlobalView",
    components: {
        DxDiagram, DxNodes, DxAutoLayout, DxEdges, DxToolbox, DxGroup
    },
    props:{
        action: Object,
        propProject: String,
        showToolbar: Boolean,
        closeTab: Function,
    },
    async mounted(){
        const res = await ProdComActionService.getActionDiagram(this.propProject, this.action?.Equipment);
        if(res.success == "n") this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(result.ret, result.retParams), 'warning'));
        else{
            this.flowNodesDataSource = new ArrayStore({
                key: 'id',
                data: res.ret.Nodes
            });
            this.flowEdgesDataSource = new ArrayStore({
                key: 'id',
                data: res.ret.Edges
            });
        }
    },
    data(){
        return {
            id: `actionFlowChart_${uuid.v4()}`,
            flowNodesDataSource: new ArrayStore({
                key: 'id',
                data: []
            }),
            flowEdgesDataSource: new ArrayStore({
                key: 'id',
                data: []
            }),
        }
    }
}
</script>
<style scoped>
</style>
import store from "@/store/index.js";

class GroupService {
    async getAllGroups() {
        const settings = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                Method: 'groups.get',
                Token: store.state.auth.user.accessToken,
                Parameters: {},
            }),
        };

        const res = await fetch(store.state.settings.API_URL, settings);
        const data = await res.json();
        return data;
    }
    async getUsersInGroup(groupId) {
        const settings = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                Method: 'groups.getUsersInGroup',
                Token: store.state.auth.user.accessToken,
                Parameters: {
                    Id: groupId,
                },
            }),
        };
        const res = await fetch(store.state.settings.API_URL, settings);
        const data = await res.json();
        return data;
    }
    async getRolesInGroup(groupId) {
        const settings = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                Method: 'groups.getRolesInGroup',
                Token: store.state.auth.user.accessToken,
                Parameters: {
                    Id: groupId,
                },
            }),
        };
        const res = await fetch(store.state.settings.API_URL, settings);
        const data = await res.json();
        return data;
    }
}

export default new GroupService();
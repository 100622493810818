<template>
    <div @contextmenu.prevent="openMenu" v-on:click="onClick">
        <div v-for="dataItem in item" :key="dataItem.id">
            <TreeViewItem :item="dataItem" :rank="0" :itemSelect="onItemClick" :itemContextMenu="itemContextMenu1" :itemEditValidated="itemEditValidated" :itemChecked="itemChecked" :parent="null" :checked="checked" :toDisable="toDisable"/>
        </div>
    </div>
</template>
<script>

import TreeViewItem from "@/components/TreeViewItem.vue";
import ContextMenu from "@/components/ContextMenu.vue";

export default {
    name: 'TreeView',
    props: {
        item: Array,
        itemSelect: Function,
        onContextMenu: Function,
        onItemEditValidated: Function,
        onItemChecked: Function,
        multiSelect: Boolean,
        checked: Boolean,
        toDisable: Function,
    },
    watch: {
        item: {
            handler(newval, oldval){
            },
            deep: true,
        }
    },
    data(){
        return {
            contextMenu: false,
            popupLocationX: 0,
            popupLocationY: 0,
            selectedContextMenuItem: null,
            selectedItem: null,
            selectedItems: [],
            comesFromSubItem: false,
            menuItems: [],
            // treeViewItems: this.item,
        }
    },
    components: {
        TreeViewItem,
        ContextMenu
    },
    methods: {
        async onItemClick(item, e){
            // Multiselect active and control key pressed
            if(this.multiSelect && e.ctrlKey){
                if(this.selectedItem != null) {
                    this.setSelectedState(this.selectedItem, this.item, false);
                }
                this.selectedItem = null
                var index = this.selectedItems.findIndex((it) => it.id == item.id);
                if(index !== -1){
                    // If we found our item, remove it from the list of selected items and set its elected property to false.
                    this.selectedItems.splice(index, 1);
                    this.setSelectedState(item, this.item, false);
                    if(this.itemSelect != null) this.itemSelect(this.selectedItems);
                }else{
                    // Otherwise add it to the list of selected items and set its selected property to false
                    this.setSelectedState(item, this.item, true);
                    this.selectedItems.push(item);
                    if(this.itemSelect != null) this.itemSelect(this.selectedItems);
                    this.contextMenu = false;
                }
            // No multiselect: only one selection at a time.
            }else{
                for(const i of this.selectedItems){
                    this.setSelectedState(i, this.item, false);
                }
                this.selectedItems = [];
                if(this.selectedItem != null) {
                    this.setSelectedState(this.selectedItem, this.item, false);
                }
                this.selectedItem = item;
                this.selectedItem.selected = true;
                // const oldId = this.selectedItem.id;
                // this.selectedItem.id += "1";
                // this.$nextTick(() => this.selectedItem.id = oldId);
                // Send and array to the callback function so that the user of the component will not have to distinguish between single selection and multiple selection.
                if(this.itemSelect != null) this.itemSelect([this.selectedItem]);
                this.contextMenu = false;
            }
        },
        /**
         * Sets the selected state by going through a list of existing items and setting the 'selected' value
         * of the corresponding one. We also force modify the 'id' property in order to trigger a re-render.
         * @param item The item to be updated
         * @param listOfItems The list of items the given item is originally from
         * @param state The future value for the 'selected' property
         */
        async setSelectedState(item, listOfItems, state){
            if(listOfItems == null) return;
            for(const it of listOfItems){
                if(it.id == item.id)  {
                    it.selected = state;
                }
                else if(it.items != null){
                    this.setSelectedState(item, it.items, state);
                }
                // Force re-render by modifying the 'key' value (don't know if it's the best method, but at least it works...)
                // const oldId = it.id;
                // it.id += "1";
                // this.$nextTick(() => it.id = oldId);
            }
        },
        async itemContextMenu1(item){
            if(this.selectedContextMenuItem != null){
                this.selectedContextMenuItem.contextMenuSelected = false;
                // const oldId = this.selectedContextMenuItem.id;
                // this.selectedContextMenuItem.id += "1";
                // this.$nextTick(() => this.selectedContextMenuItem.id = oldId);
            }
            this.selectedContextMenuItem = item;
            this.comesFromSubItem = true;
            this.selectedContextMenuItem.contextMenuSelected = true;
            // const oldId = this.selectedContextMenuItem.id;
            // this.selectedContextMenuItem.id += "1";
            // this.$nextTick(() => this.selectedContextMenuItem.id = oldId);
        },
        async itemEditValidated(item){
            await this.onItemEditValidated(item);
        },
        async itemChecked(item){
            if(this.onItemChecked != null){
                await this.onItemChecked(item);
            }
        },
        async openMenu(e){
            await this.unselectAll();
            this.onContextMenu(this.selectedContextMenuItem);
        },
        async onClick(e){
            if(this.selectedContextMenuItem != null){
                this.selectedContextMenuItem.selected = false;
                this.selectedContextMenuItem.contextMenuSelected = false;
                // const oldId = this.selectedContextMenuItem.id;
                // this.selectedContextMenuItem.id += "1";
                // this.$nextTick(() => this.selectedContextMenuItem ? this.selectedContextMenuItem.id = oldId : console.log("this.selectedContextMenuItem is null"));
            }
            this.selectedContextMenuItem = null;
            this.contextMenu = false;
        },
        async unselectAll(){
            if(this.selectedItems != null){    
                for(const it of this.selectedItems){
                    it.selected = false;
                    // const oldId = it.id;
                    // it.id += "1";
                    // this.$nextTick(() => it.id = oldId);
                }
            }
            this.selectedItems.slice(0, this.selectedItems.length);
            if(this.selectedItem != null) {
                this.selectedItem.selected = false;
                // const oldId = this.selectedItem.id;
                // this.selectedItem.id += "1";
                // this.$nextTick(() => this.selectedItem ? this.selectedItem.id = oldId : console.log(this.selectedItem));
                this.selectedItem = null;
            }
        },
    }
};
</script>

<style lang="scss">
.popup-menu{
    z-index: 100;
    background: var(--navbar-background-color);
    position: absolute;
    max-width: 500px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    white-space: nowrap;
    border-radius: 3px;
}
.menu-item{
    padding-left: 30px;
    padding-right: 30px;
    cursor: pointer;
    user-select: "none";
    font-size: 13px;
    font-family: Segoe WPC,Segoe UI,sans-serif;
    line-height: 22px;
}
.menu-item:hover{
    background: #1ca8dd;
    user-select: "none";

}
</style>
<template>
    <div>
        <b-row>
            <!-- Server IP or domain name -->
            <b-col>
                <b-form-group :label="$t('Database IP')" label-for="serverIP" :class="{'disabled': (datasource.type == 'datasource')}">
                    <b-form-input type="text" v-model="Server"/>
                </b-form-group>
            </b-col>
            <!-- Server port (5432 by default) -->
            <b-col>
                <b-form-group :label="$t('Database Port')" label-for="serverPort" :class="{'disabled': (datasource.type == 'datasource')}">
                    <b-form-input type="number" v-model="Port"/>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row>
            <!-- Username -->
            <b-col>
                <b-form-group :label="$t('User name')" label-for="username" :class="{'disabled': (datasource.type == 'datasource')}">
                    <b-form-input type="text" v-model="Username"/>
                </b-form-group>
            </b-col>
            <b-col>
                <b-form-group :label="$t('Password')" label-for="password" :class="{'disabled': (datasource.type == 'datasource')}">
                    <b-form-input type="password" v-model="Password"/>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row>
            <!-- Data source base -->
            <b-col>
                <b-form-group :label="$t('Database')" label-for="database" :class="{'disabled': (datasource.type == 'datasource')}">
                    <b-form-input type="text" v-model="Database"/>
                </b-form-group>
            </b-col>
            <!-- Entities -->
            <b-col>
                <b-form-group :label="$t('Entity')" label-for="entity" :class="{'disabled': (datasource.type == 'datasource')}">
                    <b-form-select id="entities" v-model="Entity" :options="EntityList" value-field="PK_Entite" text-field="Nom" v-on:change="entityChange"/>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row>
            <!-- Check the connexion -->
            <b-col cols="3">
                <b-button v-on:click="onCheckConnection" style="width: 100%">Check Connection</b-button>
            </b-col>
        </b-row>
    </div>
</template>

<script>
/**
 * PostgreSQL provider configuration.
 */
import ToastAlert from '@/utils/ToastAlert';
import ProdComDataSourceService from "@/services/prodcom.datasources.service.js";
export default{
    name: "ProductysManager",
    props: {
        datasource: Object,
        onEntityChange: Function,
    },
    data() {
        return{
            Server: (this.datasource.Server == null ? "127.0.0.1" : this.datasource.Server),
            Port: (this.datasource.Port == null ? 5432 : this.datasource.Port),
            Username: (this.datasource.Username == null ? "postgres" : this.datasource.Username),
            Password: (this.datasource.Password == null ? "" : this.datasource.Password),
            Database: (this.datasource.Database == null ? "" : this.datasource.Database),
            Entity: (this.datasource.Entity == null ? 1 : this.datasource.Entity),
            Schema: "public",
            EntityList: this.datasource.EntityList,
        }
    },
    watch: {
        datasource: {
            handler(newVal, oldVal){
                this.Server = newVal.Server;
                this.Port = newVal.Port;
                this.Username = newVal.Username;
                this.Password = newVal.Password;
                this.Database = newVal.Database;
                this.Schema = "public";
                this.Entity = newVal.Entity;
                this.EntityList = newVal.EntityList;
            },
            deep: true
        },
    },
    async mounted(){
        // TODO: search for database and schema if IP, Port, username and password are set.
        this.EntityList = this.datasource.EntityList;
    },
    methods: {
        // TODO: search for data & schema when refresh is clicked.
        async validate(){
            return {
                Server: this.Server,
                Port: this.Port,
                Username: this.Username,
                Password: this.Password,
                Database: this.Database,
                Schema: this.Schema,
                Provider: "PostgreSQL",
                Equipment: this.datasource.Equipment,
                Entity: this.Entity,
                EntityList: this.EntityList,
            }
        },
        async onCheckConnection(){
            const result = await ProdComDataSourceService.checkProductysManagerDataSource(this.Server, this.Port, this.Username, this.Password, this.Database, this.Entity);
            if(result.success == "n") {
                this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(result.ret, result.retParams), 'warning'));
            }else{
                this.$EventBus.$emit('show-toast', new ToastAlert(this.$t("Connection successful"), 'info'));
                // Notify user ?
            }
        },
        async entityChange(e){
            if(this.onEntityChange != null){
                await this.onEntityChange(this.Entity);
            }
        }
    }
}
</script>
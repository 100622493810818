import store from "@/store/index.js";

class UserService {
    async getMinLevelRole (){
        const settings = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                Method: 'users.getMinLevelRole',
                Token: store.state.auth.user.accessToken,
                Parameters: {},
            }),
        };

        const res = await fetch(store.state.settings.API_URL, settings);
        const data = await res.json();
        return data;        
    }
    async getAllUsers () {
        const settings = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                Method: 'users.get',
                Token: store.state.auth.user.accessToken,
                Parameters: {},
            }),
        };

        const res = await fetch(store.state.settings.API_URL, settings);
        const data = await res.json();
        return data;
    }
    async getRolesOfUser(userId) {
        const settings = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                Method: 'users.getUserRoles',
                Token: store.state.auth.user.accessToken,
                Parameters: {
                    Id: userId,
                    Direct: true,
                },
            }),
        };

        const res = await fetch(store.state.settings.API_URL, settings);
        const data = await res.json();
        return data;
    }
    async getGroupsOfUser(userId) {
        const settings = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                Method: 'users.getUserGroups',
                Token: store.state.auth.user.accessToken,
                Parameters: {
                    Id: userId,
                },
            }),
        };

        const res = await fetch(store.state.settings.API_URL, settings);
        const data = await res.json();
        return data;
    }
}

export default new UserService();
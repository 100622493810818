<template>
    <b-container
        fluid
        class="home_monitoring"
        v-bind:style="{
            height: 'calc(100% - 150px)!important',
        }"
        v-if="loggedIn"
    >
        <!-- Event list -->
        <b-row>
            <b-col>
                <DxDataGrid
                    ref="logsGrid"
                    :dataSource="dsLogs"
                    :allow-column-reordering="true"
                    :repaint-changes-only="true"
                    :show-borders="true"
                    :selection="{ mode: 'single' }"
                    :columnAutoWidth="true"
                    :allow-column-resizing="false"
                    @row-dbl-click="onRowDoubleClick"
                    @optionChanged="onOptionChanged"
                    >
                    <DxPaging :enabled="true" :page-size="pageSize" :visible="true" />
                    <DxPager :allowed-page-sizes="[10, 20, 40, 100, 250, 500]" :show-page-size-selector="true" :show-info="true" :visible="true" />
                    <template #selectTemplate>
                        <b-form-checkbox class="mt-1" v-model="autorefresh" name="autorefresh-button" :plaintext="'seconds'" switch>{{$t('AutoRefresh')}}</b-form-checkbox>
                    </template>
                    <template #checkTemplate>
                        <DxButton icon="refresh" class="mt-1" @click="reload"/>
                    </template>
                    <template #lookupRefresh>
                        <b-form-select
                            id="tableConfigurationGroup"
                            v-model="timer"
                            :options="timerOption"
                            key="id"
                            :class="{'disabled': !autorefresh}"
                            class="mt-1"
                            >
                        </b-form-select>
                    </template>
                    <DxToolbar>
                        <DxItem location="after" template="selectTemplate"/>
                        <DxItem location="after" template="lookupRefresh"/>
                        <DxItem location="after" template="checkTemplate"/>
                    </DxToolbar>
                    <DxFilterRow :visible="true"/>
                    <DxHeaderFilter :visible="true"/>
                    <DxLoadPanel :enabled="firstLoading"/>
                    <template #data-origin="{data}">
                        <div>{{data.displayValue.replace("Productys.ProdCom.", "")}}</div>
                    </template>
                    <template #data-level="{data}">
                        <div>{{data.displayValue.charAt(0) + data.displayValue.slice(1).toLowerCase()}}</div>
                    </template>
                    <template #data-ellipsis="{data}">
                        <div style="max-width: 600px; overflow: hidden; text-overflow: ellipsis; display: inline-block;" v-html="data.value" :title="data.value" ></div>
                    </template>
                    <DxColumn data-field="@Date" :caption="$t('HD')" :visible="true" data-type="datetime" :allow-editing="false" :format="dateFormat"/>
                    <DxColumn data-field="@Niveau" :caption="$t('eventDetails.level')" :visible="true" data-type="string" :allow-editing="false" cell-template="data-level"/>
                    <DxColumn data-field="@Message" :caption="$t('eventDetails.message')" :visible="true" data-type="string" :allow-editing="false" cell-template="data-ellipsis"/>
                    <DxColumn data-field="@Exception" :caption="$t('eventDetails.exception')" :visible="true" data-type="string" :allow-editing="false" cell-template="data-ellipsis"/>
                    <DxColumn data-field="@Origine" :caption="$t('eventDetails.origin')" :visible="true" data-type="string" :allow-editing="false" cell-template="data-origin"/>
                    <DxColumn data-field="@Localisation" :caption="$t('eventDetails.location')" :visible="true" data-type="string" :allow-editing="false" cell-template="data-origin"/>
                </DxDataGrid>
            </b-col>
        </b-row>
        <!-- Event details popup -->
        <b-modal id="eventDetails" v-b-modal.modal-multi-2 size="xl"
        :title="$t('eventDetails.title')" :cancel-title="$t('Cancel')" :ok-title="$t('Ok')">
            <b-form v-if="selectedEvent != null">
                <b-row>
                    <b-col cols="2">
                        {{$t('eventDetails.origin')}} :
                    </b-col>
                    <b-col>
                        <pre style="color: var(--text-color);">{{selectedEvent["@Origine"]}}</pre>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="2">
                        Date :
                    </b-col>
                    <b-col>
                        <pre style="color: var(--text-color);">{{selectedEvent["@Date"]}}</pre>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="2">
                        {{$t('eventDetails.level')}} :
                    </b-col>
                    <b-col>
                        <pre style="color: var(--text-color);">{{selectedEvent["@Niveau"]}}</pre>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="2">
                        {{$t('eventDetails.location')}} :
                    </b-col>
                    <b-col>
                        <pre style="color: var(--text-color);">{{selectedEvent["@Localisation"]}}</pre>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="2">
                        {{$t('eventDetails.message')}} :
                    </b-col>
                    <b-col>
                        <pre style="color: var(--text-color); white-space: pre-wrap">{{selectedEvent["@Message"]}}</pre>
                    </b-col>
                </b-row>
                <b-row v-if="selectedEvent['@Exception'] != ''">
                    <b-col cols="2">
                        {{$t('eventDetails.exception')}} :
                    </b-col>
                   
                    <b-col v-on:click="handleClick">
                        <DxTextArea
                            :input-attr="{ 'aria-label': 'Exception'}"
                            :value-change-event="eventValue"
                            :focus-state-enabled=true
                            :readOnly=true
                            :height="computedHeight"
                            class="overflow"
                            v-model="exceptionContent"
                            id="exceptionText"
                            >
                            {{selectedEvent["@Exception"]}}
                        </DxTextArea>
                    </b-col>
                    <div>
                        <b-button @click="onClickCopy" class="btn-success" :class="{ disabled: !this.$store.state.auth.user.permissions.PermissionRead }">
                            <font-awesome-icon  icon="fas-regular fa-copy" class="fa-lg" />
                        </b-button>
                    </div>
                      
                </b-row>
            </b-form>
        </b-modal>
    </b-container>
</template>

<script>
import {HubConnectionBuilder, LogLevel} from "@microsoft/signalr";
import {
    DxDataGrid,
    DxColumn,
    DxGrouping,
    DxGroupPanel,
    DxSearchPanel,
    DxPaging,
    DxPager,
    DxToolbar,
    DxItem,
    DxScrolling,
    DxColumnChooser,
    DxFilterRow,
    DxHeaderFilter,
    DxLoadPanel,
} from 'devextreme-vue/data-grid';
import { DxButton } from 'devextreme-vue/button';
import ProdComMonitoringService from "@/services/prodcom.monitoring.service.js";
import CustomStore from 'devextreme/data/custom_store';
import DxTextArea from 'devextreme-vue/text-area';
import ToastAlert from '@/utils/ToastAlert';

export default {
    name: 'Logger',
    components: {
        DxColumn,
        DxGroupPanel,
        DxGrouping,
        DxPaging,
        DxScrolling,
        DxPager,
        DxSearchPanel,
        DxDataGrid,
        DxToolbar,
        DxItem,
        DxColumnChooser,
        DxFilterRow,
        DxHeaderFilter,
        DxLoadPanel,
        DxButton,
        DxTextArea
    },
    data() {
        return {
            isIframe: window.location !== window.parent.location,
            dsLogs: [],
            loggingRealTime: null,
            selectedEvent: null,
            dateFormat: { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric', fractionalSecondDigits: 3 },
            firstLoading: true,
            Loading: false,
            autorefresh: true,
            timer: 2,
            timerOption: [{value: 2,text: '2sec'}, {value: 10,text: '10sec'}, {value: 60,text: '1min'}],
            pageSize: localStorage.getItem('loggerPageSize') ? parseInt(localStorage.getItem('loggerPageSize')) : 40,
            eventValue : "",
            computedHeight : "40",
            exceptionContent:""

        };
    },
    watch: {
        autorefresh() {
            if(this.autorefresh && !this.firstLoading) {
                this.firstLoading = true;
                this.createLogging();
            }
            else {
                this.stopLogging();
            }
        },
        timer(newValue, oldValue) {
            if(newValue != oldValue && this.autorefresh) {
                this.firstLoading = true;
                this.createLogging();
            }
        }
    },
    async mounted(){
        // Don't do anything if not logged in
        if(!this.loggedIn) return;
        // Get initial log list and setup websocket for further updates
        await this.createLogging();
    },
    beforeDestroy(){
        // Make sure we correctly stop the different background tasks here so that
        // no API calls are done where none should be made.
        this.stopLogging();
    },
    methods: {
        /**
         * Formats the date to the given format for correct display in the data grids.
         */
        format_date(value, format){
            if (value) {
                return dayjs(value).format(format)
            }
        },
        onOptionChanged(e) {
            if (e.fullName === 'paging.pageSize' && e.value != null) {
                if(this.pageSize != e.value){
                    this.pageSize = e.value;
                    localStorage.setItem('loggerPageSize', e.value);
                }
            }
        },
        /**
         * Create the refresh timer for the custom store
         */
        async createLogging(){
            this.dsLogs = new CustomStore({
                load: async () => {
                    this.Loading = true;
                    const result = await ProdComMonitoringService.getLogs(1000);
                    if(result.success="y")
                    {
                        if(this.autorefresh && this.firstLoading){
                            clearInterval(this.loggingRealTime);
                            // I had to do this because time betwen reload CAN'T be < 2seconds (it will stop respond if it is)
                            if(this.timer >= 2) {
                                this.loggingRealTime = setInterval(async () => {
                                    if(!this.Loading) this.reload()
                                }, this.timer * 1000);
                            }
                            else {
                                this.loggingRealTime = setInterval(async () => {
                                    if(!this.Loading) this.reload()
                                }, 2000);
                            }
                            
                        }
                        this.firstLoading = false;
                        this.Loading = false;
                        return result.ret.map(e => e.logevent);
                    }
                    this.Loading = false;
                    return [];
                }
            })
        },
        async reload() {
            if(!this.Loading) this.logsGrid.getDataSource().reload()
        },
        /**
         * Correctly the refresh timer
         */
        stopLogging(){
            clearInterval(this.loggingRealTime);
        },
        /**
         * When double clicking an event record, open its details in a modal.
         */
        async onRowDoubleClick(e){
            this.selectedEvent = e.data;
            this.$bvModal.show("eventDetails");
            this.computedHeight = "40";
        },
        handleClick(){
            if(this.computedHeight == "40")this.computedHeight = "100%";
            else this.computedHeight = "40";
        },
        onClickCopy(){
            var copyText = document.getElementById("exceptionText");
            navigator.clipboard.writeText(copyText.textContent);
            this.$EventBus.$emit('show-toast', new ToastAlert(this.$t('copy'), 'success', this.$t('success')));

        }
    },
    computed: {
        loggedIn() {
            return this.$store.state.auth.status.loggedIn && this.$store.state.auth.user != null && this.$store.state.auth.user.email != '';
        },
        logsGrid() {
            if(this.$refs.logsGrid != undefined) return this.$refs.logsGrid.instance;
            return null;
        }
    },
};
</script>

<style lang="scss">
@keyframes spinner_anim {
    to {transform: rotate(360deg);}
}
.fa-spin-custom {
animation: spinner_anim 2s linear infinite;
}
.tabHeaderIcon{
    display: inline-block;
}
.tabHeaderText{
    width: 5px;
    white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis;
}
.tabGlobal{
    padding: 0;
    height: calc(100% - 28px);
    background-color: --main-background-color;
}
.tabContent{
    min-height: 100%;
    height: 100%
}
.dx-datagrid-rowsview .order {  
    text-align: center!important;  
} 
.fas.disabled,
.fas[disabled],
.disabled > .fas,
[disabled] > .fas {
  opacity: 0.5;
  cursor: not-allowed;
  pointer-events: none;
}
.input-group-text {
    color: var(--text-color) !important;
    background-color: var(--main-background-color) !important;
}
.overflow{
    overflow-y: hidden;
    padding: 5px;
}
</style>
<template>
    <b-row>
        <b-col cols="4">
            <b-list-group>
                <div class="text-center">
                    <font-awesome-icon icon="plus" v-bind:class="{ disabled: saving || loading }" class="cursor-pointer" v-on:click="createNewRTWebConnection" v-bind:alt="$t('Add RTWebConnection')" v-bind:title="$t('Add RTWebConnection')" />
                    <font-awesome-icon
                        icon="check"
                        v-bind:class="{ disabled: saving || loading || !currentRTWebConnection.IsAlive, 'scale-animation': needSave }"
                        class="cursor-pointer ml-3"
                        v-if="!saving && this.currentRTWebConnection.Id != null"
                        v-on:click="saveRTWebConnection"
                        v-bind:alt="$t('Save')"
                        v-bind:title="$t('Save')"
                    />
                    <font-awesome-icon icon="sync" class="cursor-pointer ml-3" spin v-if="saving" />
                    <font-awesome-icon icon="minus" v-bind:class="{ disabled: saving || loading }" class="cursor-pointer ml-3" v-if="currentRTWebConnection && currentRTWebConnection.Id > 0" v-on:click="removeRTWebConnection" v-bind:alt="$t('Remove')" v-bind:title="$t('Remove')" />
                </div>
                <div class="pt-1 pb-3 text-center">
                    <transition name="fade">
                        <div class="font-italic" v-if="statusText">{{ statusText }}</div>
                    </transition>
                </div>
                <b-list-group-item
                    :disabled="saving || loading || getRTWebConnectionDevices_running"
                    v-for="rtwc in rtWebConnections"
                    :key="rtwc.Id"
                    :active="currentRTWebConnection && rtwc.Id == currentRTWebConnection.Id"
                    v-on:click="selectRTWebConnection(rtwc.Id)"
                    button
                    v-bind:class="{ 'font-italic': rtwc.Id == 0 }"
                    >{{ rtwcLabel(rtwc) }}</b-list-group-item
                >
            </b-list-group>
        </b-col>
        <b-col cols="8">
            <b-card no-body v-if="currentRTWebConnection.Id != null">
                <b-tabs pills card v-model="tabIndex">
                    <b-tab active :title="$t('RTWebConnection')">
                        <b-form>
                            <b-row>
                                <b-col>
                                    <b-form-group :label="$t('Alias') + ' *'" label-for="currentRTWebConnection_Alias">
                                        <b-form-input :maxlength="$store.state.settings.INPUT_NAME_MAX_LENGTH" :disabled="saving || loading" class="sm" ref="currentRTWebConnection_Alias" id="currentRTWebConnection_Alias" v-model="currentRTWebConnection.Alias" required></b-form-input>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col>
                                    <b-form-group :label="$t('Ip') + ' *'" label-for="currentRTWebConnection_ip">
                                        <b-form-input :maxlength="$store.state.settings.INPUT_URL_MAX_LENGTH" :disabled="saving || loading" id="currentRTWebConnection_ip" v-model="currentRTWebConnection.ServerName" required></b-form-input>
                                    </b-form-group>
                                </b-col>
                                <b-col>
                                    <b-form-group :label="$t('Port number') + ' *'" label-for="currentRTWebConnection_port">
                                        <b-form-input :disabled="saving || loading" type="number" id="currentRTWebConnection_port" v-model="currentRTWebConnection.ServerPort" min="1" max="65565" step="1" required></b-form-input>
                                    </b-form-group>
                                </b-col>
                                <b-col>
                                    <div class="pt-3 mt-4">
                                        <b-form-checkbox :disabled="saving || loading" v-model="currentRTWebConnection.IsSecured">{{ $t('Secured') }}</b-form-checkbox>
                                    </div>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col v-if="currentRTWebConnection.IsAlive" class="mt-2">
                                    {{ $t('Connection established') }}
                                </b-col>
                                <b-col class="text-right">
                                    <b-button v-on:click="getRTWebConnectionFromIp">{{ $t('Test connection') }}</b-button>
                                </b-col>
                            </b-row>
                            <FormErrors :propFormErrors="formErrors" />
                        </b-form>
                    </b-tab>
                    <b-tab>
                        <template #title>
                            {{ $t('Devices') }} <font-awesome-icon icon="sync" spin v-if="getRTWebConnectionDevices_running" class="mr-1" />
                            <b-badge pill variant="info" v-if="rtWebConnectionsDevices['rtwcid_' + currentRTWebConnection.Id] != undefined"><!--{{ selectedDevices }} / -->{{ rtWebConnectionsDevices['rtwcid_' + currentRTWebConnection.Id].length }}</b-badge>
                        </template>
                        <b-row class="m-0 border-bottom mb-2" v-if="rtWebConnectionsDevices['rtwcid_' + currentRTWebConnection.Id] != undefined">
                            <b-col class="pl-0 mb-2">
                                <!-- <b-link class="text-decoration-none mr-3" v-on:click="changeRTWebConnectionsDevicesSelectionMode('devices')">
                                    <font-awesome-icon v-if="rtWebConnectionsDevicesSelectionMode == 0" icon="check-square" style="color: var(--text-color)" />
                                    <font-awesome-icon v-if="rtWebConnectionsDevicesSelectionMode == 1" icon="square" style="color: var(--text-color)" />
                                    <font-awesome-icon v-if="rtWebConnectionsDevicesSelectionMode == 2" icon="minus-square" style="color: var(--text-color)" />
                                </b-link> -->
                            </b-col>
                            <b-col class="pr-0 text-right mb-2">
                                <font-awesome-icon icon="sync" class="cursor-pointer" v-on:click="getRTWebConnectionFromIp" />
                            </b-col>
                        </b-row>
                        <div class="devices-list">
                            <div class="border p-2 m-2" v-for="device in rtWebConnectionsDevices['rtwcid_' + currentRTWebConnection.Id]" :key="device.Name">
                                <div
                                    class="cursor-default"
                                    v-on:click.stop.prevent="
                                        if (rtWebConnectionDeviceGroupsOpened.findIndex((d) => d == device.Name) == -1) {
                                            showGroups(device.Name);
                                        } else {
                                            hideGroups(device.Name);
                                        }
                                    "
                                >
                                    <!-- <b-form-checkbox v-model="device.chosen"> -->
                                    <font-awesome-icon v-if="rtWebConnectionDeviceGroupsOpened.findIndex((d) => d == device.Name) == -1" icon="plus" class="display-inline mr-2 cursor-pointer" style="color: var(--text-color)" />
                                    <font-awesome-icon v-if="rtWebConnectionDeviceGroupsOpened.findIndex((d) => d == device.Name) != -1" icon="minus" class="display-inline mr-2 cursor-pointer" style="color: var(--text-color)" />
                                    <span class="font-bold">
                                        <font-awesome-icon icon="tools" class="mr-1" /> {{ device.Name }}
                                        <font-awesome-icon v-if="device.IsStarted" icon="play" class="float-right mt-1" />
                                        <font-awesome-icon v-if="!device.IsStarted" icon="pause" class="float-right mt-1" />
                                    </span>
                                </div>
                                <!-- </b-form-checkbox> -->
                                <div v-if="rtWebConnectionDeviceGroupsOpened.findIndex((d) => d == device.Name) != -1">
                                    <div v-for="group in device.Groups" :key="device.Name + '_' + group.Name">
                                        <div class="dx-header-row py-1 px-2 mt-2"><font-awesome-icon icon="layer-group" class="mr-1" /> {{ group.Name }}</div>
                                        <div class="pl-4 group-item" style="display: flex; justify-content: space-between" v-for="item in group.Items" :key="device.Name + '_' + group.Name + '_' + item.Name">
                                            <div class="text-ellipsis">{{ item.Name }}</div>
                                            <div class="text-right text-ellipsis">{{ item.Type.split(',')[0].split('.')[1] }}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </b-tab>
                </b-tabs>
            </b-card>
        </b-col>
    </b-row>
</template>
<script>
import ToastAlert from '@/utils/ToastAlert';
import FormErrors from '@/components/FormErrors.vue';
import RTWebConnectionService from '@/services/rtwebconnection.service.js';

export default {
    name: 'RTWebConnectionTabPanel',
    components: {
        FormErrors,
    },
    data() {
        return {
            tabIndex: 0,
            saving: false,
            rtWebConnections: [],
            currentRTWebConnection: {
                Id: null,
                Alias: null,
                ServerName: null,
                ServerPort: null,
                IsSecured: false,
                IsAlive: false,
            },
            rtWebConnectionsDevices: {},
            rtWebConnectionDeviceGroupsOpened: [],
            getRTWebConnectionDevices_running: false,
            formErrors: [],
            statusText: null,
            loading: false,
            needSave: false,
        };
    },
    watch: {
        loading(val) {
            if (val) this.$store.commit('increaseLoadingCount');
            else this.$store.commit('decreaseLoadingCount');
        },
        saving(val) {
            if (val) this.$store.commit('increaseLoadingCount');
            else this.$store.commit('decreaseLoadingCount');
        },
    },
    async mounted() {
        await this.refresh('RTWebConnectionTabPanel');
        this.$EventBus.$on('refresh-admin-panel-tab', this.refresh);
    },
    computed: {
        selectedDevices: function () {
            return this.rtWebConnectionsDevices['rtwcid_' + this.currentRTWebConnection.Id].filter((table) => table.chosen).length;
        },
    },
    methods: {
        checkFormValidity() {
            this.formErrors = [];
            if (!this.currentRTWebConnection.Alias || !this.currentRTWebConnection.ServerName || !this.currentRTWebConnection.ServerPort) this.formErrors.push(this.$t('Check required fields'));

            return this.formErrors.length == 0;
        },
        async refresh(tabRef) {
            if (tabRef == 'RTWebConnectionTabPanel') {
                this.formErrors = [];
                this.currentRTWebConnection = {
                    Id: null,
                    Alias: null,
                    ServerName: null,
                    ServerPort: null,
                    IsSecured: false,
                    IsAlive: false,
                };
                await this.getRTWebConnections();
            }
        },
        rtwcLabel(rtwc) {
            if (rtwc.Id != 0 && rtwc.Alias != '' && rtwc.Alias != null) return rtwc.Alias;
            else if (rtwc.Id == 0) return this.$t('New RTWebConnection');
            else return this.$t('Please enter Alias');
        },
        async getRTWebConnectionFromIp() {
            this.loading = true;
            this.getRTWebConnectionDevices_running = true;
            this.statusText = this.$t('Fetching info...');
            const data = await RTWebConnectionService.getRTWebConnectionData(this.currentRTWebConnection.ServerName, this.currentRTWebConnection.ServerPort, this.currentRTWebConnection.IsSecured);
            if (data.success == 'n') {
                this.loading = false;
                this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(data.ret, data.retParams), 'warning'));
                this.currentRTWebConnection.IsAlive = false;
            } else {
                this.currentRTWebConnection.IsAlive = true;
                this.$set(this.rtWebConnectionsDevices, 'rtwcid_' + this.currentRTWebConnection.Id, data.ret.equipements);
            }
            this.statusText = null;
            this.getRTWebConnectionDevices_running = false;
            this.loading = false;
        },
        showGroups(deviceName) {
            // event.stopPropagation();
            // event.preventDefault();
            this.rtWebConnectionDeviceGroupsOpened.push(deviceName);
        },
        hideGroups(deviceName, event) {
            // event.stopPropagation();
            // event.preventDefault();
            const idx = this.rtWebConnectionDeviceGroupsOpened.findIndex((d) => d == deviceName);
            if (idx != -1) this.rtWebConnectionDeviceGroupsOpened.splice(idx, 1);
        },
        async createNewRTWebConnection() {
            if (this.rtWebConnections.findIndex((rtwc) => rtwc.Id == 0) == -1) {
                this.formErrors = [];
                this.rtWebConnections.unshift({
                    Id: 0,
                    Alias: this.$t('New RTWebConnection'),
                    ServerName: '127.0.0.1',
                    ServerPort: 4461,
                    IsAlive: false,
                    IsSecured: false,
                });
                this.rtWebConnectionsDevices = [];
                this.rtWebConnectionsDevicesSelectionMode = 2;

                await this.selectRTWebConnection(0);
                await this.$refs.currentRTWebConnection_Alias.focus();
                this.$refs.currentRTWebConnection_Alias.select();
                this.needSave = true;
            }
        },
        async getRTWebConnections() {
            this.loading = true;
            const data = await RTWebConnectionService.getRTWebConnections();
            if (data.success == 'n') {
                this.loading = false;
                this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(data.ret, data.retParams), 'warning'));
            } else this.rtWebConnections = data.ret ? data.ret : [];
            this.$emit('rtwc-count', Array.isArray(this.rtWebConnections) ? this.rtWebConnections.filter((rtwc) => rtwc.Id != 0).length : 0);
            this.loading = false;
        },
        async selectRTWebConnection(id) {
            this.statusText = this.$t('Fetching info...');
            this.formErrors = [];
            this.tabIndex = 0;
            this.currentRTWebConnection = this.rtWebConnections.find((rtwc) => rtwc.Id == id);
            if (id != 0) {
                await this.getRTWebConnectionFromIp();
            }
            this.statusText = null;
        },
        async saveRTWebConnection() {
            if (!this.checkFormValidity()) return false;
            this.saving = true;
            let data = null;
            if (this.currentRTWebConnection.Id == 0) {
                this.statusText = this.$t('Saving...');
                // New RTWebConnection
                data = await RTWebConnectionService.createRTWebConnection(this.currentRTWebConnection.Alias, this.currentRTWebConnection.ServerName, this.currentRTWebConnection.ServerPort, this.currentRTWebConnection.IsSecured);
            } else {
                this.statusText = this.$t('Saving...');
                // Update rtWebConnection
                data = await RTWebConnectionService.updateRTWebConnection(this.currentRTWebConnection.Id, this.currentRTWebConnection.Alias, this.currentRTWebConnection.ServerName, this.currentRTWebConnection.ServerPort, this.currentRTWebConnection.IsSecured);
            }
            if (data.success == 'n') {
                this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(data.ret, data.retParams), 'warning'));
            } else {
                await this.getRTWebConnections();
                this.selectRTWebConnection(data.ret);
                this.$emit('rtwc-count', this.rtWebConnections.filter((rtwc) => rtwc.Id != 0).length);
            }
            this.saving = false;
            this.statusText = null;
            this.needSave = false;
        },
        async removeRTWebConnection() {
            const value = await this.$bvModal.msgBoxConfirm(this.$t('Are you sure ?'), {
                okTitle: this.$t('yes'),
                cancelTitle: this.$t('no'),
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true,
                buttonSize: 'sm',
            });
            if (value) {
                const data = await RTWebConnectionService.removeRTWebConnection(this.currentRTWebConnection.Id);
                if (data.success == 'n') this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(data.ret, data.retParams), 'warning'));
                else {
                    this.$set(this.rtWebConnectionsDevices, 'rtwcid_' + this.currentRTWebConnection.Id, []);
                    this.currentRTWebConnection = {
                        Id: null,
                        Alias: null,
                        ServerName: null,
                        ServerPort: null,
                        IsSecured: false,
                        IsAlive: false,
                    };
                    await this.refresh('RTWebConnectionTabPanel');
                }
            }
            this.needSave = false;
        },
        async changeRTWebConnectionsDevicesSelectionMode(type) {
            this.loading = true;
            if (type == 'devices') {
                this.rtWebConnectionsDevicesSelectionMode = this.rtWebConnectionsDevicesSelectionMode == 2 ? 0 : this.rtWebConnectionsDevicesSelectionMode + 1;
                this.rtWebConnectionsDevicesViewsSelect(type, this.rtWebConnectionsDevicesSelectionMode);
            } else {
                this.rtWebConnectionsViewsSelectionMode = this.rtWebConnectionsViewsSelectionMode == 2 ? 0 : this.rtWebConnectionsViewsSelectionMode + 1;
                this.rtWebConnectionsDevicesViewsSelect(type, this.rtWebConnectionsViewsSelectionMode);
            }
            this.needSave = true;
            this.loading = false;
        },
        async rtWebConnectionsDevicesViewsSelect(type, mode) {
            this.needSave = true;
            var items = type == 'devices' ? this.rtWebConnectionsDevices : this.rtWebConnectionsViews;
            switch (mode) {
                case 0:
                    items['rtwcid_' + this.currentRTWebConnection.Id].forEach((element) => {
                        element.chosen = true;
                    });
                    break;
                case 1:
                    items['rtwcid_' + this.currentRTWebConnection.Id].forEach((element) => {
                        element.chosen = false;
                    });
                    break;
                case 2:
                    items['rtwcid_' + this.currentRTWebConnection.Id].forEach((element) => {
                        element.chosen = element.Count > 0;
                    });
                    break;
            }
        },
    },
};
</script>
<style>
.devices-list {
    max-height: 344px;
    overflow: auto;
    position: relative;
}
.column-striker input[type='checkbox']:checked + label > span {
    text-decoration: line-through;
}
.column-striker {
    filter: grayscale(1);
}
.group-item:hover {
    background-color: #555555;
}
</style>
import store from "@/store/index.js";

class ReportService {
    async getAllReports() {
        const settings = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                Method: 'reports.get',
                Token: store.state.auth.user.accessToken,
                Parameters: {},
            }),
        };

        const res = await fetch(store.state.settings.API_URL, settings);
        const data = await res.json();
        return data;
    }
    async getReportByName(name, isPublic) {
        const settings = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                Method: 'reports.getReportByName',
                Token: store.state.auth.user.accessToken,
                Parameters: {
                    name: name,
                },
            }),
        };
        const res = await fetch(store.state.settings.API_URL, settings);
        const data = await res.json();
        return data;
    }    
    async removeReportByName(name) {
        const settings = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                Method: 'reports.delete',
                Token: store.state.auth.user.accessToken,
                Parameters: {
                    Name: name
                },
            }),
        };

        const res = await fetch(store.state.settings.API_URL, settings);
        const data = await res.json();
        return data;
    }
    async createNewReport() {
        const settings = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                Method: 'reports.create',
                Token: store.state.auth.user.accessToken,
                Parameters: {
                },
            }),
        };
        const res = await fetch(store.state.settings.API_URL, settings);
        const data = await res.json();
        return data;
    }
    async updateReportPublicStatus(name, isPublic) {
        const settings = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                Method: 'reports.updatePublicStatus',
                Token: store.state.auth.user.accessToken,
                Parameters: {
                    name: name,
                    isPublic: isPublic
                },
            }),
        };
        const res = await fetch(store.state.settings.API_URL, settings);
        const data = await res.json();
        return data;
    }
    async uploadReport(repxData) {
        const settings = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                Method: 'reports.uploadReport',
                Token: store.state.auth.user.accessToken,
                Parameters: {
                    repxData: repxData
                },
            }),
        };
        const res = await fetch(store.state.settings.API_URL, settings);
        const data = await res.json();
        return data;
    }
    async downloadReport(reportName){
        const settings = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                Method: 'reports.downloadReport',
                Token: store.state.auth.user.accessToken,
                Parameters: {
                    name: reportName
                },
            }),
        };
        const res = await fetch(store.state.settings.API_URL, settings);
        const data = await res.json();
        return data;
    }
}

export default new ReportService();
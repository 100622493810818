<template>
    <ItemConfiguration
        ref="itemConfiguration"
        :columns="itemColumns"
        :groups="groups"
        :onItemInserting="onItemInserting"
        :readonly="!this.$store.state.auth.user.permissions.PermissionWrite"
        :separateGroupConfig="false"
        :groupConfiguration="groupConfiguration"
        :updateMode="updateMode"
        :RefreshVarCounter="refreshVariablesCounter"
    >
    </ItemConfiguration>
</template>

<script>
import ItemConfiguration from '@/components/configuration/Equipments/ItemConfiguration.vue';
import { uuid } from 'vue-uuid';
/**
 * The Virtual Equipment configuration.
 */
export default {
    name: 'ConfigVirtual',
    props: {
        equipment: Object,
        onValidate: Function,
        updateMode: Boolean,
    },
    components: {
        ItemConfiguration,
    },
    data() {
        return {
            itemColumns: [
                { key: 'Name', dataField: 'Name', dataType: 'string', caption: this.$t('Name'), required: true },
                {
                    key: 'Type',
                    dataField: 'Type',
                    dataType: 'string',
                    caption: this.$t('Type'),
                    required: true,
                    options: ['System.Double', 'System.Single', 'System.UInt16', 'System.Int16', 'System.UInt32', 'System.Int32', 'System.UInt64', 'System.Int64', 'System.Boolean', 'System.DateTime', 'System.String'],
                },
            ],
            groups: this.equipment?.Groups ?? [{ id: uuid.v4(), Name: `${this.$t('Group')} 1`, Active: true, Items: [] }],
            groupConfiguration: { rows: [] },
        };
    },
    async mounted() {
        this.currentGroup = this.groups[0];
    },
    methods: {
        async saveEquipmentCommunication() {
            await this.$refs.itemConfiguration.saveEquipmentCommunication();
        },
        /**
         * Synchronizes the data changes from the wizard into the given equipment. This method should be called before updateing / saving / adding
         * the equipment in the parent control.
         * @public This method is public.
         */
        async synchronizeEquipment() {
            this.equipment.Groups = this.groups;
        },
        async refreshVariablesCounter(searchingForItems, variableCounter) {},
        onItemInserting(e) {
            e.data.Type = 'System.Double';
        },
    },
};
</script>
<template>
    <div>
        <b-row>
            <b-col cols="3">
                <b-form-group :label="$t('Criteria count')" label-for="criteriaCount">
                    <b-form-input v-model="criteriaCount" type="number" :min="1" :max="6"
                        :class="{ 'disabled': !$store.state.auth.user.permissions.PermissionWrite }" />
                </b-form-group>
            </b-col>
        </b-row>
        <b-tabs pills card h-100 d-flex flex-column ref="myTabs">
            <b-tab v-for="crit in criterias" :key="crit.id" :disabled="crit.id >= criteriaCount && crit.id > 0"
                :active="currentTab === crit.id">
                <template #title>{{ $t('Criteria') }} {{ crit.id + 1 }}
                    <font-awesome-icon icon="fa fa-warning" class="ml-1 text-danger"
                        v-show="invalidTabActionState[crit.id] && criteriaCount > crit.id" />
                </template>
                <!-- <b-row :class="{'disabled': crit.id == 0}">
                <b-col cols="6">
                    <b-form-group :label="$t('Link')" label-for="link">
                        <b-form-select v-model="crit.Link" :options="availableLinks" :class="{'disabled': !$store.state.auth.user.permissions.PermissionWrite}"/>
                    </b-form-group>
                </b-col>
                 </b-row> -->
                <UnitaryNotification :equipment="equipment" :action="crit" :propProject="propProject"
                    :ref="'criteria_' + crit.id" v-show="criteriaCount > 0" />
            </b-tab>
        </b-tabs>
    </div>
</template>

<script>
import UnitaryNotification from "@/components/configuration/ActionsTriggers/UnitaryNotification.vue";

export default {
    name: "NotificationAdvanced",
    components: {
        UnitaryNotification
    },
    props: {
        action: Object,
        propProject: String,
        equipment: String,
    },
    watch: {
        criteriaCount(newVal, oldVal) {
            if (newVal > 0) this.currentTab = newVal - 1;
        }
    },
    data() {
        return {
            invalidTabActionState: [false, false, false, false, false, false],
            criteriaCount: 1,
            availableLinks: [
                { value: 0, text: 'And' },
                { value: 1, text: 'Or' }
            ],
            /**
             * Static list of 6 criterias, as defined in the WinForms UI
             */
            criterias: [
                { id: 0, Link: 0, DeclencheurConfiguration: {} },
                { id: 1, Link: 0, DeclencheurConfiguration: {} },
                { id: 2, Link: 0, DeclencheurConfiguration: {} },
                { id: 3, Link: 0, DeclencheurConfiguration: {} },
                { id: 4, Link: 0, DeclencheurConfiguration: {} },
                { id: 5, Link: 0, DeclencheurConfiguration: {} },
            ],
            currentTab: 0,
        }
    },
    async mounted() {
        this.criteriaCount = this.action?.DeclencheurConfiguration?.Criteria?.length ?? 1;
        let i = 0;
        if (this.action?.DeclencheurConfiguration?.Criteria == null) return;
        for (const link of this.action.DeclencheurConfiguration.Criteria) {
            this.criterias[i].Link = link.Link;
            this.criterias[i].DeclencheurConfiguration = link;
            await this.$refs['criteria_' + i][0]?.loadConfig(link);
            i++;
        }
    },
    methods: {
        async validate() {
            this.invalidTabActionState = [false, false, false, false, false, false];
            let retVal = { Criteria: [] }
            for (const crit of this.criterias) {
                if (crit.id >= this.criteriaCount) break;
                const critEl = this.$refs['criteria_' + crit.id][0];
                if (critEl?.validate != null) {
                    let result = await critEl.validate();
                    if (result == null) {
                        this.$set(this.invalidTabActionState, crit.id, true);
                        retVal.Criteria.push(null);
                    } else {
                        this.$set(this.invalidTabActionState, crit.id, false);
                        // result.Link = crit.Link;
                        retVal.Criteria.push(result);
                    }
                }
            }
            if (retVal.Criteria.findIndex(act => act == null) != -1) {
                return null;
            }
            return retVal;
        }
    }
}
</script>
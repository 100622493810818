<template>
    <b-row>
        <b-col cols="4">
            <b-list-group>
                <div class="text-center pb-3">
                    <font-awesome-icon icon="plus" class="cursor-pointer" v-on:click="createNewWebSource" v-bind:alt="$t('Add webSource')" v-bind:title="$t('Add webSource')" />
                    <font-awesome-icon icon="check" class="cursor-pointer ml-3" v-if="!saving && this.currentWebSource.Id != null" v-on:click="saveWebSource" v-bind:alt="$t('Save')" v-bind:title="$t('Save')" />
                    <font-awesome-icon icon="sync" class="cursor-pointer ml-3" spin v-if="saving" />
                    <font-awesome-icon icon="minus" class="cursor-pointer ml-3" v-if="currentWebSource && currentWebSource.Id != null && currentWebSource.Id != 0" v-on:click="removeWebSource" v-bind:alt="$t('Remove')" v-bind:title="$t('Remove')" />
                </div>
                <b-list-group-item
                    style="overflow: hidden; white-space: nowrap; text-overflow: ellipsis"
                    v-for="webSource in webSources"
                    :key="'webSource' + webSource.Id"
                    :active="currentWebSource && webSource.Id == currentWebSource.Id"
                    v-on:click="selectWebSource(webSource.Id)"
                    button
                    v-bind:class="{ 'font-italic': webSource.Id == 0 }"
                    >{{ webSourceLabel(webSource) }}</b-list-group-item
                >
            </b-list-group>
        </b-col>
        <b-col cols="8">
            <b-card no-body v-if="currentWebSource.Id != null" class="p-3">
                <b-form>
                    <b-row>
                        <b-col>
                            <b-form-group :label="$t('Alias') + ' *'" label-for="currentWebSource_Alias">
                                <b-form-input :maxlength="$store.state.settings.INPUT_NAME_MAX_LENGTH" class="sm" ref="currentWebSource_Alias" id="currentWebSource_Alias" v-model="currentWebSource.Alias" required></b-form-input>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <b-form-group :label="$t('Url') + ' *'" label-for="currentWebSource_Url">
                                <b-form-input :maxlength="$store.state.settings.INPUT_URL_MAX_LENGTH" class="sm" ref="currentWebSource_Url" id="currentWebSource_Url" v-model="currentWebSource.Url" required></b-form-input>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </b-form>
                <FormErrors :propFormErrors="formErrors" />
            </b-card>
        </b-col>
    </b-row>
</template>
<script>
import WebSourceService from '@/services/websource.service.js';
import ToastAlert from '@/utils/ToastAlert';
import FormErrors from '@/components/FormErrors.vue';

export default {
    name: 'WebSourceTabPanel',
    components: {
        ToastAlert,
        FormErrors,
    },
    data() {
        return {
            saving: false,
            webSources: [],
            currentWebSource: {
                Id: null,
                Alias: null,
                Url: null,
            },
            formErrors: [],
        };
    },
    async mounted() {
        await this.refresh('WebSourceTabPanel');
        this.$EventBus.$on('refresh-admin-panel-tab', this.refresh);
    },
    methods: {
        checkFormValidity() {
            this.formErrors = [];
            if (!this.currentWebSource.Alias || !this.currentWebSource.Url) this.formErrors.push(this.$t('Check required fields'));
            return this.formErrors.length == 0;
        },
        async refresh(tabRef) {
            if (tabRef == 'WebSourceTabPanel') {
                this.formErrors = [];
                this.currentWebSource = {
                    Id: null,
                    Alias: null,
                    Url: null,
                };
                await this.getWebSources();
            }
        },
        webSourceLabel(webSource) {
            if (webSource.Id != 0 && webSource.Alias != '' && webSource.Alias != null) return webSource.Alias;
            else if (webSource.Id == 0) return this.$t('New webSource');
            else return this.$t('Please enter Alias');
        },
        async createNewWebSource() {
            if (this.webSources.findIndex((webSource) => webSource.Id == 0) == -1) {
                this.formErrors = [];
                this.webSources.unshift({
                    Id: 0,
                    Alias: this.$t('New webSource'),
                    Url: null,
                });
                await this.selectWebSource(0);
                await this.$refs.currentWebSource_Alias.focus();
                this.$refs.currentWebSource_Alias.select();
            }
        },
        async getWebSources() {
            const data = await WebSourceService.getWebSources();
            if (data.success == 'n') this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(data.ret, data.retParams), 'warning'));
            else this.webSources = data.ret ? data.ret : [];
            this.$emit('websource-count', Array.isArray(this.webSources) ? this.webSources.filter((webSource) => webSource.Id != 0).length : 0);
        },
        async selectWebSource(id) {
            this.formErrors = [];
            this.currentWebSource = this.webSources.find((webSource) => webSource.Id == id);
        },
        async saveWebSource() {
            if (!this.checkFormValidity()) return false;
            this.saving = true;
            let settings = null;
            if (this.currentWebSource.Id == 0) {
                // New webSource
                settings = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        Method: 'websources.create',
                        Token: this.$store.state.auth.user.accessToken,
                        Parameters: {
                            Alias: this.currentWebSource.Alias,
                            Url: this.currentWebSource.Url,
                        },
                    }),
                };
            } else {
                // Update webSource
                settings = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        Method: 'websources.update',
                        Token: this.$store.state.auth.user.accessToken,
                        Parameters: {
                            Id: this.currentWebSource.Id,
                            Alias: this.currentWebSource.Alias,
                            Url: this.currentWebSource.Url,
                        },
                    }),
                };
            }

            const res = await fetch(this.$store.state.settings.API_URL, settings);
            const data = await res.json();

            if (data.success == 'n') {
                this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(data.ret, data.retParams), 'warning'));
            } else if (this.currentWebSource.Id == 0) {
                await this.refresh('WebSourceTabPanel');
            }
            this.saving = false;
            this.$emit('websource-count', this.webSources.filter((webSource) => webSource.Id != 0).length);
            this.$EventBus.$emit('refresh-websources');
        },
        async removeWebSource(id) {
            const value = await this.$bvModal.msgBoxConfirm(this.$t('Are you sure ?'), {
                okTitle: this.$t('yes'),
                cancelTitle: this.$t('no'),
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true,
                buttonSize: 'sm',
            });
            if (value) {
                const settings = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        Method: 'websources.delete',
                        Token: this.$store.state.auth.user.accessToken,
                        Parameters: {
                            Id: this.currentWebSource.Id,
                        },
                    }),
                };
                const res = await fetch(this.$store.state.settings.API_URL, settings);
                const data = await res.json();

                if (data.success == 'n') this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(data.ret, data.retParams), 'warning'));
                else {
                    this.currentWebSource = {
                        Id: null,
                        Alias: null,
                        Url: null,
                    };
                    await this.refresh('WebSourceTabPanel');
                    this.$EventBus.$emit('refresh-websources');
                }
            }
        },
    },
};
</script>
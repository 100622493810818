<template>
    <b-row>
        <b-col cols="4">
            <b-list-group>
                <div class="text-center pb-3">
                    <font-awesome-icon icon="plus" class="cursor-pointer" v-on:click="createNewGroup" v-bind:alt="$t('Add group')" v-bind:title="$t('Add group')" />
                    <font-awesome-icon icon="check" v-bind:class="{ disabled: currentGroup.IsProtected && minLevelRole > $RoleLevels.SuperAdmin }" class="cursor-pointer ml-3" v-if="!saving && this.currentGroup.Id != null" v-on:click="saveGroup" v-bind:alt="$t('Save')" v-bind:title="$t('Save')" />
                    <font-awesome-icon icon="sync" class="cursor-pointer ml-3" spin v-if="saving" />
                    <font-awesome-icon icon="minus" v-bind:class="{ disabled: currentGroup.IsProtected }" class="cursor-pointer ml-3" v-if="currentGroup && currentGroup.Id != null && currentGroup.Id != 0" v-on:click="removeGroup" v-bind:alt="$t('Remove')" v-bind:title="$t('Remove')" />
                </div>
                <b-list-group-item v-for="group in groups" :key="'group' + group.Id" :active="currentGroup && group.Id == currentGroup.Id" v-on:click="selectGroup(group.Id)" button v-bind:class="{ 'font-italic': group.Id == 0 }">{{ groupLabel(group) }}</b-list-group-item>
            </b-list-group>
        </b-col>
        <b-col cols="8">
            <b-card no-body v-if="currentGroup.Id != null" class="p-3">
                <b-form>
                    <b-row>
                        <b-col>
                            <b-form-group :label="$t('Name') + ' *'" label-for="currentGroup_Name">
                                <b-form-input :maxlength="$store.state.settings.INPUT_NAME_MAX_LENGTH" :readonly="currentGroup.IsProtected && minLevelRole > $RoleLevels.SuperAdmin" class="sm" ref="currentGroup_Name" id="currentGroup_Name" v-model="currentGroup.Name" required></b-form-input>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <b-form-group :label="$t('Roles')" label-for="currentGroup_Roles">
                                <DxTagBox :disabled="currentGroup.IsProtected && minLevelRole > $RoleLevels.SuperAdmin" id="currentGroup_Roles" :max-displayed-tags="8" :items="allRoles" v-model="rolesInGroup[currentGroup.Id]" display-expr="Name" value-expr="Id"></DxTagBox>
                            </b-form-group>
                        </b-col>
                        <b-col>
                            <b-form-group :label="$t('Users')" label-for="currentGroup_Users">
                                <DxTagBox :disabled="currentGroup.IsProtected && minLevelRole > $RoleLevels.SuperAdmin" id="currentGroup_Users" :max-displayed-tags="5" :items="allUsers" v-model="usersInGroup[currentGroup.Id]" display-expr="Email" value-expr="Id"></DxTagBox>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </b-form>
                <FormErrors :propFormErrors="formErrors" />
            </b-card>
        </b-col>
    </b-row>
</template>
<script>
import GroupService from '@/services/group.service.js';
import UserService from '@/services/user.service.js';
import RoleService from '@/services/role.service.js';
import DxTagBox from 'devextreme-vue/tag-box';
import ToastAlert from '@/utils/ToastAlert';
import FormErrors from '@/components/FormErrors.vue';

export default {
    name: 'GroupTabPanel',
    components: {
        DxTagBox,
        ToastAlert,
        FormErrors,
    },
    data() {
        return {
            saving: false,
            groups: [],
            allUsers: [],
            allRoles: [],
            currentGroup: {
                Id: null,
                Name: null,
                IsProtected: false,
            },
            usersInGroup: {},
            rolesInGroup: {},
            formErrors: [],
        };
    },
    computed: {
        minLevelRole() {
            return this.$store.state.auth.user == null ? 999 : this.$store.state.auth.user.minLevelRole;
        },
    },
    async mounted() {
        await this.refresh('GroupTabPanel');
        this.$EventBus.$on('refresh-admin-panel-tab', this.refresh);
    },
    methods: {
        checkFormValidity() {
            this.formErrors = [];
            if (!this.currentGroup.Name) this.formErrors.push(this.$t('Check required fields'));
            return this.formErrors.length == 0;
        },
        async refresh(tabRef) {
            if (tabRef == 'GroupTabPanel') {
                this.formErrors = [];
                this.currentGroup = {
                    Id: null,
                    Name: null,
                };
                await this.getGroups();
                await this.getUsers();
                await this.getRoles();
            }
        },
        groupLabel(group) {
            if (group.Id != 0 && group.Name != '' && group.Name != null) return group.Name;
            else if (group.Id == 0) return this.$t('New group');
            else return this.$t('Please enter name');
        },
        async createNewGroup() {
            if (this.groups.findIndex((group) => group.Id == 0) == -1) {
                this.formErrors = [];
                this.groups.unshift({
                    Id: 0,
                    Name: this.$t('New group'),
                    IsProtected: false,
                });
                await this.selectGroup(0);
                await this.$refs.currentGroup_Name.focus();
                this.$refs.currentGroup_Name.select();
            }
        },
        async getGroups() {
            const data = await GroupService.getAllGroups();
            if (data.success == 'n') this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(data.ret, data.retParams), 'warning'));
            else this.groups = data.ret;
            this.$emit('group-count', Array.isArray(this.groups) ? this.groups.filter((group) => group.Id != 0).length : 0);
        },
        async getUsers() {
            const data = await UserService.getAllUsers();
            if (data.success == 'n') this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(data.ret, data.retParams), 'warning'));
            else this.allUsers = data.ret;
        },
        async getRoles() {
            const data = await RoleService.getAllRoles();
            if (data.success == 'n') this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(data.ret, data.retParams), 'warning'));
            else this.allRoles = data.ret;
        },
        async selectGroup(id) {
            this.formErrors = [];
            this.currentGroup = this.groups.find((group) => group.Id == id);
            if (id != 0) {
                await this.getUsersInGroup();
                await this.getRolesInGroup();
            }
        },
        async getUsersInGroup() {
            const data = await GroupService.getUsersInGroup(this.currentGroup.Id);
            if (data.success == 'n') this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(data.ret, data.retParams), 'warning'));
            else this.$set(this.usersInGroup, this.currentGroup.Id, data.ret ? data.ret.map((user) => user.Id) : []);
        },
        async getRolesInGroup() {
            const data = await GroupService.getRolesInGroup(this.currentGroup.Id);
            if (data.success == 'n') this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(data.ret, data.retParams), 'warning'));
            else this.$set(this.rolesInGroup, this.currentGroup.Id, data.ret ? data.ret.map((role) => role.Id) : []);
        },
        async saveGroup() {
            if (!this.checkFormValidity()) return false;
            this.saving = true;
            let settings = null;
            if (this.currentGroup.Id == 0) {
                // New group
                settings = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        Method: 'groups.create',
                        Token: this.$store.state.auth.user.accessToken,
                        Parameters: {
                            Name: this.currentGroup.Name,
                            Users: this.usersInGroup[this.currentGroup.Id],
                            Roles: this.rolesInGroup[this.currentGroup.Id],
                        },
                    }),
                };
            } else {
                // Update group
                settings = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        Method: 'groups.update',
                        Token: this.$store.state.auth.user.accessToken,
                        Parameters: {
                            Id: this.currentGroup.Id,
                            Name: this.currentGroup.Name,
                            Users: this.usersInGroup[this.currentGroup.Id],
                            Roles: this.rolesInGroup[this.currentGroup.Id],
                        },
                    }),
                };
            }

            const res = await fetch(this.$store.state.settings.API_URL, settings);
            const data = await res.json();

            if (data.success == 'n') {
                this.saving = false;
                this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(data.ret, data.retParams), 'warning'));
            } else if (this.currentGroup.Id == 0) {
                this.usersInGroup[this.currentGroup.Id] = [];
                this.rolesInGroup[this.currentGroup.Id] = [];
                await this.refresh('GroupTabPanel');
            }
            this.saving = false;
            this.$emit('group-count', this.groups.filter((group) => group.Id != 0).length);
        },
        async removeGroup(id) {
            const value = await this.$bvModal.msgBoxConfirm(this.$t('Are you sure ?'), {
                okTitle: this.$t('yes'),
                cancelTitle: this.$t('no'),
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true,
                buttonSize: 'sm',
            });
            if (value) {
                const settings = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        Method: 'groups.delete',
                        Token: this.$store.state.auth.user.accessToken,
                        Parameters: {
                            Id: this.currentGroup.Id,
                        },
                    }),
                };
                const res = await fetch(this.$store.state.settings.API_URL, settings);
                const data = await res.json();

                if (data.success == 'n') this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(data.ret, data.retParams), 'warning'));
                else {
                    this.currentGroup = {
                        Id: null,
                        Name: null,
                    };
                    await this.refresh('GroupTabPanel');
                }
            }
        },
    },
};
</script>
<style scoped>
.dx-tagbox {
    border-radius: 0;
    border-top: none;
    border-left: none;
    border-right: none;
}
</style>
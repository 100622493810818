<template>
  <b-form-checkbox v-on:change="switchTheme" v-model="theme" value="dark" unchecked-value="light" switch>
    <font-awesome-icon icon="sun" v-if="theme == 'light'" />
    <font-awesome-icon icon="moon" v-if="theme == 'dark'" />
  </b-form-checkbox>
</template>
<script>
export default {
  name: "ThemeSwitcher",
  data() {
    return {
      theme: localStorage.getItem("theme")
        ? localStorage.getItem("theme")
        : "dark",
    };
  },
  async mounted(){
      this.switchTheme();
  },
  methods: {
    // Switch Css from dark to light mode
    // spécific css + devexpress + syncfusion
    async switchTheme() {
      let htmlElement = document.documentElement;
      var choosenTheme = this.theme;
      var hiddenTheme = this.theme == "dark" ? "light" : "dark";
      localStorage.setItem("theme", this.theme);
      
      // Save state to store
      this.$store.commit("setTheme", this.theme);

      htmlElement.setAttribute("theme", this.theme);
      document.getElementById(`SyncFusionDOMBase${hiddenTheme}Theme`).type = "text/css/off";
      document.getElementById(`SyncFusionDOMLayouts${hiddenTheme}Theme`).type = "text/css/off";
      document.getElementById(`DevExpressDOM${hiddenTheme}Theme`).type = "text/css/off";
      document.getElementById(`DevExpressACORE${hiddenTheme}Theme`).type = "text/css/off";
      document.getElementById(`SyncFusionDOMBase${choosenTheme}Theme`).type = "text/css";
      document.getElementById(`SyncFusionDOMLayouts${choosenTheme}Theme`).type = "text/css";
      document.getElementById(`DevExpressDOM${choosenTheme}Theme`).type = "text/css";
      document.getElementById(`DevExpressACORE${choosenTheme}Theme`).type = "text/css";
    }
  }
};
</script>
<template>
    <b-tabs pills card h-100 d-flex flex-column style="width: 100%">
        <!-- Connection configuration -->
        <b-tab>
            <template #title>
                <span class="tabHeaderText">
                    {{ $t('Connection') }}
                    <font-awesome-icon icon="fa fa-warning" class="ml-1 text-danger" v-if="addressState == false" />
                </span>
            </template>
            <div>
                <b-form>
                    <b-row>
                        <b-col>
                            <b-form-group :label="$t('Address') + ' *'" label-for="address">
                                <b-form-input type="text" :state="addressState" v-model="address" :class="{ disabled: !this.$store.state.auth.user.permissions.PermissionWrite }" />
                            </b-form-group>
                        </b-col>
                        <b-col>
                            <b-form-group :label="$t('Port') + ' *'" label-for="port">
                                <b-form-input
                                    type="number"
                                    min="1"
                                    max="65535"
                                    @change="
                                        (value) => {
                                            if (value == null || value == '') {
                                                this.port = 6000;
                                            } else if (value > 65535) {
                                                this.port = 65535;
                                            } else if (value < 1) {
                                                this.port = 1;
                                            }
                                        }
                                    "
                                    v-model="port"
                                    :class="{ disabled: !this.$store.state.auth.user.permissions.PermissionWrite }"
                                />
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <b-form-group :label="$t('Event Frequency') + ' (s) *'" label-for="frequencyEvents">
                                <b-form-input
                                    type="number"
                                    min="1"
                                    max="3600"
                                    @change="
                                        (value) => {
                                            if (value == null || value == '') {
                                                this.frequencyEvents = 20;
                                            } else if (value > 3600) {
                                                this.frequencyEvents = 3600;
                                            } else if (value < 1) {
                                                this.frequencyEvents = 1;
                                            }
                                        }
                                    "
                                    v-model="frequencyEvents"
                                    :class="{ disabled: !this.$store.state.auth.user.permissions.PermissionWrite }"
                                />
                            </b-form-group>
                        </b-col>
                        <b-col>
                            <b-form-group :label="$t('Timeout') + ' (ms) *'" label-for="timeout">
                                <b-form-input
                                    type="number"
                                    min="1"
                                    max="120000"
                                    @change="
                                        (value) => {
                                            if (value == null || value == '') {
                                                this.timeout = 1000;
                                            } else if (value > 120000) {
                                                this.timeout = 120000;
                                            } else if (value < 1) {
                                                this.timeout = 1;
                                            }
                                        }
                                    "
                                    v-model="timeout"
                                    :class="{ disabled: !this.$store.state.auth.user.permissions.PermissionWrite }"
                                />
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="6">
                            <b-button @click="onCheckConnection">
                                {{ $t('equipment.opcua.checkConnection') }}
                                <font-awesome-icon v-if="loadingConnectionCheck" icon="fas fa-spinner-third" class="fa-lg fa-spin-custom" :class="{ disabled: !this.$store.state.auth.user.permissions.PermissionRead }" />
                            </b-button>
                        </b-col>
                    </b-row>
                </b-form>
            </div>
        </b-tab>
        <b-tab>
            <template #title>
                {{ $t('Variables') }}
                <b-badge pill variant="info">{{
                    groups
                        .map((gr) => gr.Items.length)
                        .reduce(function (a, b) {
                            return a + b;
                        }, 0)
                }}</b-badge>
            </template>
            <ItemConfiguration
                ref="itemConfiguration"
                :columns="itemColumns"
                :groups="groups"
                :OnItemBrowse="onItemBrowse"
                :separateGroupConfig="false"
                :groupConfiguration="groupConfiguration"
                :readonly="!this.$store.state.auth.user.permissions.PermissionWrite"
                :updateMode="updateMode"
                :RefreshVarCounter="refreshVariablesCounter"
            />
        </b-tab>
        <b-tab>
            <template #title>
                {{ $t('Events') }}
                <b-badge pill variant="info">{{ events.length }}</b-badge>
            </template>
            <b-form>
                <b-row>
                    <b-col cols="5">
                        <div class="text-center" v-if="this.$store.state.auth.user.permissions.PermissionWrite">
                            <font-awesome-icon v-if="!searchingForItems" icon="fas fa-redo" class="cursor-pointer" v-on:click="onEventBrowse" />
                            <font-awesome-icon v-if="searchingForItems" icon="fas fa-spinner-third" class="fa-spin-custom" />
                        </div>
                        <b-card class="card-items-scroll">
                            <TreeView id="data-treeview-items" :item="availableEvents" :multiSelect="false" :checked="true" />
                        </b-card>
                    </b-col>
                </b-row>
            </b-form>
        </b-tab>
    </b-tabs>
</template>

<script>
import ProdComEquipmentService from '@/services/prodcom.equipments.service.js';
import ItemConfiguration from '@/components/configuration/Equipments/ItemConfiguration.vue';
import ToastAlert from '@/utils/ToastAlert';
import TreeView from '@/components/TreeView.vue';
import { uuid } from 'vue-uuid';

export default {
    name: 'ConfigVijeoDesigner',
    components: {
        ItemConfiguration,
        TreeView,
    },
    props: {
        equipment: Object,
        onValidate: Function,
        updateMode: Boolean,
    },
    computed: {
        invalidState() {
            return this.addressState === false;
        },
        addressState() {
            return this.address == '' ? false : null;
        },
    },
    data() {
        return {
            loadingConnectionCheck: false,
            address: this.equipment?.Address ?? '127.0.0.1',
            port: this.equipment?.Port ?? 6000,
            frequencyEvents: this.equipment?.FrequencyEvents ?? 20,
            timeout: this.equipment?.Timeout ?? 1000,
            itemColumns: [
                { key: 'Name', dataField: 'Name', dataType: 'string', caption: this.$t('Name'), required: true },
                { key: 'Type', dataField: 'Type', dataType: 'string', caption: this.$t('Type'), required: true, options: ['Bool', 'Bit', 'String', 'Char', 'Real', 'DReal', 'Word', 'DWord', 'Int', 'DInt'] },
                { key: 'Address', dataField: 'Address', dataType: 'string', caption: this.$t('Address'), required: true },
            ],
            groups: this.equipment?.Groups ?? [{ id: uuid.v4(), Name: `${this.$t('Group')} 1`, Active: true, Items: [] }],
            groupConfiguration: { rows: [] },
            events: this.equipment?.Events ?? [],
            availableEvents: [],
            searchingForItems: false,
        };
    },
    async mounted() {
        this.availableEvents = [];
        for (const evt of this.events) {
            this.availableEvents.push({ id: evt, text: evt, checkStatus: this.events.findIndex((it) => it == evt) != -1, contextMenuSelected: false, selected: false });
        }
    },
    methods: {
        async saveEquipmentCommunication() {
            await this.$refs.itemConfiguration.saveEquipmentCommunication();
        },
        /**
         * Synchronizes the data changes from the wizard into the given equipment. This method should be called before updateing / saving / adding
         * the equipment in the parent control.
         * @public This method is public.
         */
        async synchronizeEquipment() {
            this.equipment.Groups = this.groups;
            this.equipment.Address = this.address;
            this.equipment.Port = this.port;
            this.equipment.FrequencyEvents = this.frequencyEvents;
            this.equipment.Timeout = this.timeout;
            this.equipment.Events = this.availableEvents.filter((it) => it.checkStatus).map((it) => it.text);
        },
        async refreshVariablesCounter(searchingForItems, variableCounter) {},
        /**
         * Called when browsing for items in configuration. Returns the list of available items
         */
        async onItemBrowse(e) {
            // TODO: search for Vijeo items
            try {
                const data = await ProdComEquipmentService.vijeoBrowseItems(this.validate());
                if (data.success == 'n') {
                    this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(data.ret, data.retParams), 'warning'));
                    return [];
                } else {
                    return data.ret;
                }
            } catch (error) {
                console.log(error);
                return [];
            }
        },
        /**
         * Checks if the given connection parameters result in a valid connection.
         */
        async onCheckConnection(e) {
            this.loadingConnectionCheck = true;
            try {
                const result = await ProdComEquipmentService.vijeoCheckConnection(this.validate());
                if (result.success == 'n') {
                    this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(result.ret, result.retParams), 'warning'));
                } else {
                    if (!result.ret.Connected) {
                        if (result.ConnectionFailedMessage !== '') {
                            this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(result.ret.ConnectionFailedMessage), 'warning'));
                        }
                    } else {
                        this.$EventBus.$emit('show-toast', new ToastAlert(this.$t('equipment.vijeo.connectionSuccess'), 'success', this.$t('ConnectionSuccess')));
                    }
                }
            } catch (error) {
                console.log(error);
            }
            this.loadingConnectionCheck = false;
        },
        async onEventBrowse(e) {
            try {
                this.searchingForItems = true;
                const data = await ProdComEquipmentService.vijeoBrowseEvents(this.validate());
                if (data.success == 'n') {
                    this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(data.ret, data.retParams), 'warning'));
                    this.availableEvents = [];
                } else {
                    this.availableEvents = [];
                    for (const evt of data.ret) {
                        this.availableEvents.push({ id: evt, text: evt, checkStatus: this.events.findIndex((it) => it == evt) != -1, contextMenuSelected: false, selected: false });
                    }
                }
                this.searchingForItems = false;
            } catch (error) {
                console.log(error);
                this.availableEvents = [];
                this.searchingForItems = false;
            }
        },
        validate() {
            if (this.invalidState) return null;
            return {
                Name: this.equipment.Name,
                Address: this.address,
                Port: this.port,
                FrequencyEvents: this.frequencyEvents,
                Timeout: this.timeout,
            };
        },
    },
};
</script>
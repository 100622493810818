var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-card',{class:{ disabled: _vm.Readonly },attrs:{"header":_vm.Title}},[_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('variables.details.normType')}},[_c('b-form-select',{class:{ disabled: _vm.Readonly },attrs:{"id":"normType","options":_vm.normOptions},model:{value:(_vm.Norm.SrcValCriteria),callback:function ($$v) {_vm.$set(_vm.Norm, "SrcValCriteria", $$v)},expression:"Norm.SrcValCriteria"}})],1)],1),(_vm.Norm.SrcValCriteria == 0)?_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Constant')}},[_c('b-form-input',{class:{ disabled: _vm.Readonly },attrs:{"id":"constant","type":"number","min":"-9999999999999999999999999999","max":"9999999999999999999999999999"},on:{"change":(value) => {
                            if (value == null || value == '') {
                                this.Norm.ConstantValue = 0;
                            } else if (value > 9999999999999999999999999999) {
                                this.Norm.ConstantValue = 9999999999999999999999999999;
                            } else if (value < -9999999999999999999999999999) {
                                this.Norm.ConstantValue = -9999999999999999999999999999;
                            }
                        }},model:{value:(_vm.Norm.ConstantValue),callback:function ($$v) {_vm.$set(_vm.Norm, "ConstantValue", $$v)},expression:"Norm.ConstantValue"}})],1)],1):_vm._e()],1),(_vm.Norm.SrcValCriteria != 0)?_c('b-row',[_c('b-col',[_c('b-form-group',{attrs:{"label":_vm.$t('Group')}},[_c('b-form-select',{class:{ disabled: _vm.Readonly },attrs:{"id":"group","state":_vm.VariableGroupState,"options":_vm.groupList},model:{value:(_vm.Norm.VariableGroup),callback:function ($$v) {_vm.$set(_vm.Norm, "VariableGroup", $$v)},expression:"Norm.VariableGroup"}})],1)],1),_c('b-col',[_c('b-form-group',{attrs:{"label":_vm.$t('Item')}},[_c('b-form-select',{class:{ disabled: _vm.Readonly },attrs:{"id":"var","state":_vm.VariableItemState,"options":_vm.variableList},model:{value:(_vm.Norm.VariableItem),callback:function ($$v) {_vm.$set(_vm.Norm, "VariableItem", $$v)},expression:"Norm.VariableItem"}})],1)],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
    <div>
        <div v-if="item.items != null" :style="style" v-on:click="clickItem" class="tree-view-item"
            @contextmenu.prevent="openMenu" v-bind:class="{ 'selected': item.contextMenuSelected || item.selected }">
            <font-awesome-icon v-if="!item.expanded" icon="fa fa-angle-right" style="margin-right: 5px;" />
            <font-awesome-icon v-if="item.expanded" icon="fa fa-angle-down" style="margin-right: 5px;" />
            <font-awesome-layers v-if="item.icon != null && item.subIcon != null" style="margin-right: 5px;">
                <font-awesome-icon :icon="item.icon" :color="item.iconColor" />
                <font-awesome-icon :icon="item.subIcon" class="fa-xs" transform="down-10.0 right-10.0"
                    :color="item.subIconColor" />
            </font-awesome-layers>
            <font-awesome-icon v-if="item.icon != null && item.subIcon == null" :icon="item.icon" style="margin-right: 5px;"
                :color="item.iconColor" />

            <b-form-input v-if="item.edit" class="d-inline pt-1" style="width: 160px; height: 20px; font-size: 13px"
                type="text" id="name" name="name" v-model="item.value" autofocus v-on:keyup="validateInput" />
            <span v-else>{{ item.text }} <font-awesome-icon v-if="item.toRefresh" :icon='"fa fa-arrows-rotate"'
                    style="margin-right: 5px;" color="#1ca8dd" /></span>
        </div>
        <div v-else :style="style" v-on:click="clickItem" class="tree-view-item"
            v-bind:class="{ 'selected': item.contextMenuSelected || item.selected }" @contextmenu.prevent="openMenu">
            <b-form-checkbox v-if="checked" v-model="item.checkStatus" v-on:change="itemCheckedSub" :disabled="disabled">
                <font-awesome-layers v-if="item.icon != null && item.subIcon != null" style="margin-right: 5px;">
                    <font-awesome-icon :icon="item.icon" :color="item.iconColor" />
                    <font-awesome-icon :icon="item.subIcon" class="fa-xs" transform="down-10.0 right-10.0"
                        :color="item.subIconColor" />
                </font-awesome-layers>
                <font-awesome-icon v-if="item.icon != null && item.subIcon == null" :icon="item.icon"
                    style="margin-right: 5px;" :color="item.iconColor" />

                <b-form-input v-if="item.edit" class="d-inline pt-1" style="width: 160px; height: 20px; font-size: 13px"
                    type="text" id="name" name="name" v-model="item.value" autofocus v-on:keyup="validateInput" />
                <span v-else>{{ item.text }}</span>
            </b-form-checkbox>
            <div v-else>
                <font-awesome-layers v-if="item.icon != null && item.subIcon != null" style="margin-right: 5px;">
                    <font-awesome-icon :icon="item.icon" :color="item.iconColor" />
                    <font-awesome-icon :icon="item.subIcon" class="fa-xs" transform="down-10.0 right-10.0"
                        :color="item.subIconColor" />
                </font-awesome-layers>
                <font-awesome-icon v-if="item.icon != null && item.subIcon == null" :icon="item.icon"
                    style="margin-right: 5px;" :color="item.iconColor" />

                <b-form-input v-if="item.edit" class="d-inline pt-1" style="width: 160px; height: 20px; font-size: 13px"
                    type="text" id="name" name="name" v-model="item.value" autofocus v-on:keyup="validateInput" />
                <span v-else>{{ item.text }}</span>
            </div>
        </div>
        <div v-if="item.expanded">
            <div v-for="dataItem in item.items" :key="dataItem.id">
                <TreeViewItem :item="dataItem" :rank="rank + 1" :parent="item" :itemSelect="clickSubItem"
                    :itemContextMenu="clickSubContextMenu" :itemEditValidated="subItemEditValidated" :checked="checked"
                    :itemChecked="subItemChecked" :toDisable="toDisable" />
            </div>
        </div>
    </div>
</template>

<script>
import ProdComEquipmentService from "@/services/prodcom.equipments.service.js";
import ToastAlert from '@/utils/ToastAlert';
export default {
    name: 'TreeViewItem',
    props: {
        itemSelect: Function,
        itemContextMenu: Function,
        itemEditValidated: Function,
        itemChecked: Function,
        item: Object,
        rank: Number,
        parent: Object,
        checked: Boolean,
        toDisable: Function,
    },
    data() {
        return {
            selected: false,
            checkStatus: false,
            disabled: false,
        }
    },
    async mounted() {
        this.item.parent = this.parent;
        if (this.toDisable != null) {
            this.disabled = await this.toDisable(this);
        } else {
            this.disabled = false;
        }
    },
    computed: {
        style() {
            return {
                paddingLeft: `${this.rank * 20}px`,
                // display: "block",
                // overflow: "hidden",
                // textOverflow: "ellipsis",
                // whiteSpace: "nowrap",
                // cursor: "pointer",
                // userSelect: "none",
                // fontSize: "13px",
                // fontFamily: "Segoe WPC,Segoe UI,sans-serif",
                // lineHeight: "22px",
            }
        },
        itemcontextMenuSelected() {
            return this.item.contextMenuSelected;
        }
    },
    watch: {
        item: {
            handler(val, oldval) {
                // console.log(val);
                // Doesn't work for updating value from parent ... :/
            },
            deep: true
        }
    },
    methods: {
        async clickItem(e) {
            if (!this.item.edit) {
                this.itemSelect(this.item, e);
                if (!e.ctrlKey) this.item.expanded = !this.item.expanded;
            }
        },
        async clickSubItem(item, e) {
            this.itemSelect(item, e);
        },
        async openMenu(e) {
            this.item.contextMenuSelected = true
            this.itemContextMenu(this.item);
            this.$forceUpdate();
        },
        async clickSubContextMenu(item, e) {
            this.itemContextMenu(item);
        },
        async validateInput(e) {
            // Enter pressed
            if (e.keyCode == 13) {
                if (this.item.edit) this.item.edit = false;
                // const oldId = this.item.id;
                // this.item.id += "1";
                // this.$nextTick(() => this.item.id = oldId);                
                if ( this.item.text != this.item.value && this.item.itemType == "Equipement"){
                    let result = await ProdComEquipmentService.setEquipementName(this.item.value,this.item.text,this.parent.parent.id)
                    if(result.success == "n") this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(result.ret), 'warning'));
                    else {
                        this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(result.ret), 'info'));
                        this.item.text = this.item.value;
                    }
                }
                
                if(this.item.displayCount) {
                    if(this.item.items != null) this.item.text += " (" + this.item.items.length + ")";
                    else this.item.text += " (0)";
                }
                if (this.itemEditValidated != null) this.itemEditValidated(this.item);
            }
        },
        async validateSubInput(item, e) {
            if (e.keyCode == 13) {
                if (this.item.edit) item.edit = false;
                item.id = item.id.slice(0, -1);
                this.item.text = this.item.value;
                if (this.item.displayCount) {
                    if (this.item.items != null) this.item.text += " (" + this.item.items.length + ")";
                    else this.item.text += " (0)";
                }
                if (this.itemEditValidated != null) this.itemEditValidated(item);
            }
        },
        async subItemEditValidated(item) {
            if (this.itemEditValidated != null) this.itemEditValidated(item);
        },
        async itemCheckedSub(e) {
            if (this.itemChecked != null) this.itemChecked(this.item);
        },
        async subItemChecked(item) {
            if (this.itemChecked != null) this.itemChecked(item)
        }
    }
}
</script>

<style lang="scss">
.tree-view-item {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
    user-select: none;
    font-size: 13px;
    font-family: Segoe WPC, Segoe UI, sans-serif;
    line-height: 22px;
}

.tree-view-item:hover {
    background: rgba($color: #555555, $alpha: 0.3);
}

.tree-view-item:active {
    background: #1ca8dd66;
}

.selected {
    border: 1px solid #007fd4;
    background: #1ca8dd66;
}
</style>
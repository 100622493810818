<template>
    <div>
        <b-tab>
            <template #title>{{$t('actions.statistics.title')}}</template>
            <b-card>
                <b-form>
                    <b-row>
                        <b-col cols="6">
                            <b-form-group :label="$t('actions.statistics.sourceGroup') + ' *'" label-for="sourceGroup">
                                <b-form-select id="sourceGroup" v-model="SourceGroup" :options="availableGroups" v-on:change="sourceGroupChanged" :class="{'disabled': !$store.state.auth.user.permissions.PermissionWrite}"/>
                            </b-form-group>
                        </b-col>
                        <b-col cols="6">
                            <b-form-group :label="$t('actions.statistics.sourceItem') + ' *'" label-for="sourceItem">
                                <b-form-select id="sourceItem" v-model="SourceItem" :options="availableSourceItems" :class="{'disabled': !$store.state.auth.user.permissions.PermissionWrite}"/>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <hr color="grey"/>
                    <b-row>
                        <b-col cols="6">
                            <b-form-group :label="$t('actions.statistics.activeEffectif')">
                                <b-form-select id="activeEffectif" v-model="ActiveEffectif" :options="availableEffectifs" :class="{'disabled': !$store.state.auth.user.permissions.PermissionWrite}"/>
                            </b-form-group>
                        </b-col>
                        <b-col cols="6" v-if="ActiveEffectif == 0">
                            <b-form-group :label="$t('actions.statistics.effectif')">
                                <b-form-input id="ConstantEffectifActif" v-model="ConstantEffectifActif" type="number" min="0" max="999999" :class="{'disabled': !$store.state.auth.user.permissions.PermissionWrite}"/>
                            </b-form-group>
                        </b-col>
                        <b-col cols="3" v-if="ActiveEffectif == 1">
                            <b-form-group :label="$t('Group') + ' *'" label-for="sourceGroup">
                                <b-form-select id="sourceGroup" v-model="ActiveGroup" :options="availableGroups" v-on:change="activeGroupChanged" :class="{'disabled': !$store.state.auth.user.permissions.PermissionWrite}"/>
                            </b-form-group>
                        </b-col>
                        <b-col cols="3" v-if="ActiveEffectif == 1">
                            <b-form-group :label="$t('Item') + ' *'" label-for="sourceItem">
                                <b-form-select id="sourceItem" v-model="ActiveItem" :options="availableActiveItems" :class="{'disabled': !$store.state.auth.user.permissions.PermissionWrite}"/>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <hr color="grey"/>
                    <b-row>
                        <b-col cols="6">
                            <b-form-group :label="$t('actions.statistics.inactiveEffectif')">
                                <b-form-select id="inactiveEffectif" v-model="InactiveEffectif" :options="availableEffectifs" :class="{'disabled': !$store.state.auth.user.permissions.PermissionWrite}"/>
                            </b-form-group>
                        </b-col>
                        <b-col cols="6" v-if="InactiveEffectif == 0">
                            <b-form-group :label="$t('actions.statistics.effectif')">
                                <b-form-input id="ConstantEffectifInactif" v-model="ConstantEffectifInactif" type="number" min="0" max="999999" :class="{'disabled': !$store.state.auth.user.permissions.PermissionWrite}"/>
                            </b-form-group>
                        </b-col>
                        <b-col cols="3" v-if="InactiveEffectif == 1">
                            <b-form-group :label="$t('Group') + ' *'" label-for="sourceGroup">
                                <b-form-select id="sourceGroup" v-model="InactiveGroup" :options="availableGroups" v-on:change="inactiveGroupChanged" :class="{'disabled': !$store.state.auth.user.permissions.PermissionWrite}"/>
                            </b-form-group>
                        </b-col>
                        <b-col cols="3" v-if="InactiveEffectif == 1">
                            <b-form-group :label="$t('Item') + ' *'" label-for="sourceItem">
                                <b-form-select id="sourceItem" v-model="InactiveItem" :options="availableInactiveItems" :class="{'disabled': !$store.state.auth.user.permissions.PermissionWrite}"/>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </b-form>
            </b-card>
        </b-tab>
        <b-tab>
            <template #title>{{$t('actions.statistics.title1')}}</template>
            <b-card>
                <b-form>
                    <b-row>
                        <b-col cols="2" style="margin-top: 35px;">
                            <b-form-checkbox v-model="Average" :class="{'disabled': !$store.state.auth.user.permissions.PermissionWrite}">{{$t('actions.statistics.average')}}</b-form-checkbox>
                        </b-col>
                        <b-col cols="3">
                            <b-form-group :label="$t('Group')" label-for="sourceGroup" :class="{'disabled': !Average}">
                                <b-form-select id="sourceGroup" v-model="AverageGroup" :options="availableGroups" v-on:change="averageGroupChanged" :class="{'disabled': !$store.state.auth.user.permissions.PermissionWrite}"/>
                            </b-form-group>
                        </b-col>
                        <b-col cols="3">
                            <b-form-group :label="$t('Item')" label-for="sourceItem" :class="{'disabled': !Average}">
                                <b-form-select id="sourceItem" v-model="AverageItem" :options="availableAverageItems" :class="{'disabled': !$store.state.auth.user.permissions.PermissionWrite}"/>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="2" style="margin-top: 35px;">
                            <b-form-checkbox v-model="StandardDeviation" :class="{'disabled': !$store.state.auth.user.permissions.PermissionWrite}">{{$t('actions.statistics.standardDeviation')}}</b-form-checkbox>
                        </b-col>
                        <b-col cols="3">
                            <b-form-group :label="$t('Group')" label-for="sourceGroup" :class="{'disabled': !StandardDeviation}">
                                <b-form-select id="sourceGroup" v-model="StandardDeviationGroup" :options="availableGroups" v-on:change="standardDeviationGroupChanged" :class="{'disabled': !$store.state.auth.user.permissions.PermissionWrite}"/>
                            </b-form-group>
                        </b-col>
                        <b-col cols="3">
                            <b-form-group :label="$t('Item')" label-for="sourceItem" :class="{'disabled': !StandardDeviation}">
                                <b-form-select id="sourceItem" v-model="StandardDeviationItem" :options="availableStandardDeviationItems" :class="{'disabled': !$store.state.auth.user.permissions.PermissionWrite}"/>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="2" style="margin-top: 35px;">
                            <b-form-checkbox v-model="Extent" :class="{'disabled': !$store.state.auth.user.permissions.PermissionWrite}">{{$t('actions.statistics.extent')}}</b-form-checkbox>
                        </b-col>
                        <b-col cols="3">
                            <b-form-group :label="$t('Group')" label-for="sourceGroup" :class="{'disabled': !Extent}">
                                <b-form-select id="sourceGroup" v-model="ExtentGroup" :options="availableGroups" v-on:change="extentGroupChanged" :class="{'disabled': !$store.state.auth.user.permissions.PermissionWrite}"/>
                            </b-form-group>
                        </b-col>
                        <b-col cols="3">
                            <b-form-group :label="$t('Item')" label-for="sourceItem" :class="{'disabled': !Extent}">
                                <b-form-select id="sourceItem" v-model="ExtentItem" :options="availableExtentItems" :class="{'disabled': !$store.state.auth.user.permissions.PermissionWrite}"/>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </b-form>
            </b-card>
        </b-tab>
    </div>
</template>

<script>
import ToastAlert from '@/utils/ToastAlert';
import ProdComEquipmentService from "@/services/prodcom.equipments.service.js";
export default {
    name: "Statistique",
    props:{
        /**
         * Action object from parent component. Contains the equipment concerned,
         * and details about the action if opening an existing action.
         */
        action: Object,
        /**
         * Current project's Guid
         */
        propProject: String,
    },
    data(){
        return {
            SourceGroup: '',
            SourceItem: '',
            ActiveGroup: '',
            ActiveItem: '',
            InactiveGroup: '',
            InactiveItem: '',
            AverageGroup: '',
            AverageItem: '',
            StandardDeviationGroup: '',
            StandardDeviationItem: '',
            ExtentGroup: '',
            ExtentItem: '',
            ActiveEffectif: 0,
            InactiveEffectif: 0,
            ConstantEffectifActif: 1,
            ConstantEffectifInactif: 1,
            Average: false,
            StandardDeviation: false,
            Extent: false,
            availableEffectifs: [
                {text: 'Constant', value: 0}, 
                {text: 'Dynamique', value: 1}],
            availableGroups: [],
            availableSourceItems: [],
            availableActiveItems: [],
            availableInactiveItems: [],
            availableAverageItems: [],
            availableStandardDeviationItems: [],
            availableExtentItems: [],
        }
    },
    async mounted(){
        // Get equipment's group list (only once for the full component, no need to query it for every combo box...)
        var data = await ProdComEquipmentService.getGroups(this.action.Equipment, this.propProject);
        if(data.success == "n") this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(data.ret), 'warning'));
        else {
            this.availableGroups = data.ret.List;
        }

        this.ActiveEffectif = this.action.ActionConfiguration?.ActiveEffectif == null ? this.availableEffectifs[0].value : this.action.ActionConfiguration?.ActiveEffectif;
        this.InactiveEffectif = this.action.ActionConfiguration?.InactiveEffectif == null ? this.availableEffectifs[0].value : this.action.ActionConfiguration?.InactiveEffectif;
        this.ConstantEffectifActif = this.action.ActionConfiguration?.ConstantEffectifActif == null ? 1 : this.action.ActionConfiguration?.ConstantEffectifActif;
        this.ConstantEffectifInactif = this.action.ActionConfiguration?.ConstantEffectifInactif == null ? 1 : this.action.ActionConfiguration?.ConstantEffectifInactif;
        this.Average = this.action.ActionConfiguration?.Average == null ? false : this.action.ActionConfiguration?.Average;
        this.StandardDeviation = this.action.ActionConfiguration?.StandardDeviation == null ? false : this.action.ActionConfiguration?.StandardDeviation;
        this.Extent = this.action.ActionConfiguration?.Extent == null ? false : this.action.ActionConfiguration?.Extent;

        this.SourceGroup = this.action.ActionConfiguration?.SourceGroup == null ? this.availableGroups[0] : this.action.ActionConfiguration?.SourceGroup;
        await this.sourceGroupChanged();
        this.SourceItem = this.action.ActionConfiguration?.SourceItem == null ? this.availableSourceItems?.[0] : this.action.ActionConfiguration?.SourceItem;

        this.ActiveGroup = this.action.ActionConfiguration?.ActiveGroup == null ? this.availableGroups[0] : this.action.ActionConfiguration?.ActiveGroup;
        await this.activeGroupChanged();
        this.ActiveItem = this.action.ActionConfiguration?.ActiveItem == null ? this.availableActiveItems?.[0] : this.action.ActionConfiguration?.ActiveItem;

        this.InactiveGroup = this.action.ActionConfiguration?.InactiveGroup == null ? this.availableGroups[0] : this.action.ActionConfiguration?.InactiveGroup;
        await this.inactiveGroupChanged();
        this.InactiveItem = this.action.ActionConfiguration?.InactiveItem == null ? this.availableInactiveItems?.[0] : this.action.ActionConfiguration?.InactiveItem;

        this.AverageGroup = this.action.ActionConfiguration?.AverageGroup == null ? this.availableGroups[0] : this.action.ActionConfiguration?.AverageGroup;
        await this.averageGroupChanged();
        this.AverageItem = this.action.ActionConfiguration?.AverageItem == null ? this.availableAverageItems?.[0] : this.action.ActionConfiguration?.AverageItem;

        this.StandardDeviationGroup = this.action.ActionConfiguration?.StandardDeviationGroup == null ? this.availableGroups[0] : this.action.ActionConfiguration?.StandardDeviationGroup;
        await this.standardDeviationGroupChanged();
        this.StandardDeviationItem = this.action.ActionConfiguration?.StandardDeviationItem == null ? this.availableStandardDeviationItems?.[0] : this.action.ActionConfiguration?.StandardDeviationItem;

        this.ExtentGroup = this.action.ActionConfiguration?.ExtentGroup == null ? this.availableGroups[0] : this.action.ActionConfiguration?.ExtentGroup;
        await this.extentGroupChanged();
        this.ExtentItem = this.action.ActionConfiguration?.ExtentItem == null ? this.availableExtentItems?.[0] : this.action.ActionConfiguration?.ExtentItem;
    },
    methods: {
        /**
         * Called by parent when validating the configuration.
         * Needs to return the current configuration of the action. 
         */
        async validate(){
            var result = {
                SourceGroup: this.SourceGroup,
                SourceItem: this.SourceItem,
                ActiveGroup: this.ActiveGroup,
                ActiveItem: this.ActiveItem,
                InactiveGroup: this.InactiveGroup,
                InactiveItem: this.InactiveItem,
                AverageGroup: this.AverageGroup,
                AverageItem: this.AverageItem,
                StandardDeviationGroup: this.StandardDeviationGroup,
                StandardDeviationItem: this.StandardDeviationItem,
                ExtentGroup: this.ExtentGroup,
                ExtentItem: this.ExtentItem,
                ActiveEffectif: this.ActiveEffectif,
                InactiveEffectif: this.InactiveEffectif,
                ConstantEffectifActif: this.ConstantEffectifActif,
                ConstantEffectifInactif: this.ConstantEffectifInactif,
                Average: this.Average,
                StandardDeviation: this.StandardDeviation,
                Extent: this.Extent,
            };
            return result;
        },
        /**
         * Updates source item list when source group changes
         */
        async sourceGroupChanged(e){
            if(this.SourceGroup == null || this.SourceGroup == '') return;
            const result = await ProdComEquipmentService.getItems(this.action.Equipment, this.SourceGroup, this.propProject);
            if(result.success == "n") this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(result.ret, result.retParams), 'warning'));
            else{
                this.availableSourceItems = [];
                for(const [key, val] of Object.entries(result.ret.List)){
                    this.availableSourceItems.push(val.Name);
                }
            }
        },
        /**
         * Updates active item list when active group changes
         */
        async activeGroupChanged(e){
            if(this.ActiveGroup == null || this.ActiveGroup == '') return;
            const result = await ProdComEquipmentService.getItems(this.action.Equipment, this.ActiveGroup, this.propProject);
            if(result.success == "n") this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(result.ret, result.retParams), 'warning'));
            else{
                this.availableActiveItems = [];
                for(const [key, val] of Object.entries(result.ret.List)){
                    this.availableActiveItems.push(val.Name);
                }
            }
        },
        /**
         * Updates inactive item list when inactive group changes
         */
        async inactiveGroupChanged(e){
            if(this.InactiveGroup == null || this.InactiveGroup == '') return;
            const result = await ProdComEquipmentService.getItems(this.action.Equipment, this.InactiveGroup, this.propProject);
            if(result.success == "n") this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(result.ret, result.retParams), 'warning'));
            else{
                this.availableInactiveItems = [];
                for(const [key, val] of Object.entries(result.ret.List)){
                    this.availableInactiveItems.push(val.Name);
                }
            }
        },
        /**
         * Updates average item list when average group changes
         */
        async averageGroupChanged(e){
            if(this.AverageGroup == null || this.AverageGroup == '') return;
            const result = await ProdComEquipmentService.getItems(this.action.Equipment, this.AverageGroup, this.propProject);
            if(result.success == "n") this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(result.ret, result.retParams), 'warning'));
            else{
                this.availableAverageItems = [];
                for(const [key, val] of Object.entries(result.ret.List)){
                    this.availableAverageItems.push(val.Name);
                }
            }
        },
        /**
         * Updates STD item list when STD group changes
         */
        async standardDeviationGroupChanged(e){
            if(this.StandardDeviationGroup == null || this.StandardDeviationGroup == '') return;
            const result = await ProdComEquipmentService.getItems(this.action.Equipment, this.StandardDeviationGroup, this.propProject);
            if(result.success == "n") this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(result.ret, result.retParams), 'warning'));
            else{
                this.availableStandardDeviationItems = [];
                for(const [key, val] of Object.entries(result.ret.List)){
                    this.availableStandardDeviationItems.push(val.Name);
                }
            }
        },
        /**
         * Updates extent item list when extent group changes
         */
        async extentGroupChanged(e){
            if(this.ExtentGroup == null || this.ExtentGroup == '') return;
            const result = await ProdComEquipmentService.getItems(this.action.Equipment, this.ExtentGroup, this.propProject);
            if(result.success == "n") this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(result.ret, result.retParams), 'warning'));
            else{
                this.availableExtentItems = [];
                for(const [key, val] of Object.entries(result.ret.List)){
                    this.availableExtentItems.push(val.Name);
                }
            }
        }
    }
}
</script>

<style>

</style>
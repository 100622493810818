import store from "@/store/index.js";

class LicenseService {

    async getCurrentLicense() {
        const settings = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                Method: 'license.getLicense',
                Token: store.state.auth.user.accessToken,
                Parameters: {
                },
            }),
        };

        const res = await fetch(store.state.settings.API_URL, settings);
        const data = await res.json();
        return data;
    }

    async getAvailableModes() {
        const settings = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                Method: 'license.modes',
                Token: store.state.auth.user.accessToken,
                Parameters: {
                },
            }),
        };

        const res = await fetch(store.state.settings.API_URL, settings);
        const data = await res.json();
        return data;
    }

    async getAvailableOptions() {
        const settings = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                Method: 'license.options',
                Token: store.state.auth.user.accessToken,
                Parameters: {
                },
            }),
        };

        const res = await fetch(store.state.settings.API_URL, settings);
        const data = await res.json();
        return data;
    }

    async askLicense(licenseInformations) {
        const settings = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                Method: 'license.createAskLicense',
                Token: store.state.auth.user.accessToken,
                Parameters: licenseInformations
            }),
        };

        const res = await fetch(store.state.settings.API_URL, settings);
        const data = await res.json();
        return data;
    }

    async uploadLicense(lic) {
        const settings = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                Method: 'license.upload',
                Token: store.state.auth.user.accessToken,
                Parameters: { License: lic }
            }),
        };

        const res = await fetch(store.state.settings.API_URL, settings);
        const data = await res.json();
        return data;
    }
}

export default new LicenseService();
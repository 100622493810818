var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-tabs',{class:{ disabled: _vm.loadingConnectionCheck },staticStyle:{"width":"100%"},attrs:{"pills":"","card":"","h-100":"","d-flex":"","flex-column":""}},[_c('b-tab',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('span',{staticClass:"tabHeaderText"},[_vm._v(" "+_vm._s(_vm.$t('Connection'))+" "),(_vm.ipAddressState == false)?_c('font-awesome-icon',{staticClass:"ml-1 text-danger",attrs:{"icon":"fa fa-warning"}}):_vm._e()],1)]},proxy:true}])},[_c('div',[_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('ipAddress') + ' *',"label-for":"ipAddress"}},[_c('b-form-input',{class:{ disabled: !this.$store.state.auth.user.permissions.PermissionWrite },attrs:{"id":"ipAddress","state":_vm.ipAddressState},model:{value:(_vm.ipAddress),callback:function ($$v) {_vm.ipAddress=$$v},expression:"ipAddress"}})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Port') + ' *',"label-for":"port"}},[_c('b-form-input',{class:{ disabled: !this.$store.state.auth.user.permissions.PermissionWrite },attrs:{"id":"port","min":"1","max":"65535","type":"number"},on:{"change":(value) => {
                                    if (value == null || value == '') {
                                        this.port = 9600;
                                    } else if (value > 65535) {
                                        this.port = 65535;
                                    } else if (value < 1) {
                                        this.port = 1;
                                    }
                                }},model:{value:(_vm.port),callback:function ($$v) {_vm.port=$$v},expression:"port"}})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('equipment.omron.sourceNode') + ' *',"label-for":"sourceNode"}},[_c('b-form-input',{class:{ disabled: !this.$store.state.auth.user.permissions.PermissionWrite },attrs:{"id":"posourceNodert","min":"0","max":"255","type":"number"},on:{"change":(value) => {
                                    if (value == null || value == '') {
                                        this.sourceNode = 0;
                                    } else if (value > 255) {
                                        this.sourceNode = 255;
                                    } else if (value < 0) {
                                        this.sourceNode = 0;
                                    }
                                }},model:{value:(_vm.sourceNode),callback:function ($$v) {_vm.sourceNode=$$v},expression:"sourceNode"}})],1)],1),_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('equipment.omron.destinationNode') + ' *',"label-for":"destinatioNode"}},[_c('b-form-input',{class:{ disabled: !this.$store.state.auth.user.permissions.PermissionWrite },attrs:{"id":"destinatioNode","min":"0","max":"255","type":"number"},on:{"change":(value) => {
                                    if (value == null || value == '') {
                                        this.destinationNode = 0;
                                    } else if (value > 255) {
                                        this.destinationNode = 255;
                                    } else if (value < 0) {
                                        this.destinationNode = 0;
                                    }
                                }},model:{value:(_vm.destinationNode),callback:function ($$v) {_vm.destinationNode=$$v},expression:"destinationNode"}})],1)],1),_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('equipment.omron.retries') + ' *',"label-for":"retries"}},[_c('b-form-input',{class:{ disabled: !this.$store.state.auth.user.permissions.PermissionWrite },attrs:{"id":"retries","min":"0","max":"255","type":"number"},on:{"change":(value) => {
                                    if (value == null || value == '') {
                                        this.retries = 2;
                                    } else if (value > 255) {
                                        this.retries = 255;
                                    } else if (value < 0) {
                                        this.retries = 0;
                                    }
                                }},model:{value:(_vm.retries),callback:function ($$v) {_vm.retries=$$v},expression:"retries"}})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Refresh rate') + ' * (ms)',"label-for":"pollingPeriod"}},[_c('b-form-input',{class:{ disabled: !this.$store.state.auth.user.permissions.PermissionWrite },attrs:{"id":"pollingPeriod","min":"100","max":"120000","type":"number"},on:{"change":(value) => {
                                    if (value == null || value == '') {
                                        this.pollingPeriod = 500;
                                    } else if (value > 120000) {
                                        this.pollingPeriod = 120000;
                                    } else if (value < 100) {
                                        this.pollingPeriod = 100;
                                    }
                                }},model:{value:(_vm.pollingPeriod),callback:function ($$v) {_vm.pollingPeriod=$$v},expression:"pollingPeriod"}})],1)],1),_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Timeout') + ' * (ms)',"label-for":"timeout"}},[_c('b-form-input',{class:{ disabled: !this.$store.state.auth.user.permissions.PermissionWrite },attrs:{"id":"timeout","min":"100","max":"120000","type":"number"},on:{"change":(value) => {
                                    if (value == null || value == '') {
                                        this.timeout = 1000;
                                    } else if (value > 120000) {
                                        this.timeout = 120000;
                                    } else if (value < 100) {
                                        this.timeout = 100;
                                    }
                                }},model:{value:(_vm.timeout),callback:function ($$v) {_vm.timeout=$$v},expression:"timeout"}})],1)],1)],1)],1)]),_c('b-tab',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('Variables'))+" "),_c('b-badge',{attrs:{"pill":"","variant":"info"}},[_vm._v(_vm._s(_vm.groups .map((gr) => gr.Items.length) .reduce(function (a, b) { return a + b; }, 0)))])]},proxy:true}])},[_c('ItemConfiguration',{ref:"itemConfiguration",attrs:{"columns":_vm.itemColumns,"groups":_vm.groups,"separateGroupConfig":false,"groupConfiguration":_vm.groupConfiguration,"readonly":!this.$store.state.auth.user.permissions.PermissionWrite,"updateMode":_vm.updateMode,"RefreshVarCounter":_vm.refreshVariablesCounter,"onEditingStartProp":_vm.onEditingStart,"onContentReadyProp":_vm.onContentReady,"calculateCellDisplayValueProp":_vm.calculateCellDisplayValue}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
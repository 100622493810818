var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-tabs',{class:{ disabled: _vm.loadingConnectionCheck },staticStyle:{"width":"100%"},attrs:{"pills":"","card":"","h-100":"","d-flex":"","flex-column":""}},[_c('b-tab',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('span',{staticClass:"tabHeaderText"},[_vm._v(" "+_vm._s(_vm.$t('Connection'))+" "),(_vm.invalidState)?_c('font-awesome-icon',{staticClass:"ml-1 text-danger",attrs:{"icon":"fa fa-warning"}}):_vm._e()],1)]},proxy:true}])},[_c('div',[_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('equipement.MQTT.address') + ' *',"label-for":"address"}},[_c('b-form-input',{class:{ disabled: !this.$store.state.auth.user.permissions.PermissionWrite },attrs:{"id":"address","state":_vm.addressState},model:{value:(_vm.address),callback:function ($$v) {_vm.address=$$v},expression:"address"}})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('equipments.MQTT.port') + ' *',"label-for":"port"}},[_c('b-form-input',{class:{ disabled: !this.$store.state.auth.user.permissions.PermissionWrite },attrs:{"type":"number","min":"1","max":"65535","id":"port","state":_vm.portState},on:{"change":(value) => {
                                    if (value == null || value == '') {
                                        this.port = 1883;
                                    } else if (value > 65535) {
                                        this.port = 65535;
                                    } else if (value < 1) {
                                        this.port = 1;
                                    }
                                }},model:{value:(_vm.port),callback:function ($$v) {_vm.port=$$v},expression:"port"}})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-button',{class:{ disabled: !this.$store.state.auth.user.permissions.PermissionRead },on:{"click":_vm.onCheckConnection}},[_vm._v(" "+_vm._s(_vm.$t('equipment.opcua.checkConnection'))+" "),(_vm.loadingConnectionCheck)?_c('font-awesome-icon',{staticClass:"fa-lg fa-spin-custom",attrs:{"icon":"fas fa-spinner-third"}}):_vm._e()],1)],1)],1)],1)]),_c('b-tab',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('Variables'))+" "),_c('b-badge',{attrs:{"pill":"","variant":"info"}},[_vm._v(_vm._s(_vm.groups .map((gr) => gr.Items.length) .reduce(function (a, b) { return a + b; }, 0)))])]},proxy:true}])},[_c('ItemConfiguration',{ref:"itemConfiguration",attrs:{"columns":_vm.itemColumns,"groups":_vm.groups,"OnItemBrowse":_vm.onItemBrowse,"separateGroupConfig":true,"groupConfiguration":_vm.groupConfiguration,"readonly":!this.$store.state.auth.user.permissions.PermissionWrite,"updateMode":_vm.updateMode,"RefreshVarCounter":_vm.refreshVariablesCounter}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
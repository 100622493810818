// Returns the ISO week of the date.
Date.prototype.getWeekNumber = function () {
    var date = new Date(this.getTime());
    date.setHours(0, 0, 0, 0);
    // Thursday in current week decides the year.
    date.setDate(date.getDate() + 3 - (date.getDay() + 6) % 7);
    // January 4 is always in week 1.
    var week1 = new Date(date.getFullYear(), 0, 4);
    // Adjust to Thursday in week 1 and count number of weeks from date to week1.
    return 1 + Math.round(((date.getTime() - week1.getTime()) / 86400000
        - 3 + (week1.getDay() + 6) % 7) / 7);
}

// Returns first day of current week
Date.prototype.getStartOfCurrentWeek = function () {
    var date = new Date(this.getTime());
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);
    var diff = date.getDate() - date.getDay() + (date.getDay() === 0 ? -6 : 1);
    return new Date(date.setDate(diff));
}

// Returns last day of current week
Date.prototype.getEndOfCurrentWeek = function () {
    var date = new Date(this.getTime());
    date.setHours(23);
    date.setMinutes(59);
    date.setSeconds(59);
    date.setMilliseconds(999);
    var diff = date.getDate() - date.getDay() + (date.getDay() === 0 ? 0 : 7);
    return new Date(date.setDate(diff));
}

Date.prototype.getFirstDayOfCurrentMonth = function () {
    var date = new Date(this.getTime());
    return new Date(date.getFullYear(), date.getMonth(), 1);
}
Date.prototype.getLastDayOfCurrentMonth = function () {
    var date = new Date(this.getTime());
    return new Date(date.getFullYear(), date.getMonth() + 1, 0, 23, 59, 59, 0);
}
Date.prototype.getFirstDayOfCurrentQuarter = function () {
    var date = new Date(this.getTime());
    quarter = Math.floor(date.getMonth() / 3);
    return new Date(date.getFullYear(), quarter * 3, 1);
}
Date.prototype.getLastDayOfCurrentQuarter = function () {
    var date = new Date(this.getTime());
    quarter = Math.floor(date.getMonth() / 3);
    var startDate = new Date(date.getFullYear(), quarter * 3, 1);
    return new Date(startDate.getFullYear(), startDate.getMonth() + 3, 0, 23, 59, 59, 0);
}
Date.prototype.getFirstDayOfCurrentSemester = function () {
    var date = new Date(this.getTime());
    semester = Math.floor(date.getMonth() / 6);
    return new Date(date.getFullYear(), semester * 6, 1);
}
Date.prototype.getLastDayOfCurrentSemester = function () {
    var date = new Date(this.getTime());
    semester = Math.floor(date.getMonth() / 6);
    var startDate = new Date(date.getFullYear(), semester * 6, 1);
    return new Date(startDate.getFullYear(), startDate.getMonth() + 6, 0, 23, 59, 59, 0);
}
Date.prototype.getFirstDayOfCurrentYear = function () {
    var date = new Date(this.getTime());
    return new Date(date.getFullYear(), 0, 1);
}
Date.prototype.getLastDayOfCurrentYear = function () {
    var date = new Date(this.getTime());
    return new Date(date.getFullYear(), 11, 31, 23, 59, 59, 0);
}
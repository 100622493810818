<template>
    <b-container 
        fluid
        class="home_monitoring mb-1"
        v-bind:style="{
            height: 'calc(100% - 180px)!important',
        }"
    >
        <DxToolbar>
            <DxItem location="after" template="checkTemplate"/>
            <DxItem location="after" template="restoreTemplate"/>
            <DxItem location="after" template="helpTemplate"/>
            <template #restoreTemplate>
                <DxButton icon="menu" @click="onShowSettings"/>
            </template>
            <template #checkTemplate>
                <DxButton icon="check" @click="onCheck"/>
            </template>
            <template #helpTemplate>
                <DxButton icon="help" @click="displayHelp"/>
            </template>
        </DxToolbar>
        <div v-if="!$store.state.monitoringGraph.displaySeparated">
            <DxChart
                id="monitoringGraph"
                ref="monitoringGraph"
                :data-source="store">
                <DxSeries
                    v-for="serie in series"
                    :key="'serie_' + serie.serieName"
                    :name="serie.serieName"
                    :type="$store.state.monitoringGraph.type"
                    :argument-field="serie.argumentField"
                    :value-field="serie.valueField"
                />
                <DxAnimation
                    easing="linear"
                    :duration="500"
                    :max-point-count-supported="100"
                />
                <DxArgumentAxis argument-type="datetime">
                </DxArgumentAxis>
            </DxChart>
        </div>
        <div v-else>
            <!-- {{charts}} -->
            <DxChart
                v-for="chart in charts"
                :key="chart.id"
                :id="'monitoringGraph_' + chart.id"
                :ref="'monitoringGraph_' + chart.id" 
                :data-source="chart.store">
                <DxSeries
                    :name="chart.serieName"
                    :type="$store.state.monitoringGraph.type"
                    argument-field="HD"
                    :value-field="chart.valueField"
                />
                <DxAnimation
                    easing="easeOutCubic"
                    :duration="500"
                    :max-point-count-supported="100"
                />
                <DxArgumentAxis argument-type="datetime">
                </DxArgumentAxis>
            </DxChart>
        </div>
        <b-modal :dialog-class="'panel-settings-modal'" scrollable hide-backdrop hide-footer hide-header :visible="showSettings" @hide="onSettingsHide">
            <div v-if="loadingSettings">
                loading...
            </div>
            <b-row inline class="pb-2 mb-2 border-top pt-2" v-if="!loadingSettings">
                <b-col>
                    <b-form-group :label="$t('Auto refresh (s)')" label-for="projectNotes">
                        <b-row>
                            <b-col>
                                <input type="range" min="0" max="2" step="1" v-model="settings.refreshInterval" @input="refreshIntervalChange"> 
                            </b-col>
                            <b-col>
                                <div v-if="settings.refreshInterval == 0">
                                    1 sec
                                </div>
                                <div v-else-if="settings.refreshInterval == 1">
                                    10 sec
                                </div>
                                <div v-else-if="settings.refreshInterval == 2">
                                    1 min
                                </div>
                            </b-col>
                        </b-row>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row inline class="pb-2 mb-2 border-bottom pt-2" v-if="!loadingSettings">
                <b-col>
                    <b-form-checkbox v-model="settings.displaySeparated" name="check-button" @input="displaySeparatedChange" switch>
                        <div style="margin-top: 2px;">{{$t('Display separated graphs')}}</div>
                    </b-form-checkbox>
                </b-col>
            </b-row>
            <b-row inline class="border-bottom" v-if="!loadingSettings">
                <TreeView id="data-treeview" :item="equipments" :multiSelect="false" :checked="true" :onItemChecked="onItemCheck" />
            </b-row>
        </b-modal>
    </b-container>
</template>

<script>
import {
    DxChart,
    DxSeries,
    DxArgumentAxis,
    DxCommonSeriesSettings,
    DxExport,
    DxGrid,
    DxMargin,
    DxLegend,
    DxTitle,
    DxSubtitle,
    DxTooltip,
    DxAnimation
} from 'devextreme-vue/chart';
import DxToolbar, { DxItem } from 'devextreme-vue/toolbar';
import { DxButton } from 'devextreme-vue/button';
import CustomStore from 'devextreme/data/custom_store';
import DxSelectBox from 'devextreme-vue/select-box';
import ProdComDataSourceService from "@/services/prodcom.datasources.service.js"
import ProdComEquipmentService from "@/services/prodcom.equipments.service.js";
import ProdComProjectService from "@/services/prodcom.projects.service.js"
import TreeView from "@/components/TreeView.vue";
export default {
    name: "ProdComMonitoringGraph",
    components: {
        DxSelectBox,
        DxChart,
        DxSeries,
        DxArgumentAxis,
        DxCommonSeriesSettings,
        DxExport,
        DxGrid,
        DxMargin,
        DxLegend,
        DxTitle,
        DxSubtitle,
        DxTooltip,
        DxToolbar,
        DxItem,
        DxButton,
        TreeView,
        DxAnimation,
    },
    data(){
        return {
            showSettings: false,
            loadingSettings: false,
            settings: {
                autoRefresh: false,
                refreshInterval: 2,
                displaySeparated: false,
                SelectedVariables: []
            },
            equipments: null,
            dataSource: [],
            timerRefreshChart: null,
            store: null,
            series: [],
            charts: [],
        }
    },
    beforeDestroy(){
        clearInterval(this.timerRefreshChart);
    },
    async mounted(){
        this.settings.refreshInterval = this.$store.state.monitoringGraph.refreshInterval;
        this.settings.displaySeparated = this.$store.state.monitoringGraph.displaySeparated;
        await this.onCheck();
    },
    methods: {
        async onShowSettings(e){
            this.showSettings = true;
            this.loadingSettings = true;
            const fullTree = await ProdComProjectService.getFullTree(this.$store.state.monitoringGraph.selectedVariables);
            if(fullTree.success == "n") this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(fullTree.ret, fullTree.retParams), 'warning'));
            else this.equipments = fullTree.ret;
            this.loadingSettings = false;
            this.settings.refreshInterval = this.$store.state.monitoringGraph.refreshInterval;
            return;
        },
        async onSettingsHide(e){
            this.showSettings = false;
        },
        refreshIntervalChange() {
            this.$store.commit('changeMonitoringGraphRefreshInterval', this.settings.refreshInterval);
        },
        displaySeparatedChange() {
            this.$store.commit('changeMonitoringGraphdisplaySeparated', this.settings.displaySeparated);
        },
        async toDisable(e){
            // if item is not a variable
            if (e.rank < 3) return false;
            let req = await ProdComEquipmentService.getEquipmentVariables(e.parent.parent.text, e.parent.parent.parent.id);
            if (req.success != "y"){
                this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(req.ret, req.retParams), 'warning'));
                return true;
            }
            for (const variable of req.ret.List){
                if (variable.Name == e.item.text && variable.Group == e.parent.text){
                    if (!variable.Histo) e.item.checkStatus = false;
                    return !variable.Histo;
                }
            }
            return true;
        },
        async onItemCheck(e){
            if(e.checkStatus){
                // Maximum 6 variables
                if(this.$store.state.monitoringGraph.selectedVariables.length >= 6){
                    e.checkStatus = false;
                    return;
                }
                // Item added
                this.$store.commit('addVariableMonitorChart', {Id: e.parent.parent.parent.id, EquipmentName: e.parent.parent.obj, Group: e.parent.text, Variable: e.text})
            }else{
                // Item removed
                this.$store.commit('removeVariableMonitorChart', e)
            }
        },
        async onCheck(e){
            this.series = [];
            this.$store.state.monitoringGraph.refreshInterval = this.settings.refreshInterval;
            this.$store.state.monitoringGraph.displaySeparated = this.settings.displaySeparated;
            this.charts = [];
            const result = await ProdComEquipmentService.setMonitorVariation(this.$store.state.monitoringGraph.selectedVariables);
            for(const variable of this.$store.state.monitoringGraph.selectedVariables){
                if(this.$store.state.monitoringGraph.displaySeparated){
                    this.charts.push({
                        id: `${variable.Id}_${variable.EquipmentName}_${variable.Group}_${variable.Variable}`,
                        serieName: variable.Variable,
                        store: new CustomStore({
                            load: async () => {
                                const result = await ProdComDataSourceService.getMonitoringTableValues([variable]);
                                if(result.success="y")
                                {
                                    return result.ret[0].Data;
                                }
                                return [];
                            }
                        }),
                        valueField: `${variable.Id}_${variable.EquipmentName}_${variable.Group}_${variable.Variable}`
                    })
                }else{
                    this.series.push({
                        serieName: variable.Variable,
                        argumentField: "HD",
                        valueField: `${variable.Id}_${variable.EquipmentName}_${variable.Group}_${variable.Variable}`
                    })
                }
            }
            
            if(!this.$store.state.monitoringGraph.displaySeparated){
                this.store = new CustomStore({
                    load: async () => {
                        const result = await ProdComDataSourceService.getMonitoringTableValues(this.$store.state.monitoringGraph.selectedVariables);
                        if(result.success="y")
                        {
                            var series = [];
                            for(const serieData of result.ret){
                                series = series.concat(serieData.Data);
                            }
                            return series;
                        }
                        return [];
                    }
                })
            }

            clearInterval(this.timerRefreshChart);

            this.timerRefreshChart = setInterval(async () => {
                if(this.$store.state.monitoringGraph.displaySeparated){
                    for(const variable of this.$store.state.monitoringGraph.selectedVariables){
                        this.$refs[`monitoringGraph_${variable.Id}_${variable.EquipmentName}_${variable.Group}_${variable.Variable}`][0].instance.getDataSource().reload();
                    }
                }else{
                    this.monitoringGraph.getDataSource().reload();
                }
            }, this.intervalRefresh);
        },
        async displayHelp(){
            this.$store.commit("displayHelp", this.$t('help.history.monitorGraph'))
            this.$bvModal.show("bv-modal-help");
        }
    },
    computed: {
        monitoringGraph(){
            return this.$refs.monitoringGraph?.instance;
        },
        intervalRefresh(){
            switch(this.$store.state.monitoringGraph.refreshInterval){
                case '0':
                    // 1 second
                    return 1000;
                case '1':
                    // 10 seconds
                    return 10000;
                case '2':
                    // 1 minute
                    return 60000;
                default:
                    // Default to 1 second
                    return 1000;
            }
        }
    }
}
</script>

<style>
.panel-settings-modal {
    width: 360px;
    max-width: 360px;
    min-width: 360px;
    font-size: 0.78em;
    padding-top: 35px;
    bottom: 2px;
    min-height: calc(100vh - 56px);
    float: right !important;
}
.graph-fixed {
    height: calc(100% - 120px)!important;
    max-height: calc(100% - 120px)!important
}
</style>